import React from 'react';
import { connect } from 'react-redux';
import ScrollManager from "ScrollManager/index.jsx";
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import LazyLoad, { forceCheck } from 'react-lazyload';
import ProductWidget from './ProductWidget';
import HeaderCart from 'components/HeaderCart';
import MobileCart from '_Mobile/scenes/MobileCart';
import CHECKOUT_IMG from '_Mobile/components/MobileHeaderCart/checkout.png';
import { fetchCheckout } from 'actions/fetchCheckout';
import { withRouter } from 'withRouter';
import CHECKOUT_BORDER from "./checkOut.png";
import SHOP_LOGO from "./shop.png";

function IframeFeastonBoosterPack(props) {
  const { fetchBoosterPackReducer, fetchCheckoutReducer } = props;
  const { products } = fetchBoosterPackReducer;
  const { checkout } = fetchCheckoutReducer;

  const openCheckout = () => {
    window.open(props.fetchCheckoutReducer.checkout.webUrl);
  }

  if (!products) {
    return null;
  }
  return (
    <div className="IframeFeastonBoosterPack">
      <Helmet>
        <title>FEASTON</title>
        {/* <meta name="description" content="FEASTON"/> */}
        <meta property="og:image" content="https://feaston.co/favicon.ico" />
        <meta property="og:title" content="FEASTON"/>
        <link rel="canonical" href="https://feaston.co" />
      </Helmet>
      <style jsx>{`
        .IframeFeastonBoosterPack::-webkit-scrollbar { 
          display: none;  /* Safari and Chrome */
        }
        .App {
          min-width: unset;
          max-width: 500px;
          position: relative;
          margin: auto;
        }
        .Cart {
          height: unset !important;
          background: white;
        }
        .Cart .terms {
          padding-bottom: 0;
          margin-bottom: 20px;
        }
        .IframeFeastonBoosterPack .iframeStickBottom {
          //position: absolute;
          //bottom: 0;
          //left: 0;
          //right: 27px;
          padding-right: 27px;
          //margin-top: 10px;
          //border: 1px solid black;
        }
        .IframeFeastonBoosterPack .iframeStickBottom .Cart {
          padding-top: 10px;
        } 
        .IframeFeastonBoosterPack .iframeStickBottom .checkoutBtn {
          width: 150px;
        }
        .IframeFeastonBoosterPack .iframeStickBottom .checkoutBtnContainer {
          margin: 0 20px 20px;
        }
        .IframeFeastonBoosterPack .productContainer {
          padding-right: 28px;
        }
        .Cart .CartLoading, .Cart .EmptyCart {
          display: none !important;
        }
        .Cart .CartLoading, .Cart .EmptyCart img {
          width: 50% !important;
          height: auto !important;
        }
        .Cart_empty + .checkoutBtnContainer {
          background: #818282;
          pointer-events: none;
        }
        .checkoutBtn {
          mix-blend-mode: plus-lighter;
        }
        .Cart .CartLineItems {
          padding: 15px !important;
        }
        .LineItem {
          max-width: unset !important;
        }
        .LineItem__quantity-update, .checkoutBtnContainer, .checkoutBtn, .terms {
          cursor: pointer;
        }
        .LineItem .LineItem__Content {
          flex-direction: row !important;
          justify-content: center !important;
        }
        .LineItem .LineItem__img {
          width: 110px !important;
          height: 150px !important;
        }
        .checkoutBtnContainer {
          background: black;
        }
        .IframeFeastonBoosterPack {
          position: relative;
          height: 100%;
          overflow-y: scroll;
          background: #fff;
          overflow-x: hidden;
        }
        .IframeFeastonBoosterPack .homeBanner {
          max-width: 100%;
        }
        .IframeFeastonBoosterPack .homeBanner img{
          width: 100%;
        }
        .IframeFeastonBoosterPack .homeBanner-container {
          overflow-y: scroll;
          overflow-x: hidden;
          height: fit-content;
        }
        .IframeFeastonBoosterPack .homeBanner {
          overflow: hidden;
        }
        .IframeFeastonBoosterPack .homeBanner-item-container {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 10px 30px;
          flex-direction: column;
        }

        .IframeFeastonBoosterPack .homeBanner-item-container .homeBanner-item-name {
          width: 100%;
          text-align: center;
          margin-top: 40px;
          font-size: 14px;
          font-weight: bold;
        }
        .IframeFeastonBoosterPack .feaston-home-footer {
          height: 100%;
          min-height: 100vh;
          display: flex;
          align-items: center;
          //padding: 0 30px;
        }
        .IframeFeastonBoosterPack .feaston-home-banner {
          height: calc(100vh - 40px);
          display: flex;
          align-items: center;
          justify-content: center;
          overflow: hidden;
          background: #FFCD06;
          margin-bottom: 100px;
          padding: 0px 40px;
        }
        .IframeFeastonBoosterPack .banner-container {
          margin-bottom: 170px;
        }
        .IframeFeastonBoosterPack .loading {
          display: none;
          width: 100%;
          height: 100vh;
          position: fixed;
          background: white;
          color: black;
          align-items: center;
          justify-content: center;
          z-index: 2;
        }
        * {
          cursor: default;
        }
        .IframeFeastonBoosterPack .homeBanner-item-container {
          padding: 10px 80px;
        }
        .IframeFeastonBoosterPack #shop-logo {
          background-image: url(${SHOP_LOGO});
          width: calc(100% - 68px);
          height: 80px;
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center;
          margin: 20px;
          margin-top: 40px;
        }
        .IframeFeastonBoosterPack #right-border {
          position: fixed;
          right: 0;
          top: 0;
          bottom: 0;
          background-image: url(${CHECKOUT_BORDER});
          width: 28px;
          background-repeat: repeat-y;
          background-size: contain;
          background-color: #818282;
        }
        .IframeFeastonBoosterPack #right-border.checkout {
          background-blend-mode: hard-light;
          background-color: black;
          cursor: pointer;
        }
        .IframeFeastonBoosterPack #right-border.disabled {
          pointer-events: none;
        }
        .IframeFeastonBoosterPack .ProductWidgetGeneral {
          padding: 0 25px 30px 25px;
        }
      `}</style>
      <div id="shop-logo"/>
      <div className="productContainer">
        {products.map((product, index) => {
          return (
            <ProductWidget key={product.handle} product={product} isIframe/>
          )}
        )}
      </div>
      <div className="iframeStickBottom">
        <MobileCart isIframe/>
        <div className="checkoutBtnContainer" onClick={openCheckout}>
          <img className="checkoutBtn" src={CHECKOUT_IMG} alt="" />
        </div>
      </div>
      <div id="right-border" className={`right-border${checkout?.lineItems.length > 0 ? ' checkout' : ' disabled'}`} onClick={openCheckout}/>
    </div>
  )
}

const mapStateToProps = state => ({
  ...state
})

const mapDispatchToProps = dispatch => ({
  fetchCheckout: (checkout) => dispatch(fetchCheckout(checkout)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(IframeFeastonBoosterPack));
