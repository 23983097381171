import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'withRouter';
import CartPopup from '../CartPopup';
import { fetchIsCartOpen } from '../../actions/fetchIsCartOpen';
import TagManager from 'react-gtm-module';
import { debounce } from "lodash";
import CART_IMG from 'assets/Navigation/cart.png';

class HeaderCart extends Component {

  constructor(props) {
    super(props);
    this.state = {
      cartPopupShown: false,
    }
  }

  componentDidUpdate(prevProps) {
    // check add-to-cart action:
    const prevCheckout = prevProps.fetchCheckoutReducer.checkout;
    const { products } = this.props.fetchProductsReducer;
    const { checkout } = this.props.fetchCheckoutReducer;
    if (checkout && prevCheckout && products && products.length > 0) {
      const { lineItems } = checkout;
      const prevLineItems = prevCheckout.lineItems;
      let cartProductQuantity = 0;
      let prevCartProductQuantity = 0;
      if (lineItems.length > 0) {
        for (let p of lineItems) {
          cartProductQuantity = cartProductQuantity + p.quantity;
        }
      }
      if (prevLineItems.length > 0) {
        for (let p of prevLineItems) {
          prevCartProductQuantity = prevCartProductQuantity + p.quantity;
        }
      }

      if (cartProductQuantity - prevCartProductQuantity > 0) { // add to cart Event:
        if (lineItems.length > 0) {
          for (let i of lineItems) {
            if (prevLineItems.find(p => p.id === i.id && i.quantity > p.quantity) || !prevLineItems.find(p => p.id === i.id)) {
              // debounce(function() { console.log(i, 1); }, 500)();
              const product = products.find((p) => i.variant && p.id === i.variant.product.id);
              if (product) {
                TagManager.dataLayer({
                  dataLayer: {
                    event: 'addToCart',
                    ecommerce: {
                      currencyCode: checkout.currencyCode,
                      add: {
                        products: [{
                          id: (product.id).split('/').pop(),
                          name: i.title,
                          price: i.variant.price?.amount,
                          brand: product.vendor,
                          category: product.productType,
                          variant: i.variant.title,
                          quantity: 1
                      }]
                    },
                    },
                    ProductAdded: {
                      id: (product.id).split('/').pop(),
                      handle: product.handle,
                      name: i.title,
                      price: i.variant.price?.amount,
                      brand: product.vendor,
                      category: product.productType,
                      variant: i.variant.title,
                      quantity: 1,
                      currencyCode: checkout.currencyCode,
                    },
                  },
                });
              }
            }
          }
        }
      } else { // remove from cart Event:
        if (prevLineItems.length > 0) {
          for (let i of prevLineItems) {
            if (lineItems.find(p => p.id === i.id && i.quantity < p.quantity) || !lineItems.find(p => p.id === i.id)) {
              // debounce(function() { console.log(i, 1); }, 500)();
              const product = products.find((p) => i.variant && p.id === i.variant.product.id);
              if (product) {
                TagManager.dataLayer({
                  dataLayer: {
                    event: 'removeFromCart',
                    ecommerce: {
                      currencyCode: checkout.currencyCode,
                      remove: {
                        products: [{
                          id: (product.id).split('/').pop(),
                          name: i.title,
                          price: i.variant.price?.amount,
                          brand: product.vendor,
                          category: product.productType,
                          variant: i.variant.title,
                          quantity: 1
                      }]
                    },
                    },
                    ProductRemoved: {
                      id: (product.id).split('/').pop(),
                      handle: product.handle,
                      name: i.title,
                      price: i.variant.price?.amount,
                      brand: product.vendor,
                      category: product.productType,
                      variant: i.variant.title,
                      quantity: 1,
                      currencyCode: checkout.currencyCode,
                    },
                  },
                });
              }
            }
          }
        }
      }
    }
  }

  render() {
    let cartProductQuantity = 0;

    if (this.props.fetchCheckoutReducer.checkout && this.props.fetchCheckoutReducer.checkout.lineItems) {
      if (this.props.fetchCheckoutReducer.checkout.lineItems.length > 0) {
        for (let p of this.props.fetchCheckoutReducer.checkout.lineItems) {
          cartProductQuantity = cartProductQuantity + p.quantity;
        }
      }
    }

    return (
      <div className={`HeaderCart${this.props.location.pathname === "/cart" ? " atCart" : ''}`}
        onClick={() => {
          if (this.props.location.pathname !== "/cart") {
            this.props.navigate(`/cart`);
          } 
          // this.setState({
          //   cartPopupShown: !this.state.cartPopupShown,
          // }, () => {
          //   this.props.fetchIsCartOpen(this.state.cartPopupShown);
          // })
        }
      }>
        <style jsx>{`
          .HeaderCart{
            width: 100px;
            padding: 0 40px;
          }
          .HeaderCart img {
            width: 100%;
          }
          .HeaderCart.atCart {
            position: fixed;
            right: 0;
            top: 0;
            width: 240px;
            padding: 20px 40px;
          }
          .HeaderCart {
            display: flex;
            align-items: center;
            cursor: pointer;
          }
          .HeaderCart .HeaderCartNumber {
            margin-left: 5px;
          }
          .HeaderCart .HeaderCartNumber.non-empty {
            background: black;
            border-radius: 50%;
            color: white;
            padding: 2px;
            width: 20px;
            height: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .HeaderCart .HeaderCartNumber.popupShown {
            background: white;
            color: black;
            align-items: unset;
          }
        `}</style>
        <img className="cart-img" src={CART_IMG} alt="" />
        {/* <div className={`HeaderCartNumber${cartProductQuantity > 0 ? " non-empty" : ""}${this.state.cartPopupShown ? " popupShown" : ""}`}>
          {this.state.cartPopupShown ? '^' : (cartProductQuantity > 0 ? cartProductQuantity : null)}
        </div> */}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  ...state
})

const mapDispatchToProps = dispatch => ({
  fetchIsCartOpen: (isOpen) => dispatch(fetchIsCartOpen(isOpen)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps) (HeaderCart));