import React, { Component } from 'react';

export default class MobileDescContainer extends Component {

  componentDidMount() {
    let element = window.document.getElementById("MobileDescContainer");
    element.scrollIntoView(true);
  }

  render() {
    const { children, page } = this.props;
    return (
      <div id="MobileDescContainer" className={`MobileDescContainer${page === "product" ? " MobileDescContainer_product-page" : ""}`}>
        <style jsx>{`
          .MobileDescContainer {
            position: fixed;
            top : 0;
            left: 0;
            height: 35%;
            overflow: scroll;
            z-index: 1000;
            font-size: 13px;
            display: flex;
            flex-wrap: nowrap;
            overflow-x: auto;
            width: 100%;
            //border-bottom: 1px solid lightgrey;
            box-shadow: 0px 10px 1px -10px lightgrey;
            background: white;
          }
          .MobileDescContainer.MobileDescContainer_product-page {
            font-weight: normal;
            font-size: unset;
            position: static;
          }
          .MobileDescContainer::-webkit-scrollbar { 
            display: none;  // Safari and Chrome
          }
        `}</style>
        { children }
      </div>
    );
  }
}
