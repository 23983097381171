import { isMobile } from 'react-device-detect';

export function isEmpty(obj) {
  return !obj || Object.keys(obj).length === 0;
}

export function getCoverImg(product, color) {
  let images = product ? product.images : [];
  let result = images.filter(image => !(image.altText && image.altText.includes(`#not_in_cover`)));
  if (isMobile) {
    result = result.filter(image => !(image.altText && image.altText.includes(`#not_in_mobile`)));
  } else {
    result = result.filter(image => !(image.altText && image.altText.includes(`#not_in_desktop`)));
  }
  for (let img of result) {
    if (img.altText && img.altText.includes(`#color_${color}`)) {
      return img;
    }
  }
  return result[0] || {};
}