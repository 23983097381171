import React, { PureComponent } from 'react';
import { Helmet } from "react-helmet";

export class SchemaOrg extends PureComponent { // p for product

    constructor(props) {
        super(props);
    }

    render() {
      const { p, url} = this.props;
      if (!p) {
        return null;
      }
      const getImages = (images) => {
        const items = [];
        if (images.length > 0) {
          items.push(images[0].src);
        }
        return items;
      };

      const getStockType = (availableForSale) => {
        if (availableForSale) {
            return "InStock";
        } else {
            return "OutOfStock";
        }
      };

      let item = {
          "@context": "https://schema.org",
          "@type": "Product",
          name: p.title,
          image: getImages(p.images),
          description: p.description,
          brand: {
              "@type": "Brand",
              name: "Feaston",
          },
          offers: {
              "@type": "Offer",
              availability: `http://schema.org/${getStockType(p.availableForSale)}`,
              price: p.variants[0].price?.amount,
              priceCurrency: "USD",
              url: `https://feaston.co${url}`,
          },
          sku: p.sn,
      };

      // if (p.rating_count > 0) {
      //     item.aggregateRating = {
      //         "@type": "AggregateRating",
      //         ratingValue: p.rating_avg || 0,
      //         bestRating: "5",
      //         worstRating: "0",
      //         ratingCount: p.rating_count,
      //     };
      // }

      /**
      * Extracted from 'react-schemaorg' and removed 'class ... extends React.Compoennt' for IE compatability
      * @param {*} _
      */
      // Utility: Assert never
      function isNever(_) { }
      const safeJsonLdReplacer = (() => {
          // Replace per https://www.w3.org/TR/json-ld11/#restrictions-for-contents-of-json-ld-script-elements
          // Solution from https://stackoverflow.com/a/5499821/864313
          const entities = Object.freeze({
              "&": "&amp;",
              "<": "&lt;",
              ">": "&gt;",
              '"': "&quot;",
              "'": "&apos;"
          });
          const replace = t => entities[t] || t;
          return (_, value) => {
              switch (typeof value) {
                  case "object":
                      return value; // JSON.stringify will recursively call replacer.
                  case "number":
                  case "boolean":
                  case "bigint":
                      return value; // These values are not risky.
                  case "string":
                      return value.replace(/[&<>'"]/g, replace);
                  default: {
                      // We shouldn't expect other types.
                      isNever(value);
                      // JSON.stringify will remove this element.
                      return undefined;
                  }
              }
          };
      })();

      return p ? (
        <Helmet>
          {/* <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(item, safeJsonLdReplacer)}} /> */}
          <script type="application/ld+json">{JSON.stringify(item)}</script>
        </Helmet>
      ) : null;
    }
}
