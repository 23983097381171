import ReactPlayer from 'react-player/youtube';
import ORANGE_PUMP from "assets/About/orangePump_tinified.png";
import COVER from "./reelofPerformance_tinified.png";
import PERFORMANCE_TITLE from "./performance_title.png";
import { isMobile } from "react-device-detect";

function About() {
  return (
    <div className={`web-page About${isMobile ? ' mobileAbout' : ''}`}>
      <style jsx>{`
        .About {
          width: 600px;
          display: flex;
          align-items: center:
          justify-contents: center;
          flex-direction: column;
          margin: auto;
        }
        .About .pump {
          overflow: hidden;
        }
        .About .pump img {
          transform: scale(1.02);
        }
        .About .intro {
          text-align: left;
          margin-bottom: 44px;
        }
        .About .intro p {
          text-align: left;
        }
        .About img {
          width: 100%;
          height: auto;
        }
        .About p {
          line-height: 1.25rem;
        }
        .About a {
          color: unset;
          text-decoration: underline; 
        }
        .About .performance-title {
          width: 400px;
          margin: 60px auto 40px;
        }
        .mobileAbout {
          width: unset;
        }
        .mobileAbout > div {
          margin-left: 20px;
          margin-right: 20px;
        }
        .mobileAbout .performance-title {
          width: auto;
        }
      `}</style>
      <div className="pump">
        <img src={ORANGE_PUMP} alt="about1" />
      </div>
      <div className="intro">
        <p>
        Feaston est. 2019 is a collective from Hong Kong. 
        They run a brand under the same name as an 
        intervention into pop culture and a critique 
        of contemporary society.< br />
        < br />
        They conduct expeditions into various scenes 
        including, but not limited to fashion, sport, food, 
        startup culture, and underground music.< br />
        < br />
        Deconstructing and juxtaposing anything that 
        comes their way, they produce merchandise 
        that offer new perspectives to the public.< br />
        < br />
        Their works and performances are exhibited 
        internationally on Instagram (<a href='https://www.instagram.com/feaston96/' target="_blank" rel="noreferrer">@feaston96</a>)
        </p>
      </div>
      <ReactPlayer
          url='https://www.youtube.com/watch?v=inPTGEjXrIg'
          className='react-player'
          playing
          width="100%"
          height={isMobile ? `${(338/600)*window.innerWidth}px` : '338px'}
          light={COVER}
          controls={true}
      />

      <div>
        <div className="performance-title">
          <img src={PERFORMANCE_TITLE} alt="performances_list" />
        </div>
        <p>
          17/5/2024<br />
          Nnscya’s Pie EP Release<br />
          <br />
          10/5/2024<br />
          軟對抗零A慢搖地溝油樂隊D23軍需特供<br />
          愚公移山老北京電音大重構九環音樂節<br />
          798塊打口青年香港大亨<br />
          w/ Zhao Xiyi, Nerve, Red<br />
          <br />
          30/12/2023<br />
          Winter Clouds: Serve The People<br />
          <br />
          22/11/2023<br />
          Interisland Festival<br />
          <br />
          9/12/2023<br />
          Subway Gathering w/ Nerve, Shojiki & friends<br />
          <br />
          7/10/2023<br />
          Haptic Collision #10 w/ Karen Yu, Mzm<br />
          <br />
          8/6/2023<br />
          Subway Choir w/ Nerve, Ken Ueno<br />
          <br />
          6/5/2023<br />
          Katz Mulk & Feaston @ Catalyst<br />
          <br />
          10/3/2023<br />
          SCM 25th Anniversary DJ FEST<br />
          <br />
          25/2/2023<br />
          Haptic Collision #8 @ Para-Site w/Jasper Fung<br />
          <br />
          <br />
        </p>
      </div>
    </div>
  )
}

export default About;