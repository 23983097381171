import { connect } from 'react-redux';
import TCG1 from './tcg1.png';
import TCG2 from './tcg2.png';
import TCG3 from './tcg3.png';
import TCG4 from './tcg4.png';
import TCG5 from './tcg5.png';
import TCG6 from './tcg6.png';
import TCG7 from './tcg7.png';
import TCG8 from './tcg8.png';
import { useEffect } from 'react';

const TcgIntro = (props) => {
  const { fetchIsNavigationExpandedReducer } = props;
  const imgs = [TCG1, TCG2, TCG3, TCG4, TCG5, TCG6, TCG7, TCG8];
  const { isExpanded } = fetchIsNavigationExpandedReducer;

  useEffect(() => {
    window.imageMapResize();
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  function handleScroll() {
    window.imageMapResize();
  }

  const scrollIntoViewWithOffsetY = (element, offset) => {
    element.scrollIntoView();
    window.scrollBy({
      top: -offset,
      behavior: 'smooth'
    });
  };

  const handleAnchorClick = e => {
    e.preventDefault();
    e.stopPropagation();
    const hash = e.currentTarget.hash;
    const element = document.getElementById(hash.replace('#', ''));
    scrollIntoViewWithOffsetY(element, 70);
  };

  return (
    <div className='TcgIntro' id="TcgIntro">
      <style jsx>{`
        .App {
          height: unset;
        }
        .TcgIntro {
          display: flex;
          justify-content: center;
          align-items: center;
          overflow: auto;
          padding-bottom: 80px;
        }
        .TcgIntro div {
          max-width: 500px;
        }
        .TcgIntro div img {
          width: 100%;
          height: auto;
        }
        .App_mobile .TcgIntro div {
          margin-top: ${isExpanded ? '220px' : '90px'};
          max-width: 100%;
        }
      `}</style>
      <div className="intro-imgs" id="intro-imgs">
        {imgs.map((img, index) => (
          <img id={`intro-${index + 1}`} src={img} alt={`Card ${index + 1}`} useMap={`#image-map_${index + 1}`}/>
        ))}
        <map name="image-map_1">
          <a href="#intro-2" onClick={handleAnchorClick}>
            <area target="" alt="" title="" href="" coords="66,1197,538,1270" shape="rect"></area>
          </a>
          <a href="#intro-3" onClick={handleAnchorClick}>
            <area target="" alt="" title="" href="" coords="67,1307,601,1381" shape="rect" />
          </a>
          <a href="#intro-4" onClick={handleAnchorClick}>
            <area target="" alt="" title="" href="" coords="67,1418,233,1497" shape="rect" />
          </a>
          <a href="#intro-5" onClick={handleAnchorClick}>
            <area target="" alt="" title="" href="" coords="67,1526,645,1612" shape="rect" />
          </a>
          <a href="#intro-7" onClick={handleAnchorClick}>
            <area target="" alt="" title="" href="" coords="67,1638,689,1727" shape="rect" />
          </a>
          <a href="#intro-8" onClick={handleAnchorClick}>
            <area target="" alt="" title="" href="" coords="67,1749,714,1838" shape="rect" />
          </a>
          <a href="#intro-9" onClick={handleAnchorClick}>
            <area target="" alt="" title="" href="" coords="66,1866,425,1974" shape="rect" />
          </a>
        </map>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  ...state
})

export default connect(mapStateToProps)(TcgIntro);