import React, { Component } from 'react';
import { connect } from 'react-redux';
import categories from "data/categories/";
import ScrollManager from "ScrollManager/index.jsx";
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { includes } from 'lodash';
import LazyLoad, { forceCheck } from 'react-lazyload';
import MobileHomeProductWidget from './MobileHomeProductWidget';
import IMG01 from "./assets/1.png";
import IMG02 from "./assets/2.png";
import IMG03 from "./assets/3.png";
import IMG04 from "./assets/4.png";
import IMG05 from "./assets/5.png";
import IMG06 from "./assets/6.png";
import IMG07 from "./assets/7.png";
import IMG08 from "./assets/8.png";
import IMG09 from "./assets/9.png";
import IMG10 from "./assets/10.png";
import IMG11 from "./assets/11.png";
import IMG12 from "./assets/12.png";
import IMG13 from "./assets/13.png";
import IMG14 from "./assets/14.png";
import IMG15 from "./assets/15.png";
import IMG16 from "./assets/16.png";
import PIE from "./pie_mobile.png";
import MobileFeatureCollection from '_Mobile/components/MobileFeatureCollection';

class MobileHome extends Component {

  constructor(props) {
    super(props);
    this.changeRoute = this.changeRoute.bind(this);
    this.state = {};
    this.getNameFromUrl = this.getNameFromUrl.bind(this);
  }

  changeRoute(category_url) {
    this.props.history.push({
      pathname: `/${category_url}`,
      state: { category: category_url }
      // state: { product: p } // data size too large to be passed through history.push, get data from redux store instead.
    });
  }

  componentDidMount() {
    window.imageMapResize();
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll(event) {
    window.imageMapResize();
  }

  getNameFromUrl(url) {
    const { fetchProductsReducer } = this.props;
    const { products } = fetchProductsReducer;
    const curr_category = categories[url];
    const curr_handle = curr_category.products[0];
    if (products && products.length > 0) {
      for (let product of products) {
        const { title } = product;
        const { handle } = product;
        if (handle === curr_handle) {
          return title;
        }
      }
    }
    return "";
  }

  getProductFromUrl(url) {
    const { fetchProductsReducer } = this.props;
    const { products } = fetchProductsReducer;
    const curr_category = categories[url];
    const curr_handle = curr_category.products[0];
    if (products && products.length > 0) {
      for (let product of products) {
        const { handle } = product;
        if (handle === curr_handle) {
          return product;
        }
      }
    }
    return null;
  }

  render() {
    const viewportWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    const mobileSlideHeight = (6667 * viewportWidth) / 3751;
    const mobileFooterHeight = ((1728 * viewportWidth) / 1898) / 2.8; 
    const mobileOriginalFooterHeight = (1898 * viewportWidth) / 1728;
    const containerHieght = mobileSlideHeight * 29 + 250;
    const offset = 690;

    const { fetchProductsReducer, fetchIsNavigationExpandedReducer } = this.props;
    const { isExpanded } = fetchIsNavigationExpandedReducer;
    const { products } = fetchProductsReducer;
    let clothing = ['clothing', 'tee', 'hoodie', 'sweater', 'shirt', 'suit', 'shoes'];
    const categoryProducts = products?.filter(p => includes(clothing, p.productType.toLowerCase())) || [];
  
    const { collection } = this.props;
    const banner = collection.metafields?.find(
      (metafield) => metafield?.key === "collection_banner_url_mobile"
    )?.value;
    const bannerTo = collection.metafields?.find(
      (metafield) => metafield?.key === "collection_banner_link_path"
    )?.value;

    return (
      // <ScrollManager scrollKey="MobileHome" >
      //   {({ connectScrollTarget, ...props }) => 
      <>
      <div className="MobileHome">
        <Helmet>
          <title>FEASTON</title>
          {/* <meta name="description" content="FEASTON"/> */}
          <meta property="og:image" content="https://feaston.co/favicon.ico" />
          <meta property="og:title" content="FEASTON"/>
          <link rel="canonical" href="https://feaston.co" />
        </Helmet>
        <style jsx>{`
          .MobileHome {
            position: relative;
            height: auto;
            overflow-y: scroll;
            background: #fff;
            overflow-x: hidden;
            padding: 50px 0 40px 0;
          }
          .MobileHome .lazyload-wrapper {
            //min-height: ${`${mobileSlideHeight}px`};
          }
          .MobileHome .homeBanner {
            max-width: 100%;
          }
          .MobileHome .coverBanner {
            max-width: 100%;
            width: auto;
          }
          .MobileHome .homeBanner img{
            width: 100%;
          }
          .MobileHome .last {
            margin-bottom: 120px;
          }
          .MobileHome .top {
            margin-top: 60px;
          }
          .MobileHome .homeBanner .banner, map area{
            outline: none;
          }
          .MobileHome .homeBanner-container {
            overflow-y: scroll;
            overflow-x: hidden;
            height: fit-content; //${`${containerHieght}px`}; //fit-content;
          }
          .MobileHome .homeBanner {
            overflow: hidden;
          }
          .MobileHome map {
            overflow: hidden;
          }
          .MobileHome .homeBanner-arrow-container {
            min-height: calc(100vh - 40px);
            display: flex;
            justify-content: center;
            align-items: center;
            min-height: -webkit-fill-available;
          }
          .MobileHome .homeBanner-arrow-container img {
            //height: 60vh;
            width: 100%;
          }
          .MobileHome .homeBanner-item-container {
            //height: 100%;
            //min-height: 100vh;
            //margin-bottom: 170px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 10px 30px;
            flex-direction: column;
          }
          .MobileHome .cover-wrapper {
            height: auto;
            min-height: calc(100vh - 40px);
          }
          .MobileHome .cover {
            //margin-top: 15px;
            height: calc(100vh - 40px);
            //padding: 0 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
          }
          .MobileHome .cover img {
            width: 100%;
            height: auto;
          }
          .MobileHome .schoolLogo {
            max-width: 100%;
            height: auto;
          }
          .MobileHome .bigHead {
            max-width: 150%;
            width: 110%;
            max-height: 100%;
            //height: 120%;
            margin: 20px 0 40px 0;
          }
          .MobileHome .homeBanner-item-container .homeBanner-item-name {
            width: 100%;
            text-align: center;
            margin-top: 40px;
            font-size: 14px;
            font-weight: bold;
          }
          .MobileHome .homeBanner-arrow {
            margin-top: 20px;
            height: 18vh;
          }
          .MobileHome .feaston-home-footer {
            height: 100%;
            min-height: 100vh;
            display: flex;
            align-items: center;
            //padding: 0 30px;
          }
          .MobileHome .feaston-home-footer img {
            max-width: 100%;
          }
          .MobileHome .feaston-home-banner {
            height: calc(100vh - 40px);
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;
            background: #FFCD06;
            margin-bottom: 100px;
            padding: 0px 40px;
          }
          .MobileHome .feaston-home-banner img {
            //padding-bottom: 60px;
          }
          .MobileHome .banner-container {
            margin-bottom: 170px;
          }
          .MobileHome .topBar {
            position: fixed;
            top: 0;
            font-weight: bold;
            font-size: 11px;
            height: 38px;
            background: #fff;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 1000;
          }
          .MobileHome .spacer {
            height: 50px;
          }
          .MobileHome .loading {
            display: none;
            width: 100%;
            height: 100vh;
            position: fixed;
            background: white;
            color: black;
            align-items: center;
            justify-content: center;
            z-index: 2;
          }
          * {
            cursor: default;
          }
          .MobileHome .homeBanner-item-container {
            padding: 50px 30px;
          }
        `}</style>
          {bannerTo ? (
            <Link to={bannerTo}>
              <div>
                <img style={{ width: '100%', height: 'auto' }} src={banner} alt="" />
              </div>
            </Link>) : (
              banner ? (
                <div>
                  <img style={{ width: '100%', height: 'auto' }} src={banner} alt="" />
                </div>
              ) : null
            )}
          <MobileFeatureCollection />
          {/* {categoryProducts.map((p, index) => {
              return (
                <MobileHomeProductWidget p={p} key={index}/>
              );
            }
          )} */}

          {/* <LazyLoad offset={offset} height={'calc(100vh - 40px)'} once >
            <div className="cover-wrapper">
              <div className="cover">
                <img className="" src={IMG01} useMap="#image-map_01" alt="" />
              </div>
            </div>
          </LazyLoad>

          <MobileHomeProductWidget url="faestonHood" />

          <img className="homeBanner product-temp" src={IMG02} alt="" />

          <MobileHomeProductWidget url="emperorHood" />

          <img className="homeBanner product-temp" src={IMG03} alt="" />

          <MobileHomeProductWidget url="portsmouth" />

          <img className="homeBanner product-temp" src={IMG04} alt="" />

          <MobileHomeProductWidget url="jazzHood" />

          <img className="homeBanner product-temp" src={IMG05} alt="" />

          <MobileHomeProductWidget url="emperorSweat" />

          <img className="homeBanner product-temp" src={IMG06} alt="" />

          <MobileHomeProductWidget url="singularityHood" />

          <img className="homeBanner product-temp" src={IMG07} alt="" />

          <MobileHomeProductWidget url="esPortrait" />

          <img className="homeBanner product-temp" src={IMG08} alt="" />

          <MobileHomeProductWidget url="P" />
          <MobileHomeProductWidget url="fsports" />

          <img className="homeBanner product-temp" src={IMG09} alt="" />

          <MobileHomeProductWidget url="liberia" />
          <MobileHomeProductWidget url="california" />

          <img className="homeBanner product-temp" src={IMG10} alt="" />

          <MobileHomeProductWidget url="bsSmile" />
          <MobileHomeProductWidget url="feelington" />

          <img className="homeBanner product-temp" src={IMG11} alt="" />

          <MobileHomeProductWidget url="healington" />
          <MobileHomeProductWidget url="dj_rothschild_tee" />

          <img className="homeBanner product-temp" src={IMG12} alt="" />

          <MobileHomeProductWidget url="examiners_tee" />
          <MobileHomeProductWidget url="opium_mug" />

          <img className="homeBanner product-temp" src={IMG13} alt="" />

          <MobileHomeProductWidget url="imported_cap" />
          <MobileHomeProductWidget url="opium_socks" />

          <img className="homeBanner product-temp" src={IMG14} alt="" />

          <MobileHomeProductWidget url="3107_hood" />
          <MobileHomeProductWidget url="opium_tee" />

          <img className="homeBanner product-temp" src={IMG15} alt="" />

          <MobileHomeProductWidget url="96km_h_tee" />
          <MobileHomeProductWidget url="scs_tee" />
          <MobileHomeProductWidget url="feaston_faeston_slipmat" />

          <img className="homeBanner product-temp" src={IMG16} alt="" /> */}

          
          
          
          
          {/* <LazyLoad offset={offset} height={mobileFooterHeight} once > */}
          {/* </LazyLoad> */}
      </div>
      </>
      // </ScrollManager>
    );
  }
}

const mapStateToProps = state => ({
  ...state
})

export default connect(mapStateToProps, {}) (MobileHome);
