import React, { Component } from 'react';
import { BrowserRouter as Router, Link } from 'react-router-dom';
import { withRouter } from 'withRouter';
import { withCookies } from 'react-cookie';
import HeaderCart from '../HeaderCart';
import VariantSelector from './VariantSelector';
import { connect } from 'react-redux';
import { fetchSelectedCurrency } from '../../actions/fetchSelectedCurrency';
import FEASTONLOGO from 'assets/Home/web_feastonLogo-small-removebg.png';
import NavigationOptions from './NavigationOptions';

class Navigation extends Component {

  constructor(props) {
    super(props);
    this.state = {
      expanded: true,
    };
    this.handleCurrencyChange = this.handleCurrencyChange.bind(this);
  }

  handleCurrencyChange(event) {
    this.props.cookies.set("selectedCurrency", event.target.value, { path: '/', expires: new Date(Date.now() + 31556952000) });
    this.props.fetchSelectedCurrency(event.target.value);
  }

  // componentDidMount() {
  //   if (this.props.cookies.get("selectedCurrency")) {
  //     this.props.fetchSelectedCurrency(this.props.cookies.get("selectedCurrency"));
  //   }
  // }

  // componentWillReceiveProps(nextProps) {
  //   if (nextProps.fetchCheckoutReducer.checkout && !this.props.cookies.get("selectedCurrency")) {
  //     const defaultCurrency = nextProps.fetchCheckoutReducer.checkout.currencyCode;
  //     this.props.cookies.set("selectedCurrency", defaultCurrency, { path: '/', expires: new Date(Date.now() + 31556952000) });
  //     this.props.fetchSelectedCurrency(defaultCurrency);
  //   }
  // }

  render() {
    const { location } = this.props;
    const { pathname } = location;

    const isIframe = pathname.includes("/feaston_booster_pack");
    const is_navigation_hidden = isIframe;

    if (isIframe) {
      return null;
    }

    return (
      <div className={`Navigation`} onClick={() => { this.setState({ expanded: !this.state.expanded }); }}>
        <style jsx>{`
          .Navigation {
            z-index: 1000;
            width: 100%;
            height: 80px;
            display: flex;
            justify-content: space-between;
            font-size: 16px;
            min-width: 580px;
            // max-width: 850px;
            background: -moz-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(top, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to bottom, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%);
          }
          .Navigation .options {
            display: block;
            overflow: unset;
            justify-content: space-between;
            width: 100%;
            position: fixed;
            top: 0;
            left: 0;
            overflow-y: unset;
            z-index: 1;
          }
          .Navigation .options .option {
            cursor: pointer;
          }
          .Navigation .options .option:hover{
            text-decoration: underline;
          }
          .Navigation .Navigation_bottom {
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .Navigation .Navigation_bottom select {
            width: 100px;
            padding-bottom: 2px;
          }
          .Navigation .Navigation_bottom .HeaderCart {
            width: 100px;
          }
          .Navigation .Product_option {
            background: white;
            border: none;
            border-radius: 0;
            font-size: 16px;
            margin: 0 15px 0 0;
            /* for Firefox */
            -moz-appearance: none;
            /* for Chrome */
            -webkit-appearance: none;
          }
          .Navigation .Product_option:focus {
            outline: 0;
          }
          .Navigation .Product_option ::-ms-expand {
            display: none;
          }
          .Navigation .Product_option:hover {
            text-decoration: underline;
          }
          .Navigation .options .option_home {
            background: url(${FEASTONLOGO});
            background-size: contain;
            background-repeat: no-repeat;
            width: 65px;
            height: 30px;
          }
          .Navigation .header {
            text-align: left;
            padding: 0 15px;
            display: flex;
            justify-content: space-between;
            background: url(${FEASTONLOGO}) !important;
            background-size: contain !important;
            background-repeat: no-repeat !important;
            width: 140px;
            height: 62px;
            margin: 10px 15px;
          }
          .Navigation .header img {
            width: 140px;
            height: auto;
          }
          .Navigation .fixed {
            position: fixed;
            z-index: 1;
          }
          .Navigation .NavLogo {
            top: 0;
            left: 0;
            padding: 10px 15px;
          }
          .Navigation .NavCart {
            top: 0;
            right: 0;
            height: 80px;
            display: flex;
          }
          .Navigation .NavOptions {
            top: 90px;
            left: 0;
            padding: 10px 15px;
          }
        `}</style>
        <div className="options">
          {is_navigation_hidden ? null :
            <Link to="/">
              <div className="fixed NavLogo header">
                  {/* <img src={FEASTONLOGO} alt="" /> */}
              </div>
            </Link>
          }
          <div className="fixed NavCart">
            <HeaderCart />
          </div>
          <div className="Navigation_bottom">
            {/* <VariantSelector
              handleOptionChange={this.handleCurrencyChange}
              option={{name: "Currency", values: ["USD", "HKD", "CAD", "CNY"]}}
              selectedValue={this.props.fetchSelectedCurrencyReducer.selectedCurrency}
            /> */}
          </div>
        </div>
        {is_navigation_hidden ? null : <div className="fixed NavOptions"><NavigationOptions /></div>}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  ...state
})

const mapDispatchToProps = dispatch => ({
  fetchSelectedCurrency: (currency) => dispatch(fetchSelectedCurrency(currency)),
})

export default withRouter(withCookies(connect(mapStateToProps, mapDispatchToProps)(Navigation)));