import { useState, useLayoutEffect } from 'react';
import { connect } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import HeaderCart from 'components/HeaderCart';
import SHOP_IMG from "assets/Navigation/shop.png";
import CLOTHING_IMG from "assets/Navigation/clothing.png";
import SOUNDS_IMG from "assets/Navigation/sounds.png";
import ITEMS_IMG from "assets/Navigation/items.png";
import EVENTS_IMG from "assets/Navigation/events.png";
import UPCOMING_IMG from "assets/Navigation/upcoming.png";
import PAST_IMG from "assets/Navigation/past.png";
import STOCKIST_IMG from "assets/Navigation/stockist.png";
import CONTACT_IMG from "assets/Navigation/contact.png";
import MEMBERSHIP_IMG from "assets/Navigation/membership.png";
import FEASTONLOGO from 'assets/Home/web_feastonLogo-small-removebg.png';
import ARCHIVES_IMG from "assets/Navigation/archives.png";
import ARCHIVES_WHITE_IMG from "assets/Navigation/archives_white.png";
import ABOUT_IMG from "assets/Navigation/about_tinified.png";

function NavigationOptions(props) {
  const { fetchFeatureCollectionReducer, show } = props;
  const { collection } = fetchFeatureCollectionReducer;
  const isGreatReset = !collection || !collection?.product || collection?.products?.length <= 0;
  const [showShop, setShowShop] = useState(false);
  const [showEvent, setShowEvent] = useState(false);

  let location = useLocation();
  let { pathname } = location;
  useLayoutEffect(() => {
    closeOptions();
  }, [pathname]);

  const openShop = () => {
    setShowEvent(false);
    setShowShop(!showShop);
  }
  const openEvent = () => {
    setShowShop(false);
    setShowEvent(!showEvent);
  }
  const closeOptions = () => {
    setShowShop(false);
    setShowEvent(false);
  }

  return (
    <div className={`NavigationOptions`}>
      <style jsx>{`
        .Navigation .NavigationOptions {
          width: 100%;
          height: 80px;
          font-size: 14px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          transition-property: all; 
          transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
          transition-duration: 500ms;
        }
        .Navigation .column_wrapper {
          height: 80px;
        }
        .Navigation .column_wrapper.hide {
          height: 0;
        }
        .Navigation .column_wrapper .none {
          display: none;
        }
        .Navigation .column_wrapper .option:not(.option_expand) {
          padding: 24px 50px;
        }
        .Navigation .column_wrapper .option.option_expand {
          padding: 8px 50px;
        }
        .Navigation .column_wrapper .column {
          padding-right: 24px;
          text-align: left;
          overflow: hidden;
          transition-property: all; 
          transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
          transition-duration: 500ms;
        }
        .Navigation .column_wrapper .column.right.hide {
          height: 0;
        }
        .Navigation .option {
          width: auto;
          height: 24px;
          display: flex;
          justify-content: center;
        }
        .Navigation .option img {
          width: auto;
          height: 25px;
        }
        .Navigation .column_wrapper .top {
          display: block;
        }
        .Navigation .column_wrapper .expand2 {
          padding-left: 150px;
        }
        .Navigation a {
          display: block;
        }
        .Navigation .option_shop {
          display: ${isGreatReset ? 'none' : 'block'};
        }
        .Navigation .option_about img {
          height: 20px;
        }
      `}</style>
      <div className="column_wrapper">
        <div className="column top">
          <Link to="/">
            <div className="option option_other option_shop">
              <img src={SHOP_IMG} alt="" />
            </div>
          </Link>
          {/* <Link to="/archived">
            <div className="option option_other">
              archived
            </div>
          </Link> */}
          {/* <div className="option option_other" onClick={openEvent}>
            <img src={EVENTS_IMG} alt="" />
          </div>
          <Link to="/stockist">
            <div className="option option_other" onClick={closeOptions}>
              <img src={STOCKIST_IMG} alt="" />
            </div>
          </Link> */}
          <Link to="/archives">
            <div className="option option_other option_archives" onClick={closeOptions}>
              <img style={{ transform: 'scale(2)' }} src={
                !collection?.products?.length && pathname === "/" ? ARCHIVES_WHITE_IMG : ARCHIVES_IMG} alt="" />
            </div>
          </Link>
          <Link to="/about">
            <div className="option option_other option_about" onClick={closeOptions}>
              <img src={ABOUT_IMG} alt="" />
            </div>
          </Link>
          {/* <Link to="/contact">
            <div className="option option_other" onClick={closeOptions}>
              <img src={CONTACT_IMG} alt="" />
            </div>
          </Link> */}
          {/* <Link to="/mtt">
            <div className="option option_other" onClick={closeOptions}>
              <img style={{
                    height: '20px',
                    padding: '3px 0 3px'
              }} src={MEMBERSHIP_IMG} alt="" />
            </div>
          </Link> */}
        </div>
        <div className={`column bottom`}>
          {showShop ? (
            <div className={`expand expand1`}>

              <div className="option option_expand">
                <div>
                <Link to="/shop/clothing">
                  <img src={CLOTHING_IMG} alt="" />
                </Link>
                </div>
              </div>


              <div className="option option_expand">
                <Link to="/shop/sounds">
                  <img src={SOUNDS_IMG} alt="" />
                </Link>
              </div>


              <div className="option option_expand">
                <Link to="/shop/items">
                  <img src={ITEMS_IMG} alt="" />
                </Link>
              </div>

            </div>
          ) : null}
          {showEvent ? (
            <div className={`expand expand2`}>

              <div className="option option_expand">
                <Link to="/events/upcoming">
                  <img src={UPCOMING_IMG} alt="" />
                </Link>
              </div>


              <div className="option option_expand">
                <Link to="/events/past">
                  <img src={PAST_IMG} alt="" />
                </Link>
              </div>

            </div>
          ) : null}
        </div>
      </div>
      {/* <HeaderCart /> */}
    </div>
  )
}

const mapStateToProps = state => ({
  ...state
})

export default connect(mapStateToProps, {})(NavigationOptions);