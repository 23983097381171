import React, { useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { withRouter } from 'withRouter';
import { withCookies } from 'react-cookie';
import { fetchSelectedCurrency } from '../../../actions/fetchSelectedCurrency';
import { fetchIsNavigationExpanded } from 'actions/fetchIsNavigationExpanded';
import NavigationOptions from './NavigationOptions';
import MobileHeaderCart from '../MobileHeaderCart';
import Logo from './Logo';
// class MobileNavigation extends Component {

//   constructor(props) {
//     super(props);
//     this.state = {
//       expanded: true,
//     };
//     this.handleCurrencyChange = this.handleCurrencyChange.bind(this);
//   }

// handleCurrencyChange(event) {
//   this.props.cookies.set("selectedCurrency", event.target.value, { path: '/', expires: new Date(Date.now() + 31556952000) });
//   this.props.fetchSelectedCurrency(event.target.value);
// }

// componentDidMount() {
//   if (this.props.cookies.get("selectedCurrency")) {
//     this.props.fetchSelectedCurrency(this.props.cookies.get("selectedCurrency"));
//   }
// }

// componentWillReceiveProps(nextProps) {
//   if (nextProps.fetchCheckoutReducer.checkout && !this.props.cookies.get("selectedCurrency")) {
//     const defaultCurrency = nextProps.fetchCheckoutReducer.checkout.currencyCode;
//     this.props.cookies.set("selectedCurrency", defaultCurrency, { path: '/', expires: new Date(Date.now() + 31556952000) });
//     this.props.fetchSelectedCurrency(defaultCurrency);
//   }
// }

function MobileNavigation(props) {
  const { match, fetchIsNavigationExpanded, fetchIsNavigationExpandedReducer } = props;
  const { isExpanded } = fetchIsNavigationExpandedReducer;
  const { url } = match;

  let location = useLocation();
  let { pathname } = location;
  useLayoutEffect(() => {
    if (pathname !== "/(deprecated)") {
      fetchIsNavigationExpanded(false);
    }
  }, [pathname]);

  const isIframe = pathname.includes("/feaston_booster_pack");
  if (isIframe) {
    return null;
  }

  return (
    <div className={`MobileNavigation`}>
      <style jsx>{`
          .MobileCategory, .web-page, .web-page-align-top,
          .Cart, .MobileContact, .MobileTerms, .MobileHome {
            padding-top: ${/*url === "/" ||*/ !isExpanded ? '70px' : '120px'} !important;
            padding-bottom: 60px;
            transition-property: all; 
            transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
            transition-duration: 500ms;
          }

          .MobileNavigation {
            position: fixed;
            top: 0;
            z-index: 9999;
            width: -webkit-fill-available;
            display: flex;
            flex-direction: column;
            font-weight: bold;
            font-size: 12px;
            background: ${url === "/(deprecated)" || !isExpanded ? 'transparent' : '#fff'};
          }
          .MobileNavigation .logo {
            width: 100%;
            padding: 5px 20px 5px 10px;
            background: ${!isExpanded ? 'linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%)' : '#fff'};
            //background-image: linear-gradient(to bottom, #fff, transparent);
          }
          .MobileNavigation .Product_option {
            padding-bottom: 3px;
            border-color: white;
            background-color: white;
            outline: none;
            -webkit-appearance: none;
          }
          .MobileNavigation .Expandable {
            position: fixed;
            top: 70px;
            left: 0;
            width: 50px;
            z-index: 10000;
            display: flex;
            background: ${url === "/(deprecated)" || !isExpanded ? 'transparent' : '#fff'};
            transition-property: all; 
            transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
            transition-duration: 500ms;
          }
          .MobileNavigation .Expandable.expanded {
            right: 0;
            width: 100vw;
          }
          .MobileNavigation .Expandable.notHome {
            background: transparent;
            //background: -moz-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%); /* FF3.6-15 */
            //background: -webkit-linear-gradient(top, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%); /* Chrome10-25,Safari5.1-6 */
            //background: linear-gradient(to bottom, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%);
          }
          .MobileNavigation .Expandable.notHome .expandBg {
            position: absolute;
            top: 0;
            left: 0;
            width: 100vw;
            height: 0px;
            background: -moz-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(top, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to bottom, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%);
            z-index: 1;
            pointer-events: none;
            transition-property: all;
            transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
            transition-duration: 500ms;
          }
          .MobileNavigation .Expandable.expanded.notHome .expandBg {
            height: 100%;
          }
          .MobileNavigation .Expandable .icon {
            z-index: 9999;
          }
          .MobileNavigation .Expandable .icon .arrow {
            width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 10px 10px 10px 0;
            font-weight: bold;           
          }
        `}</style>
      <Logo />
      <div className={`Expandable${isExpanded ? " expanded" : ""}${url === "/(deprecated)" ? "" : " notHome"}`}>
        <div className="expandBg" />
        <div className="icon" onClick={() => { fetchIsNavigationExpanded(!isExpanded); /*setScrollDirection('up');*/ }}>
          <div className="arrow">{isExpanded  ? '<' : '>'}</div>
        </div>
        <NavigationOptions show={isExpanded} />
      </div>
      <MobileHeaderCart />
      {/* <VariantSelector
          handleOptionChange={this.handleCurrencyChange}
          option={{name: "Currency", values: ["USD", "HKD", "CAD", "CNY"]}}
          selectedValue={this.props.fetchSelectedCurrencyReducer.selectedCurrency}
        />  */}
    </div>
  );
}

const mapStateToProps = state => ({
  ...state
})

const mapDispatchToProps = (dispatch) => ({
  fetchIsNavigationExpanded: (bool) => dispatch(fetchIsNavigationExpanded(bool)),
  fetchSelectedCurrency: (currency) => dispatch(fetchSelectedCurrency(currency)),
})

export default withRouter(withCookies(connect(mapStateToProps, mapDispatchToProps)(MobileNavigation)));
