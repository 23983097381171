import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import categories from 'data/categories/index.jsx';

import { Link } from 'react-router-dom';
import { withRouter } from 'withRouter';
import MobileProductWidgetGeneral from '_Mobile/scenes/MobileProduct/ProductWidgetGeneral';
import { getCoverImg } from 'functions.js';

function createElementFromHTML(htmlString) {
  var div = document.createElement('div');
  div.innerHTML = htmlString.trim();
  // Change this to div.childNodes to support multiple top-level nodes
  return div; 
}

class Home extends Component {

  constructor(props) {
    super(props);
    this.state = {
      option: {},
    };
    this.onOptionChange = this.onOptionChange.bind(this);
    this.product = this.getProductFromUrl(props.url);
  }

  getProductFromUrl(url) {
    const { fetchProductsReducer } = this.props;
    const { products } = fetchProductsReducer;
    const curr_category = categories[url];
    const curr_handle = curr_category.products[0];
    if (products && products.length > 0) {
      for (let product of products) {
        const { handle } = product;
        if (handle === curr_handle) {
          return product;
        }
      }
    }
    return null;
  }

  onOptionChange(option) {
    this.setState({
      option,
    });
  }

  render() {
    const { url, imgHeight, pickUpOnly } = this.props;
    const { option } = this.state;
    const product = this.getProductFromUrl(url);
    let descriptionHtml = null;
    let areaHTML = null;
    if (product) {
      ({ descriptionHtml } = product);
    }
    if (descriptionHtml) {
      const areaHTMLs = [...createElementFromHTML(descriptionHtml).getElementsByTagName("AREA")];
      areaHTML = option.Color ? areaHTMLs.find(a => a.alt=option.Color) : areaHTMLs[0];
    }
    
    // temp
    areaHTML = null;
    return (
      <div className="homeBanner-container">
        
        <style jsx>{`
          .homeBanner-container {
            position: relative;
            width: 80%;
            height: 850px;
            margin: auto;
            z-index: 999;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
          }
          .homeBanner-container .homeBanner {
            //max-width: 100vw;
            position: absolute;
          }
          .homeBanner-container .homeBanner.product-item {
            position: unset;
            max-width: ${imgHeight ? "unset !important" : "500px !important"};
            height: ${imgHeight || "unset"};
          }
          .homeBanner-container .homeBanner img{
            width: 100%;
            margin: auto;
          }
          .homeBanner-container .homeBanner .banner, map area{
            outline: none;
          }
        `}</style>
        {areaHTML ? (
          <img className="homeBanner product-item" src={getCoverImg(product, option.Color).src} useMap={`#image-map_${url}`} alt={getCoverImg(product, option.Color).altText || ""}/>
        ) : (
          <Link to={`/${url}`}>
            <img className="homeBanner product-item" src={getCoverImg(product, option.Color).src} useMap={`#image-map_${url}`} alt={getCoverImg(product, option.Color).altText || ""}/>
          </Link>
        )}
        <map name={`image-map_${url}`}>
          <Link to={`/${url}`}>
            {/* {areaHTML ? <span dangerouslySetInnerHTML={{
              __html: areaHTML.outerHTML
            }} /> : null} */}
            {areaHTML ? <area coords={areaHTML.coords} alt={areaHTML.alt} shape={areaHTML.shape} /> : null}
          </Link>
        </map>
        <MobileProductWidgetGeneral product={product} onOptionChange={this.onOptionChange} isHomeWidget isDesktop pickUpOnly={pickUpOnly} />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  ...state
})

export default withRouter(connect(mapStateToProps, {}) (Home));
