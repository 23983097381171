import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'withRouter';
import categories from '../../../data/categories';
import PanelLeft from '../../../components/PanelLeft';
import PanelRight from '../../../components/PanelRight';
import LMMG_LOGO from '../../../assets/LMMG/lmmgLogo.png';
import LMMG_FRAME from '../../../assets/LMMG/lmmgFrame.png';
import LM_GUY from '../../../assets/LMMG/lmGuyWhiteHands.png';
import { Link } from 'react-router-dom';

class LMMG extends Component {

  constructor(props) {
    super(props);
    this.state = {
      productsInProject: [],
      project: "lmmg",
    };
    this.initiateLoadedProducts = this.initiateLoadedProducts.bind(this);
  }

  initiateLoadedProducts(products) {
    const project_url = this.state.project;
    let temp_product_array = [];
    if (products) {
      for (let c of categories[project_url].products) {
        for (let p of products) {
          if (c === p.handle) {
            temp_product_array.push(p);
            break;
          } else if (c.image) {
            temp_product_array.push(c);
            break;
          }
        }
      }
      this.setState({
        productsInProject: temp_product_array,
      })
    }
  }

  componentWillMount() {
    this.initiateLoadedProducts(this.props.fetchProductsReducer.products);
  }

  componentWillReceiveProps(nextProps) {
    this.initiateLoadedProducts(nextProps.fetchProductsReducer.products);
  }

  render() {
    const { productsInProject } = this.state;
    console.log(productsInProject)
    return (
      <div className="LMMG web-page">
        <style jsx>{`
          .LMMG {
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .LMMG div {
            overflow-y: unset;
          }
          .LMMG .LMMG-mid {
            font-weight: bold;
            font-size: 28px;
            display: inline-block;
          }
          .LMMG .LMMG-mid .LMMG-mid-inner {
            padding: 100px 0;
          }
          .LMMG .LMMG-mid .LMMG-scroll-mid {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .LMMG .LMMG-mid .LMMG-scroll-mid.LMMG-scroll-mid-product-img {
            padding: 0 60px;
          }
          .LMMG .LMMG-mid img {
            width: 100%;
            max-height: 100%;
            object-fit: cover;
          }
          .LMMG .LMMG-panelLeft {
            font-size: 13px;
          }
          .LMMG .LMMG-panelLeft .LMMG-logo {
            padding-bottom: 20px;
          }
          .LMMG .LMMG-panelLeft .LMMG-logo img {
            width: 100%;
            max-width: 80px;
          }
          .LMMG .LMMG-panelLeft .LMMG-desc {
            text-align: left;
          }
          .LMMG .LMMG-panelLeft .LMMG-white-guy-container img {
            width: 100%;
            margin: 20px 0;
          }
          .LMMG .LMMG-panelLeft .LMMG-white-guy-container .LMMG-white-guy-desc {
            text-align: left;
          }
          .LMMG .LMMG-product-container {
            background-image: url('${LMMG_FRAME}');
            background-size: cover;
            background-position: center;
            width: 240px;
            height: 325px;
            display: inline-flex;
            margin: 20px;
            align-items: center;
            justify-content: center;
            position: relative;
            cursor: pointer;
          }
          .LMMG .LMMG-product-container img {
            width: 80%;
            max-height: 90%;
          }
          .LMMG .LMMG-product-container .LMMG-product-title {
            background: black;
            color: white;
            position: absolute;
            top: -10px;
            left: -10px;
            font-size: 20px;
            padding: 2px;
          }
        `}</style>
        <div className="web-mid LMMG-mid">
          <div className="LMMG-mid-inner">
            {productsInProject && productsInProject.length > 0 ? (
                  productsInProject.map((product) => {
                    return (
                      <Link key={product.id} to={`/project/lmmg/${product.handle}`}>
                        <div className="LMMG-product-container">
                          <img src={product.images[0].src} alt="" />
                          <div className="LMMG-product-title">
                            {product.title}
                          </div>
                        </div>
                      </Link>
                    )
                  })
                ) : null}
          </div>
        </div>
        <PanelLeft>
          <div className="LMMG-panelLeft">
            <div className="LMMG-logo">
              <img src={LMMG_LOGO} alt="LMMG" />
            </div>
            <div className="LMMG-desc">
              The classic logo was printed in red for the first time during Lunar New Year 2019
              The classic logo was printed in red for the first time during Lunar New Year 2019
              The classic logo was printed in red for the first time during Lunar New Year 2019
              The classic logo was printed in red for the first time during Lunar New Year 2019
              The classic logo was printed in red for the first time during Lunar New Year 2019
            </div>
            <div className="LMMG-white-guy-container">
                <img src={LM_GUY} alt="" />
                <div className="LMMG-white-guy-desc">
                  The classic logo was printed in red for the first time during Lunar New Year 2019
                </div>
            </div>
          </div>
        </PanelLeft>
        <PanelRight withPath />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  ...state
})

export default withRouter(connect(mapStateToProps, {}) (LMMG));
