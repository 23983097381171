import React, { Component } from 'react';
import { client } from '../../../shopify';
import { withCookies } from 'react-cookie';
import { connect } from 'react-redux';
import { fetchCheckout } from '../../../actions/fetchCheckout';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { isMobile, browserName, mobileVendor, mobileModel, deviceType, osName } from "react-device-detect";
import DAIYU from '_Mobile/assets/daiyu.jpeg';

class EmailMe extends Component {

  constructor(props) {
    super(props);
    this.state = {
      dialogOpen: false,
      isLoading: false,
      isButtonHovered: false,
      contact: null,
    };
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.getFormData = this.getFormData.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.loaded = this.loaded.bind(this);
    this.disableAllButtons = this.disableAllButtons.bind(this);
    this.buttonState = this.buttonState.bind(this);
    this.handleContactChange = this.handleContactChange.bind(this);
  }

  // get all data in form and return object
  getFormData(form) {
    var elements = form.elements;
    var honeypot;

    var fields = Object.keys(elements).filter(function(k) {
      if (elements[k].name === "honeypot") {
        honeypot = elements[k].value;
        return false;
      }
      return true;
    }).map(function(k) {
      if(elements[k].name !== undefined) {
        return elements[k].name;
      // special case for Edge's html collection
      }else if(elements[k].length > 0){
        return elements[k].item(0).name;
      }
    }).filter(function(item, pos, self) {
      return self.indexOf(item) == pos && item;
    });

    var formData = {};
    fields.forEach(function(name){
      var element = elements[name];
      
      // singular form elements just have one value
      formData[name] = element.value;

      // when our element has multiple items, get their values
      if (element.length) {
        var data = [];
        for (var i = 0; i < element.length; i++) {
          var item = element.item(i);
          if (item.checked || item.selected) {
            data.push(item.value);
          }
        }
        formData[name] = data.join(', ');
      }
    });

    // add form-specific values into the data
    formData.formDataNameOrder = JSON.stringify(fields);
    formData.formGoogleSheetName = form.dataset.sheet || "responses"; // default sheet name
    formData.formGoogleSendEmail
      = form.dataset.email || ""; // no email by default

    return {data: formData, honeypot: honeypot};
  }

  handleFormSubmit(event) {  // handles form submit without any jquery
    event.preventDefault();           // we are submitting via xhr below
    this.setState({
      isLoading: true,
    });
    var form = event.target;
    var formData = this.getFormData(form);
    var data = formData.data;

    // If a honeypot field is filled, assume it was done so by a spam bot.
    if (formData.honeypot) {
      return false;
    }

    this.disableAllButtons(form);
    var url = form.action;
    var xhr = new XMLHttpRequest();
    xhr.open('POST', url);
    // xhr.withCredentials = true;
    xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    // xhr.setRequestHeader("Content-Type", "text/plain;charset=utf-8");
    xhr.onreadystatechange = () => {
        if (xhr.readyState === 4 && xhr.status === 200) {
          form.reset();
          var formElements = form.querySelector(".form-elements")
          if (formElements) {
            formElements.style.display = "none"; // hide form
          }
          var thankYouMessage = form.querySelector(".thankyou_message");
          if (thankYouMessage) {
            this.setState({
              isLoading: false,
            });
            thankYouMessage.style.display = "block";
            setTimeout(() => {
              if (this.state.dialogOpen) {
                this.setState({
                  dialogOpen: false,
                });
              }
            }, 3000);
          }
        }
    };
    // url encode form data for sending as post data
    var encoded = Object.keys(data).map(function(k) {
        return encodeURIComponent(k) + "=" + encodeURIComponent(data[k]);
    }).join('&');
    xhr.send(encoded);
    console.log('encoded, ', encoded);
  }

  loaded() {
    // bind to the submit event of our form
    var forms = window.document.querySelectorAll("form.gform");
    for (var i = 0; i < forms.length; i++) {
      forms[i].addEventListener("submit", this.handleFormSubmit, false);
    }
  };

  disableAllButtons(form) {
    var buttons = form.querySelectorAll("button");
    for (var i = 0; i < buttons.length; i++) {
      buttons[i].disabled = true;
    }
  }

  handleClickOpen() {
    this.setState({
      dialogOpen: true,
    }, () => {
      setTimeout(() => {
        this.loaded();
      }, 0);
    });
  }

  handleClose() {
    this.setState({
      dialogOpen: false,
    });
  }

  buttonState(state) {
    this.setState({
      isButtonHovered: state,
    })
  }

  handleContactChange(event) {
    this.setState({
      contact: event.target.value
    });
  }

  render() {
    const { dialogOpen, isLoading, isButtonHovered, contact } = this.state;
    const { product_name, size, color } = this.props;
    const loadingSVG = (
      <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" style={{ margin: "auto", background: isButtonHovered ? "white" : "rgb(0, 0, 0)", display: "block"}} width="18px" height="18px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
        <circle cx="50" cy="50" fill="none" stroke={isButtonHovered ? "#000000" : "#ffffff"} strokeWidth="10" r="35" strokeDasharray="164.93361431346415 56.97787143782138" transform="rotate(243.686 50 50)">
          <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="5s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
        </circle>
      </svg>
    );
    return (
      <div className="emailUs">
        <style jsx>{`
          .emailUs .triggerButton {
            font-size: 14px;
            //background: red;
            color: black;
            //border-radius: 4px;
            //border: 1px solid red;
            cursor: pointer;
            margin-top: 4px;
            outline: none;
            text-decoration: underline;
          }
          .emailUs .triggerButton:hover {
            background: white;
            color: red;
          }
          .emailUsFormElements {
            font-size: 14px;
            padding: 16px;
            max-width: 425px;
            margin: auto;
          }
          .emailUsFormElements input {
            box-sizing : border-box;
            //margin-top: 6px;
            width: 100%;
            line-height: 16px;
            outline: none;
            font-size: 16px;
          }
          .emailUsFormElements input:-webkit-autofill {
            -webkit-box-shadow: 0 0 0 30px white inset !important;
          }
          .emailUsFormElements .dialog-button {
            text-align: center;
            margin-top: 8px;
          }
          .emailUsFormElements .dialog-button button b {
            display: flex;
            width: 80px;
            margin: auto;
            align-items: center;
            justify-content: center;
          }
          .emailUsFormElements button {
            font-size: 16px;
            background: black;
            color: white;
            border-radius: 4px;
            border: 1px solid black;
            cursor: pointer;
            outline: none;
            pointer-events: ${isLoading ? "none" : "unset"};
          }
          .emailUsFormElements button:hover {
            background: white;
            color: black;
          }
          .gform .thankyou_message {
            padding: 16px;
          }
        `}</style>
        <div className="emailUs-button">
          <div className="triggerButton" onClick={this.handleClickOpen}>
            <b>Join Waiting List</b> 
          </div>
        </div>
        <Dialog onClose={this.handleClose} aria-labelledby="customized-dialog-title" open={dialogOpen}>
          <img style={{ maxWidth:"100%" }} src={DAIYU} alt="" />
          <form className="gform pure-form pure-form-stacked" method="POST"
            action="https://script.google.com/macros/s/AKfycbzBK53Pr1cIW4y1l5QmhA2HsKuaF697Jupygj8vOibES6_uGHg8/exec">

            <div className="form-elements emailUsFormElements">

              <div className="pure-group">
                <label htmlFor="contact">
                  {/* We may send you notification when this item or/and related items are restocked.<br/> */}
                </label>
                <input id="contact" name="contact" value={contact} type={contact && contact !== '' && contact.includes('@') && !contact.includes('/') && !contact.includes(',') ? 'email' : 'text'} onChange={this.handleContactChange.bind(this)} //autoFocus
                required placeholder="Leave your email / Instagram here"/>
              </div>

              <fieldset className="pure-group" style={{ display:"none" }}>
                <label htmlFor="product_name">Selected Item: </label>
                <input id="product_name" name="product_name" placeholder={product_name} value={product_name || ""} readOnly/>
              </fieldset>

              <fieldset className="pure-group" style={{ display:"none" }}>
                <label htmlFor="color">Selected Color: </label>
                <input id="color" name="color" placeholder={color} value={color || ""} readOnly/>
              </fieldset>

              <fieldset className="pure-group" style={{ display:"none" }}>
                <label htmlFor="size">Selected Size: </label>
                <input id="size" name="size" placeholder={size} value={size || ""} readOnly/>
              </fieldset>

              <fieldset className="pure-group" style={{ display:"none" }}>
                <label htmlFor="device_type">Device Type: </label>
                <input id="device_type" name="device_type" placeholder={deviceType} value={deviceType || ""} readOnly/>
              </fieldset>

              <fieldset className="pure-group" style={{ display:"none" }}>
                <label htmlFor="os">OS Name: </label>
                <input id="os" name="os" placeholder={osName} value={osName || ""} readOnly/>
              </fieldset>

              <fieldset className="pure-group" style={{ display:"none" }}>
                <label htmlFor="browser">Browser Name: </label>
                <input id="browser" name="browser" placeholder={browserName} value={browserName || ""} readOnly/>
              </fieldset>

              <fieldset className="pure-group" style={{ display:"none" }}>
                <label htmlFor="mobile_device">Mobile Device: </label>
                <input id="mobile_device" name="mobile_device" placeholder={`${mobileVendor}, ${mobileModel}`} value={`${mobileVendor}, ${mobileModel}`} readOnly/>
              </fieldset>

              <fieldset className="pure-group honeypot-field" style={{ display:"none" }}>
                <label htmlFor="honeypot">To help avoid spam, utilize a Honeypot technique with a hidden text field; must be empty to submit the form! Otherwise, we assume the user is a spam bot.</label>
                <input id="honeypot" type="text" name="honeypot" defaultValue="" />
              </fieldset>

              <div className="dialog-button" >
                <button className="button-success pure-button button-xlarge" onMouseEnter={() => this.buttonState(true)} onMouseLeave={() => this.buttonState(false)}>
                  <b>{isLoading ? loadingSVG : "SEND"}</b>
                </button>
              </div>
            </div>

            <div className="thankyou_message" style={{ display: "none" }}>
              You will hear from us when the items are back and stocked.
            </div>

          </form>
        </Dialog>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  ...state
})

export default withCookies(connect(mapStateToProps) (EmailMe));
