import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'withRouter';
import categories from '../../../data/categories';
import PanelLeft from '../../../components/PanelLeft';
import PanelRight from '../../../components/PanelRight';
import FEASTON_LOGO from '../../../assets/Feaston/feastonLogo.png';
import { Link } from 'react-router-dom';

function pad(n, length) {
  var len = length - (''+n).length;
  return (len > 0 ? new Array(++len).join('0') : '') + n
}

class Feaston extends Component {

  constructor(props) {
    super(props);
    this.state = {
      productsLoaded: false,
      productsInProject: [],
      project: "feaston",
    };
    this.initiateLoadedProducts = this.initiateLoadedProducts.bind(this);
  }

  initiateLoadedProducts(products) {
    const project_url = this.state.project;
    let temp_product_array = [];
    if (products) {
      for (let c of categories[project_url].products) {
        for (let p of products) {
          if (c === p.handle) {
            temp_product_array.push(p);
            break;
          } else if (c.image) {
            temp_product_array.push(c);
            break;
          }
        }
      }
      this.setState({
        productsLoaded: true,
        productsInProject: temp_product_array,
      })
    }
  }

  componentWillMount() {
    this.initiateLoadedProducts(this.props.fetchProductsReducer.products);
  }

  componentWillReceiveProps(nextProps) {
    this.initiateLoadedProducts(nextProps.fetchProductsReducer.products);
  }

  render() {
    const { productsInProject } = this.state;
    return (
      <div className="Feaston web-page">
        <style jsx>{`
          .Feaston {
            
          }
          .Feaston .Feaston-mid {
            font-weight: bold;
            font-size: 28px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
          }
          .Feaston .Feaston-title {
            margin-bottom: 30px;
          }
          .Feaston .Feaston-printNumber-container {
            text-align: left;
            max-width: 570px;
            overflow-y: unset;
            word-wrap: break-word;
          }
          .Feaston .Feaston-printNumber-container .Feaston-printNumber {
            text-decoration: underline;
            cursor: pointer;
            margin: 5px 10px;
            display: inline-block;
            text-align: center;
            width: 75px;
          }
          .Feaston .Feaston-panelLeft {
            font-size: 13px;
          }
          .Feaston .Feaston-panelLeft .Feaston-logo {
            padding-bottom: 20px;
          }
          .Feaston .Feaston-panelLeft .Feaston-logo img {
            width: 100%;
          }
          .Feaston .Feaston-panelLeft .Feaston-desc {
            text-align: left;
          }
        `}</style>
        <div className="web-mid Feaston-mid">
          <div className="Feaston-title">
            SINGLE-PRINT
          </div>
          <div className="Feaston-printNumber-container">
            {productsInProject.map((p, i) => {
              return (
                <Link to={`/project/feaston/${p.handle}`}>
                  <div className="Feaston-printNumber" key={`Feaston-printNumber-${i}`}>
                    {pad(i+1, 3)}
                  </div>
                </Link>
              )
            })}
          </div>
        </div>
        <PanelLeft>
          <div className="Feaston-panelLeft">
            <div className="Feaston-logo">
              <img src={FEASTON_LOGO} alt="Feaston" />
            </div>
            <div className="Feaston-desc">
              Feaston is an
              on-going graphic
              project started in 2018. All graphics
              in this project
              are co-created in a 
              connected virtual 
              space. Within this virtual space, each participant has the freedom to include
              any new imagery, amend and exclude any existing imagery while all amendments 
              are visible to all 
              participants.<br/>
              <br/>
              Selected graphics from Feaston are now available as single print editions.<br/>
              <br/>
              Navigate Feaston with arrow keys or view specific prints by clicking on the numbered code. 
            </div>
          </div>
        </PanelLeft>
        <PanelRight withPath />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  ...state
})

export default withRouter(connect(mapStateToProps, {}) (Feaston));
