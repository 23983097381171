import { useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

function Category(props) {
  const { product } = props;
  const { images, handle } = product;
  const [ image, setImage] = useState(images[0]);

  const handleMouseEnter = () => {
    setImage(images[1] || images[0]);
  }

  const handleMouseLeave = () => {
    setImage(images[0]);
  }

  return (
    <div className="categoryItem" onMouseOver={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <style jsx>{`
        .categoryItem {
          width: 15vw;
          height: auto;
          min-width: 150px;
          max-width: 250px;
        }
        .categoryItem img{
          width: 100%;
          height: 100%;
        }
        .Category a {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0 10px;
        }
      `}</style>
        <Link to={`/${handle}`} key={handle}>
          <div className="categoryItem">
            <img src={image.src} alt="" />
          </div>
        </Link>
    </div>
  )
}

const mapStateToProps = state => ({
  ...state
})

export default connect(mapStateToProps, {}) (Category);
