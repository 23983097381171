import React, { Component } from 'react';
import { withCookies } from 'react-cookie';
import { connect } from 'react-redux';
import { Helmet } from "react-helmet";
// import * as handTrack from 'handtrackjs';

// const img = document.getElementById('img'); 
// const model = await handTrack.load();
// const predictions = await model.detect(img); 
//
class Test extends Component {

  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
    this.onPlay = this.onPlay.bind(this);
    this.run = this.run.bind(this);
    this.onStart = this.onStart.bind(this);
    this.onBtnClick = this.onBtnClick.bind(this);
    // this.runDetection = this.runDetection.bind(this);
    this.model = null;    
  }

  componentDidMount() {
    // this.run();
    setTimeout(() => this.onStart(), 5000);
  }

  onBtnClick() {
      window.synth = new (window.AudioContext || window.webkitAudioContext || window.mozAudioContext || window.oAudioContext || window.msAudioContext)()
      window.osc = window.synth.createOscillator()
      window.gain = window.synth.createGain()
      window.output = window.document.getElementById('output')
      window.convolver = window.synth.createConvolver();
      window.osc.type = 'sine'
      window.osc.connect(window.convolver);
      window.osc.connect(window.gain)
      window.gain.gain.value = 0
      window.gain.connect(window.synth.destination)
      window.osc.start()
  }

  async onStart() {
    const canvas = document.getElementById("overlay");
    const video = document.getElementById("inputVideo");
//
    // The Synth
    // var synth = new (window.AudioContext || window.webkitAudioContext || window.mozAudioContext || window.oAudioContext || window.msAudioContext)(),
    // osc = synth.createOscillator(),
    // gain = synth.createGain()
    // // output = window.document.getElementById('output')
    // osc.type = 'sine';
    // osc.connect(gain);
    // gain.gain.value = 0;
    // gain.connect(synth.destination);
    // osc.start();

    if (!canvas || !video || !window.gain || !window.osc) {
      setTimeout(() => this.onStart());
    } else {
      const modelParams = {
        flipHorizontal: true, // flip e.g for video
        maxNumBoxes: 20, // maximum number of boxes to detect
        iouThreshold: 0.5, // ioU threshold for non-max suppression
        scoreThreshold: 0.6, // confidence threshold for predictions.
      };
      const context = canvas ? canvas.getContext("2d") : null;

        let model = null;
        let isVideo = false;
        const { handTrack } = window;
        // console.log('handTrack', window)
        handTrack.load(modelParams).then((lmodel) => {

        model = lmodel;
        console.log(lmodel);

        let volume = 0;
        let freq = 0;
        //let freq2 = 0;

        function runDetection() {
          window.active = true;
          model.detect(video).then((predictions) => {
            // console.log("Predictions rrr: ", predictions, window);
            if (predictions.length === 0) {
              window.gain.gain.value = 0;
            } else {
              window.gain.gain.value = volume
            }
            model.renderPredictions(predictions, canvas, context, video);
            predictions.sort(function (a, b) {
              return a.bbox[0] - b.bbox[0]
            });
            //
            // console.log('predictions', predictions)
            for (let i = 0; i < predictions.length; i++) {
              if (predictions[i].label !== "face") {
                let { bbox } = predictions[i];
                let size = ((bbox[2]-bbox[0]) * (bbox[3]-bbox[1]));
                // let size = ((bbox[2]-bbox[0]) * (bbox[1]-bbox[3]));
                let width = bbox[2]-bbox[0];
                
                // console.log('bboxoxxxx', bbox)
                if (i === 0) {
                  // console.log('size', size)
                  volume = ~~(size/((320 * 480)/2)*100)/80;
                  // window.convolver.buffer = window.impulseResponse(8, width, false);;
                  // console.log('volume', volume, ' ==== ', size, (320 * 480)/2);
                } else {
                  // console.log('freq', freq, size)
                  // if (i === 1) {
                  freq = ~~(10000*(1-(size/((320 * 480)/2))));
                  // } else {
                  //   freq2 = ~~(10000*(1-(size/((320 * 480)/2))));
                  // }
                }//
                // console.log('tttt', i, bbox, freq, volume, size)
                window.osc.frequency.value = freq
                // window.osc2.frequency.value = freq2
                // osc.frequency.value = freq
                
                // gain.gain.value = volume
                // console.log('window', window.osc, window.gain)
                
              }
              
            }
            
            if (isVideo) {
              requestAnimationFrame(runDetection);
            }
          });
        }
        const video = document.getElementById("inputVideo");
        handTrack.startVideo(video).then(function (status) {
          console.log("video started", status);
          if (status) {
              isVideo = true;
              model.detect(video).then((predictions) => {
                console.log("Predictions ddd: ", predictions);
                model.renderPredictions(predictions, canvas, context, video);
                if (isVideo) {
                  requestAnimationFrame(runDetection);
                }
              });
          }
        });
      });
    }
  }

  async run() {

    // try to access users webcam and stream the images
    // to the video element
    const stream = await navigator.mediaDevices.getUserMedia({ video: {} })
    const videoEl = window.document.getElementById('inputVideo')
    videoEl.srcObject = stream
  }

  async onPlay() {
    // const videoEl = window.document.getElementById('inputVideo')
    // const withBoxes = true;

    // if(videoEl.paused || videoEl.ended) {
    //   return setTimeout(() => this.onPlay())
    // }

    // if (result) {
    //   const canvas = window.document.getElementById('overlay')
    //   const dims = faceapi.matchDimensions(canvas, videoEl, true)

    //   const resizedResult = faceapi.resizeResults(result, dims)
    //   const minConfidence = 0.05
    //   if (withBoxes) {
    //     faceapi.draw.drawDetections(canvas, resizedResult)
    //   }
    //   faceapi.draw.drawFaceExpressions(canvas, resizedResult, minConfidence)
    // }
    

    // setTimeout(() => this.onPlay())
  }

  render() {
    return (
      <div className="web-page Test" id="Test">
        <Helmet>
          <title>Feaston Test</title>
        </Helmet>
        <style jsx>{`
          .Test {
            padding: 20px 0 0 20px;
            display: block;
            text-align: left;
          }
          .Navigation {
            display: none;
          }
          .Testscreen {
            position: relative;
            height: 100vh;
          }
          #overlay {
            position: absolute;
            width: 1080px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
          #inputVideo {
            width: 100%;
            height: 100% !important;
            -webkit-transform: scaleX(-1);
            transform: scaleX(-1);
            display: none;
          }
          #output {
            display: none;
          }
          .btnStart {
            font-weight: bold;
            margin-left: 10px;
          }
        `}</style>
        <div>
    
          <div className="Test_screen">
          </div>
          <div style={{ position: "relative" }} className="Testscreen">
            {/* <input value='sine' type='button' data-button='grey'/>
            <input value='triangle' type='button' data-button='blue'/>
            <input value='square' type='button' data-button='green'/>
            <input value='sawtooth' type='button' data-button /> */}
            <button className="btnStart" onClick={this.onBtnClick}>start</button>
            <div id='output'></div>

            <video onLoadedMetadata={this.onPlay} id="inputVideo" autoPlay muted playsInline msHorizontalMirror></video>
            <canvas id="overlay" />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  ...state
})

export default withCookies(connect(mapStateToProps, {}) (Test));
