import React, { Component } from 'react';
import { connect } from 'react-redux';
import ScrollManager from "ScrollManager/index.jsx";
import { Helmet } from "react-helmet";
import DN4 from "assets/Stockists/DN4.jpg";
import INFREE from "assets/Stockists/infree.png";
import WHITENOISE from "assets/Stockists/whitenoise.png";

function Stockist() {
  return (
    <div className="Stockist web-page">
      <style jsx>{`
        .Stockist {
          height: calc(100vh - 90px);
        }
        .Stockist .stockist-wrap {
          display: flex;
          justify-content: center;
        }
        .Stockist .stockist {
          height: 25vh;
          width: auto;
          padding: 0 45px;
        }
        .Stockist img {
          height: 100%;
          padding-bottom: 50px;
        }
      `}</style>
      <div className="stockist-wrap">

        <div className="stockist stockist1">
          <a href="https://www.instagram.com/doors_and_fours/" target="blank" >
            <img src={DN4} alt="" />
          </a>
        </div>

        <div className="stockist stockist2">
          <a href="https://www.instagram.com/infree.records/" target="blank" >
            <img src={INFREE} alt="" />
          </a>
        </div>

        <div className="stockist stockist3">
          <a href="https://www.instagram.com/white_noise_records/" target="blank" >
            <img src={WHITENOISE} alt="" />
          </a>
        </div>

      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  ...state
})

export default connect(mapStateToProps, {})(Stockist);
