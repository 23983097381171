import Arrow1 from './arrowLarge1.png';
import Arrow2 from './arrowLarge2.png';
import Arrow3 from './arrowLarge3.png';
import Arrow4 from './arrowLarge4.png';
import Arrow5 from './arrowLarge5.png';
import Arrow6 from './arrowLarge6.png';
import Arrow7 from './arrowLarge7.png';
import Arrow8 from './arrowLarge8.png';
import Arrow9 from './arrowLarge9.png';

export default [
  Arrow1,
  Arrow2,
  Arrow3,
  Arrow4,
  Arrow5,
  Arrow6,
  Arrow7,
  Arrow8,
  Arrow9,
];