import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

function MobileArchivedFeatureCollection(props) {
  const { fetchArchivedFeatureCollectionsReducer } = props;
  const { collections = [] } = fetchArchivedFeatureCollectionsReducer;
  return (
    <div className="MobileArchivedFeatureCollection web-page-align-top">
      <style jsx>{`
        .MobileArchivedFeatureCollection {
          flex-direction: column;
          display: flex;
          position: fixed;
          top: 70px;
          bottom: 0;
        }
        .MobileArchivedFeatureCollection .item {
          display: flex;
        }
        .MobileArchivedFeatureCollection .item img {
          height: auto;
          width: 100%;
        }
        .NavOptions {
          displays: none;
        }
      `}</style>
      {collections.map((collection, index) => {
        const archiveBanner = collection.metafields?.find(
          (metafield) => metafield?.key === "archive_banner_url"
        )?.value;
        // console.log('archiveBannerDesktop', archiveBannerDesktop)
        // let url = "";
        // if (archiveBannerDesktop) {
        //   url = client.image.helpers.imageForSize({ src: archiveBannerDesktop }, {maxWidth: 50, maxHeight: 50});
        // }
        // console.log('client image', url)
        return (
          <div className="item" key={index}>
            <Link to={`/archives/${collection.handle}`}>
              <img src={archiveBanner} alt={collection.title} />
            </Link>
          </div>
        );
      })}
    </div>
  );
}

const mapStateToProps = state => ({
  ...state
})

export default connect(mapStateToProps, {})(MobileArchivedFeatureCollection);