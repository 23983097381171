import { combineReducers } from 'redux';
import fetchProductsReducer from './fetchProductsReducer';
import fetchSelectedCurrencyReducer from './fetchSelectedCurrencyReducer';
import fetchCheckoutReducer from './fetchCheckoutReducer';
import fetchIsCartOpenReducer from './fetchIsCartOpenReducer';
import fetchIsNavigationExpandedReducer from './fetchIsNavigationExpandedReducer';
import fetchBoosterPackReducer from './fetchBoosterPackReducer';
import fetchFeatureCollectionReducer from './fetchFeatureCollectionReducer';
import fetchArchivedFeatureCollectionsReducer from './fetchArchivedFeatureCollectionsReducer';

export default combineReducers({
 fetchProductsReducer,
 fetchSelectedCurrencyReducer,
 fetchCheckoutReducer,
 fetchIsCartOpenReducer,
 fetchIsNavigationExpandedReducer,
 fetchBoosterPackReducer,
 fetchFeatureCollectionReducer,
 fetchArchivedFeatureCollectionsReducer,
});