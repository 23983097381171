import { Fragment, useEffect, useRef, useState } from 'react';
import { fetchIsNavigationExpanded } from 'actions/fetchIsNavigationExpanded';
import { connect } from 'react-redux';
import BG_01 from "./background/frontpagesDesktop-01.png";
import BG_02 from "./background/frontDesk-02.png";
import BG_03 from "./background/frontDesk-03.png";
import BG_04 from "./background/frontDesk-04.png";
import BG_05 from "./background/frontDesk-05.png";
import BG_06 from "./background/frontDesk-06.png";
import BG_07 from "./background/frontDesk-07.png";

function Home2022June(props) {
  const homeRef = useRef(null);
  const [activeBg, setActiveBg] = useState(0);
  const BG_IMGS = [
    BG_01,
    BG_02,
    BG_03,
    BG_04,
    BG_05,
    BG_06,
    BG_07,
  ];
  const { fetchIsNavigationExpanded } = props;

  useEffect(() => {
    fetchIsNavigationExpanded(true);
    let n = 0;
    const changeBgLoop = setInterval(() => {
      setActiveBg(n);
      n = n === 6 ? 0 : n + 1;
    }, 3500);
    return () => clearInterval(changeBgLoop);
  }, []);

  return (
    <div className="Home2022June" ref={homeRef}>
      <style jsx>{`
        @keyframes mobilebgchange {
          0% {background-image: url(${BG_01});}
          14% {background-image: url(${BG_02});}
          29% {background-image: url(${BG_03});}
          43% {background-image: url(${BG_04});}
          57% {background-image: url(${BG_05});}
          71% {background-image: url(${BG_06});}
          86% {background-image: url(${BG_07});}
          100% {background-image: url(${BG_01});}
        }
        .Home2022June {
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
        }
        .Home2022June .background{
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          z-index: 0;
          display: none;
        }
        .Home2022June.repeat {
          background-size: contain;
          background-repeat: repeat;
        }
        .Home2022June .background.show {
          display: block;
        }
        .Home2022June .bg0 {
          background-size: cover;
          background-repeat: no-repeat;
        }
      `}</style>
      {BG_IMGS.map((img, index) => {
        return (
          <Fragment key={`fragment${img}`}>
            <link key={`link${img}`} rel="preload" href={img} as="image" />
            <div key={img} style={{ backgroundImage: `url(${img})` }} className={`background ${`bg${index}`}${activeBg === index ? " show" : ""}`} />
          </Fragment>
        )
      })}
    </div>
  )
}

const mapStateToProps = state => ({
  ...state
})

const mapDispatchToProps = (dispatch) => ({
  fetchIsNavigationExpanded: (bool) => dispatch(fetchIsNavigationExpanded(bool))
})

export default connect(mapStateToProps, mapDispatchToProps)(Home2022June);
