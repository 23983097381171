import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'withRouter';

class PanelLeft extends Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { children } = this.props;
    return (
      <div className="PanelLeft">
        <style jsx>{`
          .PanelLeft {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            width: calc(17vw - 80px);
            min-width: 125px;
            background: white;
            border-right: 1px solid rgba(0, 0, 0, 0.2);
            z-index: 1001;
            padding: 70px 40px 20px 40px;
            overflow-y: scroll !important;
            -ms-overflow-style: none;
            overflow: -moz-scrollbars-none;
          }
          .PanelLeft::-webkit-scrollbar {
            display: none;
          }
        `}</style>
        {children}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  ...state
})

export default withRouter(connect(mapStateToProps, {}) (PanelLeft));
