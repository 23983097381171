import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'withRouter';
import categories from '../../../../data/categories';
import FEASTON_LOGO from '../../../../assets/Feaston/feastonLogo.png';
import { Link } from 'react-router-dom';
import MobileDescContainer from '../../../components/MobileDescContainer';

function pad(n, length) {
  var len = length - (''+n).length;
  return (len > 0 ? new Array(++len).join('0') : '') + n
}

class Feaston extends Component {

  constructor(props) {
    super(props);
    this.state = {
      productsInProject: [],
      project: "feaston",
    };
    this.initiateLoadedProducts = this.initiateLoadedProducts.bind(this);
  }

  initiateLoadedProducts(products) {
    const project_url = this.state.project;
    let temp_product_array = [];
    if (products) {
      for (let c of categories[project_url].products) {
        for (let p of products) {
          if (c === p.handle) {
            temp_product_array.push(p);
            break;
          } else if (c.image) {
            temp_product_array.push(c);
            break;
          }
        }
      }
      this.setState({
        productsLoaded: true,
        productsInProject: temp_product_array,
      })
    }
  }

  componentWillMount() {
    this.initiateLoadedProducts(this.props.fetchProductsReducer.products);
  }

  componentWillReceiveProps(nextProps) {
    this.initiateLoadedProducts(nextProps.fetchProductsReducer.products);
  }

  render() {
    const { productsInProject } = this.state;
    return (
      <div className="Feaston web-page">
        <style jsx>{`
          .Feaston {
            font-family: Cousine;
            height: 100vh;
          }
          .Feaston .Feaston-mid {
            padding-top: 40px;
            font-weight: bold;
            font-size: 16px;
          }
          .Feaston .Feaston-title {
            margin-bottom: 30px;
          }
          .Feaston .Feaston-printNumber-container {
            overflow-y: unset;
            word-wrap: break-word;
            width: 75%;
            margin: auto;
          }
          .Feaston .Feaston-printNumber-container .Feaston-printNumber {
            text-decoration: underline;
            cursor: pointer;
            margin: 15px 10px;
            display: inline-block;
            text-align: center;
            width: 30%;
          }

          .Feaston .Top-item {
            flex: 0 0 auto;
            align-items: center;
            display: flex;
          }
          .Feaston .Feaston-logo {
            margin: 0 20px;
          }
          .Feaston .Feaston-logo img {
            width: 200px;
          }
          .Feaston .Feaston-desc {
            text-align: left;
            width: 160px;
            padding-right: 20px;
            font-size: 14px;
          }
        `}</style>
        <div className="mobile-web-mid Feaston-mid">
          <div className="Feaston-title">
            SINGLE-PRINT
          </div>
          <div className="Feaston-printNumber-container">
            {productsInProject.map((p, i) => {
              return (
                <Link key={`Feaston-printNumber-${i}`} to={`/project/feaston/${p.handle}`}>
                  <div className="Feaston-printNumber">
                    {pad(i+1, 3)}
                  </div>
                </Link>
              )
            })}
            {productsInProject.map((p, i) => {
              return (
                <Link key={`Feaston-printNumber2-${i}`} to={`/project/feaston/${p.handle}`}>
                  <div className="Feaston-printNumber">
                    {pad(i+1, 3)}
                  </div>
                </Link>
              )
            })}
          </div>
        </div>
        <MobileDescContainer>
            <div className="Top-item Feaston-logo">
              <img src={FEASTON_LOGO} alt="Feaston" />
            </div>
            <div className="Top-item Feaston-desc">
              Feaston is an
              on&#8209;going graphic
              project started in 2018. All graphics
              in this project
              are co&#8209;created in a 
              connected virtual 
              space. Within this virtual space, each
            </div>
            <div className="Top-item Feaston-desc">
              participant has the freedom to include
              any new imagery, amend and exclude any existing imagery while all amendments 
              are visible to all 
              participants.
            </div>
            <div className="Top-item Feaston-desc">
              Selected graphics from Feaston are now available as single print editions.<br/>
              <br/>
              Navigate Feaston with arrow keys or view specific prints by clicking on the numbered code. 
            </div>
        </MobileDescContainer>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  ...state
})

export default withRouter(connect(mapStateToProps, {}) (Feaston));
