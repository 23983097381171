export default (state = {}, action) => {
  switch (action.type) {
   case 'FETCH_PRODUCTS':
    return {
      products: [...(state.products || []), ...action.payload]
    }
   default:
    return state
  }
 }
 