import { useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchIsNavigationExpanded } from 'actions/fetchIsNavigationExpanded';

function UseScrollDirection(props) {
  const { fetchIsNavigationExpanded, fetchIsNavigationExpandedReducer } = props;
  const { isExpanded } = fetchIsNavigationExpandedReducer;
  const scrollDirection = isExpanded;

  // useEffect(() => {
  //   const threshold = 0;
  //   let lastScrollY = window.pageYOffset;
  //   let ticking = false;
  
  //   const updateScrollDir = () => {
  //     const scrollY = window.pageYOffset;
  
  //     if (Math.abs(scrollY - lastScrollY) < threshold) {
  //       ticking = false;
  //       return;
  //     }
  //     // fetchIsNavigationExpanded(scrollY > lastScrollY ? "scrolling down" : "scrolling up");
  //     fetchIsNavigationExpanded(scrollY > lastScrollY ? false : true);
  //     lastScrollY = scrollY > 0 ? scrollY : 0;
  //     ticking = false;
  //   };
  
  //   const onScroll = () => {
  //     if (!ticking) {
  //       window.requestAnimationFrame(updateScrollDir);
  //       ticking = true;
  //     }
  //   };
  
  //   window.addEventListener("scroll", onScroll);
  
  //   return () => window.removeEventListener("scroll", onScroll);
  // }, [scrollDirection]);

  // useEffect(() => {
  //   let lastScrollY = window.pageYOffset;
  //   const updateScrollDirection = (e) => {
  //     const ele = e.currentTarget;
  //     const scrollY = ele.pageYOffset;
  //     const direction = scrollY > lastScrollY ? "down" : "up";
  //     if (direction !== scrollDirection && (scrollY - lastScrollY > 3 || scrollY - lastScrollY < -3)) {
  //       // setScrollDirection(direction);
  //       console.log('direction', direction);
  //       fetchIsNavigationExpanded(direction === "up");
  //     }
  //     lastScrollY = scrollY > 0 ? scrollY : 0;
  //   };
  //   window.addEventListener("scroll", updateScrollDirection); // add event listener
  //   return () => {
  //     window.removeEventListener("scroll", updateScrollDirection); // clean up
  //   }
  // }, [scrollDirection]);

  return scrollDirection;
};

const mapStateToProps = state => ({
  ...state
})

const mapDispatchToProps = (dispatch) => ({
  fetchIsNavigationExpanded: (isExpanded) => dispatch(fetchIsNavigationExpanded(isExpanded)),
});

// export default useScrollDirection;
export default connect(mapStateToProps, mapDispatchToProps)(UseScrollDirection);