export default function fetchIsNavigationExpandedReducer(state = {}, action) {
  switch (action.type) {
    case "FETCH_IS_NAVIGATION_EXPANDED":
      return {
        isExpanded: action.payload,
      };
    default:
      state.isExpanded =
        state.isExpanded === undefined ? true : state.isExpanded;
      return state;
  }
};
