import React, { Component } from 'react';
import { connect } from 'react-redux';
import categories from '../../data/categories/index.jsx';

import { Link } from 'react-router-dom';
import { withRouter } from 'withRouter';
import PanelRight from 'components/PanelRight';
import { Helmet } from "react-helmet";
import LazyLoad from 'react-lazyload';
import OS from './OS.png';
import HEAD01 from './bigHeads/LBphotos-01.png';
import HEAD02 from './bigHeads/LBphotos-02.png';
import HEAD03 from './bigHeads/LBphotos-03.png';
import HEAD04 from './bigHeads/LBphotos-04.png';
import HEAD05 from './bigHeads/LBphotos-05.png';
import HEAD06 from './bigHeads/LBphotos-06.png';
import HEAD07 from './bigHeads/LBphotos-07.png';
import HEAD08 from './bigHeads/LBphotos-08.png';
import HEAD09 from './bigHeads/LBphotos-09.png';
import HEAD10 from './bigHeads/LBphotos-10.png';
import HEAD11 from './bigHeads/LBphotos-11.png';
import HEAD12 from './bigHeads/LBphotos-12.png';
import HEAD13 from './bigHeads/LBphotos-13.png';
import HEAD14 from './bigHeads/LBphotos-14.png';
import HEAD15 from './bigHeads/LBphotos-15.png';
import HEAD16 from './bigHeads/LBphotos-16.png';
import FOOTER_IMG from './FEASTON_ORANGE_1_SQUARE.png';
import FEASTON_SOUND from './feaston_trimmed2.mp3';
import HomeProductWidget from './HomeProductWidget';
import ReactCardFlip from 'react-card-flip';

function shuffle(array) {
  var currentIndex = array.length, temporaryValue, randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {

    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
}

const PlaySound = function () {
  var audio = new Audio(FEASTON_SOUND);
  audio.loop = false;
  audio.play(); 
}

function random_item(items) { 
  return items[Math.floor(Math.random()*items.length)];
}

let headsArray = shuffle([HEAD01, HEAD02, HEAD03, HEAD04, HEAD05, HEAD06, HEAD07, HEAD08, HEAD09, HEAD10, HEAD11, HEAD12, HEAD13, HEAD14, HEAD15, HEAD16]);

class Home extends Component {

  constructor(props) {
    super(props);
    this.state = {
      productsLoaded: false,
      category: "",
      headsArray: headsArray,
      headsArrayRandomItemFront: headsArray[1],
      headsArrayRandomItemBack: headsArray[0],
      isFlipped: false,
      flippedTime: 0,
      started: false,
    };
    this.initiateLoadedProducts = this.initiateLoadedProducts.bind(this);
    this.changeRoute = this.changeRoute.bind(this);
    this.handleFlip = this.handleFlip.bind(this);
    this.addListenerToHead = this.addListenerToHead.bind(this);
    this.startFlip = this.startFlip.bind(this);
  }

  changeRoute(category_url) {
    this.setState({
      category: category_url,
    }, () => {
      this.props.history.push({
        pathname: `/${category_url}`,
        state: { category: category_url }
        // state: { product: p } // data size too large to be passed through history.push, get data from redux store instead.
      });
    });
  }

  componentDidMount() {
    window.imageMapResize();
    window.addEventListener('scroll', this.handleScroll);
    this.addListenerToHead();
    this.startFlip();
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
    if (window.document.getElementsByClassName("react-card-front")) {
      window.document.getElementsByClassName("react-card-front")[0].removeEventListener("transitionend", () => this.handleFlip(), false);
    }
  }

  handleScroll(event) {
    window.imageMapResize();
  }

  handleFlip() {
    this.setState(prevState => ({ 
      started: true,
      isFlipped: !prevState.isFlipped,
      flippedTime: !prevState.started ? prevState.flippedTime + 2 : (prevState.flippedTime >= headsArray.length - 1 ? 0 : prevState.flippedTime + 1),
      headsArrayRandomItemFront: (!prevState.isFlipped) ? prevState.headsArrayRandomItemFront : prevState.headsArray[prevState.flippedTime],
      headsArrayRandomItemBack: (prevState.isFlipped) || (!prevState.started) ? prevState.headsArrayRandomItemBack : prevState.headsArray[prevState.flippedTime],
      headsArray: prevState.flippedTime >= headsArray.length - 1 ? shuffle(prevState.headsArray) : prevState.headsArray,
    }));
  }

  addListenerToHead() {
    if (!window.document.getElementById("bigHeadFront")) {
      setTimeout(() => {
        this.addListenerToHead(window.document.getElementById("bigHeadFront"));
      }, 500);
    } else {
      window.document.getElementsByClassName("react-card-front")[0].addEventListener("transitionend", () => this.handleFlip(), false);
    }
  }

  startFlip() {
    if (!window.document.getElementById("bigHeadFront")) {
      setTimeout(() => {
        this.startFlip();
      }, 500);
    } else {
      this.handleFlip();
    }
  }

  initiateLoadedProducts(products, props) {
    const category_url = props.location.pathname.replace("/project/","");
    if (category_url == "/project" || category_url == "") {
      this.setState({
        productsLoaded: true,
        category: "",
      })
    } else if (categories[category_url]) {
      this.setState({
        productsLoaded: true,
        category: category_url,
      });
    }
  }

  getProductFromUrl(url) {
    const { fetchProductsReducer } = this.props;
    const { products } = fetchProductsReducer;
    const curr_category = categories[url];
    const curr_handle = curr_category.products[0];
    if (products && products.length > 0) {
      for (let product of products) {
        const { handle } = product;
        if (handle === curr_handle) {
          return product;
        }
      }
    }
    return null;
  }

  componentWillMount() {
    this.initiateLoadedProducts(this.props.fetchProductsReducer.products, this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.initiateLoadedProducts(nextProps.fetchProductsReducer.products, this.props);
  }

  componentWillUpdate(nextProps) {
    if (this.props.location !== nextProps.location) {
      this.initiateLoadedProducts(nextProps.fetchProductsReducer.products, nextProps);
    }
  }

  render() {
    const viewportWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    const slideHeight = Math.floor((1920 * viewportWidth) / 3072);
    const { headsArrayRandomItemFront, headsArrayRandomItemBack, headsArray } = this.state;

    const BigHead = ({ img }) => {
      return (
        <div className="bigHead" onMouseEnter={() => PlaySound() }>
          <style jsx>{`
            .bigHead {
              width: 100%;
              margin-bottom: 22px;
            }
            .bigHead img {
              width: 100%;
            }
          `}</style>
          <img src={img} alt="" />
        </div>
      );
    }

    return (
      <div className="Home">
        <Helmet>
          <title>FEASTON</title>
          {/* <meta name="description" content="FEASTON"/> */}
          <meta property="og:image" content="https://feaston.co/favicon.ico" />
          <meta property="og:title" content="FEASTON"/>
          <link rel="canonical" href="https://feaston.co" />
        </Helmet>
        <style jsx>{`
          .HomePageBanner {
            overflow-y: auto;
            display: flex;
          }
          .Home .homeCover {
            height: 100%;
            position: relative;
            min-width: 1155px;
            display: flex;
            background: white;
            align-items: center;
            //min-height: 880px;
            justify-content: center;
          }
          .Home .homeCover .homeCover-inner {
            display: flex;
            align-items: center;
            height: 100%;
          }
          .Home .homeCover .schoolLogo {
            //position: absolute;
            //width: 50%;
            max-width: 600px;
            //left: -150px;
            //top: -240px;
            position: relative;
            z-index: 1;
            mix-blend-mode: multiply;
            padding-top: 20px;
          }
          .Home .homeCover .column {
            width: 127px;
            margin-right: 75px;
          }
          .Home .homeCover .column1 {
            margin-top: 165px;
            margin-left: 205px;
            position: relative;
          }
          .Home .homeCover .column2 {
            margin-top: 115px;
          }
          .Home .homeCover .column3 {
            margin-top: 45px;
          }
          .Home .homeCover .column4 {
            margin-top: 115px;
          }
          .Home .homeCover .column5 {
            margin-top: 45px;
          }
          .Home .homeCover .column6 {
            margin-top: 165px;
          }
          .Home .home-row {
            display: flex;
            max-width: 1417px;
            margin: auto;
          }
          .Home .lazyload-wrapper {
            min-height: ${`${slideHeight}px`};
          }
          .HomePageBanner .homeBanner-container-wrapper {
            height: fit-content;
            margin-left: auto;
            margin-right: auto;
          }
          .HomePageBanner .homeBanner-container-wrapper ::-webkit-scrollbar {
            width: 0px;  /* Remove scrollbar space */
            background: transparent;  /* Optional: just make scrollbar invisible */
          }
          .HomePageBanner .homeBanner-container-wrapper ::-webkit-scrollbar-thumb {
            background: #FF0000;
          }
          .Home .feaston-home {
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;
            margin-bottom: 100px;
            min-height: 100vh;
          }
          .Home .feaston-home img, .Home .feaston-home-footer img {
            max-height: 100%;
            max-width: 100%;
            margin: 0 auto;
            object-fit: contain;
          }
          .HomePageBanner .homeBanner-container {
            position: relative;
            width: 80%;
            height: 850px;
            margin: auto;
            z-index: 999;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
          }
          .HomePageBanner .homeBanner-container.c-top{
            margin-top: 150px;
          }
          .HomePageBanner .homeBanner {
            //max-width: 100vw;
            position: absolute;
          }
          .HomePageBanner .homeBanner.product-item {
            position: unset;
            max-width: 600px !important;
          }
          .HomePageBanner .homeBanner.product-1 {
            left: 40px;
            top: 50px;
          }
          .HomePageBanner .homeBanner.product-2 {
            width: 400px;
            left: 800px;
            top: -80px;
            transform: rotate(0deg);
          }
          .HomePageBanner .homeBanner.product-3 {
            left: 40px;
            top: 50px;
            z-index: 10;
          }
          .HomePageBanner .homeBanner.product-4 {
            width: 400px;
            left: 800px;
            top: -80px;
            transform: rotate(0deg);
          }
          .HomePageBanner .homeBanner img{
            width: 100%;
            margin: auto;
          }
          .HomePageBanner .homeBanner .banner, map area{
            outline: none;
          }
          .HomePageBanner .homeBanner-container-wrapper .feaston-home-footer {
            height: 100vh;
            display: flex;
            align-items: center;
            //margin-top: 80px;
          }
          .HomePageBanner .homeBanner-container-wrapper .feaston-home-footer img {
            //max-width: 515px; //1317px;
            //margin-bottom: 50px;
          }
          .HomePageBanner .homeBanner-arrow {
            height: 120px;
            margin-top: 50px;
          }
          .HomePageBanner .banner-container {
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .HomePageBanner .topBar {
            position: fixed;
            top: 0;
            font-weight: bold;
            height: 28px;
            background: #fff;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 1000;
          }
          .Home .autoHeight .homeBanner-container {
            height: auto;
          }
          .Home .bigHead {
            max-width: 1240px !important;
          }
          .react-card-flip, .react-card-flipper, .react-card-front, .react-card-back, .noSelect {
            -webkit-touch-callout: none;
            -webkit-user-select: none;
            -khtml-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            outline: none !important;
            transition: all 5s linear 0s !important;
          }
          .react-card-flip {
            padding-bottom: 120px;
          }
        `}</style>
        <div className="feaston-home">
          <div className="homeCover">
            <div className="homeCover-inner">
              <div className="cover-wrapper">
                <div className="cover">
                  <img className="schoolLogo" src={OS} useMap="#image-map_01" alt="" />
                  <Link to="/lookbook">
                    <ReactCardFlip isFlipped={this.state.isFlipped} flipDirection="horizontal" infinite={true} /*flipSpeedFrontToBack={5} flipSpeedBackToFront={5}*/>
                      <img className="bigHead noSelect" id="bigHeadFront" src={headsArrayRandomItemFront} useMap="#image-map_01" alt="" />
                      <img className="bigHead noSelect" id="bigHeadBack" src={headsArrayRandomItemBack} useMap="#image-map_02" alt="" />
                    </ReactCardFlip>
                  </Link>
                </div>
              </div>
              {/* <div className="column column1">
                <img className="schoolLogo" src={OS} alt="" />
                <BigHead img={headsArray[0]} />
                <BigHead img={headsArray[1]} />
              </div>
              <div className="column column2">
                <BigHead img={headsArray[2]} />
                <BigHead img={headsArray[3]} />
                <BigHead img={headsArray[4]} />
              </div>
              <div className="column column3">
                <BigHead img={headsArray[5]} />
                <BigHead img={headsArray[6]} />
                <BigHead img={headsArray[7]} />
              </div>
              <div className="column column4">
                <BigHead img={headsArray[8]} />
                <BigHead img={headsArray[9]} />
                <BigHead img={headsArray[10]} />
              </div>
              <div className="column column5">
                <BigHead img={headsArray[11]} />
                <BigHead img={headsArray[12]} />
                <BigHead img={headsArray[13]} />
              </div>
              <div className="column column6">
                <BigHead img={headsArray[14]} />
                <BigHead img={headsArray[15]} />
              </div> */}
            </div>
          </div>
        </div>
        {this.state.category === "" ? (
          <div className="">
            <div className="home-row">
              <HomeProductWidget url="opium_socks" />
              <HomeProductWidget url="examiners_tee" />
            </div>
            <div className="home-row">
              <HomeProductWidget url="dj_rothschild_tee" />
              <HomeProductWidget url="scs_tee" />
            </div>
            <div className="home-row">
              <HomeProductWidget url="96km_h_tee" />
              <HomeProductWidget url="feaston_labs_tee" />
            </div>
            <div className="home-row">
              <HomeProductWidget url="opium_tee" />
              <HomeProductWidget url="juk_tee" />
            </div>
            <div className="home-row">
              <HomeProductWidget url="ljmb_tee" />
              <HomeProductWidget url="fushinsha_sweat" />     
            </div>
            <div className="home-row">
              <HomeProductWidget url="panopticon_tee" /> 
              <HomeProductWidget url="grandpa_tee" />             
            </div>
            <div className="home-row">
              <HomeProductWidget url="kid_tee" />
              <HomeProductWidget url="sunn5G_tee" />
            </div>
            <div className="home-row">
              <HomeProductWidget url="smile_tee" />
              <HomeProductWidget url="toast_tee" /> 
              {/* <HomeProductWidget url="logo_hood_black" /> */}
            </div>
            <div className="home-row">
              <HomeProductWidget url="imported_cap" /> 
              <HomeProductWidget url="opium_mug" />       
              {/* <HomeProductWidget url="opium_sweat" /> */}   
            </div>
            <div className="home-row">
              {/* <HomeProductWidget url="andy_lau" /> */}
              <HomeProductWidget url="band1_tee" />
              <HomeProductWidget url="names_tee" />
              {/* <HomeProductWidget url="logo_hood_red" /> */}
              {/* <HomeProductWidget url="juk_hood" /> */}
            </div>
            <div className="home-row">
              <HomeProductWidget url="marker_tee" /> 
              <HomeProductWidget url="3107_hood" />
              {/* <HomeProductWidget url="imported_hood" /> */}
              {/* <HomeProductWidget url="ES_hood" /> */}
            </div>
            <div className="home-row">
              <HomeProductWidget url="yaohan_hood" />  
            </div>
             
            <div className="feaston-home-footer" style={{ marginBottom: '50px', padding: '0 80px' }}>
              <img src={FOOTER_IMG} alt="" />
            </div> 
          </div>
        ) : (
          this.state.productsLoaded ?
            categories[this.state.category].component :
            <div style={{ fontSize: "30px" }}> {/* To be replaced by Loading animation */}
                Loading...
            </div>
        )}

        <PanelRight noBorder noBackground atHome/*withPath*/ />
      </div>
      
    );
  }
}

const mapStateToProps = state => ({
  ...state
})

export default withRouter(connect(mapStateToProps, {}) (Home));
