import React, { Component, useState } from 'react';
import { connect } from 'react-redux';
import ScrollManager from "ScrollManager/index.jsx";
import { Helmet } from "react-helmet";
import CONTACT_IMG_1 from "_Mobile/scenes/MobileContact/contact_1.png";
import CONTACT_IMG_2 from "_Mobile/scenes/MobileContact/contact_2.png";
import CONTACT_IMG_3 from "_Mobile/scenes/MobileContact/contact_3.png";
import { Link } from "react-router-dom";

function Contact() {
  return (
    <div className="Contact">
      <style jsx>{`
        .App.App_desktop {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .Contact {
          padding-bottom: 60px;
        }
        .Contact .contact_item {
          mix-blend-mode: multiply;
        }
        .Contact .contact1 {
          position: fixed;
          left: 10vw;
          top: 13vh;
          height: 210px;
          width: 30vw;
          margin: auto;
          padding-top: 10vh;
        }
        .Contact .contact2 {
          position: fixed;
          left: 35vw;
          top: 3vh;
          width: 25vw;
          margin: auto;
          margin-top: 250px;
          padding-left: 25vw;
        }
        .Contact .contact3 {
          position: fixed;
          left: 20vw;
          top: 75vh;
          width: 25vw;
          margin: auto;
          padding-right: 20vw;
        }
        .Contact img {
          width: 100%;
          padding-bottom: 50px;
          background-blend-mode: multiply;
        }
      `}</style>
      <div>
        {/* <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div style={{ display: 'flex', justifyContent: 'center' }}> */}
            <div className="contact_item contact1">
              <div>
                <Link to={'/address'}>
                  <img src={CONTACT_IMG_1} alt="" />
                </Link>
              </div>
            {/* </div>
          </div> */}


          <div className="contact_item contact2">
            <a href="https://www.instagram.com/feaston96/" target="blank" >
              <img src={CONTACT_IMG_2} alt="" />
            </a>
          </div>

        </div>

        <div className="contact_item contact3">
          <a href="mailto:info@feaston.co">
            <img src={CONTACT_IMG_3} alt="" />
          </a>
        </div>


      </div>
    </div >
  )
}

const mapStateToProps = state => ({
  ...state
})

export default connect(mapStateToProps, {})(Contact);
