import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import configureStore from "./store";

import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import WebFont from "webfontloader";
import TagManager from "react-gtm-module";
import { BrowserRouter } from "react-router-dom";

const tagManagerArgs = {
  gtmId: "GTM-KC2VCVG",
};

TagManager.initialize(tagManagerArgs);

WebFont.load({
  google: {
    families: ["Cousine"],
  },
});

ReactDOM.render(
  <Provider store={configureStore()}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
