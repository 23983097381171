import React, { Component } from 'react';
import { connect } from 'react-redux';
import { find } from 'lodash';
import MobileProductPathContainer from '_Mobile/components/MobileProductPathContainer';
import ProductWidgetFeaston from './ProductWidgetFeaston';
import ProductWidgetLMMG from './ProductWidgetLMMG';

import TESTING_IMG from './testing.png';
import MobileDescContainer from '../../components/MobileDescContainer';

class Product extends Component {

  constructor(props) {
    super(props);
    this.state = {
      product: null,
    };
  }

  componentWillMount() {
    if (this.props.fetchProductsReducer.products) {
      this.setState({
        product: find(this.props.fetchProductsReducer.products, { handle: this.props.match.params.product_handle }),
      })
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.fetchProductsReducer.products) {
      this.setState({
        product: find(nextProps.fetchProductsReducer.products, { handle: this.props.match.params.product_handle }),
      })
    }
  }

  render() {
    const { product } = this.state;
    const { match } = this.props;
    const { params } = match;
    console.log('for checking html', product);
    return (
      <div className="Product web-page">
        <style jsx>{`
          .Product {
            height: calc(100vh - 61px);
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .Product .Product-mid {
            font-weight: bold;
            font-size: 28px;
            top: unset;
            height: calc(100vh - 40px);
          }
          .Product .Product-mid img {
            max-width: 100%;
          }
          .Product .Product-panelLeft {
            font-size: 13px;
            text-align: left;
            padding: 10px;
          }
          .Product .Top-item {
            flex: 0 0 auto;
            align-items: center;
            display: flex;
          }
          .Product .Feaston-desc {
            text-align: left;
            width: 160px;
            padding-right: 20px;
            font-size: 14px;
          }
        `}</style>
        

        {product ? (
        <div className="mobile-web-mid Product-mid">
          {/* <img src={product.images[0].src} alt="product_image" /> */}
          <MobileDescContainer page="product">
          {/* <div className="Product-panelLeft">
            <div dangerouslySetInnerHTML={{__html: product ? product.descriptionHtml : null}} />
          </div> */}
          <div className="Top-item Feaston-logo">
            </div>
            <div className="Top-item Feaston-desc">
              Feaston is an
              on&#8209;going graphic
              project started in 2018. All graphics
              in this project
              are co&#8209;created in a 
              connected virtual 
              space. Within this virtual space, each
            </div>
            <div className="Top-item Feaston-desc">
              participant has the freedom to include
              any new imagery, amend and exclude any existing imagery while all amendments 
              are visible to all 
              participants.
            </div>
            <div className="Top-item Feaston-desc">
              Selected graphics from Feaston are now available as single print editions.<br/>
              <br/>
              Navigate Feaston with arrow keys or view specific prints by clicking on the numbered code. 
            </div>
        </MobileDescContainer>
        {/* <PanelRight withPath>
          <div className="PanelRight-productWidget">
            {product && params.project === "feaston" ? (
              <ProductWidgetFeaston
                product={product}
              />
            ) : null}
            {product && params.project === "lmmg" ? (
              <ProductWidgetLMMG
                product={product}
              />
            ) : null}
          </div>
        </PanelRight> */}
          <img src={TESTING_IMG} alt="product_image" />
          {/* <div className="mobile-productPage-productWidget">
            <MobileProductPathContainer withPath>
              {product && params.project === "feaston" ? (
                <ProductWidgetFeaston
                  product={product}
                />
              ) : null}
              {product && params.project === "lmmg" ? (
                <ProductWidgetLMMG
                  product={product}
                />
              ) : null}
            </MobileProductPathContainer>
          </div> */}

        </div>) : null}
        
      </div>
    );
  }
}

const mapStateToProps = state => ({
  ...state
})

export default connect(mapStateToProps, {}) (Product);