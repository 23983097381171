import React, {Component} from 'react';
import { client } from 'shopify';
import { isEmpty } from 'functions.js';

class MobileCartVariantSelector extends Component {

  constructor(props) {
    super(props);
    let defaultOptionValues = {};
    if (!isEmpty(props.line_item)) {
      props.line_item.variant.selectedOptions.forEach((selector) => {
          defaultOptionValues[selector.name] = selector.value;
      });
    }
    this.state = {
      selectedOptions: defaultOptionValues,
    }
  }

  render() {
    const { product, option } = this.props;

    let unavailableValues = [];
    let selectedOptions = this.state.selectedOptions;
    for (let value of option.values) {
      selectedOptions[option.name] = value.value;
      const selectedVariant = client.product.helpers.variantForOptions(product, selectedOptions);
      if (selectedVariant && !selectedVariant.available) {
        unavailableValues.push(value.value);
      }
    }

    return (
        <div>
          <style jsx>{`
            .MobileCartVariantSelector {
              background: transparent;
              border: none;
              -moz-appearance: none;
              -webkit-appearance: none;
              font-size: 15px;
              color: black;
              //margin: ${/iPhone/i.test(navigator.userAgent) ? "-5px" : "0"}; // for ios
            }
          `}</style>
          <select
            className="MobileCartVariantSelector"
            name={this.props.option.name}
            key={this.props.option.name}
            onChange={this.props.handleOptionChange}
            defaultValue={this.props.selected}
          >
            {this.props.option.values.map((value) => {
              return (
                <option disabled={unavailableValues.includes(value.value)} value={value} key={`${this.props.option.name}-${value}`}>{`${value}`}</option>
              )
            })}
          </select>
        </div>
    );
  }
}

export default MobileCartVariantSelector;
