import React, {Component} from 'react';
import CartVariantSelector from './CartVariantSelector';
import { isEmpty } from 'functions.js';
import { connect } from 'react-redux';

class LineItem extends Component {
  constructor(props) {
    super(props);

    this.decrementQuantity = this.decrementQuantity.bind(this);
    this.incrementQuantity = this.incrementQuantity.bind(this);
    this.removeItem = this.removeItem.bind(this);
  }

  decrementQuantity(lineItemId) {
    const updatedQuantity = this.props.line_item.quantity - 1
    this.props.updateQuantityInCart(lineItemId, updatedQuantity, this.props.line_item.variant.id);
  }

  incrementQuantity(lineItemId) {
    const updatedQuantity = this.props.line_item.quantity + 1
    this.props.updateQuantityInCart(lineItemId, updatedQuantity, this.props.line_item.variant.id);
  }

  removeItem(lineItemId) {
    this.props.updateQuantityInCart(lineItemId, 0, this.props.line_item.variant.id);
  }

  render() {
    const { product, line_item, warning } = this.props;
    let descriptionHtml = null;
    if (!isEmpty(product)) {
      descriptionHtml = product.descriptionHtml;
    }
    let el = window.document.createElement('html');
    if (descriptionHtml) {
      el.innerHTML = descriptionHtml;
    }
    let title = el.getElementsByClassName('title')[0] ? el.getElementsByClassName('title')[0].innerHTML : line_item.title;

    const currencyAfterConversion = (price) => {
      return (window.Currency.convert(price, this.props.checkoutCurrencyCode, this.props.fetchSelectedCurrencyReducer.selectedCurrency) * line_item.quantity).toFixed(2)
    };

    return (
      <div className="LineItem">
        <style jsx>{`
          .LineItem .LineItem__title {
            font-size: 18px;
            padding-bottom: 5px;
            text-align: left;
          }
          .LineItem .LineItem__img {
            width: 100px;
            height: 100px;
            border-radius: 3px;
            display: flex;
            justify-content: center;
            margin-right: 20px;
          }
          .LineItem .LineItem__img img{
            width: 100%;
            min-width: 50px;
            object-fit: scale-down;
          }
          .LineItem .LineItem__Content {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            font-size: 16px;
            padding-bottom: 10px;
          }
          .LineItem .LineItem__quantity-container {
            display: flex;
            flex-direction: row;
            align-items: center;
          }
          .LineItem .LineItem__color {
            text-align: left;
          }
          .LineItem .LineItem__quantity-container .LineItem__quantity-update-container {
            display: flex;
            flex-direction: column;
          }
          .LineItem .LineItem__quantity-update {
            cursor: pointer;
          }
          .LineItem .size-qty-price-container {
            display: flex;
          }
          .LineItem .remove-row {
            font-size: 10px;
            font-style: italic;
            text-align: left;
            margin-top: 8px;
            cursor: pointer;
          }
          .warning {
            color: red;
            font-size: 12px;
            text-align: left;
          }
        `}</style>
        <div className="LineItem__title">
          {title.split('\\n').map(function(item) {
            return (
              <span key={item}>
                {item.replace(/-/g, '‑')}
                <br/>
              </span>
            )
          })}
        </div>

        <div className="LineItem__Content">
          <div className="LineItem__img">
            {this.props.line_item.variant.image ? <img src={this.props.line_item.variant.image.src} alt={`${this.props.line_item.title} product shot`}/> : null}
          </div>
        
        <div className="img-right-container">
          <div className="size-qty-price-container">
            {warning ? <div className="warning">{warning}</div> : null}
            <div className="size-qty-container">

              {line_item.variant.selectedOptions.map((option) => {
                if (option.name === "Color") {
                  return (
                    <div className="color-container" key={option.value}>
                      <div className="LineItem__color">
                        <CartVariantSelector
                          handleOptionChange={(e) => this.props.handleOptionChange(e, this.props.line_item, this.props.product)}
                          option={this.props.options.find(o => o.name === "Color")}
                          selected={option.value}
                          product={product}
                          line_item={line_item}
                        />
                      </div>
                    </div>
                  )
                }
                return null;
              })}

              {this.props.options.map((option) => {
                  let selected = "";
                  if (option.name === "Size") {
                    for (let o of this.props.line_item.variant.selectedOptions) {
                      if (o.name === "Size") {
                        selected = o.value;
                        break;
                      }
                    }
                  }
                  return (
                    option.name === "Size" ?
                      <div className="LineItem__variant" key={option.id.toString()}>
                        <style jsx>{`
                          .LineItem .LineItem__variant {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            overflow-y: unset;
                          }
                          .LineItem .LineItem__variant-title {
                            margin-right: 8px;
                            padding-top: 2px;
                          }
                          .LineItem .LineItem__price {
                            margin-left: 8px;
                            width: 90px;
                            font-size: 18px;
                          }
                          .LineItem .LineItem__quantity-update {
                            font-size: 16px;
                            cursor: pointer;
                          }
                        `}</style>
                        <div className="LineItem__variant-title">size</div>
                        <CartVariantSelector
                          handleOptionChange={(e) => this.props.handleOptionChange(e, this.props.line_item, this.props.product)}
                          option={option}
                          selected={selected}
                          product={product}
                          line_item={line_item}
                        />
                      </div> : null
                  );
                })}

                <div className="LineItem__quantity-container">
                  qty&nbsp;
                  <div className="LineItem__quantity-update" onClick={() => this.incrementQuantity(this.props.line_item.id)}>+</div>
                  &nbsp;<div className="LineItem__quantity">{this.props.line_item.quantity}</div>&nbsp;
                  <div className="LineItem__quantity-update" onClick={() => this.decrementQuantity(this.props.line_item.id)}>-</div>
                </div>
            </div>

            <div className="LineItem__price">
              {this.props.fetchSelectedCurrencyReducer.selectedCurrency} {currencyAfterConversion(this.props.line_item.variant.price.amount)}
            </div>
          </div>

          <div className="remove-row" onClick={() => this.removeItem(this.props.line_item.id)}>
            remove item
          </div>
        </div>
    </div>

    </div>
    );
  }
}

const mapStateToProps = state => ({
  ...state
})

export default connect(mapStateToProps, {}) (LineItem);