import { connect } from 'react-redux';
import { Link, useParams } from "react-router-dom";
import MobileFeatureCollection from '_Mobile/components/MobileFeatureCollection';

function ArchivedFeatureCollection(props) {
  const { collection: collectionQuery } = useParams();
  const { fetchArchivedFeatureCollectionsReducer } = props;
  const { collections = [] } = fetchArchivedFeatureCollectionsReducer;
  const foundCollection = collections.find((collection) => collection.handle === collectionQuery);
  console.log('foundCollec', foundCollection)
  const collectionBannerImg = foundCollection?.metafields?.find(
    (metafield) => metafield?.key === "collection_banner_url_mobile"
  )?.value;
  const collectionBannerPath = foundCollection?.metafields?.find(
    (metafield) => metafield?.key === "collection_banner_link_path"
  )?.value;
  
  const CollectionBanner = () => (
    <div>
      <img style={{ width: '634px', height: 'auto' }} src={collectionBannerImg} alt="" />
    </div>
  )
  return (
    <>
      <style jsx>{`
        .MobileFeatureCollection .price-button-container {
          display: none !important;
        }
      `}</style>
      {collectionBannerImg ? (
        collectionBannerPath ? (
          <Link to={collectionBannerPath}>
            <CollectionBanner />
          </Link>
        ) : <CollectionBanner/>
      ) : null}
      <MobileFeatureCollection collectionProps={foundCollection} />
    </>
  )
}

const mapStateToProps = state => ({
  ...state
})

export default connect(mapStateToProps, {})(ArchivedFeatureCollection);