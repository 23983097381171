import React, { Component } from 'react';
import LineItem from './LineItem';
import { client } from 'shopify';
import { withRouter } from 'withRouter';
import { withCookies } from 'react-cookie';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import EMMPTY_CART_IMG from '_Mobile/assets/jc_empty_cart.png';
import CART_LOADING_IMG from './cart_loading.png';
import { fetchCheckout } from 'actions/fetchCheckout';
import { Helmet } from "react-helmet";
import CHECKOUT_IMG from '_Mobile/components/MobileHeaderCart/checkout.png';


// * deprecated (for cookies ProductsInCart)
// function removeByKey(array, params) {
// 	array.some(function(item, index) {
// 		if (array[index][params.key] === params.value) {
// 			array.splice(index, 1);
// 			return true; // stops the loop
// 		}
// 		return false;
// 	});
// 	return array;
// }

class Cart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cartLoading: false,
      isBoosterLimitReached: false,
    };
    this.openCheckout = this.openCheckout.bind(this);
    this.removeLineItemInCart = this.removeLineItemInCart.bind(this);
    this.updateQuantityInCart = this.updateQuantityInCart.bind(this);
    this.handleOptionChange = this.handleOptionChange.bind(this);
    this.addVariantToCart = this.addVariantToCart.bind(this);
  }

  openCheckout() {
    window.open(this.props.fetchCheckoutReducer.checkout.webUrl);
  }

  updateQuantityInCart(lineItemId, quantity, variantId) {
    const checkoutId = this.props.fetchCheckoutReducer.checkout.id;
    const lineItemsToUpdate = [{ id: lineItemId, quantity: parseInt(quantity, 10) }];
    if (parseInt(quantity, 10) === 0) {
      this.removeLineItemInCart(lineItemId, variantId);
    } else {

      const { lineItems } = this.props.fetchCheckoutReducer.checkout;
      if (lineItems.length > 0) {
        for (let p of lineItems) {
          if (p.variant.id === variantId) {
            // check if booster limit is reached:
            if (p.variant.product.handle === "feaston-booster-pack" && parseInt(quantity, 10) > 3 && parseInt(quantity, 10) - p.quantity > 0) {
              this.setState({
                isBoosterLimitReached: true,
              }, () => {
                setTimeout(() => {
                this.setState({
                  isBoosterLimitReached: false,
                  })
                }, 3000);
              });
              return;
            }
          }
        }
      }

      return client.checkout.updateLineItems(checkoutId, lineItemsToUpdate).then(res => {
        this.props.fetchCheckout(res);
      });
    }
  }

  removeLineItemInCart(lineItemId, variantId) {
    const checkoutId = this.props.fetchCheckoutReducer.checkout.id

    return client.checkout.removeLineItems(checkoutId, [lineItemId]).then(res => {
      this.props.fetchCheckout(res);
    });
  }

  handleOptionChange(event, line_item, product) {
    const target = event.target;

    let selectedOptions = {};
    line_item.variant.selectedOptions.forEach((selector) => {
      selectedOptions[selector.name] = selector.value;
    });
    selectedOptions[target.name] = target.value;

    const selectedVariant = client.product.helpers.variantForOptions(product, selectedOptions);
    this.removeLineItemInCart(line_item.id, line_item.variant.id).then(() => {
      this.addVariantToCart(selectedVariant.id, line_item.quantity);
    });

  }

  addVariantToCart(variantId, quantity) {
    const lineItemsToAdd = [{ variantId, quantity: parseInt(quantity, 10) }];
    const checkoutId = this.props.fetchCheckoutReducer.checkout.id;

    return client.checkout.addLineItems(checkoutId, lineItemsToAdd).then(res => {
      this.props.fetchCheckout(res);
    });
  }

  componentDidMount() {
    let { cookies } = this.props;
    const checkoutId = cookies.get('checkoutID');
    if (checkoutId) {
      client.checkout.fetch(checkoutId).then((checkout) => {
        if (checkout.completedAt) {


          client.checkout.create().then((res) => {
            this.setState({
              cartLoading: false,
            }, () => {
              cookies.set('checkoutID', res.id, { path: '/', expires: new Date(Date.now() + 31556952000) });
              this.props.fetchCheckout(res);
            });
          });


        } else {
          this.setState({
            cartLoading: false,
          }, () => this.props.fetchCheckout(checkout));
        }
      });
    } else {
      client.checkout.create().then((res) => {
        this.setState({
          cartLoading: false,
        }, () => {
          cookies.set('checkoutID', res.id, { path: '/', expires: new Date(Date.now() + 31556952000) });
          this.props.fetchCheckout(res);
        });
      });
    }
  }

  render() {
    let checkout = {};
    const { match, fetchProductsReducer, fetchCheckoutReducer, fetchSelectedCurrencyReducer } = this.props;
    if (fetchCheckoutReducer) {
      checkout = fetchCheckoutReducer.checkout;
    }

    let line_items = checkout && checkout.lineItems && fetchProductsReducer.products ? checkout.lineItems.map((line_item) => {
      let product = null;
      if (fetchProductsReducer.products) {
        for (let p of fetchProductsReducer.products) {
          if (line_item.variant && p.id === line_item.variant.product.id.toString()) {
            product = p;
            break;
          }
        }
      }

      if (!product) {
        this.removeLineItemInCart(line_item.id);
      }

      return product ? (
        <LineItem
          updateQuantityInCart={this.updateQuantityInCart}
          removeLineItemInCart={this.removeLineItemInCart}
          handleOptionChange={this.handleOptionChange}
          key={line_item.id.toString()}
          line_item={line_item}
          product={product}
          options={product ? product.options : []}
          checkoutCurrencyCode={checkout.currencyCode}
          warning={this.state.isBoosterLimitReached && product.handle === 'feaston-booster-pack' ? <>You can only buy<br />3 boosters at a time.</> : ""}
        />
      ) : null;
    }) : null;

    const currencyAfterConversion = (price) => {
      return window.Currency.convert(price, checkout.currencyCode, fetchSelectedCurrencyReducer.selectedCurrency).toFixed(2)
    };

    const { url } = match;

    return (
      fetchCheckoutReducer && checkout ?
        <div className={`Cart${checkout.lineItems.length > 0 ? "" : " Cart_empty"}`} onClick={(e) => e.stopPropagation()}>
          <Helmet>
            <title>FEASTON - Cart</title>
            <link rel="canonical" href={`https://feaston.co${url}`} />
          </Helmet>
          <style jsx>{`
            .Cart {
              height: calc(100vh + 60px); /* -80px */
              width: 100%; /*375px;*/
              max-width: 100%;
              cursor: auto;
              overflow: hidden;
              overflow-y: scroll;
            }
            .Cart .CartLineItems {
              padding: 200px 0 100px 100px;
              width: 45vw;
              display: flex;
              flex-wrap: wrap;
              justify-content: space-between;
            }
            .Cart .CartTotal__container {
              font-size: 20px;
            }
            .Cart .CartTotal__container .shipping-notice {
              font-size: 13px;
              padding: 10px 0;
              line-height: 1.7;
            }
            .Cart .CartTotal__container .CartTotal {
              padding: 0 0 40px 0; /* 0 0 80px 0; */
            }

            .Cart .CartLoading, .Cart .EmptyCart {
              display: flex;
              align-items: center;
              justify-content: center;
            }
            .Cart .CartLoading, .Cart .EmptyCart img {
              height: 100%;
            }
            .Cart_empty {
              height: calc(100vh - 40px);
            }
            .terms {
              text-decoration: underline;
              padding: 0 0 40px 0;
              font-size: 18px;
            }
            .cart-rightPanel {
              position: fixed;
              right: 0;
              width: 20vw;
              top: 200px;
              padding: 40px;
              text-align: right;
            }
            .cart-rightPanel .CartTotal {
              font-size: 28px;
              font-weight: bold;
            }
            .cart-rightPanel .checkoutBtn{
              width: 75%;
              padding-top: 20px;
              height: auto;
              cursor: pointer;
            }
          `}</style>

          {this.state.cartLoading ? (
            <div className="CartLoading">
              <img src={CART_LOADING_IMG} alt="" />
            </div>
          ) : (
            checkout.lineItems.length > 0 ? (
              <div>
                <div className="CartLineItems">
                  {line_items}
                </div>
              </div>
            ) : (
              <div className="EmptyCart">
                <img src={EMMPTY_CART_IMG} alt="" />
              </div>
            )
          )}

          <div className="cart-rightPanel">
            <div className="CartTotal__container">
              <div className="shipping-notice">
                shipping calculated at checkout<br />
                apply discount code at the next step
              </div>
              <Link to="/terms">
                <div className="terms">
                  terms and conditions
                </div>
              </Link>
            </div>
            {this.props.location.pathname === "/cart" ? (
              <div className="CartTotal">
                <span className="CartTotal__total">Total:</span>&nbsp;
                <span className="CartTotal__price">{fetchSelectedCurrencyReducer.selectedCurrency} {currencyAfterConversion(checkout.subtotalPrice?.amount)}</span>
              </div>
            ) : null}
            {this.props.location.pathname === "/cart" ? (
              <div onClick={this.openCheckout}>
                <img className="checkoutBtn" src={CHECKOUT_IMG} alt="" />
              </div>
            ) : null}
          </div>

        </div>
        : null
    )
  }
}

const mapStateToProps = state => ({
  ...state
})

const mapDispatchToProps = dispatch => ({
  fetchCheckout: (checkout) => dispatch(fetchCheckout(checkout)),
})

export default withCookies(withRouter(connect(mapStateToProps, mapDispatchToProps)(Cart)));