import React, { Component } from 'react';
import { connect } from 'react-redux';
// import homeBanner from'assets/Home/homeBanner.png';
// import homeBanner2 from'assets/Home/rdno_mockup_Front_Flat_Black.png';
// import homeBanner3 from'assets/Home/deRucciSmallRes.png';
// import homeBanner4 from'assets/Home/ronaldinoSmallRes.png';
// import FEASTON_HOME from'assets/Home/feaston_home.png';
// import HOME_FOOTER from'assets/Home/web_footer.png';
// import N796FULLPRINT from 'assets/Home/n796FullPrint.png';
// import RONALDIN01 from 'assets/Home/products/ronaldino01.png';
// import HOLYFUCKINGTON from 'assets/Home/products/holyfuckington.png';
// import DJFORESKIN01 from 'assets/Home/products/djforeskin01.png';
// import SINGULARITY01 from 'assets/Home/products/singularity01.png';
// import LMMG01 from 'assets/Home/products/lmmg01.png';
// import FEASTOPHER_01 from 'assets/Home/products/everfeast.png';
// import N796_COLLAR from 'assets/Home/products/n796collar.png';
// import BOTTLE from 'assets/Home/products/bottle_front_med.png';
// import INVESTOR_HP from 'assets/Home/products/investorHP.png';

import ArrowsArrayLarge from '_Mobile/assets/Home/arrows-large/';
// import ArrowsArray from 'assets/Home/arrows/index-mobile.js';
// import APgift600 from "./APgift600.png";
// import XMAS2_MED from "./xmas2_med.png";
import categories from "data/categories/";
import ScrollManager from "ScrollManager/index.jsx";
import { Link } from 'react-router-dom';
// import MobileProductWidgetGeneral from '_Mobile/scenes/MobileProduct/ProductWidgetGeneral';
// import MobileHomeProductWidget from './MobileHomeProductWidget';

// import BANNER1 from "_Mobile/assets/Home/homeBanners/1.png";
// import BANNER2 from "_Mobile/assets/Home/homeBanners/2.png";
// import BANNER3 from "_Mobile/assets/Home/homeBanners/3.png";
// import BANNER4 from "_Mobile/assets/Home/homeBanners/4.png";
// import BANNER5 from "_Mobile/assets/Home/homeBanners/5.png";
// import BANNER6 from "_Mobile/assets/Home/homeBanners/6.png";
// import BANNER7 from "_Mobile/assets/Home/homeBanners/7.png";
// import TESTBANNER1 from "_Mobile/assets/Home/homeBanners/homepageTest1.JPG";
// import APBOX01 from "_Mobile/assets/Home/homeBanners/apbox-01.png";
import IMG01 from "./2021/tinified_jpg/mobile-01.jpg";
import IMG02 from "./2021/tinified_jpg/mobile-02.jpg";
import IMG03 from "./2021/tinified_jpg/mobile-03.jpg";
import IMG04 from "./2021/tinified_jpg/mobile-04.jpg";
import IMG05 from "./2021/tinified_jpg/mobile-05.jpg";
import IMG06 from "./2021/tinified_jpg/mobile-06.jpg";
import IMG07 from "./2021/tinified_jpg/mobile-07.jpg";
import IMG08 from "./2021/tinified_jpg/mobile-08.jpg";
import IMG09 from "./2021/tinified_jpg/mobile-09.jpg";
import IMG10 from "./2021/tinified_jpg/mobile-10.jpg";
import IMG11 from "./2021/tinified_jpg/mobile-11.jpg";
import IMG12 from "./2021/tinified_jpg/mobile-12.jpg";
import IMG13 from "./2021/tinified_jpg/mobile-13.jpg";
import IMG14 from "./2021/tinified_jpg/mobile-14.jpg";
import IMG15 from "./2021/tinified_jpg/mobile-15.jpg";
import IMG16 from "./2021/tinified_jpg/mobile-16.jpg";
import IMG17 from "./2021/tinified_jpg/mobile-17.jpg";
import IMG18 from "./2021/tinified_jpg/mobile-18.jpg";
import IMG19 from "./2021/tinified_jpg/mobile-19.jpg";
import IMG20 from "./2021/tinified_jpg/mobile-20.jpg";
import IMG21 from "./2021/tinified_jpg/mobile-21.jpg";
import IMG22 from "./2021/tinified_jpg/mobile-22.jpg";
import IMG23 from "./2021/tinified_jpg/mobile-23.jpg";
import IMG24 from "./2021/tinified_jpg/mobile-24.jpg";
import IMG25 from "./2021/tinified_jpg/mobile-25.jpg";
import IMG26 from "./2021/tinified_jpg/mobile-26.jpg";
import IMG27 from "./2021/tinified_jpg/mobile-27.jpg";
import IMG28 from "./2021/tinified_jpg/mobile-28.jpg";
import IMG29 from "./2021/tinified_jpg/mobile-29.jpg";
import GAFFER from "./2021/tinified_jpg/gaffer.png";
import RULER1 from "./2021/tinified_jpg/rule_ci1.png";
import { Helmet } from "react-helmet";
import LazyLoad, { forceCheck } from 'react-lazyload';

function getRandomIntInclusive(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min; //The maximum is inclusive and the minimum is inclusive 
}

class MobileHome extends Component {

  constructor(props) {
    super(props);
    this.changeRoute = this.changeRoute.bind(this);
    this.state={
      mobileHomeArrow: ArrowsArrayLarge[getRandomIntInclusive(0, 8)],
    }
    this.getNameFromUrl = this.getNameFromUrl.bind(this);
  }

  changeRoute(category_url) {
    this.props.history.push({
      pathname: `/${category_url}`,
      state: { category: category_url }
      // state: { product: p } // data size too large to be passed through history.push, get data from redux store instead.
    });
  }

  componentDidMount() {
    window.imageMapResize();
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll(event) {
    window.imageMapResize();
  }

  getNameFromUrl(url) {
    const { fetchProductsReducer } = this.props;
    const { products } = fetchProductsReducer;
    const curr_category = categories[url];
    const curr_handle = curr_category.products[0];
    if (products && products.length > 0) {
      for (let product of products) {
        const { title } = product;
        const { handle } = product;
        if (handle === curr_handle) {
          return title;
        }
      }
    }
    return ""
  }

  getProductFromUrl(url) {
    const { fetchProductsReducer } = this.props;
    const { products } = fetchProductsReducer;
    const curr_category = categories[url];
    const curr_handle = curr_category.products[0];
    if (products && products.length > 0) {
      for (let product of products) {
        const { handle } = product;
        if (handle === curr_handle) {
          return product;
        }
      }
    }
    return null;
  }

  render() {
    const viewportWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    const mobileSlideHeight = (6667 * viewportWidth) / 3751;
    const containerHieght = mobileSlideHeight * 29 + 250;
    const offset = 690;
    return (
      // <ScrollManager scrollKey="MobileHome" >
      //   {({ connectScrollTarget, ...props }) => 
      <div className="MobileHome">
        <Helmet>
          <title>FEASTON</title>
          {/* <meta name="description" content="FEASTON"/> */}
          <meta property="og:image" content="https://feaston.co/favicon.ico" />
          <meta property="og:title" content="FEASTON"/>
          <link rel="canonical" href="https://feaston.co" />
        </Helmet>
        <style jsx>{`
          .MobileHome {
            position: relative;
            height: auto;
            overflow-y: scroll;
            background: #fff;
            overflow-x: hidden;
          }
          .MobileHome .lazyload-wrapper {
            min-height: ${`${mobileSlideHeight}px`};
          }
          .MobileHome .homeBanner {
            max-width: 100%;
          }
          .MobileHome .coverBanner {
            max-height: 100%;
            width: auto;
          }
          .MobileHome .homeBanner img{
            width: 100%;
          }
          .MobileHome .last {
            margin-bottom: 120px;
          }
          .MobileHome .top {
            margin-top: 60px;
          }
          .MobileHome .homeBanner .banner, map area{
            outline: none;
          }
          .MobileHome .homeBanner-container {
            overflow-y: scroll;
            overflow-x: hidden;
            height: fit-content; //${`${containerHieght}px`}; //fit-content;
          }
          .MobileHome .homeBanner {
            overflow: hidden;
          }
          .MobileHome map {
            overflow: hidden;
          }
          .MobileHome .homeBanner-arrow-container {
            min-height: calc(100vh - 40px);
            display: flex;
            justify-content: center;
            align-items: center;
            min-height: -webkit-fill-available;
          }
          .MobileHome .homeBanner-arrow-container img {
            //height: 60vh;
            width: 100%;
          }
          .MobileHome .homeBanner-item-container {
            height: 100%;
            //min-height: 100vh;
            //margin-bottom: 170px;
            display: flex;
            justify-content: center;
            align-items: center;
            //padding: 0 30px;
            flex-direction: column;
          }
          .MobileHome .cover {
            height: calc(100vh - 40px);
          }
          .MobileHome .cover img {
            transform: scale(1.1);
          }
          .MobileHome .homeBanner-item-container .homeBanner-item-name {
            width: 100%;
            text-align: center;
            margin-top: 40px;
            font-size: 14px;
            font-weight: bold;
          }
          .MobileHome .homeBanner-arrow {
            margin-top: 20px;
            height: 18vh;
          }
          .MobileHome .feaston-home-footer {
            height: 100%;
            min-height: 100vh;
            display: flex;
            align-items: center;
            //padding: 0 30px;
          }
          .MobileHome .feaston-home-footer img {
            max-width: 100%;
          }
          .MobileHome .feaston-home-banner {
            height: calc(100vh - 40px);
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;
            background: #FFCD06;
            margin-bottom: 100px;
            padding: 0px 40px;
          }
          .MobileHome .feaston-home-banner img {
            //padding-bottom: 60px;
          }
          .MobileHome .banner-container {
            margin-bottom: 170px;
          }
          .MobileHome .topBar {
            position: fixed;
            top: 0;
            font-weight: bold;
            font-size: 11px;
            height: 38px;
            background: #fff;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 1000;
          }
          .MobileHome .spacer {
            height: 50px;
          }
          .MobileHome .loading {
            display: none;
            width: 100%;
            height: 100vh;
            position: fixed;
            background: white;
            color: black;
            align-items: center;
            justify-content: center;
            z-index: 2;
          }
        `}</style>
        {/* {new Date() < new Date('2020-12-02 23:59:59'.replace(/-/g, "/")) ? (
          <div className="topBar">
            <div>End of Season Sale |&nbsp;<span style={{ color: '#FF0000' }}>22</span>% off Discount Code: JASON</div>
          </div>
        ) : null} */}

        {/* <div id="loading" className="loading">loading...</div> */}
        {/* <div className="homeBanner-container c-1"> */}

          {/* <div className="homeBanner-item-container">
            <img className="homeBanner product-temp" src="https://cdn.shopify.com/s/files/1/0075/7763/2850/products/chain_front_mid.png?v=1578832907" useMap="#image-map_chain" alt=""/>
            <map name="image-map_chain">
              <Link to="/chain">
              <area target="" alt="" title="" href="" coords="377,25,315,44,219,58,178,79,145,100,108,120,70,132,0,161,77,350,137,339,170,343,185,339,190,345,179,355,170,405,172,859,530,851,914,816,852,370,830,306,878,300,935,304,999,117,892,84,871,68,780,27,622,12,573,0,538,12,450,17,403,2" shape="poly" />
              </Link>
            </map>
            <MobileProductWidgetGeneral product={this.getProductFromUrl('chain')} isHomeWidget />
          </div> */}

          <LazyLoad offset={offset} height={'calc(100vh - 40px)'} once >
            <div className="homeBanner-item-container cover">
              <img className="coverBanner product-temp" src={IMG01} useMap="#image-map_01" alt="" />
            </div>
          </LazyLoad>

          <LazyLoad offset={offset} height={mobileSlideHeight} once >
            <div className="homeBanner-item-container">
              <img className="homeBanner product-temp" src={IMG02} useMap="#image-map_02" alt="" />
              <map name="image-map_02">       
                <Link to="/grandpa_tee?color=white">
                  <area target="" alt="" title="" href="" coords="412,57,335,84,191,111,-1,191,-1,342,55,451,135,440,128,452,114,634,77,968,449,985,762,1016,799,603,815,496,877,518,899,484,899,219,815,156,614,81,570,94,444,76" shape="poly" />
                </Link>
                <Link to="/kid_tee?color=red">
                  <area target="" alt="" title="" href="" coords="311,1127,268,1144,137,1180,87,1202,-1,1266,-1,1554,10,1567,41,1560,108,1539,105,1573,110,1599,831,1599,816,1493,801,1430,814,1425,899,1418,899,1151,729,1094,589,1093,497,1095,457,1116,355,1135" shape="poly" />
                </Link>
              </map>
            </div>
          </LazyLoad>

          <LazyLoad offset={offset} height={mobileSlideHeight} once >
            <div className="homeBanner-item-container" style={{ position: 'relative' }}>
              <img className="homeBanner product-temp" src={IMG03} useMap="#image-map_03" alt="" />
            </div>
          </LazyLoad>

          

          <LazyLoad offset={offset} height={mobileSlideHeight + 50} once >
            {/* space */}
            <div className="spacer" />
            <div className="homeBanner-item-container">
              <img className="homeBanner product-temp" src={IMG04} useMap="#image-map_04" alt="" />
              <map name="image-map_04">   
                <Link to="/fushinsha_sweat?color=black">
                  <area target="" alt="" title="" href="" coords="1427,136,1285,182,536,440,251,705,0,1010,-3,2146,0,1752,0,2732,63,2815,387,2613,285,2378,123,2246,-3,2149,-3,1739,126,1636,378,1616,484,1583,553,1706,553,2130,679,2845,865,3322,971,3574,2001,3474,2746,3411,2975,3444,3034,3242,3157,2713,3154,2255,3167,1894,3154,1540,3442,1533,3747,1467,3747,1967,3747,2573,3545,2487,3478,2451,3545,2252,3747,1961,3747,1464,3747,917,3415,633,2991,335,2362,182,2057,113,1918,159,1623,169" shape="poly" />
                </Link>
                <Link to="/fushinsha_sweat?color=grey">
                  <area target="" alt="" title="" href="" coords="2004,4170,1451,4365,964,4716,964,5425,1421,5597,1497,6024,1627,6432,2345,6372,2899,6405,3051,5962,3097,5657,3574,5428,3551,4726,3041,4362,2475,4163,2359,4196,2157,4196" shape="poly" />
                </Link>
              </map>
            </div>
          </LazyLoad>

          <LazyLoad offset={offset} height={mobileSlideHeight} once >
            <div className="homeBanner-item-container">
              <img className="homeBanner product-temp" src={IMG05} useMap="#image-map_05" alt="" />
              <map name="image-map_05">
                <Link to="/panopticon_tee">
                  <area target="" alt="" title="" href="" coords="2376,318,2276,358,1693,464,1349,686,938,915,1296,1494,1375,1475,1524,1488,1504,2227,1431,3191,2585,3208,3748,3251,3748,517,3632,457,3164,351,2916,298,2770,361,2542,358" shape="poly" />
                </Link>
              </map>
            </div>
          </LazyLoad>

            

          <LazyLoad offset={offset} height={mobileSlideHeight + 50} once >
            {/* space */} 
            <div className="spacer" />
            <div className="homeBanner-item-container">
              <img className="homeBanner product-temp" src={IMG06} useMap="#image-map_06" alt="" />
              <map name="image-map_06">
                <Link to="/opium_sweat">
                  <area target="" alt="" title="" href="" coords="1577,219,1458,255,1120,368,921,444,812,517,570,1130,348,1909,215,2412,205,2555,222,2760,401,2783,474,2830,524,2823,573,2697,765,2442,941,2174,961,2624,1007,2989,1057,3224,1246,3191,1846,3155,2465,3174,2614,3221,2734,3221,2764,2969,2783,2290,3098,2803,3201,2962,3267,2969,3314,2939,3519,2899,3529,2714,3536,2538,3231,1289,3075,799,2946,580,2823,461,2273,239,2164,209,1988,239,1866,258,1756,252,1657,252" shape="poly" />
                </Link>  
                <Link to="/imported_cap">
                  <area target="" alt="" title="" href="" coords="2866,4699,2793,4729,2611,4762,2336,4881,2157,5096,2064,5292,1958,5623,1786,6034,1783,6143,1832,6220,2008,6269,2442,6346,2962,6455,3161,6422,3300,6226,3353,5971,3377,5862,3516,5796,3575,5736,3569,5428,3499,5216,3440,5067,3314,4888,3158,4798" shape="poly" />
                </Link>
                <Link to="/opium_mug">
                  <area target="" alt="" title="" href="" coords="404,4503,421,5275,527,5332,838,5338,1040,5289,1054,5123,1176,5133,1296,5023,1325,4795,1259,4639,1183,4596,1067,4596,1054,4483,772,4470,524,4477" shape="poly" />
                </Link>
              </map>
            </div>
          </LazyLoad>

          <LazyLoad offset={offset} height={mobileSlideHeight} once >
            <div className="homeBanner-item-container">
              <img className="homeBanner product-temp" src={IMG07} useMap="#image-map_07" alt="" />
              <map name="image-map_07">
                <Link to="/ljmb_tee?color=white">
                  <area target="" alt="" title="" href="" coords="1249,845,1113,917,1060,917,527,1080,-3,1212,0,3511,467,3511,911,3454,1034,3421,1305,3375,1398,3501,1448,3501,1530,3574,1756,3646,2107,3690,2458,3726,2806,3709,2928,3660,2968,3610,3121,3683,3213,3706,3369,3779,3535,3862,3747,3941,3747,927,3343,914,2892,848,2663,792,2438,755,2329,874,2084,947,1441,931" shape="poly" />
                </Link> 
                <Link to="/ljmb_tee?color=black">
                  <area target="" alt="" title="" href="" coords="1299,3391,1040,3421,590,3504,235,3517,7,3511,3,6660,3747,6664,3747,3941,3415,3809,3220,3713,3034,3650,2975,3617,2849,3706,2640,3729,2180,3699,1623,3603,1434,3511" shape="poly" />
                </Link>
              </map>
            </div>
          </LazyLoad>

          <LazyLoad offset={offset} height={mobileSlideHeight} once >
            <div className="homeBanner-item-container">
              <img className="homeBanner product-temp" src={IMG08} useMap="#image-map_08" alt="" />
              <map name="image-map_08">
                <Link to="/logo_hood_black">
                  <area target="" alt="" title="" href="" coords="1849,1501,1584,1945,1624,2088,1335,2174,1239,2157,1176,2237,1080,2257,782,2220,756,2137,693,2035,673,1935,676,1859,477,1829,225,1842,219,2071,169,2147,129,2323,116,2555,162,2598,285,2730,222,2999,162,3095,172,3161,232,3204,311,3201,404,3234,547,3267,752,3327,623,3595,610,3811,590,3963,527,4089,543,4149,567,4241,560,4738,577,4848,712,5325,1246,5421,1584,5520,1653,5517,1667,5494,2151,5689,2359,5388,2482,5255,2631,4977,2717,4735,2807,4579,2846,4460,3118,4440,3148,4742,2972,4762,2986,4941,2959,5173,3227,5239,3264,5259,3350,5272,3473,5014,3483,4960,3522,4868,3592,4586,3642,4490,3609,4424,3632,4125,3555,3585,3469,3204,3357,2800,3300,2704,3058,2545,3174,2379,3214,2333,3111,2011,3088,1905,3009,1842,2479,1667" shape="poly" />
                </Link>
              </map>
            </div>
          </LazyLoad>

          <LazyLoad offset={offset} height={mobileSlideHeight} once >
            <div className="homeBanner-item-container">
              <img className="homeBanner product-temp" src={IMG09} useMap="#image-map_09" alt="" />
            </div>
          </LazyLoad>

          <LazyLoad offset={offset} height={mobileSlideHeight} once >
            <div className="homeBanner-item-container">
              <img className="homeBanner product-temp" src={IMG10} useMap="#image-map_10" alt="" />
              <map name="image-map_10">
                <Link to="/sunn5G_tee?color=white">
                  <area target="" alt="" title="" href="" coords="2320,242,2101,298,1259,401,812,636,404,841,249,881,116,961,364,1474,537,1958,722,1941,1017,1928,968,2101,908,2743,875,3267,1219,3300,1604,3267,1720,3211,1866,3260,2044,3293,2522,3406,2982,3486,3241,3532,3748,3794,3748,324,3383,265,3148,252,3049,305,2783,334,2472,308" shape="poly" />
                </Link>
                <Link to="/grandpa_tee?color=purple">
                  <area target="" alt="" title="" href="" coords="553,3161,351,3247,113,3334,-3,3353,7,6664,3609,6664,3509,5676,3486,5603,3748,5607,3748,3791,3241,3536,2711,3430,2154,3320,1909,3261,1726,3218,1600,3271,1223,3300,875,3264,649,3204" shape="poly" />
                </Link>
              </map>
            </div>
          </LazyLoad>

          <LazyLoad offset={offset} height={mobileSlideHeight} once >
            <div className="homeBanner-item-container">
              <img className="homeBanner product-temp" src={IMG11} useMap="#image-map_11" alt="" />
            </div>
          </LazyLoad>

          <LazyLoad offset={offset} height={mobileSlideHeight} once >
            <div className="homeBanner-item-container">
              <img className="homeBanner product-temp" src={IMG12} useMap="#image-map_12" alt="" />
              <map name="image-map_12">
                <Link to="/names_tee?color=navy">
                  <area target="" alt="" title="" href="" coords="1998,80,1783,126,1634,176,1034,232,520,262,288,394,-3,504,-3,4659,109,4526,146,4487,497,4374,404,4294,348,4278,331,4238,444,3794,447,3691,504,3635,802,3569,1408,3449,1862,3400,2336,3406,2426,3413,2575,3406,2628,3420,2681,3562,2724,3632,2886,3940,2876,3980,2797,4043,2720,4119,3131,4172,3241,4245,3330,4311,3519,4543,3539,4609,3748,4990,3748,603,3625,514,3546,487,3516,444,3357,321,3161,378,2827,394,2442,315,2197,219" shape="poly" />
                </Link>
                <Link to="/yaohan_hood?color=white">
                  <area target="" alt="" title="" href="" coords="514,3642,451,3701,444,3801,348,4195,338,4248,355,4278,401,4291,507,4377,295,4444,149,4493,-3,4666,0,6664,3748,6664,3748,4997,3526,4546,3317,4288,3128,4172,2717,4122,2863,3976,2886,3943,2674,3565,2634,3430,2575,3406,2412,3413,1882,3403,1398,3453,689,3599" shape="poly" />
                </Link>
              </map>
            </div>
          </LazyLoad>

          <LazyLoad offset={offset} height={mobileSlideHeight} once >
            <div className="homeBanner-item-container">
              <img className="homeBanner product-temp" src={IMG13} useMap="#image-map_13" alt="" />
            </div>
          </LazyLoad>

          <LazyLoad offset={offset} height={mobileSlideHeight} once >
            <div className="homeBanner-item-container">
              <img className="homeBanner product-temp" src={IMG14} useMap="#image-map_14" alt="" />
              <map name="image-map_14">
                <Link to="/kid_tee?color=purple">
                  <area target="" alt="" title="" href="" coords="2292,235,2170,285,2110,282,2041,325,1514,411,1100,520,769,729,186,1017,-3,1106,-3,1567,242,1974,407,2322,451,2328,603,2299,782,2262,964,2265,914,2345,931,2918,977,2898,1130,2961,1411,2984,1680,2961,1782,2915,1805,2878,1931,2915,2034,2938,2223,2997,2879,3100,3091,3236,3240,3348,3747,3584,3747,4210,3482,4749,3379,4720,3213,4650,2991,4650,3068,4875,3097,5134,3747,5117,3747,4200,3747,401,3339,328,3250,315,3121,285,3034,321,2786,371,2551,351,2385,295" shape="poly" />
                </Link>
                <Link to="/sunn5G_tee?color=black">
                  <area target="" alt="" title="" href="" coords="971,2888,818,2961,629,2997,527,2997,-3,3064,-3,6660,3137,6664,3097,5130,3061,4852,3028,4749,2988,4650,3121,4650,3223,4660,3382,4723,3478,4746,3747,4216,3747,3570,3237,3345,2895,3093,2220,2991,2017,2931,1812,2881,1782,2911,1686,2958,1415,2981,1130,2951" shape="poly" />
                </Link>
              </map>
            </div>
          </LazyLoad>

          <LazyLoad offset={offset} height={mobileSlideHeight + 50} once >
            {/* space */}
            <div className="spacer" />
            <div className="homeBanner-item-container">
              <img className="homeBanner product-temp" src={IMG15} useMap="#image-map_15" alt="" />
              <map name="image-map_15">
                <Link to="/ES_hood">
                  <area target="" alt="" title="" href="" coords="-3,325,-3,6329,606,6319,1262,6356,1746,6462,1972,6475,1982,6392,2068,5908,2141,5265,2147,4861,2442,5395,2644,5812,2777,5945,2880,6170,2982,6173,3111,6097,3522,6054,3506,5779,3555,5593,3502,5133,3340,4424,3055,3290,2899,2677,2654,2041,2465,1581,2296,1352,2131,1213,1982,1156,1776,1097,1872,954,1872,881,1829,799,1760,550,1707,454,1697,348,1657,298,1405,315,676,302" shape="poly" />
                </Link>
              </map>
            </div>
          </LazyLoad>

          <LazyLoad offset={offset} height={mobileSlideHeight} once >
            <div className="homeBanner-item-container">
              <img className="homeBanner product-temp" src={IMG16} useMap="#image-map_16" alt="" />
              <map name="image-map_16">
                <Link to="/imported_hood">
                  <area target="" alt="" title="" href="" coords="517,239,480,285,454,444,431,570,371,669,371,702,474,792,368,818,129,981,3,1153,-3,3771,192,3519,199,4019,209,4546,272,5014,500,4997,1027,3469,1296,2810,1395,2740,1975,2489,1905,2419,1892,2376,1942,2316,2041,2008,2101,1892,2296,1919,2641,1925,3211,1952,2913,1206,2899,1153,2684,964,2542,885,2320,789,2452,640,2399,543,2376,474,2323,275,2310,215,2257,199,2074,192,1491,146,961,192" shape="poly" />
                </Link>
                <Link to="/3107_hood">
                  <area target="" alt="" title="" href="" coords="2104,1895,2044,2015,1945,2313,1889,2379,1915,2419,1975,2489,1723,2605,1402,2737,1299,2807,1011,3493,494,5007,308,5636,229,5908,209,6067,229,6110,199,6435,388,6452,437,6491,676,6561,729,6501,828,6299,905,6206,1180,5832,1412,5497,1408,6027,1451,6571,1471,6664,3748,6664,3748,1985,3058,1938" shape="poly" />
                </Link>
              </map>
            </div>
          </LazyLoad>

          <LazyLoad offset={offset} height={mobileSlideHeight} once >
            <div className="homeBanner-item-container" style={{ position: "relative" }}>
              <img className="homeBanner product-temp" src={IMG17} useMap="#image-map_17" alt="" />
              <img src={GAFFER} style={{ position: "absolute", transform: 'rotate(-24deg)', top: '67%', left: '-37%', width: '114%', height: 'auto' }} useMap="#image-map_gaffer" alt="" />
              <map name="image-map_gaffer">
                <Link to="/andy_lau">
                  <area target="" alt="" title="" href="" coords="820,3818,398,4032" shape="rect" />
                </Link>
              </map>
            </div>
          </LazyLoad>

          <LazyLoad offset={offset} height={mobileSlideHeight} once >
            <div className="homeBanner-item-container">
              <img className="homeBanner product-temp" src={IMG18} useMap="#image-map_18" alt="" />
              <map name="image-map_18">
                <Link to="/andy_lau">
                  <area target="" alt="" title="" href="" coords="746,3609,669,3645,649,3678,427,3678,414,3734,418,3947,315,5680,497,5713,795,5726,961,5703,1067,3837,868,3840,878,3731,835,3642" shape="poly" />
                </Link>
              </map>
            </div>
          </LazyLoad>

          <LazyLoad offset={offset} height={mobileSlideHeight} once >
            <div className="homeBanner-item-container">
              <img className="homeBanner product-temp" src={IMG19} useMap="#image-map_19" alt="" />
              <map name="image-map_19">
                <Link to="/names_tee?color=white">
                  <area target="" alt="" title="" href="" coords="258,119,136,166,-3,205,-3,5070,192,5067,739,3960,1339,2677,1342,2634,1448,2479,1600,2373,1842,2247,1750,2117,1803,1968,1922,1673,1925,1634,1978,1571,2253,1577,2817,1564,3065,1567,3347,1610,3609,1183,2817,716,2416,444,1789,275,1494,212,1312,123,1180,162,825,229,437,196" shape="poly" />
                </Link>
                <Link to="/juk_hood">
                  <area target="" alt="" title="" href="" coords="1982,1571,1932,1627,1925,1683,1813,1958,1753,2124,1849,2250,1600,2382,1451,2479,1349,2628,1345,2677,881,3638,583,4265,196,5070,-3,5517,-3,6495,292,6657,447,6462,596,6349,891,6001,822,5743,812,5587,835,5391,729,5365,742,5083,775,4924,852,4788,941,4546,1031,4417,1153,4308,1332,4208,1415,4172,1544,4172,1567,4129,1723,4139,1720,4175,1962,4205,2114,4271,2320,4407,2459,4586,2591,4818,2631,5030,2648,5275,2608,5308,2770,5600,2833,5786,2840,5898,2813,5978,2644,6034,2194,6064,1836,6084,1418,6143,1412,6240,1478,6664,3748,6664,3748,1677,3350,1610,3049,1571,2452,1567" shape="poly" />
                </Link>
                <Link to="/imported_cap">
                  <area target="" alt="" title="" href="" coords="1577,4132,1551,4178,1412,4172,1219,4271,1070,4374,958,4513,895,4685,828,4835,772,4937,742,5093,732,5361,838,5395,812,5587,828,5762,895,6011,958,6097,1097,6153,1415,6147,1905,6074,2634,6037,2817,5978,2850,5905,2836,5782,2767,5587,2614,5305,2648,5262,2631,5027,2595,4815,2462,4573,2320,4400,2111,4271,1962,4212,1720,4175,1716,4129,1647,4132" shape="poly" />
                </Link>
              </map>
            </div>
          </LazyLoad>

          {/* <LazyLoad offset={offset} height={mobileSlideHeight} once > */}
            <div className="homeBanner-item-container" style={{ position: "relative" }}>
              <img className="homeBanner product-temp" src={IMG20} useMap="#image-map_20" alt="" />
              <map name="image-map_20">
                <Link to="/smile_tee?color=purple">
                  <area style={{ position: "relative", zIndex: 1 }} target="" alt="" title="" href="" coords="65,810,24,823,-1,828,2,1599,598,1599,598,1290,707,1316,862,1048,792,1012,683,956,572,886,445,849,352,826,318,822,271,809,235,821,163,829,111,824" shape="poly" />
                </Link>
              </map>
              <img src={RULER1} style={{ position: "absolute", transform: 'rotate(-0.5deg)', top: '61%', left: '40%', width: '18%', height: 'auto', zIndex: 0 }} useMap="#image-map_ruler" alt="" />
            </div>
          {/* </LazyLoad> */}

          <LazyLoad offset={offset} height={mobileSlideHeight} once >
            <div className="homeBanner-item-container">
              <img className="homeBanner product-temp" src={IMG21} useMap="#image-map_21" alt="" />
              <map name="image-map_21">
                <Link to="/kid_tee?color=purple">
                  <area target="" alt="" title="" href="" coords="-3,845,-3,5786,50,5842,795,5819,1776,5812,1776,5733,2121,5736,2114,5444,2240,5395,2286,5289,2303,5047,2462,4076,2452,4000,2498,3827,2382,3569,2339,3181,2389,2916,2502,2694,2435,2528,2429,2452,2402,2412,2276,1836,2243,1620,2117,1143,2068,1024,1982,1027,1965,858,1743,885,1309,905,726,915,391,888,179,858,103,825,33,825" shape="poly" />
                </Link>
              </map>
            </div>
          </LazyLoad>

          <LazyLoad offset={offset} height={mobileSlideHeight} once >
            <div className="homeBanner-item-container">
              <img className="homeBanner product-temp" src={IMG22} useMap="#image-map_22" alt="" />
              <map name="image-map_22">
                <Link to="/fushinsha_sweat">
                  <area target="" alt="" title="" href="" coords="2210,497,2154,524,2114,510,1909,596,1750,636,1660,673,1591,722,1551,785,1349,1299,1209,1776,1236,1822,1620,2313,1660,2389,1690,2402,1730,2399,1713,2853,1716,2913,1730,2942,1713,3108,1720,3174,1935,3165,2293,3125,2740,3118,3009,3128,3310,3121,3320,2856,3294,2730,3297,2485,3287,2296,3360,2250,3360,2213,3675,1538,3701,1508,3698,1412,3675,1375,3383,742,3363,712,3072,587,2853,510,2827,517,2790,484,2740,487,2601,530,2445,537,2310,527" shape="poly" />
                </Link>
                <Link to="/imported_cap">
                  <area target="" alt="" title="" href="" coords="1819,3973,1760,3973,1733,3996,1610,4009,1471,4029,1239,4142,1084,4212,987,4341,828,4513,702,4765,557,5143,557,5239,577,5262,431,5504,318,5786,308,5955,358,6044,447,6094,643,6140,1103,6220,1730,6359,2001,6392,2187,6356,2346,6193,2419,6047,2492,5786,2525,5577,2691,5507,2813,5388,2803,5129,2770,4884,2704,4692,2667,4560,2472,4241,2382,4169,2194,4086,1922,4016,1912,3983" shape="poly" />
                </Link>
              </map>
            </div>
          </LazyLoad>

          <LazyLoad offset={offset} height={mobileSlideHeight} once >
            <div className="homeBanner-item-container">
              <img className="homeBanner product-temp" src={IMG23} useMap="#image-map_23" alt="" />
              <map name="image-map_23">
                <Link to="/logo_hood_black">
                  <area target="" alt="" title="" href="" coords="1338,1474,1256,1474,1146,1606,1020,1861,934,2060,725,1981,676,2020,497,2481,298,2759,272,2868,321,2928,374,2928,570,3007,553,3070,510,3113,504,3156,547,3183,762,3302,749,3408,629,3610,547,3782,553,3954,639,4130,706,4335,775,4491,1153,4905,1275,4981,1381,5120,1415,5153,1448,5196,1636,5104,2163,4839,2627,4504,2720,4418,2922,4405,3015,4365,3131,4259,3210,4094,3273,3964,3372,3719,3399,3696,3396,3630,3445,3405,3472,3179,3429,3136,3379,3120,3366,3064,3329,2948,3286,2911,3382,2742,3419,2560,3442,2481,3415,2421,3256,2401,3051,2345,2776,2255,2710,2255,2663,2269,2647,2196,2627,2110,2644,2053,2667,1841,2700,1792,2617,1735,2415,1682,2286,1669,2087,1613,2031,1580,2004,1633,1918,1825,1855,1788,1802,1788,1540,1673,1415,1557" shape="poly" />
                </Link>
              </map>
            </div>
          </LazyLoad>

          <LazyLoad offset={offset} height={mobileSlideHeight} once >
            <div className="homeBanner-item-container">
              <img className="homeBanner product-temp" src={IMG24} useMap="#image-map_24" alt="" />
              <map name="image-map_24">
                <Link to="/band1_tee?color=white">
                  <area target="" alt="" title="" href="" coords="1365,146,1113,249,1074,249,1047,272,-3,471,-3,3280,881,3161,938,3138,1140,3065,1209,3105,1445,3208,1740,3257,2038,3247,2349,3191,2595,3072,2780,3111,2956,3121,3108,3135,3748,3218,3748,384,3211,282,2913,222,2780,298,2482,345,2174,345,1826,308,1571,232" shape="poly" />
                </Link>
                <Link to="/band1_tee?color=black">
                  <area target="" alt="" title="" href="" coords="1137,3058,924,3148,852,3161,-3,3280,3,6664,3748,6660,3748,3218,3221,3151,3049,3125,2734,3102,2604,3075,2349,3191,2038,3247,1740,3254,1431,3201,1209,3105" shape="poly" />
                </Link>
              </map>
            </div>
          </LazyLoad>

          

          <LazyLoad offset={offset} height={mobileSlideHeight + 50} once >
            {/* space */}
            <div className="spacer" />
            <div className="homeBanner-item-container">
              <img className="homeBanner product-temp" src={IMG25} useMap="#image-map_25" alt="" />
              <map name="image-map_25">
                <Link to="/toast_tee?color=white">
                  <area target="" alt="" title="" href="" coords="501,47,456,59,169,96,78,146,-1,169,-1,522,55,519,85,510,114,512,124,519,93,582,75,750,121,758,200,749,272,728,298,704,343,714,562,734,701,801,820,839,848,855,757,1050,741,1094,731,1133,681,1126,649,1129,670,1158,695,1345,899,1362,899,96,737,61,677,75,561,70" shape="poly" />
                </Link>
                <Link to="/smile_tee?color=red">
                  <area target="" alt="" title="" href="" coords="76,753,38,772,-1,793,1,1599,743,1599,696,1347,669,1159,648,1126,679,1125,733,1133,755,1056,850,854,820,840,694,797,566,736,341,714,300,703,281,724,217,742,119,757" shape="poly" />
                </Link>
              </map>
            </div>
          </LazyLoad>

          <LazyLoad offset={offset} height={mobileSlideHeight} once >
            <div className="homeBanner-item-container">
              <img className="homeBanner product-temp" src={IMG26} useMap="#image-map_26" alt="" />
              <map name="image-map_26">
                <Link to="/marker_tee?color=white">
                  <area target="" alt="" title="" href="" coords="2027,2116,1812,2120,1762,2120,947,2063,769,2070,739,2053,702,2077,89,2159,-3,2176,-3,3991,434,3971,560,3954,729,3968,855,3981,1176,3945,1312,3991,1358,3968,1428,3971,1590,3938,1627,4021,1766,4130,2001,4210,2435,4299,2710,4355,2958,4359,2975,4335,3068,4325,3121,4256,3217,4299,3369,4392,3455,4428,3747,4567,3747,2838,3551,2726,3538,2699,3316,2547,3286,2547,3200,2567,3121,2557,3101,2570,2813,2514,2524,2421,2239,2295,2087,2196" shape="poly" />
                </Link>
                <Link to="/marker_tee?color=navy">
                  <area target="" alt="" title="" href="" coords="1593,3945,1415,3974,1362,3964,1318,3994,1189,3948,858,3988,755,3984,676,3971,563,3954,444,3978,-3,3988,0,6664,3747,6657,3747,4557,3382,4405,3323,4362,3233,4306,3127,4256,3074,4322,2985,4332,2962,4349,2716,4355,2306,4276,1991,4213,1766,4133,1627,4027" shape="poly" />
                </Link>
              </map>
            </div>
          </LazyLoad>  
        
          

          <LazyLoad offset={offset} height={mobileSlideHeight + 50} once >
            {/* space */}
            <div className="spacer" style={{ background: 'rgb(4, 7, 7)', height: '50px', position: 'relative', zIndex: 1 }}>
              <div style={{ background: 'rgb(4, 7, 7)', height: '52px', position: 'relative', top: 0, zIndex: 1 }} />
            </div>
            <div className="homeBanner-item-container" style={{ marginTop: '-2px', position: 'relative', zIndex: 0 }}>
              <img className="homeBanner product-temp" src={IMG27} useMap="#image-map_27" alt="" />
              <map name="image-map_27">
                <Link to="/marker_hood">
                  <area target="" alt="" title="" href="" coords="656,268,580,391,557,620,527,855,580,898,901,994,855,1004,785,1070,557,1054,388,1097,235,1153,60,1223,-3,1256,-3,2581,89,2581,80,3105,109,3433,169,3840,249,4082,295,4192,378,4510,510,4480,1127,4414,1183,4238,1325,3751,1388,3572,1488,3483,1508,3440,1756,3304,1885,3244,2008,3204,1958,3135,1945,3082,1978,3058,2068,2800,2084,2767,2107,2707,2177,2694,2270,2711,2913,2747,2919,2518,3049,2525,3178,2548,3748,2740,3748,1653,3208,1402,2976,1269,2810,1190,2611,1110,2426,1074,2313,1054,2273,1054,2253,1040,2240,1007,2363,974,2495,895,2492,792,2442,547,2419,341,2320,298,2084,288,1740,242,1554,205,1024,249" shape="poly" />
                </Link>
                <Link to="/logo_hood_black">
                  <area target="" alt="" title="" href="" coords="2170,2704,2107,2711,2064,2813,1975,3065,1952,3075,1952,3121,2011,3198,1713,3330,1521,3440,1501,3473,1395,3569,1309,3778,1180,4248,1123,4420,984,4947,825,5587,673,6269,693,6558,732,6664,1296,6664,1398,6505,1435,6442,1478,6664,3748,6664,3748,2793" shape="poly" />
                </Link>
              </map>
            </div>
          </LazyLoad>

          <LazyLoad offset={offset} height={mobileSlideHeight} once >
            <div className="homeBanner-item-container" style={{ marginTop: '-2px', position: 'relative', zIndex: 0 }}>
              <img className="homeBanner product-temp" src={IMG28} useMap="#image-map_28" alt="" />
              <map name="image-map_28">
                <Link to="/logo_hood_red">
                  <area target="" alt="" title="" href="" coords="254,642,245,674,213,732,227,782,248,822,255,883,208,910,159,978,123,1047,80,1193,75,1237,91,1279,145,1360,197,1424,229,1469,247,1473,334,1385,492,1408,602,1358,656,1318,686,1337,750,1286,755,1268,768,1261,776,1243,822,1308,843,1320,849,1310,869,1190,865,1059,752,893,659,799,607,754,568,747,538,752,517,771,513,753,540,733,538,698,491,609,294,626" shape="poly"/>
                </Link>
              </map>
            </div>
          </LazyLoad>

          <LazyLoad offset={offset} height={mobileSlideHeight} once >
            <div className="homeBanner-item-container">
              <div style={{ position: 'relative' }}>
                <img className="homeBanner product-temp" src={IMG29} useMap="#image-map_29" alt="" />
                <div style={{ position: 'absolute', width: '100%', height: '50px', background: 'white', bottom: 0 }} />
              </div>
            </div>
          </LazyLoad>

          {/* <div className="homeBanner-arrow-container">
            <img src={APgift600} alt=""/> 
          </div> */}

          {/* <div className="feaston-home-banner">
            <img className="" src={APBOX01} alt="" style={{ maxWidth: "100%", maxHeight: "unset" }}/>
          </div> */}

          {/* {new Date() > new Date('2020-12-05 23:59:59'.replace(/-/g, "/")) ? (
            <MobileHomeProductWidget url="andy_lau" pickUpOnly />
          ) : null} */}

          {/* <div className="feaston-home-footer">
            <img src={HOME_FOOTER} alt=""/>
          </div> */}

          {/* <MobileHomeProductWidget url="sm2202" />
          <MobileHomeProductWidget url="deep_learning" />
          <MobileHomeProductWidget url="kale" />
          <MobileHomeProductWidget url="chain" />

          <div className="banner-container">
            <img src={BANNER2} width="100%" alt="" />
          </div>

          <MobileHomeProductWidget url="opium_t" />
          <MobileHomeProductWidget url="discontinued_siu_mai" />

          <div className="banner-container">
            <img src={BANNER3} width="100%" alt="" />
          </div>

          <MobileHomeProductWidget url="pickles_t" />
          <MobileHomeProductWidget url="investor" />

          <div className="banner-container">
            <img src={BANNER4} width="100%" alt="" />
          </div>

          <MobileHomeProductWidget url="snake_tote" />
          <MobileHomeProductWidget url="bottle" />

          <div className="banner-container">
            <img src={BANNER5} width="100%" alt="" />
          </div>

          <MobileHomeProductWidget url="boot_01" />
          <MobileHomeProductWidget url="lmmg_01" />

          <div className="banner-container">
            <img src={BANNER6} width="100%" alt="" />
          </div>

          <MobileHomeProductWidget url="holyfuckington" />
          <MobileHomeProductWidget url="singularity_01" />

          <div className="banner-container">
            <img src={BANNER7} width="90%" alt="" />
          </div>

          <MobileHomeProductWidget url="n796" />
          <MobileHomeProductWidget url="n796_collar" /> */}

          {/* <div className="homeBanner-item-container">
            <img className="homeBanner product-temp" src="https://cdn.shopify.com/s/files/1/0075/7763/2850/products/kale_front_mid.png?v=1578832944" useMap="#image-map_kale" alt=""/>
            <map name="image-map_kale">
              <Link to="/kale">
              <area target="" alt="" title="" href="" coords="415,11,387,21,221,57,123,114,1,171,15,207,97,347,140,340,182,339,184,548,189,834,370,821,543,818,723,805,755,810,829,803,917,807,928,801,862,321,904,321,910,300,921,292,999,148,885,101,783,37,735,29,626,8,598,6,574,3,558,12,453,21" shape="poly" />
              </Link>
            </map>
            <MobileProductWidgetGeneral product={this.getProductFromUrl('kale')} isHomeWidget />
          </div>

          <div className="homeBanner-item-container">
            <img className="homeBanner product-temp" src="https://cdn.shopify.com/s/files/1/0075/7763/2850/products/chain_front_mid.png?v=1578832907" useMap="#image-map_chain" alt=""/>
            <map name="image-map_chain">
              <Link to="/chain">
              <area target="" alt="" title="" href="" coords="377,25,315,44,219,58,178,79,145,100,108,120,70,132,0,161,77,350,137,339,170,343,185,339,190,345,179,355,170,405,172,859,530,851,914,816,852,370,830,306,878,300,935,304,999,117,892,84,871,68,780,27,622,12,573,0,538,12,450,17,403,2" shape="poly" />
              </Link>
            </map>
            <MobileProductWidgetGeneral product={this.getProductFromUrl('chain')} isHomeWidget />
          </div>

          <div className="homeBanner-item-container">
            <img className="homeBanner product-temp" src={INVESTOR_HP} useMap="#image-map_investor" alt=""/>
            <map name="image-map_investor">
              <Link to="/investor">
              <area target="" alt="" title="" href="" coords="483,197,420,206,369,227,321,266,294,307,258,392,248,451,237,477,237,503,243,511,224,600,230,647,255,666,335,690,516,694,683,680,736,657,754,624,748,571,732,513,735,492,728,460,709,353,692,304,662,254,606,223,528,196" shape="poly" />
              </Link>
            </map>
            <MobileProductWidgetGeneral product={this.getProductFromUrl('investor')} isHomeWidget />
          </div>          

          <div className="homeBanner-item-container">
            <img className="homeBanner product-temp" src="https://cdn.shopify.com/s/files/1/0075/7763/2850/products/opiumFront-med__1__1.png" useMap="#image-map_opium_t" alt=""/>
            <map name="image-map_opium_t">
              <Link to="/opium_t">
                <area target="" alt="" title="" href="" ref="" coords="162,951,837,949,824,432,813,403,869,386,950,380,1008,193,607,63,570,84,486,84,447,71,10,188,58,379,137,388,177,377,184,405" shape="poly"/>
              </Link>
            </map>
            <MobileProductWidgetGeneral product={this.getProductFromUrl('opium_t')} isHomeWidget />
          </div>

          <div className="homeBanner-item-container">
            <img className="homeBanner product-temp" src="https://cdn.shopify.com/s/files/1/0075/7763/2850/products/snakeFront_med.png" useMap="#image-map_snake_tote" alt=""/>
            <map name="image-map_snake_tote">
              <Link to="/snake_tote">
                <area target="" alt="" title="" href="" coords="261,973,301,356,401,360,412,232,457,97,513,44,537,40,584,54,620,92,638,179,642,215,644,359,740,357,761,978" shape="poly"/>
              </Link>
            </map>
            <MobileProductWidgetGeneral product={this.getProductFromUrl('snake_tote')} isHomeWidget />
          </div>

          <div className="homeBanner-item-container">
            <img className="homeBanner product-temp" src="https://cdn.shopify.com/s/files/1/0075/7763/2850/products/bottle_front_med.png" useMap="#image-map9" alt=""/>
            <map name="image-map9">
              <Link to="/bottle">
                <area target="" alt="" title="" href="" coords="343,4,297,14,196,50,0,127,45,266,56,276,136,281,151,275,159,277,159,292,152,724,451,728,717,719,683,295,675,277,686,282,796,274,850,119,498,0,466,17,421,26,360,18" shape="poly"/>
              </Link>
            </map>
            <MobileProductWidgetGeneral product={this.getProductFromUrl('bottle')} isHomeWidget />
          </div>

          <div className="homeBanner-item-container">
            <img className="homeBanner product-temp" src="https://cdn.shopify.com/s/files/1/0075/7763/2850/products/boot_back_med.png" useMap="#image-map5" alt=""/>
            <map name="image-map5">
              <Link to="/boot_01">
                <area target="" alt="" title="" href="" coords="418,1,235,52,1,149,65,329,200,323,197,577,199,876,549,872,882,855,853,532,838,369,820,319,871,308,966,309,987,209,1000,128,823,66,625,6,611,6,595,2,555,14,473,13" shape="poly"/>
              </Link>
            </map>
            <MobileProductWidgetGeneral product={this.getProductFromUrl('boot_01')} isHomeWidget />
          </div>

          <div className="homeBanner-item-container">
            <img className="homeBanner product-temp" src={LMMG01} useMap="#image-map2" alt=""/>
            <map name="image-map2">
              <Link to="/lmmg_01">
                <area target="" alt="" title="" href="" coords="332,12,309,21,175,46,81,87,68,86,10,107,51,245,93,246,148,248,117,673,240,685,415,694,666,679,646,262,669,251,710,250,755,241,771,184,795,106,665,66,548,27,497,16,485,15,471,7,451,21,404,25,367,20" shape="poly"/>
              </Link>
            </map>
            <MobileProductWidgetGeneral product={this.getProductFromUrl('lmmg_01')} isHomeWidget />
          </div>

          <div className="homeBanner-item-container">
            <img className="homeBanner product-temp" src={HOLYFUCKINGTON} useMap="#image-map6" alt=""/>
            <map name="image-map6">
              <Link to="/holyfuckington">
                <area target="" alt="" title="" href="" coords="352,23,275,42,182,74,4,149,57,300,168,306,162,394,155,772,452,776,731,765,701,356,689,295,750,270,769,274,804,286,845,133,841,119,738,82,729,84,687,67,678,60,528,19,490,18,462,28,379,29" shape="poly"/>
              </Link>
            </map>
            <MobileProductWidgetGeneral product={this.getProductFromUrl('holyfuckington')} isHomeWidget />
          </div>

          <div className="homeBanner-item-container">
            <img className="homeBanner product-temp" src={SINGULARITY01} useMap="#image-map3" alt=""/>
            <map name="image-map3">
              <Link to="/singularity_01">
                <area target="" alt="" title="" href="" coords="355,7,330,13,185,47,164,64,130,68,5,107,43,259,101,272,137,271,170,289,156,318,149,756,342,764,526,769,734,740,711,494,681,313,685,279,706,269,727,271,778,264,803,264,849,108,703,59,688,56,671,47,506,0,481,13,437,20,384,17" shape="poly"/>
              </Link>
            </map>
            <MobileProductWidgetGeneral product={this.getProductFromUrl('singularity_01')} isHomeWidget />
          </div>

          <div className="homeBanner-item-container">
            <img className="homeBanner product-temp" src={FEASTOPHER_01} useMap="#image-map7" alt=""/>
            <map name="image-map7">
              <Link to="/feastopher_01">
                <area target="" alt="" title="" href="" coords="331,2,297,8,148,53,0,113,51,254,151,257,141,657,692,654,656,288,645,249,746,256,799,108,622,40,510,6,461,3,431,16,368,14" shape="poly"/>
              </Link>
            </map>
            <MobileProductWidgetGeneral product={this.getProductFromUrl('feastopher_01')} isHomeWidget />
          </div>

          <div className="homeBanner-item-container">
            <img className="homeBanner product-temp" src={DJFORESKIN01} useMap="#image-map4" alt=""/>
            <map name="image-map4">
              <Link to="/djforeskin_01">
                <area target="" alt="" title="" href="" coords="346,7,153,71,4,121,58,280,97,273,161,281,154,416,152,755,448,764,707,747,688,292,711,281,722,285,794,273,845,125,672,64,496,4,465,22,417,27,375,21" shape="poly"/>
              </Link>
            </map>
            <MobileProductWidgetGeneral product={this.getProductFromUrl('djforeskin_01')} isHomeWidget />
          </div>

          <div className="homeBanner-item-container">
            <img className="homeBanner product-temp" src={N796FULLPRINT} useMap="#image-map1" alt=""/>
            <map name="image-map1">
              <Link to="/n796">
                <area target="" alt="" title="" href="" coords="326,29,319,35,309,35,300,43,205,69,21,154,22,167,52,230,63,299,125,309,139,308,166,319,175,371,171,393,171,538,171,581,171,689,173,725,184,735,309,720,425,715,668,713,669,690,642,454,634,367,636,319,648,302,694,286,737,277,762,173,780,124,601,58,503,34,478,28,436,36,398,43,361,42" shape="poly"/>
              </Link>
            </map>
            <MobileProductWidgetGeneral product={this.getProductFromUrl('n796')} isHomeWidget />
          </div>

          <div className="homeBanner-item-container">
            <img className="homeBanner product-temp" src={N796_COLLAR} useMap="#image-map8" alt=""/>
            <map name="image-map8">
              <Link to="/n796_collar">
                <area target="" alt="" title="" href="" coords="331,10,317,41,301,38,153,92,139,106,1,179,62,323,136,315,123,356,114,433,109,714,383,717,727,705,691,364,674,287,730,327,799,157,618,69,506,51,496,43,484,40,468,5,436,1,365,3" shape="poly"/>
              </Link>
            </map>
            <MobileProductWidgetGeneral product={this.getProductFromUrl('n796_collar')} isHomeWidget />
          </div> */}

          {/* <div className="feaston-home-footer">
            <img src={HOME_FOOTER} alt=""/>
          </div>  */}

        {/* </div>  */}
        {/* <div className="homeBanner-container c-1">
          <img className="homeBanner product-4 top" src={homeBanner4} useMap="#image-map4" alt=""/>
          <img className="homeBanner product-3" src={homeBanner3} useMap="#image-map3" alt=""/>
          <img className="homeBanner product-1" src={homeBanner} useMap="#image-map" alt=""/>
          <map name="image-map">
              <area onClick={() => this.changeRoute('n796')} target="" alt="" title="" href="" coords="291,33,181,65,16,142,39,195,57,272,105,279,127,279,148,288,153,344,152,500,159,652,193,656,370,649,598,643,570,350,575,276,591,262,664,249,699,110,509,43,429,26,356,37" shape="poly"/>
          </map>
          <img className="homeBanner product-2 last" src={homeBanner2} useMap="#image-map2" alt=""/>
          <map name="image-map2">
            <area target="" alt="" title="" href="" coords="121,957,886,959,886,638,873,635,855,372,830,264,763,148,693,95,637,64,520,27,447,32,354,66,265,138,209,206,171,275,157,351,144,648,124,650,117,662,116,930" shape="poly"/>
          </map>
        </div> */}
      </div>
      // </ScrollManager>
    );
  }
}

const mapStateToProps = state => ({
  ...state
})

export default connect(mapStateToProps, {}) (MobileHome);
