import { Link } from 'react-router-dom';
import React from 'react';
import FEASTONLOGO from 'assets/Home/web_feastonLogo-small-removebg.png';
import MEMBERSHIP from 'assets/Navigation/membership.png';

function Logo() {
  return (
    <div className="logo">
      <style jsx>{`
        .MobileNavigation .logo .option_home {
          background: url(${FEASTONLOGO});
          background-size: contain;
          background-repeat: no-repeat;
          width: 135px;
          height: 60px;
          display: flex;
        }
        .MobileNavigation .logo {
          width: auto;
          height: 60px;
          display: flex;
          justify-content: space-between;
        }
        .MobileNavigation .membershipDiv {
          display: flex;
          align-items: center;
          padding-top: 14px;
        }
        .MobileNavigation .membershipImg {
          width: 140px;
        }
        .MobileNavigation .logo .link {
          width: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      `}</style>
      <Link to="/">
        <div className="option option_home"
          onClick={() => {
            const pathname = window.location.pathname;
            if (pathname === "/") {
              window.scrollTo(0, 0);
            }
          }}
        >
          {/* <img className="logo_img" src={FEASTONLOGO} alt="" /> */}
        </div>
      </Link>
      {/* <Link to="/mtt" className="link">
        <div className='membershipDiv'>
          <img className='membershipImg' src={MEMBERSHIP} alt="" />
        </div>
      </Link> */}
    </div>
  )
}

export default React.memo(Logo);