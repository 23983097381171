import React, { Component } from 'react';
import { connect } from 'react-redux';
import categories from '../../data/categories/index.jsx';
import homeBanner from'../../_Mobile/assets/smaller_homePage.001.jpeg';
import PanelRight from '../../components/PanelRight';
import { withRouter } from 'withRouter';

class Project extends Component {

  constructor(props) {
    super(props);
    this.state = {
      productsLoaded: false,
      category: "",
    };
    this.initiateLoadedProducts = this.initiateLoadedProducts.bind(this);
    this.changeRoute = this.changeRoute.bind(this);
  }

  changeRoute(category_url) {
    this.setState({
      category: category_url,
    }, () => {
      this.props.history.push({
        pathname: `/project/${category_url}`,
        state: { category: category_url }
        // state: { product: p } // data size too large to be passed through history.push, get data from redux store instead.
      });
    });
  }

  initiateLoadedProducts(products, props) {
    const category_url = props.location.pathname.replace("/project/","");
    if (category_url == "/project" || category_url == "") {
      this.setState({
        productsLoaded: true,
        category: "",
      })
    } else if (categories[category_url]) {
      this.setState({
        productsLoaded: true,
        category: category_url,
      });
    }
  }

  componentWillMount() {
    this.initiateLoadedProducts(this.props.fetchProductsReducer.products, this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.initiateLoadedProducts(nextProps.fetchProductsReducer.products, this.props);
  }

  componentWillUpdate(nextProps) {
    if (this.props.location !== nextProps.location) {
      this.initiateLoadedProducts(nextProps.fetchProductsReducer.products, nextProps);
    }
  }

  render() {
    return (
      <div>
        {this.state.category == "" ? (
          <div className="ProjectBanner">
            <style jsx>{`
              .ProjectBanner {
                position: relative;
                height: 100vh;
                overflow-y: auto;
              }
              .ProjectBanner .homeBanner {
                max-width: 100vw;
              }
              .ProjectBanner .homeBanner img{
                width: 100%;
                margin: auto;
              }
              .ProjectBanner .homeBanner .banner, map area{
                outline: none;
              }
            `}</style>
            <img className="homeBanner" src={homeBanner} useMap="#image-map" alt=""/>
            {/* image-map is created by https://www.image-map.net/ */}
            <map name="image-map">
                <area alt="Feaston" title="Feaston" onClick={() => this.changeRoute('feaston')} coords="78,100,571,252" shape="rect"/>
                <area alt="N796" title="N796" onClick={() => this.changeRoute('n796')} coords="102,507,177,464,253,432,390,506,342,552,251,560,200,558,126,539,149,553" shape="poly"/>
                <area alt="LMMG" title="LMMG" onClick={() => this.changeRoute('lmmg')} coords="106,1179,341,1848" shape="rect"></area>
                <area alt="Ronaldino" title="Ronaldino" onClick={() => this.changeRoute('ronaldino')} coords="497,380,501,423,470,438,472,479,484,517,492,548,506,618,529,619,533,528,557,534,561,627,588,629,593,534,609,532,613,612,634,614,645,528,659,488,663,474,663,455,593,403,548,411,546,368,535,361,514,365" shape="poly"/>
            </map>
          </div>
        ) : (
          this.state.productsLoaded ?
            categories[this.state.category].component :
            <div style={{ fontSize: "30px" }}> {/* To be replaced by Loading animation */}
                Loading...
            </div>
        )}
        <PanelRight noBorder /*withPath*/ />
      </div>
      
    );
  }
}

const mapStateToProps = state => ({
  ...state
})

export default withRouter(connect(mapStateToProps, {}) (Project));
