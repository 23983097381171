import React, { Component } from 'react';
import { client } from 'shopify';
import { withCookies } from 'react-cookie';
import { connect } from 'react-redux';
import { fetchCheckout } from 'actions/fetchCheckout';
import { isEmpty } from 'functions.js';
import EmailUs from 'scenes/Product/ProductWidgetGeneral/emailUs.jsx';
import SizeChartPopup from 'components/SizeChartPopup';

class ProductWidgetGeneral extends Component {

  constructor(props) {
    super(props);
    let defaultOptionValues = {};
    this.handleSizeChange = this.handleSizeChange.bind(this);
    if (!isEmpty(this.props.product)) {
      this.props.product.options.forEach((selector) => {
        if (selector.values.length === 1) {
          defaultOptionValues[selector.name] = selector.values[0].value;
        }
      });
    }
    this.state = {
      selectedOptions: defaultOptionValues,
      selectedVariant: null,
      selectedSize: null,
      selectedColor: null,
      isSizeSelected: defaultOptionValues["Size"] || (this.props.product && this.props.product.options && !this.props.product.options.find(o => o.name === "Size")),
      isColorSelected: defaultOptionValues["Color"] || (this.props.product && this.props.product.options && !this.props.product.options.find(o => o.name === "Color")),
      addedToCartReminder: false,
      isAddPressed: false,
      isBoosterLimitReached: false,
      isLoading: false,
    }
  }

  addVariantToCart(variantId, quantity){
    const { isLoading } = this.state;
    if (isLoading) {
      return;
    }
    if (variantId) {
      const lineItemsToAdd = [{variantId, quantity: parseInt(quantity, 10)}];
      const checkoutId = this.props.fetchCheckoutReducer.checkout.id;

      const { lineItems } = this.props.fetchCheckoutReducer.checkout;
      if (lineItems.length > 0) {
        for (let p of lineItems) {
          if (p.variant.id === variantId) {
            // check if booster limit is reached:
            if (p.variant.product.handle === "feaston-booster-pack" && p.quantity + quantity > 3) {
              this.setState({
                isBoosterLimitReached: true,
              }, () => {
                setTimeout(() => {
                this.setState({
                  isBoosterLimitReached: false,
                  })
                }, 3000);
              });
              return;
            }
          }
        }
      }

      this.setState({
        isLoading: true,
      }, () => {
        return client.checkout.addLineItems(checkoutId, lineItemsToAdd).then(res => {
          this.props.fetchCheckout(res);
          this.setState({
            addedToCartReminder: true,
            isAddPressed: true,
            isLoading: false
          }, () => {
            setTimeout(() => {
              this.setState({
                addedToCartReminder: false,
              })
            }, 3000);
          })
        });
      });
    } else {
      this.setState({
        isAddPressed: true,
      });
    }
  }

  handleSizeChange(size) {
    let selectedOptions = this.state.selectedOptions;
    selectedOptions["Size"] = size;
    const selectedVariant = client.product.helpers.variantForOptions(this.props.product, selectedOptions);
    this.setState({
      selectedVariant: selectedVariant,
      selectedSize: size,
      isSizeSelected: true,
      // selectedVariantImage: selectedVariant.attrs.image
    }, () => {
      const { onOptionChange } = this.props;
      if (onOptionChange) {
        onOptionChange(selectedOptions);
      }
    });
  }

  handleColorChange(color) {
    let selectedOptions = this.state.selectedOptions;
    selectedOptions["Color"] = color;
    const selectedVariant = client.product.helpers.variantForOptions(this.props.product, selectedOptions);
    this.setState({
      selectedVariant: selectedVariant,
      selectedColor: color,
      isColorSelected: true,
      // selectedVariantImage: selectedVariant.attrs.image
    }, () => {
      const { onOptionChange } = this.props;
      if (onOptionChange) {
        onOptionChange(selectedOptions);
      }
    });
  }

  componentWillMount() {
    let { cookies, product } = this.props;
    const checkoutId = cookies.get('checkoutID');
    if (checkoutId) {
      client.checkout.fetch(checkoutId).then((checkout) => {
        if (checkout.completedAt) {
          client.checkout.create().then((res) => {
            cookies.set('checkoutID', res.id, { path: '/', expires: new Date(Date.now()+31556952000)});
          });
        }
      });
    } else {
      client.checkout.create().then((res) => {
        cookies.set('checkoutID', res.id, { path: '/', expires: new Date(Date.now()+31556952000)});
      });
    }
    if (!isEmpty(product) && product.variants.length === 1) {
      this.setState({
        selectedVariant: product.variants[0],
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (isEmpty(this.props.product) && !isEmpty(nextProps.product)) {
      let defaultOptionValues = {};
      nextProps.product.options.forEach((selector) => {
        if (selector.values.length === 1) {
          defaultOptionValues[selector.name] = selector.values[0].value;
        }
      });
      this.setState({
        selectedVariant: nextProps.product.variants.length === 1 ? nextProps.product.variants[0] : null,
        selectedOptions: defaultOptionValues,
        isSizeSelected: defaultOptionValues["Size"] || !nextProps.product.options.find(o => o.name === "Size"),
        isColorSelected: defaultOptionValues["Color"] || !nextProps.product.options.find(o => o.name === "Color"),
      });
    }
  }

  render() {
    const { product, isHomeWidget, isDesktop, pickUpOnly, page, isIframe } = this.props;
    let descriptionHtml, productType = null;
    if (!isEmpty(product)) {
      descriptionHtml = product.descriptionHtml;
      productType = product.productType;
    }
    let el = window.document.createElement('html');
    if (descriptionHtml) {
      el.innerHTML = descriptionHtml;
    }
    let title = el.getElementsByClassName('title')[0] ? el.getElementsByClassName('title')[0].innerHTML : (isEmpty(product) ? "" : product.title);

    const sizes = !isEmpty(product) && product.options.find(o => o.name == "Size") ? product.options.find(o => o.name == "Size").values : [];
    const colors = !isEmpty(product) && product.options.find(o => o.name == "Color") ? product.options.find(o => o.name == "Color").values : [];

    const { selectedVariant, isAddPressed } = this.state;

    const currencyAfterConversion = (price) => {
      return this.props.fetchCheckoutReducer.checkout ?
        window.Currency.convert(price, this.props.fetchCheckoutReducer.checkout?.currencyCode || "USD", this.props.fetchSelectedCurrencyReducer.selectedCurrency || "USD").toFixed(2)
        :
        0
    };
    return (
      !isEmpty(product) ? (
        <div className={`ProductWidgetGeneral${page ? ` ProductWidgetGeneral_in_${page}` : ""}${isHomeWidget ? ' isHomeWidget' : ''}`}>
          <style jsx>{`
            .ProductWidgetGeneral.isHomeWidget > div{
              display: flex;
              justify-content: space-between;
            }
            .ProductWidgetGeneral.isHomeWidget .price-button-container .price {
              display: flex;
              padding-left: 30px;
              font-weight: normal;
            }
            .ProductWidgetGeneral.isHomeWidget .price-button-container .price > span{
              text-align: right;
            }
            .ProductWidgetGeneral {
              bottom: 0;
              padding: 0 25px 5px 25px;
              width: ${isDesktop ? "500px" : (isHomeWidget ? '100%' : 'unset')};
              padding-top: ${isDesktop ? "20px" : "0"};
            }

            .ProductWidgetGeneral .color-title {
              padding-right: 20px;
            }
            
            .ProductWidgetGeneral .bg-white {
              background: #FFFFFF;
            }

            .ProductWidgetGeneral .product-title-container {
              font-size: 20px;
              background: white;
              width: fit-content;
              text-align: left;
              margin: 15px 0 15px;
              font-weight: bold;
              display: flex;
              align-items: center;
            }

            .ProductWidgetGeneral .product-title-container .Feaston-product-title {
              text-align: left;
            }
            
            .ProductWidgetGeneral .variant-container {
              display: flex;
              align-items: center;
              margin-bottom: 5px;
              font-weight: bold;
            }

            .ProductWidgetGeneral .variant-container .variants{
              display: flex;
              width: 100%;
              justify-content: center;
            }

            .ProductWidgetGeneral .variant-container .variant{
              width: 40px;
              cursor: pointer;
            }

            .ProductWidgetGeneral .color-variant-container{
              margin-top: 25px;
            }

            .ProductWidgetGeneral .variant-container .color-variant{
              width: 100%;
              cursor: pointer;
              padding-left: 20px;
            }

            .ProductWidgetGeneral .option-container .Product_option{
              background: white;
              border: none;
              border-radius: 0;
              font-size: 16px;
              margin: 0 5px 10px 5px;
            }
            .ProductWidgetGeneral select {
              text-align-last: center;
              -webkit-appearance: none;
              appearance: none;
              font-weight: bold;
              padding: 2px;
            }
            .ProductWidgetGeneral select::-ms-expand {
              display: none;
            }
            .ProductWidgetGeneral .variant-title {
              font-size: 12px;
              font-weight: bold;
            }
            .ProductWidgetGeneral .Product_buy {
              font-size: 12px;
              color: black;
              background: white;
              font-weight: bold;
              width: 100px;
              border-radius: 0;
              border-color: black;
              border: 1px solid;
              cursor: pointer;
              font-family: "Cousine";
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 4px 0 2px 0;
            }

            .ProductWidgetGeneral .variant-sold-out {
              color: red;
              font-size: 20px;
              font-weight: bold;
            }

            .ProductWidgetGeneral .reminder {
              color: rgb(227, 8, 28);
              font-weight: bold;
              text-align: left;
              margin-top: 20px;
            }

            .ProductWidgetGeneral .reminder.added-to-cart {
              color: green;
            }

            .ProductWidgetGeneral .price-button-container {
              display: flex;
              justify-content: space-between;
              margin: 15px 0;
            }

            .ProductWidgetGeneral .price-button-container .price {
              font-size: 23px;
              font-weight: bold;
              text-align: left;
              margin-right: 5px;
            }

            .ProductWidgetGeneral .price-button-container .product-widget-button {
              display: flex;
              align-items: center;
            }

            .ProductWidgetGeneral_in_productDetail {
              //padding: 0 25px;
            }

            .product-desc-container {
              font-size: 12px;
              text-align: left;
            }
          `}</style>
            <div>
              <div className="product-title-container">
                <div className="Feaston-product-title">
                  {title.split('\\n').map(function(item) {
                    return (
                      <span key={item}>
                        {item.replace(/-/g, '‑')}
                        <br/>
                      </span>
                    )
                  })}
                </div>
              </div>

              {isIframe ? (
                <div className="product-desc-container">
                  <div dangerouslySetInnerHTML={{__html: product ? product.descriptionHtml : null}} />
                </div>
              ) : null}

              {sizes.length > 0 ? (
                isHomeWidget || !product.availableForSale ? null :
                  <div className="variant-container">
                    <SizeChartPopup productType={productType}>
                      <div className="size-title">
                        SIZE
                      </div>
                    </SizeChartPopup>
                    <div className="variants">
                      {sizes.length > 0 ? sizes.map((s) => {
                        return (
                          <div className="variant" key={s.value} onClick={() => this.handleSizeChange(s.value)}>
                            <span className="bg-white">
                              {this.state.selectedSize == s.value ? "(" : ""}
                              {s.value}
                              {this.state.selectedSize == s.value ? ")" : ""}
                            </span>
                          </div>
                        )
                      }) : null}
                    </div>
                  </div>
              ) : null}

              {colors.length > 0 ? (
                isHomeWidget ? null : <div className="variant-container color-variant-container">
                  <div className="color-title bg-white">
                    COLOR
                  </div>
                  {colors.length > 0 ? colors.map((c) => {
                    return colors.length === 1 ? (
                        <div className="color-variant" key={c.value}>
                          <span className="bg-white">{c.value}</span>
                        </div>
                      ) : (
                        <div className="color-variant" key={c.value} onClick={() => this.handleColorChange(c.value)}>
                          <span className="bg-white">
                            {this.state.selectedColor == c.value ? "(" : ""}
                            {c.value}
                            {this.state.selectedColor == c.value ? ")" : ""}
                          </span>
                        </div>
                      );
                  }) : null}
                </div>
              ) : null}
                
              {isAddPressed && (!this.state.isSizeSelected || !this.state.isColorSelected) ?
                <div className="reminder">
                  SELECT YOUR {this.state.isSizeSelected ? "" : `SIZE${!this.state.isColorSelected ? "," : ""}`} {this.state.isColorSelected ? "" : "COLOR"}
                </div> : null}

              {this.state.addedToCartReminder ?
                <div className="reminder added-to-cart">
                  ADDED TO CART
                </div> : null}

              {this.state.isBoosterLimitReached ? 
                <div className="reminder">
                  Each customer may only purchase 3 boosters at a time.
                </div> : null}

              {pickUpOnly ? <div style={{ textAlign: "left" }}><b>* Local pick-up only</b></div> : null}

              <div className="price-button-container">
                <div className="price">
                  <span className="bg-white">{this.props.fetchSelectedCurrencyReducer.selectedCurrency || "USD"} {currencyAfterConversion(product.variants[0].price.amount)}</span>
                </div>

                {!isHomeWidget && (
                  <div className="product-widget-button">
                    {product.availableForSale && ((selectedVariant && selectedVariant.available) || !selectedVariant) ?
                      <div className="Product_buy button" onClick={() => this.addVariantToCart(selectedVariant ? selectedVariant.id : null, 1)}>
                        ADD TO CART
                      </div>
                      :
                      <div className="variant-sold-out">
                        SOLD OUT
                        <EmailUs product_name={title} size={this.state.selectedSize} color={this.state.selectedColor} />
                      </div>}
                  </div>
                )}
              </div>
              
              {product && product.handle === "andy-lau-lighter" && !isHomeWidget ? (
                <div style={{ textAlign: "left" }}>
                  <p>
                    Select "Pick up" as delivery method.
                  </p>
                </div>
              ) : null}
             
            </div>

        </div>
      ) : null
    );
  }
}

const mapStateToProps = state => ({
  ...state
})

const mapDispatchToProps = dispatch => ({
  fetchCheckout: (checkout) => dispatch(fetchCheckout(checkout)),
})

export default withCookies(connect(mapStateToProps, mapDispatchToProps) (ProductWidgetGeneral));
