import React, { Component } from 'react';
import { connect } from 'react-redux';
import ScrollManager from "ScrollManager/index.jsx";
import { Helmet } from "react-helmet";
import FW1986 from "./FW1986_mobile.png";
import MobileHome2021winter from "_Mobile/scenes/MobileHome2021winter";
// import WIHTE_LOGO from 'scenes/GreatReset/FW1986_logo.png';

function MobileGreatReset(props) {
  const { fetchFeatureCollectionReducer } = props;
  const { collection } = fetchFeatureCollectionReducer;
  if (!collection) {
    return (
      <div>
        <style jsx>{`
        .MobileHeaderCart {
          display: none;
        }
        .MobileNavigation .Expandable {
          display: none;
        }
      `}</style>
      </div>
    )
  } else if (collection?.products?.length > 0) {
    return (
      <MobileHome2021winter collection={collection} />
    )
  }
  return (
    <div className="MobileGreatReset">
      <style jsx>{`
        .App {
          background-color: #201c42 !important;
        }
        .MobileNavigation .logo {
          background: none;
        }
        .MobileNavigation .logo .option_home {
          filter: invert(1);
        }
        .MobileGreatReset {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          text-align: left;
        }
        .MobileGreatReset img {
          width: 100%;
          height: auto;
          padding-bottom: 20px;
        }
        .MobileHeaderCart {
          display: none;
        }
        .MobileNavigation .Expandable.notHome .expandBg {
          background: none;
        }
        .MobileNavigation .Expandable .icon .arrow {
          color: white;
        }
        .MobileNavigation .MobileOptions .option_shop {
          display: none;
        }
      `}</style>
      <img src={FW1986} alt="" />
    </div>
  )
}

const mapStateToProps = state => ({
  ...state
})

export default connect(mapStateToProps, {})(MobileGreatReset);
