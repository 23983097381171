import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Link } from 'react-router-dom';
import { withRouter } from 'withRouter';
import PanelRight from 'components/PanelRight';
import { Helmet } from "react-helmet";
import LazyLoad from 'react-lazyload';
import OS from './OS.png';
import HEAD01 from 'scenes/YearBook/bigHeads/desktopHeadsAgain-01.png';
import HEAD02 from 'scenes/YearBook/bigHeads/desktopHeadsAgain-02.png';
import HEAD03 from 'scenes/YearBook/bigHeads/desktopHeadsAgain-03.png';
import HEAD04 from 'scenes/YearBook/bigHeads/desktopHeadsAgain-04.png';
import HEAD05 from 'scenes/YearBook/bigHeads/desktopHeadsAgain-05.png';
import HEAD06 from 'scenes/YearBook/bigHeads/desktopHeadsAgain-06.png';
import HEAD07 from 'scenes/YearBook/bigHeads/desktopHeadsAgain-07.png';
import HEAD08 from 'scenes/YearBook/bigHeads/desktopHeadsAgain-08.png';
import HEAD09 from 'scenes/YearBook/bigHeads/desktopHeadsAgain-09.png';
import HEAD10 from 'scenes/YearBook/bigHeads/desktopHeadsAgain-10.png';
import HEAD11 from 'scenes/YearBook/bigHeads/desktopHeadsAgain-11.png';
import HEAD12 from 'scenes/YearBook/bigHeads/desktopHeadsAgain-12.png';
import HEAD13 from 'scenes/YearBook/bigHeads/desktopHeadsAgain-13.png';
import HEAD14 from 'scenes/YearBook/bigHeads/desktopHeadsAgain-14.png';
import HEAD15 from 'scenes/YearBook/bigHeads/desktopHeadsAgain-15.png';
import HEAD16 from 'scenes/YearBook/bigHeads/desktopHeadsAgain-16.png';
import PortraitPopup from './PortraitPopup';

const BigHead = ({ img, onClick }) => {
  return (
    <div className="bigHead" onClick={onClick}>
      <style jsx>{`
        .bigHead {
          width: 100%;
          margin-bottom: 22px;
          cursor: pointer;
        }
        .bigHead img {
          width: 100%;
        }
      `}</style>
      <img src={img} alt="" />
    </div>
  );
}

class Home extends Component {

  constructor(props) {
    super(props);
    this.state = {
      portrait: null,
    };
    this.onPortraitClose = this.onPortraitClose.bind(this);
  }

  onPortraitClose() {
    this.setState({
      portrait: null,
    });
  }

  componentDidMount() {
    window.imageMapResize();
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll(event) {
    window.imageMapResize();
  }

  render() {
    const { portrait } = this.state;
    const viewportWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    const slideHeight = Math.floor((1920 * viewportWidth) / 3072);

    return (
      <div className="LookbookPage">
        <Helmet>
          <title>FEASTON</title>
          {/* <meta name="description" content="FEASTON"/> */}
          <meta property="og:image" content="https://feaston.co/favicon.ico" />
          <meta property="og:title" content="FEASTON"/>
          <link rel="canonical" href="https://feaston.co" />
        </Helmet>
        <style jsx>{`
          .HomePageBanner {
            overflow-y: auto;
            display: flex;
          }
          .LookbookPage .homeCover {
            height: 100%;
            position: relative;
            min-width: 1155px;
            display: flex;
            background: white;
            align-items: center;
            //min-height: 880px;
          }
          .LookbookPage .homeCover .homeCover-inner {
            display: flex;
            flex-direction: column;
            align-items: center;
            height: 100%;
            width: 100%;
          }
          .LookbookPage .homeCover .schoolLogo {
            width: 400px;
            max-height: 134px;
            padding-bottom: 60px;
          }
          .LookbookPage .homeCover .row {
            display: flex;
            width: 100%;
            justify-content: space-between;
          }
          .LookbookPage .homeCover .row .bigHead {
            width: 127px;
          }
          .LookbookPage .home-row {
            display: flex;
            max-width: 1417px;
            margin: auto;
          }
          .LookbookPage .lazyload-wrapper {
            min-height: ${`${slideHeight}px`};
          }
          .HomePageBanner .homeBanner-container-wrapper {
            height: fit-content;
            margin-left: auto;
            margin-right: auto;
          }
          .HomePageBanner .homeBanner-container-wrapper ::-webkit-scrollbar {
            width: 0px;  /* Remove scrollbar space */
            background: transparent;  /* Optional: just make scrollbar invisible */
          }
          .HomePageBanner .homeBanner-container-wrapper ::-webkit-scrollbar-thumb {
            background: #FF0000;
          }
          .LookbookPage .feaston-home {
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;
            min-height: calc(100vh - 50px);
          }
          .LookbookPage .feaston-home img, .LookbookPage .feaston-home-footer img {
            max-height: 100%;
            max-width: 100%;
            margin: 0 auto;
            object-fit: contain;
          }
          .HomePageBanner .homeBanner-container {
            position: relative;
            width: 80%;
            height: 850px;
            margin: auto;
            z-index: 999;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
          }
          .HomePageBanner .homeBanner-container.c-top{
            margin-top: 150px;
          }
          .HomePageBanner .homeBanner {
            //max-width: 100vw;
            position: absolute;
          }
          .HomePageBanner .homeBanner.product-item {
            position: unset;
            max-width: 600px !important;
          }
          .HomePageBanner .homeBanner.product-1 {
            left: 40px;
            top: 50px;
          }
          .HomePageBanner .homeBanner.product-2 {
            width: 400px;
            left: 800px;
            top: -80px;
            transform: rotate(0deg);
          }
          .HomePageBanner .homeBanner.product-3 {
            left: 40px;
            top: 50px;
            z-index: 10;
          }
          .HomePageBanner .homeBanner.product-4 {
            width: 400px;
            left: 800px;
            top: -80px;
            transform: rotate(0deg);
          }
          .HomePageBanner .homeBanner img{
            width: 100%;
            margin: auto;
          }
          .HomePageBanner .homeBanner .banner, map area{
            outline: none;
          }
          .HomePageBanner .homeBanner-container-wrapper .feaston-home-footer {
            height: 100vh;
            display: flex;
            align-items: center;
            //margin-top: 80px;
          }
          .HomePageBanner .homeBanner-container-wrapper .feaston-home-footer img {
            //max-width: 515px; //1317px;
            //margin-bottom: 50px;
          }
          .HomePageBanner .homeBanner-arrow {
            height: 120px;
            margin-top: 50px;
          }
          .HomePageBanner .banner-container {
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .HomePageBanner .topBar {
            position: fixed;
            top: 0;
            font-weight: bold;
            height: 28px;
            background: #fff;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 1000;
          }
          .LookbookPage .autoHeight .homeBanner-container {
            height: auto;
          }
        `}</style>
        <div className="feaston-home">
          <div className="homeCover">
            <div className="homeCover-inner">
              <div className="row row1">
                <img className="schoolLogo" src={OS} alt="" />
              </div>

              <div className="row row2">
                <BigHead img={HEAD11} onClick={() => this.setState({ portrait: 10 })}/>
                <BigHead img={HEAD12} onClick={() => this.setState({ portrait: 11 })}/>
                <BigHead img={HEAD16} onClick={() => this.setState({ portrait: 15 })}/>
                <BigHead img={HEAD15} onClick={() => this.setState({ portrait: 14 })}/>
                <BigHead img={HEAD14} onClick={() => this.setState({ portrait: 13 })}/>
                <BigHead img={HEAD13} onClick={() => this.setState({ portrait: 12 })}/>
                <BigHead img={HEAD04} onClick={() => this.setState({ portrait: 3 })}/>
                <BigHead img={HEAD01} onClick={() => this.setState({ portrait: 0 })}/>
              </div>
              <div className="row row3">
                <BigHead img={HEAD02} onClick={() => this.setState({ portrait: 1 })}/>
                <BigHead img={HEAD03} onClick={() => this.setState({ portrait: 2 })}/>
                <BigHead img={HEAD05} onClick={() => this.setState({ portrait: 4 })}/>
                <BigHead img={HEAD06} onClick={() => this.setState({ portrait: 5 })}/>
                <BigHead img={HEAD07} onClick={() => this.setState({ portrait: 6 })}/>
                <BigHead img={HEAD08} onClick={() => this.setState({ portrait: 7 })}/>
                <BigHead img={HEAD09} onClick={() => this.setState({ portrait: 8 })}/>
                <BigHead img={HEAD10} onClick={() => this.setState({ portrait: 9 })}/>
              </div>
            </div>
          </div>
        </div>
        <PortraitPopup portrait={portrait} onClose={this.onPortraitClose} show={portrait != null} />
        <PanelRight noBorder noBackground atHome/*withPath*/ />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  ...state
})

export default withRouter(connect(mapStateToProps, {}) (Home));
