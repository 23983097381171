export default (state = {}, action) => {
  switch (action.type) {
   case 'FETCH_IS_CART_OPEN':
    return {
      isCartOpen: action.payload
    }
   default:
    return state
  }
 }
 