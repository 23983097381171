import React, { Component } from 'react';
import { connect } from 'react-redux';
import ABOUT_IMG from 'assets/AboutUs/aboutUs.png';
import { withRouter } from 'withRouter';
import { Helmet } from "react-helmet";
import ABOUT from "./Tabs/about.png";
import ABOUT_LINE from "./Tabs/about_line.png";
import EVENTS from "./Tabs/events.png";
import EVENTS_LINE from "./Tabs/events_line.png";
import STOCKISTS from "./Tabs/stockists.png";
import STOCKISTS_LINE from "./Tabs/stockists_line.png";
import ABOUT_CONTENT_IMG from "./About/aboutImages.png";
import FFF from "./Events/fff.png";
import HC from "./Events/hc.png";
import LC from "./Events/lc.png";
import TK from "./Events/tk.png";
import YHSY from "./Events/yhsy.png";
import SGCARD from "./Events/sgcarda.png";
import GLA from "./Events/GLA.png";
import APD from "./Events/APD.png";
import HA from "./Events/HA.png";
import repoPop from "./Events/repoPop.png";
import SHEEPS from "./Events/SHEEPS.png";
import FL from "./Events/FL.png";
import BOON from "./Stockists/BOON.png";
import DN4 from "./Stockists/DN4.jpg";
import STOCKISTS_BOX from "_Mobile/assets/Home/homeBanners/apbox-01.png";

class About extends Component {

  constructor(props) {
    super(props);
    this.updateDimensions = this.updateDimensions.bind(this);
    this.state = {
      scale: 1,
      tab: 1,
    }
  }

  updateDimensions() {
    const newScale = (window.innerWidth < 375 ? 375 : window.innerWidth)/375;
    this.setState({
      scale: newScale < 1 ? newScale : 1,
    });
  }

  componentWillMount() {
    this.updateDimensions();
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  render() {
    const { scale, tab } = this.state;
    const { match } =  this.props;
    const { url } = match;

    return (
      <div className="web-page About">
        <Helmet>
          <title>FEASTON - About</title>
          <link rel="canonical" href={`https://feaston.co${url}`} />
        </Helmet>
        <style jsx>{`
          .About {
            height: 100vh;
            display: unset;
          }
          .About .AboutBanner {
            transform: scale(${scale});
          }
          .About .AboutUs-title {
            font-family: Helvetica Neue;
            font-weight: bold;
            font-size: 19px;
            line-height: 1.5;
            text-align: center;
            color: #000000;
            margin-bottom: 4px;
          }
          .About .AboutUs-sub-desc {
            font-family: Helvetica Neue;
            font-size: 11px;
            line-height: normal;
            text-align: center;
            color: #000000;
            font-weight: bold;
          }
          .About .AboutBanner img {
            width: 100%;
            margin-bottom: 4px;
          }
          .About .AboutUs-right-container {
            padding: 30px 30px;
            margin: 0 5px;
            text-align: left;
            font-weight: bold;
            font-family: Cousine;
            // temp: temp;
            display: flex;
            justify-content: space-between;
          }
          .About .About-tabs {
            border-top: 1px solid darkgrey;
            display: flex;
            justify-content: space-evenly;
            padding: 20px;
          }
          .About .About-tab {
            cursor: pointer;
            color: grey;
          }
          .About .About-tab img {
            height: 22px;
            width: auto;
          }
          .About .About-tab.active {
            color: black;
            //border-bottom: 2px solid black;
          }
          .About .AboutUs-address {
            margin-bottom: 30px;
            font-size: 18px;
            line-height: 1.2;
          }
          .About .AboutUs-email {
            cursor: pointer;
            font-size: 16px;
            font-family: Helvetica Neue;
          }
          .About .AboutUs-email .inquiries {
            padding-bottom: 4px;
          }
          .About .AboutUs-email a {
            font-size: 22px;
          }
          .About .ig_icon {
            font-weight: normal;
          }
          .About .AboutUs-address-ig-container {
            display: flex;
            justify-content: space-between;
            padding-top: 4px;
          }
          .About .AboutUs-address-ig-container .icon-wrapper {
            height: max-content;
            transform: scale(1.5) translate(-3px, 1px);
          }
          .eventRow {
            padding: 20px 0;
            margin: auto;
          }
          .stockistIcon {
            padding-top: 20px;
            margin: auto;
          }
          .stockistAddress {
            font-weight: bold;
            padding: 20px 0;
          }
        `}</style>
        <div className="AboutUs-right-container">
          {/* <div className="AboutUs-address-ig-container">
            <div className="AboutUs-address">
              29A 7/F<br/>
              15 Wang Hoi Road<br/>
              Kowloon Bay,<br/>
              Kowloon,<br/>
              HONG KONG
            </div>
            <a className="icon-wrapper" href="https://www.instagram.com/feaston96/" target="blank" >
              <i className="ig_icon fab fa-instagram fa-2x"></i>
            </a>
          </div>
          <div className="AboutUs-email">
            <a href="mailto:info@feaston.co">info(at)feaston.co</a>
          </div> */}
          <div className="AboutUs-email">
            <div className="inquiries">inquiries and booking:</div>
            <a href="mailto:info@feaston.co">info@feaston.co</a>
          </div>
          <div className="AboutUs-address-ig-container">
            {/* <div className="AboutUs-address">
              29A 7/F<br/>
              15 Wang Hoi Road<br/>
              Kowloon Bay,<br/>
              Kowloon,<br/>
              HONG KONG
            </div> */}
            <a className="icon-wrapper" href="https://www.instagram.com/feaston96/" target="blank" >
              <i className="ig_icon fab fa-instagram fa-2x"></i>
            </a>
          </div>
        </div>
        <div className="About-tabs">
          <div className={`About-tab tab1${tab === 1 ? " active" : ""}`} onClick={() => this.setState({ tab: 1 })}>
            <img src={tab === 1 ? ABOUT_LINE : ABOUT} alt="" />
          </div>
          <div className={`About-tab tab2${tab === 2 ? " active" : ""}`} onClick={() => this.setState({ tab: 2 })}>
            <img src={tab === 2 ? EVENTS_LINE : EVENTS} alt="" />
          </div>
          <div className={`About-tab tab3${tab === 3 ? " active" : ""}`} onClick={() => this.setState({ tab: 3 })}>
            <img src={tab === 3 ? STOCKISTS_LINE : STOCKISTS} alt="" />
          </div>
        </div>
        <div className="AboutBanner">
          {tab === 1 ? (
            <div style={{ padding: '0 25px 40px 25px' }}>
              <div>
                <img src={ABOUT_CONTENT_IMG} alt=""/>
              </div>
            </div>
          ) : null}
          {tab === 2 ? (
            <div style={{ padding: '20px 30px 40px 30px'}}>
              <div className="eventRow" style={{ width: "80%" }}>
                <img src={HA} alt="" />
              </div>
              <div className="eventRow" style={{ width: "80%" }}>
                <img src={SHEEPS} alt="" />
              </div>
              <div className="eventRow" style={{ width: "70%" }}>
                <img src={repoPop} alt="" />
              </div>
              <div className="eventRow" style={{ width: "70%" }}>
                <img src={FL} alt="" />
              </div>
              <div className="eventRow" style={{ width: "70%" }}>
                <img src={APD} alt="" />
              </div>
              <div className="eventRow" style={{ width: "45%" }}>
                <img src={GLA} alt="" />
              </div>
              <div className="eventRow" style={{ width: "85%" }}>
                <img src={HC} alt="" />
              </div>
              <div className="eventRow" style={{ width: "30%" }}>
                <img src={TK} alt="" />
              </div>
              <div className="eventRow" style={{ width: "55%" }}>
                <img src={YHSY} alt="" />
              </div>
              <div className="eventRow" style={{ width: "70%" }}>
                <img src={LC} alt="" />
              </div>
              <div className="eventRow" style={{ width: "50%", paddingBottom: "40px" }}>
                <img src={FFF} alt="" />
              </div>
            </div>
          ) : null}
          {tab === 3 ? (
            <div style={{ padding: '0 25px', height: 'calc(100vh - 218px)' }}>
              <a href="https://www.instagram.com/doors_and_fours/" target="_blank" rel="noopener noreferrer">
                <div className="stockistIcon" style={{ width: "50%" }}>
                  <img src={DN4} alt="" />
                </div>
              </a>
              <div className="stockistAddress">
                Flat 8, 1/F,<br />
                Hang Wai Industrial Centre,<br />
                Tuen Mun
              </div>
              <a href="https://www.instagram.com/dtby_/" target="_blank" rel="noopener noreferrer">
                <div className="stockistIcon" style={{ width: "70%" }}>
                  <img src={BOON} alt="" />
                </div>
              </a>
              <div className="stockistAddress" style={{ paddingBottom: "40px" }}>
                G/F, K11 Art Mall,<br />
                18 Hanoi Road,<br />
                Tsim Sha Tsui, Kowloon
              </div>
            </div>
          ) : null}
        </div>
      </div>
      
    );
  }
}

const mapStateToProps = state => ({
  ...state
})

export default withRouter(connect(mapStateToProps, {}) (About));
