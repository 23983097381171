import React, { Component, useState } from 'react';
import { connect } from 'react-redux';
import ScrollManager from "ScrollManager/index.jsx";
import { Helmet } from "react-helmet";
import PAST from "assets/Navigation/past.png";
import ES_TALK from "assets/Past/es_talk.png";
import FFF from "assets/Past/fff.png";
import HC from "assets/Past/hc.png";
import LC from "assets/Past/lc.png";
import TK from "assets/Past/tk.png";
import YHSY from "assets/Past/yhsy.png";
import GLA from "assets/Past/GLA.png";
import APD from "assets/Past/APD.png";
import HA from "assets/Past/HA.png";
import repoPop from "assets/Past/repoPop.png";
import SHEEPS from "assets/Past/SHEEPS.png";
import FL from "assets/Past/FL.png";
// import FFF from "_Mobile/scenes/MobileAbout/Events/fff.png";
// import HC from "_Mobile/scenes/MobileAbout/Events/hc.png";
// import LC from "_Mobile/scenes/MobileAbout/Events/lc.png";
// import TK from "_Mobile/scenes/MobileAbout/Events/tk.png";
// import YHSY from "_Mobile/scenes/MobileAbout/Events/yhsy.png";
// import GLA from "_Mobile/scenes/MobileAbout/Events/GLA.png";
// import APD from "_Mobile/scenes/MobileAbout/Events/APD.png";
// import HA from "_Mobile/scenes/MobileAbout/Events/HA.png";
// import repoPop from "_Mobile/scenes/MobileAbout/Events/repoPop.png";
// import SHEEPS from "_Mobile/scenes/MobileAbout/Events/SHEEPS.png";
// import FL from "_Mobile/scenes/MobileAbout/Events/FL.png";

function Past() {
  return (
    <div className="Past">
      <style jsx>{`
        .Past {
          padding: 50px;
          height: calc(100vh - 90px);
        }
        .Past .past-title {
          height: 80px;
          margin: auto;
        }
        .Past .past-title img {
          height: 100%;
          width: auto;
        }
        .Past .pasts {
          width: 85%;
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: center;
          margin: auto;
        }
        .Past .event {
          width: 13vw;
          padding: 10px;
          min-height: 15vh;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .Past .event img {
          width: 100%;
        }
      `}</style>
      <div className="past-title">
        <img src={PAST} alt="" />
      </div>
      <div className="pasts">
        <div className="event">
          <img src={ES_TALK} alt="" />
        </div>
        <div className="event">
          <img src={HA} alt="" />
        </div>
        <div className="event">
          <img src={SHEEPS} alt="" />
        </div>
        <div className="event">
          <img src={repoPop} alt="" />
        </div>
        <div className="event">
          <img src={FL} alt="" />
        </div>
        <div className="event">
          <img src={APD} alt="" />
        </div>
        <div className="event">
          <img src={GLA} alt="" />
        </div>
        <div className="event">
          <img src={HC} alt="" />
        </div>
        <div className="event">
          <img src={TK} alt="" />
        </div>
        <div className="event">
          <img src={YHSY} alt="" />
        </div>
        <div className="event">
          <img src={LC} alt="" />
        </div>
        <div className="event">
          <img src={FFF} alt="" />
        </div>
      </div>
    </div >
  )
}

const mapStateToProps = state => ({
  ...state
})

export default connect(mapStateToProps, {})(Past);
