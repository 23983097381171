import React, { Component } from 'react';
import { client } from '../../../shopify';
import { withCookies } from 'react-cookie';
import { connect } from 'react-redux';
import { fetchCheckout } from '../../../actions/fetchCheckout';

class ProductWidgetLMMG extends Component {

  constructor(props) {
    super(props);
    let defaultOptionValues = {};
    this.handleSizeChange = this.handleSizeChange.bind(this);
    this.handleColorChange = this.handleColorChange.bind(this);
    this.props.product.options.forEach((selector) => {
      defaultOptionValues[selector.name] = selector.values[0].value;
    });
    this.state = {
      selectedOptions: defaultOptionValues,
      selectedVariant: null,
      selectedSize: null,
      selectedColor: null,
      isSizeSelected: true,
      isColorSelected: true,
    }
  }

  addVariantToCart(variantId, quantity){
    if (variantId) {
      const lineItemsToAdd = [{variantId, quantity: parseInt(quantity, 10)}];
      const checkoutId = this.props.fetchCheckoutReducer.checkout.id;
      return client.checkout.addLineItems(checkoutId, lineItemsToAdd).then(res => {
        this.props.fetchCheckout(res);
      });
    } else {
      this.setState({
        isSizeSelected: this.state.selectedSize,
        isColorSelected: this.state.selectedColor,
      })
    }
  }

  handleSizeChange(size) {
    let selectedOptions = this.state.selectedOptions;
    selectedOptions["Size"] = size;
    selectedOptions["Color"] = this.state.selectedColor;
    const selectedVariant = client.product.helpers.variantForOptions(this.props.product, selectedOptions)
    this.setState({
      selectedVariant: selectedVariant,
      selectedSize: size,
      isSizeSelected: true,
      // selectedVariantImage: selectedVariant.attrs.image
    });
  }

  handleColorChange(color) {
    let selectedOptions = this.state.selectedOptions;
    selectedOptions["Size"] = this.state.selectedSize;
    selectedOptions["Color"] = color;
    const selectedVariant = client.product.helpers.variantForOptions(this.props.product, selectedOptions)
    this.setState({
      selectedVariant: selectedVariant,
      selectedColor: color,
      isColorSelected: true,
    });
  }

  componentWillMount() {
    let { cookies } = this.props;
    const checkoutId = cookies.get('checkoutID');
    if (checkoutId) {
      client.checkout.fetch(checkoutId).then((checkout) => {
        if (checkout.completedAt) {
          client.checkout.create().then((res) => {
            cookies.set('checkoutID', res.id, { path: '/', expires: new Date(Date.now()+31556952000)});
          });
        }
      });
    } else {
      client.checkout.create().then((res) => {
        cookies.set('checkoutID', res.id, { path: '/', expires: new Date(Date.now()+31556952000)});
      });
    }
  }

  render() {
    const { product } = this.props;
    const sizes = product.options.find(o => o.name == "Size").values;
    const colors = product.options.find(o => o.name == "Color").values;
    const { selectedVariant } = this.state;

    const currencyAfterConversion = (price) => {
      return this.props.fetchCheckoutReducer.checkout ? 
        window.Currency.convert(price, this.props.fetchCheckoutReducer.checkout.currencyCode, this.props.fetchSelectedCurrencyReducer.selectedCurrency).toFixed(2)
        :
        0
    };

    return (
      <div className="ProductWidgetLMMG">
        <style jsx>{`
          .ProductWidgetLMMG {
            position: absolute;
            bottom: 0;
            height: 40vh;
            padding: 0 25px 20px 25px;
            width: calc(17vw - 50px);
          }

          .ProductWidgetLMMG .product-title-container {
            font-size: 16px;
            background: white;
            width: fit-content;
            text-align: center;
            margin: 20px auto;
            padding: 0 10%;
            font-weight: bold;
          }

          .ProductWidgetLMMG .product-title-container .Feaston-product-title {
            margin-top: 10px;
            text-align: left;
          }
          
          .ProductWidgetLMMG .variant-container {
            display: flex;
            align-items: center;
            margin-bottom: 15px;
            font-weight: bold;
          }

          .ProductWidgetLMMG .variant-container .variant{
            width: 40px;
            cursor: pointer;
          }
          .ProductWidgetLMMG .variant-container .variant.variant_color {
            width: 120px;
            cursor: pointer;
          }

          .ProductWidgetLMMG .option-container .Product_option{
            background: white;
            border: none;
            border-radius: 0;
            font-size: 16px;
            margin: 0 5px 10px 5px;
          }
          .ProductWidgetLMMG select {
            text-align-last: center;
            -webkit-appearance: none;
            appearance: none;
            font-weight: bold;
            padding: 2px;
          }
          .ProductWidgetLMMG select::-ms-expand {
            display: none;
          }
          .ProductWidgetLMMG .variant-title {
            font-size: 12px;
            font-weight: bold;
          }
          .ProductWidgetLMMG .color-title {
            margin-right: 30px;
          }
          .ProductWidgetLMMG .Product_buy {
            font-size: 10px;
            color: black;
            background: white;
            font-weight: bold;
            width: 100px;
            border-radius: 0;
            border-color: black;
            border: 1px solid;
            cursor: pointer;
            font-family: "Cousine";
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 4px 0 2px 0;
          }

          .ProductWidgetLMMG .variant-sold-out {
            color: red;
            font-size: 16px;
            font-weight: bold;
          }

          .ProductWidgetLMMG .reminder {
            color: rgb(227, 8, 28);
            font-weight: bold;
            text-align: left;
            margin-top: 20px;
          }

          .ProductWidgetLMMG .price-button-container {
            display: flex;
            justify-content: space-between;
            margin: 30px 0;
          }

          .ProductWidgetLMMG .price-button-container .price {
            size: 30px;
            font-weight: bold;
            text-align: left;
            margin-right: 10px;
          }

          .ProductWidgetLMMG .price-button-container .product-widget-button {
            display: flex;
            align-items: center;
          }
        `}</style>
          <div> 
            <div className="product-title-container">
              <div className="Feaston-product-title">
                {product.title}
              </div>
            </div>

            <div className="variant-container">
              <div className="size-title">
                SIZE
              </div>
              {sizes.length > 0 ? sizes.map((s) => {
                return (
                  <div className="variant" key={s.value} onClick={() => this.handleSizeChange(s.value)}>
                    {this.state.selectedSize == s.value ? "(" : ""}
                    {s.value}
                    {this.state.selectedSize == s.value ? ")" : ""}
                  </div>
                )
              }) : null}
            </div>

            <div className="variant-container variant-container_color">
              <div className="color-title">
                COLOR
              </div>
              <div className="color-options">
                {colors.length > 0 ? colors.map((c) => {
                  return (
                    <div className="variant variant_color" key={c.value} onClick={() => this.handleColorChange(c.value)}>
                      {this.state.selectedColor == c.value ? "(" : ""}
                      {c.value}
                      {this.state.selectedColor == c.value ? ")" : ""}
                    </div>
                  )
                }) : null}
              </div>
            </div>

            <div className="price-button-container">
              <div className="price">
                {this.props.fetchSelectedCurrencyReducer.selectedCurrency} {currencyAfterConversion(product.variants[0].price?.amount)}
              </div>
              <div className="product-widget-button">
                {(selectedVariant && selectedVariant.available) || !selectedVariant ?
                  <div className="Product_buy button" onClick={() => this.addVariantToCart(selectedVariant ? selectedVariant.id : null, 1)}>
                    ADD TO CART
                  </div>
                  :
                  <div className="variant-sold-out">
                    SOLD OUT
                  </div>}
              </div>
            </div>

            {!this.state.isSizeSelected ?
              <div className="reminder">
                SELECT YOUR SIZE
              </div> : null}
            {!this.state.isColorSelected ?
              <div className="reminder">
                SELECT YOUR COLOR
              </div> : null}

          </div>

      </div>
    );
  }
}

const mapStateToProps = state => ({
  ...state
})

const mapDispatchToProps = dispatch => ({
  fetchCheckout: (checkout) => dispatch(fetchCheckout(checkout)),
})

export default withCookies(connect(mapStateToProps, mapDispatchToProps) (ProductWidgetLMMG));
