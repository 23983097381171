import React, { Component } from 'react';
import { connect } from 'react-redux';
import categories from '../../data/categories/index.jsx';
// import homeBanner from'../../assets/Home/homeBanner.png';
// import homeBanner2 from'../../assets/Home/rdno_mockup_Front_Flat_Black.png';
// import homeBanner3 from'../../assets/Home/deRucciSmallRes.png';
// import homeBanner4 from'../../assets/Home/ronaldinoSmallRes.png';
import FEASTON_HOME from'assets/Home/web_header.png';
import HOME_FOOTER from'assets/Home/web_footer.png';
import N796FULLPRINT from 'assets/Home/n796FullPrint.png';
import RONALDINO1 from 'assets/Home/products/ronaldino01.png';
import HOLYFUCKINGTON from 'assets/Home/products/holyfuckington.png';
import DJFORESKIN01 from 'assets/Home/products/djforeskin01.png';
import SINGULARITY01 from 'assets/Home/products/singularity01.png';
import LMMG01 from 'assets/Home/products/lmmg01.png';
import FEASTOPHER_01 from 'assets/Home/products/everfeast.png';
import N796_COLLAR from 'assets/Home/products/n796collar.png';
import BOTTLE from 'assets/Home/products/bottle_front_med.png';
import INVESTOR_HP from 'assets/Home/products/investorHP.png';

import { Link } from 'react-router-dom';
import { withRouter } from 'withRouter';
import PanelRight from 'components/PanelRight';
import ArrowsArray from '../../assets/Home/arrows';
import ScrollManager from "ScrollManager";
import MobileProductWidgetGeneral from '_Mobile/scenes/MobileProduct/ProductWidgetGeneral';
import HomeProductWidget from './HomeProductWidget';
import BANNER1 from "assets/Home/homeBanners/1.png";
import BANNER2 from "assets/Home/homeBanners/2.png";
import BANNER3 from "assets/Home/homeBanners/3.png";
import BANNER4 from "assets/Home/homeBanners/4.png";
import BANNER5 from "assets/Home/homeBanners/5.png";
import BANNER6 from "assets/Home/homeBanners/6.png";
import BANNER7 from "assets/Home/homeBanners/7.png";
import TESTBANNER1 from "assets/Home/homeBanners/homepageTest1.JPG";
import APBOX01 from "assets/Home/homeBanners/apbox-01.png";
import { Helmet } from "react-helmet";
import IMG01 from "./2021/tinified_jpg/desktop-001.jpg";
import IMG02 from "./2021/tinified_jpg/desktop-002.jpg";
import IMG03 from "./2021/tinified_jpg/desktop-003.jpg";
import IMG04 from "./2021/tinified_jpg/desktop-004.jpg";
import IMG05 from "./2021/tinified_jpg/desktop-005.jpg";
import IMG06 from "./2021/tinified_jpg/desktop-006.jpg";
import IMG07 from "./2021/tinified_jpg/desktop-007.jpg";
import IMG08 from "./2021/tinified_jpg/desktop-008.jpg";
import IMG09 from "./2021/tinified_jpg/desktop-009.jpg";
import IMG10 from "./2021/tinified_jpg/desktop-010.jpg";
import IMG11 from "./2021/tinified_jpg/desktop-011.jpg";
import IMG12 from "./2021/tinified_jpg/desktop-012.jpg";
import IMG13 from "./2021/tinified_jpg/desktop-013.jpg";
import IMG14 from "./2021/tinified_jpg/desktop-014.jpg";
import IMG15 from "./2021/tinified_jpg/desktop-015.jpg";
import IMG16 from "./2021/tinified_jpg/desktop-016.jpg";
import IMG17 from "./2021/tinified_jpg/desktop-017.jpg";
import IMG18 from "./2021/tinified_jpg/desktop-018.jpg";
import IMG19 from "./2021/tinified_jpg/desktop-019.jpg";
import IMG20 from "./2021/tinified_jpg/desktop-020.jpg";
import IMG21 from "./2021/tinified_jpg/desktop-021.jpg";
import IMG22 from "./2021/tinified_jpg/desktop-022.jpg";
import IMG23 from "./2021/tinified_jpg/desktop-023.jpg";
import IMG24 from "./2021/tinified_jpg/desktop-024.jpg";
import IMG25 from "./2021/tinified_jpg/desktop-025.jpg";
import IMG26 from "./2021/tinified_jpg/desktop-026.jpg";
import LazyLoad from 'react-lazyload';

function getRandomIntInclusive(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min; //The maximum is inclusive and the minimum is inclusive 
}

class Home extends Component {

  constructor(props) {
    super(props);
    this.state = {
      productsLoaded: false,
      category: "",
    };
    this.initiateLoadedProducts = this.initiateLoadedProducts.bind(this);
    this.changeRoute = this.changeRoute.bind(this);
  }

  changeRoute(category_url) {
    this.setState({
      category: category_url,
    }, () => {
      this.props.history.push({
        pathname: `/${category_url}`,
        state: { category: category_url }
        // state: { product: p } // data size too large to be passed through history.push, get data from redux store instead.
      });
    });
  }

  componentDidMount() {
    window.imageMapResize();
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll(event) {
    window.imageMapResize();
  }

  initiateLoadedProducts(products, props) {
    const category_url = props.location.pathname.replace("/project/","");
    if (category_url == "/project" || category_url == "") {
      this.setState({
        productsLoaded: true,
        category: "",
      })
    } else if (categories[category_url]) {
      this.setState({
        productsLoaded: true,
        category: category_url,
      });
    }
  }

  getProductFromUrl(url) {
    const { fetchProductsReducer } = this.props;
    const { products } = fetchProductsReducer;
    const curr_category = categories[url];
    const curr_handle = curr_category.products[0];
    if (products && products.length > 0) {
      for (let product of products) {
        const { handle } = product;
        if (handle === curr_handle) {
          return product;
        }
      }
    }
    return null;
  }

  componentWillMount() {
    this.initiateLoadedProducts(this.props.fetchProductsReducer.products, this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.initiateLoadedProducts(nextProps.fetchProductsReducer.products, this.props);
  }

  componentWillUpdate(nextProps) {
    if (this.props.location !== nextProps.location) {
      this.initiateLoadedProducts(nextProps.fetchProductsReducer.products, nextProps);
    }
  }

  render() {
    const viewportWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    const slideHeight = Math.floor((1920 * viewportWidth) / 3072);
    return (
      <div className="Home">
        <Helmet>
          <title>FEASTON</title>
          {/* <meta name="description" content="FEASTON"/> */}
          <meta property="og:image" content="https://feaston.co/favicon.ico" />
          <meta property="og:title" content="FEASTON"/>
          <link rel="canonical" href="https://feaston.co" />
        </Helmet>
        {this.state.category == "" ? (
          <div className="HomePageBanner">
            <style jsx>{`
              .HomePageBanner {
                //height: 100vh;
                overflow-y: auto;
                display: flex;
              }
              .Home .lazyload-wrapper {
                min-height: ${`${slideHeight}px`};
              }
              .HomePageBanner .homeBanner-container-wrapper {
                height: fit-content;
                margin-left: auto;
                margin-right: auto;
                //padding-bottom: 100px;
              }
              .HomePageBanner .homeBanner-container-wrapper ::-webkit-scrollbar {
                width: 0px;  /* Remove scrollbar space */
                background: transparent;  /* Optional: just make scrollbar invisible */
              }
              .HomePageBanner .homeBanner-container-wrapper ::-webkit-scrollbar-thumb {
                background: #FF0000;
              }
              .HomePageBanner .feaston-home {
                height: calc(100vh - 50px);
                display: flex;
                align-items: center;
                justify-content: center;
                overflow: hidden;
                margin-bottom: 100px;
                padding-top: 10px;
              }
              .HomePageBanner .feaston-home img, .HomePageBanner .feaston-home-footer img {
                max-height: 100%;
                max-width: 100%;
                margin: 0 auto;
                object-fit: contain;
              }
              .HomePageBanner .feaston-home img {
                transform: scale(1.1);
              }
              .HomePageBanner .homeBanner-container {
                position: relative;
                width: 80%;
                height: 850px;
                margin: auto;
                z-index: 999;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
              }
              .HomePageBanner .homeBanner-container.c-top{
                margin-top: 150px;
              }
              .HomePageBanner .homeBanner {
                //max-width: 100vw;
                position: absolute;
              }
              .HomePageBanner .homeBanner.product-item {
                position: unset;
                max-width: 600px !important;
              }
              .HomePageBanner .homeBanner.product-1 {
                left: 40px;
                top: 50px;
              }
              .HomePageBanner .homeBanner.product-2 {
                width: 400px;
                left: 800px;
                top: -80px;
                transform: rotate(0deg);
              }
              .HomePageBanner .homeBanner.product-3 {
                left: 40px;
                top: 50px;
                z-index: 10;
              }
              .HomePageBanner .homeBanner.product-4 {
                width: 400px;
                left: 800px;
                top: -80px;
                transform: rotate(0deg);
              }
              .HomePageBanner .homeBanner img{
                width: 100%;
                margin: auto;
              }
              .HomePageBanner .homeBanner .banner, map area{
                outline: none;
              }
              .HomePageBanner .homeBanner-container-wrapper .feaston-home-footer {
                height: 100vh;
                display: flex;
                align-items: center;
                //margin-top: 80px;
              }
              .HomePageBanner .homeBanner-container-wrapper .feaston-home-footer img {
                //max-width: 515px; //1317px;
                //margin-bottom: 50px;
              }
              .HomePageBanner .homeBanner-arrow {
                height: 120px;
                margin-top: 50px;
              }

              .HomePageBanner .homeBanner-container-wrapper .home-row {
                display: flex;
              }

              .HomePageBanner .banner-container {
                display: flex;
                align-items: center;
                justify-content: center;
              }
              .HomePageBanner .topBar {
                position: fixed;
                top: 0;
                font-weight: bold;
                height: 28px;
                background: #fff;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                z-index: 1000;
              }

              .Home .autoHeight .homeBanner-container {
                height: auto;
              }
            `}</style>
            {/* {new Date() < new Date('2020-12-02 23:59:59'.replace(/-/g, "/")) ? (
              <div className="topBar">
                <div>End of Season Sale |&nbsp;<span style={{ color: '#FF0000' }}>22</span>% off Discount Code: JASON</div>
              </div>
            ) : null} */}
            {/* <ScrollManager scrollKey="Home">
              {({ connectScrollTarget, ...props }) =>  */}
                <div className="homeBanner-container-wrapper">
                  {/* <div className="feaston-home">
                    <img className="" src={APBOX01} alt="" style={{ maxWidth: "40%", maxHeight: "unset" }}/>
                  </div>

                  {new Date() > new Date('2020-12-05 23:59:59'.replace(/-/g, "/")) ? (
                    <div className="home-row autoHeight">
                      <HomeProductWidget url="andy_lau" pickUpOnly />
                    </div>
                  ) : null}

                  <div className="feaston-home-footer">
                    <img src={HOME_FOOTER} alt=""/>
                  </div> */}

                  <div className="feaston-home">
                    <img src={IMG01} width="100%" alt="" />
                  </div>

                  <LazyLoad offset={690} height={slideHeight} once >
                    <div className="banner-container">
                      <img src={IMG02} width="100%" alt="" useMap="#image-map_02d" />
                      <map name="image-map_02d">
                        <Link to="/fushinsha_sweat">
                          <area target="" alt="" title="" href="" coords="1243,3,1102,119,841,247,752,377,803,494,893,578,1061,578,1330,781,1308,1318,1273,1456,1322,1535,1704,1662,2106,1652,2233,1543,2204,1429,2242,1337,2198,1220,2233,1174,2516,1131,2592,1022,2575,835,2418,190,2100,-3" shape="poly" />
                        </Link>
                      </map>
                    </div>
                  </LazyLoad>

                  <LazyLoad offset={690} height={slideHeight} once >
                    <div className="banner-container">
                      <img src={IMG03} width="100%" alt="" useMap="#image-map_03d"/>
                      <map name="image-map_03d">
                        <Link to="/fushinsha_sweat">
                          <area target="" alt="" title="" href="" coords="540,1104,440,1242,380,1364,372,1483,366,1736,890,1736,844,1378,752,1201,659,1144" shape="poly" />
                        </Link>
                        <Link to="/fushinsha_sweat"> 
                          <area target="" alt="" title="" href="" coords="1425,1106,1172,1199,1115,1253,1061,1736,2049,1738,2008,1410,1970,1277,1921,1226,1680,1101,1566,1209,1482,1196" shape="poly" />
                        </Link>
                        <Link to="/fushinsha_sweat">
                          <area target="" alt="" title="" href="" coords="2358,1212,2274,1380,2242,1505,2190,1738,2689,1738,2687,1554,2673,1361,2635,1261,2565,1120,2486,1139" shape="poly" />
                        </Link>
                      </map>
                    </div>
                  </LazyLoad>

                  <LazyLoad offset={690} height={slideHeight} once >
                    <div className="banner-container">
                      <img src={IMG04} width="100%" alt="" useMap="#image-map_04d"/>
                      <map name="image-map_04d">
                        <Link to="/fushinsha_sweat?color=grey">
                          <area target="" alt="" title="" href="" coords="570,95,328,184,109,347,117,659,312,727,334,892,402,1101,692,1077,969,1093,1039,889,1058,762,1267,651,1262,342,1034,182,776,92" shape="poly" />
                        </Link>
                        <Link to="/fushinsha_sweat?color=black">
                          <area target="" alt="" title="" href="" coords="1590,849,1330,936,1202,1063,1115,1191,1080,1296,1094,1443,1153,1567,1191,1608,1286,1538,1248,1456,1132,1369,1151,1318,1216,1280,1316,1261,1327,1285,1335,1524,1384,1681,1433,1822,2014,1812,2068,1540,2081,1275,2239,1250,2247,1345,2166,1473,2155,1524,2242,1570,2304,1445,2334,1258,2277,1125,2217,1063,2043,914,1772,846" shape="poly" />
                        </Link>
                      </map>
                    </div>
                  </LazyLoad>

                  <LazyLoad offset={690} height={slideHeight} once >
                    <div className="banner-container">
                      <img src={IMG05} width="100%" alt="" useMap="#image-map_05d"/>
                      <map name="image-map_05d">
                        <Link to="/panopticon_tee">
                          <area target="" alt="" title="" href="" coords="1929,160,1883,184,1585,244,1202,466,1392,773,1501,759,1498,936,1460,1630,2692,1649,2670,1163,2657,765,2782,778,2963,445,2584,239,2204,157,2062,184" shape="poly" />
                        </Link>
                      </map>
                    </div>
                  </LazyLoad>

                  <LazyLoad offset={690} height={slideHeight} once >
                    <div className="banner-container">
                      <img src={IMG06} width="100%" alt="" useMap="#image-map_06d"/>
                      <map name="image-map_06d">
                        <Link to="/grandpa_tee?color=white">
                          <area target="" alt="" title="" href="" coords="1479,133,1376,184,1137,255,725,485,953,849,1072,819,1088,1711,1463,1673,1479,1532,1324,1554,1143,1196,1408,1077,1536,995,1892,944,1976,974,2117,974,2190,936,2214,944,2231,786,2334,808,2546,431,2174,222,1821,127,1729,155,1588,155" shape="poly" />
                        </Link>
                        <Link to="/kid_tee?color=red">
                          <area target="" alt="" title="" href="" coords="1894,938,1563,987,1178,1158,1148,1188,1319,1554,1484,1540,1468,1592,1471,1917,2592,1915,2586,1532,2746,1535,2833,1361,2934,1191,2559,993,2198,941,2117,960,1978,968" shape="poly" />
                        </Link>
                      </map>
                    </div>
                  </LazyLoad>

                  <LazyLoad offset={690} height={slideHeight} once >
                    <div className="banner-container">
                      <img src={IMG07} width="100%" alt="" useMap="#image-map_07d"/>
                      <map name="image-map_07d">
                        <Link to="/ljmb_tee?color=white">
                          <area target="" alt="" title="" href="" coords="896,76,763,136,502,193,43,477,315,903,431,868,429,936,670,922,844,865,923,927,1251,963,1335,919,1550,1020,1813,1104,1892,1169,1856,765,2003,773,2195,301,1699,106,1289,60,1175,114,1001,111" shape="poly" />
                        </Link>
                        <Link to="/ljmb_tee?color=black">
                          <area target="" alt="" title="" href="" coords="844,868,676,917,353,944,-3,1068,-3,1652,185,1679,122,1809,103,1917,1799,1915,1794,1852,1962,1871,2071,1906,2350,1418,1813,1109,1341,930,1256,955,928,936" shape="poly" />
                        </Link>
                      </map>
                    </div>
                  </LazyLoad>

                  <LazyLoad offset={690} height={slideHeight} once >
                    <div className="banner-container">
                      <img src={IMG08} width="100%" alt="" useMap="#image-map_08d"/>
                      <map name="image-map_08d">
                        <Link to="/sunn5G_tee?color=white">
                          <area target="" alt="" title="" href="" coords="1275,81,928,133,537,317,678,662,830,645,814,765,1308,854,1862,1144,1631,1548,1976,1554,1951,637,2122,670,2307,336,1929,144,1547,81,1474,100,1368,100" shape="poly" />
                        </Link>
                        <Link to="/grandpa_tee?color=purple">
                          <area target="" alt="" title="" href="" coords="393,740,214,803,-3,822,8,1917,1436,1917,1389,1567,1612,1576,1864,1144,1308,857,784,751,722,770,535,765" shape="poly" />
                        </Link>
                      </map>
                    </div>
                  </LazyLoad>

                  <LazyLoad offset={690} height={slideHeight} once >
                    <div className="banner-container">
                      <img src={IMG09} width="100%" alt="" useMap="#image-map_09d"/>
                      <map name="image-map_09d">
                        <Link to="/smile_tee?color=purple">
                          <area target="" alt="" title="" href="" coords="1954,138,1832,155,1647,168,1251,366,1414,686,1558,678,1482,1440,2434,1516,2440,247,2190,157,2117,171,2019,163" shape="poly" />
                        </Link>
                        <Link to="/imported_cap">
                          <area target="" alt="" title="" href="" coords="727,1134,597,1182,505,1275,440,1473,507,1779,638,1782,882,1765,1110,1733,1129,1662,1067,1529,1045,1323,942,1193,839,1158" shape="poly" />
                        </Link>
                      </map>
                    </div>
                  </LazyLoad>

                  <LazyLoad offset={690} height={slideHeight} once >
                    <div className="banner-container">
                      <img src={IMG10} width="100%" alt="" useMap="#image-map_10d"/>
                      <map name="image-map_10d">
                        <Link to="/fushinsha_sweat">
                          <area target="" alt="" title="" href="" coords="2193,127,2144,138,1948,203,1902,258,1753,697,1954,971,1973,990,1978,1329,2434,1294,2437,125,2372,141,2274,141" shape="poly" />
                        </Link>
                        <Link to="/logo_hood_black">
                          <area target="" alt="" title="" href="" coords="1465,1378,1118,1047" shape="rect" />
                        </Link>
                      </map>
                    </div>
                  </LazyLoad>

                  <LazyLoad offset={690} height={slideHeight} once >
                    <div className="banner-container">
                      <img src={IMG11} width="100%" alt="" useMap="#image-map_11d"/>
                    </div>
                  </LazyLoad>

                  <LazyLoad offset={690} height={slideHeight} once >
                    <div className="banner-container">
                      <img src={IMG12} width="100%" alt="" useMap="#image-map_12d"/>
                      <map name="image-map_12d">
                        <Link to="/opium_sweat">
                          <area target="" alt="" title="" href="" coords="1840,122,1517,233,1403,290,1240,748,1080,1383,1088,1535,1243,1581,1487,1212,1512,1657,1552,1801,1813,1765,2331,1782,2478,1795,2505,1589,2513,1269,2749,1654,2931,1624,2939,1399,2746,654,2594,306,2505,239,2171,119,2073,127,1957,138" shape="poly" />
                        </Link>  
                        <Link to="/imported_cap">  
                          <area target="" alt="" title="" href="" coords="662,133,361,233,231,540,136,765,144,889,350,938,727,998,866,919,906,719,1020,651,996,442,931,304,860,214" shape="poly" />
                        </Link>
                        <Link to="/opium_mug">
                          <area target="" alt="" title="" href="" coords="293,1201,312,1698,505,1717,681,1684,689,1592,809,1565,855,1416,795,1283,727,1261,687,1261,684,1204,483,1193" shape="poly" />
                        </Link>
                      </map>
                    </div>
                  </LazyLoad>

                  <LazyLoad offset={690} height={slideHeight} once >
                    <div className="banner-container">
                      <img src={IMG13} width="100%" alt="" useMap="#image-map_13d"/>
                      <map name="image-map_13d">
                        <Link to="/logo_hood_black">
                          <area target="" alt="" title="" href="" coords="1297,168,1199,315,1205,356,1067,375,1048,402,917,388,893,266,806,242,730,242,719,320,670,480,733,562,678,659,687,708,868,765,822,849,811,952,779,1031,787,1096,776,1248,817,1465,1153,1557,1311,1617,1357,1557,1292,1400,1292,1321,1425,1031,1501,928,1563,901,1560,803,1525,684,1590,562,1710,548,1761,483,1712,320,1533,239" shape="poly" />
                        </Link>
                        <Link to="/logo_hood_red">
                          <area target="" alt="" title="" href="" coords="1598,532,1512,659,1563,808,1558,879,1474,925,1349,1128,1265,1337,1278,1426,1471,1727,1634,1611,1851,1660,2022,1603,2092,1557,2130,1581,2228,1508,2266,1470,2356,1578,2415,1369,2418,1180,2152,805,2052,724,1967,738,1989,689,1929,515" shape="poly" />
                        </Link>
                      </map>
                    </div>
                  </LazyLoad>

                  <LazyLoad offset={690} height={slideHeight} once >
                    <div className="banner-container">
                      <img src={IMG14} width="100%" alt="" useMap="#image-map_14d"/>
                      <map name="image-map_14d">
                        <Link to="/juk_hood">
                          <area target="" alt="" title="" href="" coords="1473,3,1633,542,1758,626,1858,792,1562,830,1264,738,1109,662,1037,602,879,618,453,640,-3,645,-3,-3" shape="poly" />
                        </Link>
                        <Link to="/band1_tee?color=black">
                          <area target="" alt="" title="" href="" coords="1031,599,445,648,-3,643,3,1917,2527,1917,2155,1443,2011,1313,1976,1085,1902,895,1856,803,1598,822,1414,789,1161,692" shape="poly" />
                        </Link>
                        <Link to="/band1_tee?color=white">
                          <area target="" alt="" title="" href="" coords="1474,-3,1628,534,1750,629,1938,971,1995,1166,2005,1296,2269,1584,2521,1917,3069,1917,3069,5" shape="poly" />
                        </Link>
                      </map>
                    </div>
                  </LazyLoad>

                  <LazyLoad offset={690} height={slideHeight} once >
                    <div className="banner-container">
                      <img src={IMG15} width="100%" alt="" useMap="#image-map_15d"/>
                    </div>
                  </LazyLoad>

                  <LazyLoad offset={690} height={slideHeight} once >
                    <div className="banner-container">
                      <img src={IMG16} width="100%" alt="" useMap="#image-map_16d"/>
                      <map name="image-map_16d">
                        <Link to="/sunn5G_tee?color=black">
                          <area target="" alt="" title="" href="" coords="974,1033,923,1060,640,1077,437,1191,271,1264,410,1581,570,1546,532,1915,1628,1917,1623,1671,1598,1616,1661,1619,1745,1654,1924,1323,1683,1207,1588,1117,1376,1082,1246,1033,1186,1066,1072,1058" shape="poly" />
                        </Link>
                      </map>
                    </div>
                  </LazyLoad>

                  <LazyLoad offset={690} height={slideHeight} once >
                    <div className="banner-container">
                      <img src={IMG17} width="100%" alt="" useMap="#image-map_17d"/>
                      <map name="image-map_17d">
                        <Link to="/names_tee?color=navy">
                          <area target="" alt="" title="" href="" coords="157,751,87,792,-3,808,-3,1817,657,1803,651,1209,730,1234,885,995,640,830,499,803,399,754,342,773,225,778" shape="poly" />
                        </Link>
                        <Link to="/names_tee?color=white">
                          <area target="" alt="" title="" href="" coords="2716,65,2502,127,2274,285,2396,502,2470,499,2475,1017,3069,1009,3069,103,2912,62,2852,84,2773,84" shape="poly" />
                        </Link>
                      </map>
                    </div>
                  </LazyLoad>

                  <LazyLoad offset={690} height={slideHeight} once >
                    <div className="banner-container">
                      <img src={IMG18} width="100%" alt="" useMap="#image-map_18d"/>
                      <map name="image-map_18d">
                        <Link to="/andy_lau">
                          <area target="" alt="" title="" href="" coords="638,301,586,304,570,334,459,325,407,1399,564,1426,738,1407,798,420,695,415,697,336" shape="poly" />
                        </Link>
                      </map>
                    </div>
                  </LazyLoad>

                  <LazyLoad offset={690} height={slideHeight} once >
                    <div className="banner-container">
                      <img style={{ paddingLeft: '22px' }} src={IMG19} width="100%" alt="" useMap="#image-map_19d"/>
                      <map name="image-map_19d">
                        <Link to="/imported_hood">
                          <area target="" alt="" title="" href="" coords="2193,255,2149,407,2176,431,2073,488,2008,578,1704,1410,1712,1500,1702,1584,1843,1649,1867,1576,1908,1535,2090,1288,2095,1603,2125,1755,2350,1719,2668,1725,2836,1730,2871,1307,3069,1595,3069,917,2936,545,2839,469,2760,431,2795,380,2744,239,2478,225" shape="poly" />
                        </Link>
                      </map>
                    </div>
                  </LazyLoad>

                  <LazyLoad offset={690} height={slideHeight} once >
                    <div className="banner-container">
                      <img src={IMG20} width="100%" alt="" useMap="#image-map_20d"/>
                    </div>
                  </LazyLoad>

                  <LazyLoad offset={690} height={slideHeight} once >
                    <div className="banner-container">
                      <img src={IMG21} width="100%" alt="" useMap="#image-map_21d"/>
                      <map name="image-map_21d">
                        <Link to="/logo_hood_black">
                          <area target="" alt="" title="" href="" coords="1308,231,1240,304,1170,469,1088,437,1001,618,915,767,931,808,1026,843,999,903,1099,955,1099,993,1018,1134,1015,1228,1058,1272,1124,1435,1248,1559,1305,1600,1373,1687,1683,1513,1854,1380,1965,1378,2043,1283,2125,1066,2157,889,2119,871,2092,789,2125,740,2136,602,2016,583,1870,529,1845,548,1826,483,1859,361,1593,282,1560,374,1468,342,1398,312" shape="poly" />
                        </Link>
                      </map>
                    </div>
                  </LazyLoad>

                  <LazyLoad offset={690} height={slideHeight} once >
                    <div className="banner-container">
                      <img src={IMG22} width="100%" alt="" useMap="#image-map_22d"/>
                      <map name="image-map_22d">
                        <Link to="/marker_tee?color=navy">
                          <area target="" alt="" title="" href="" coords="-3,-3,0,1003,461,754,874,632,1275,510,1438,447,1498,494,1875,545,2358,496,2470,439,2497,418,2703,480,3069,548,3069,3" shape="poly" />
                        </Link>
                        <Link to="/marker_tee?color=white">
                          <area target="" alt="" title="" href="" coords="1433,447,1248,521,469,751,418,781,-3,1009,0,1915,3069,1912,3069,556,2697,480,2494,415,2366,491,2128,526,1778,540,1579,510" shape="poly" />
                        </Link>
                      </map>
                    </div>
                  </LazyLoad>

                  <LazyLoad offset={690} height={slideHeight} once >
                    <div className="banner-container">
                      <img src={IMG23} width="100%" alt="" useMap="#image-map_23d"/>
                      <map name="image-map_23d">
                        <Link to="/juk_hood">
                          <area target="" alt="" title="" href="" coords="204,559,152,702,174,729,87,786,-3,1001,-3,1611,95,1473,95,1578,125,1803,263,1790,402,1763,784,1798,825,1576,825,1470,982,1687,1056,1738,1178,1673,1156,1584,953,1055,833,795,706,721,722,694,681,586,459,556" shape="poly" />
                        </Link>
                        <Link to="/ES_hood">
                          <area target="" alt="" title="" href="" coords="2350,111,2342,163,2309,239,2331,274,2242,315,2073,759,1970,1204,1986,1299,2111,1307,2136,1250,2261,1077,2261,1253,2299,1413,2508,1386,2725,1394,2904,1421,2925,1090,3069,1334,3069,542,2980,358,2925,304,2855,285,2871,244,2820,117" shape="poly" />
                        </Link>
                      </map>
                    </div>
                  </LazyLoad>

                  <LazyLoad offset={690} height={slideHeight} once >
                    <div className="banner-container">
                      <img src={IMG24} width="100%" alt="" useMap="#image-map_24d"/>
                      <map name="image-map_24d">
                        <Link to="/3107_hood">
                          <area target="" alt="" title="" href="" coords="81,287,35,423,54,439,-3,480,0,1589,377,1584,613,1586,665,1348,668,1234,847,1475,963,1454,947,1321,839,908,719,548,649,480,578,445,602,418,545,285" shape="poly" />
                        </Link>
                        <Link to="/yaohan_hood?color=white">
                          <area target="" alt="" title="" href="" coords="2508,675,2470,795,2497,816,2418,819,2364,903,2239,1258,2141,1622,2155,1727,2277,1752,2315,1689,2426,1519,2437,1676,2467,1801,2641,1763,2822,1774,2972,1806,3004,1673,3010,1529,3069,1616,3069,911,3018,830,2942,814,2963,781,2920,678,2697,656" shape="poly" />
                        </Link>
                      </map>
                    </div>
                  </LazyLoad>

                  <LazyLoad offset={690} height={slideHeight} once >
                    <div className="banner-container">
                      <img src={IMG25} width="100%" alt="" useMap="#image-map_25d"/>
                      <map name="image-map_25d">
                        <Link to="/toast_tee?color=white">
                          <area target="" alt="" title="" href="" coords="931,100,624,138,442,206,274,285,426,567,570,553,535,599,524,762,570,762,703,751,741,721,993,751,1254,857,1167,1052,1145,1120,1069,1117,1096,1153,1113,1318,1520,1361,1512,705,1493,605,1569,597,1634,613,1672,526,1778,347,1490,190,1151,108,1088,125,996,117" shape="poly" />
                        </Link>
                        <Link to="/smile_tee?color=red">
                          <area target="" alt="" title="" href="" coords="529,757,407,833,290,873,79,1047,255,1280,337,1258,421,1817,1186,1684,1091,1158,1069,1125,1148,1117,1256,854,996,757,738,724,684,754,592,767" shape="poly" />
                        </Link>
                      </map>
                    </div>
                  </LazyLoad>

                  <LazyLoad offset={690} height={slideHeight} once >
                    <div className="feaston-home-footer">
                      <img src={IMG26} width="100%" alt="" useMap="#image-map_26d"/>
                    </div>
                  </LazyLoad>
  
                  {/* <div className="banner-container">
                    <img src={BANNER1} width="100%" alt="" />
                  </div> */}

                  {/* <div className="home-row">
                    <HomeProductWidget url="sm2202" />
                    <HomeProductWidget url="deep_learning" />
                  </div>

                  <div className="home-row">
                    <HomeProductWidget url="kale" />
                    <HomeProductWidget url="chain" />
                  </div>
                  
                  <div style={{ minHeight: "600px" }} className="banner-container">
                    <img src={BANNER2} width="100%" alt="" />
                  </div>

                  <div className="home-row">
                    <HomeProductWidget url="opium_t" />
                    <HomeProductWidget url="discontinued_siu_mai" imgHeight="500px"/>
                  </div>

                  <div className="banner-container">
                    <img src={BANNER3} width="1200px" alt="" />
                  </div>
                  
                  <div className="home-row">
                    <HomeProductWidget url="pickles_t" />
                    <HomeProductWidget url="investor" />
                  </div>

                  <div style={{ minHeight: "600px" }} className="banner-container">
                    <img src={BANNER4} width="500px" alt="" />
                  </div>
                  
                  <div className="home-row">
                    <HomeProductWidget url="snake_tote" />
                    <HomeProductWidget url="bottle" />
                  </div>

                  <div style={{ minHeight: "600px" }} className="banner-container">
                    <img src={BANNER5} width="600px" alt="" />
                  </div>
                  
                  <div className="home-row">
                    <HomeProductWidget url="boot_01" />
                    <HomeProductWidget url="lmmg_01" />
                  </div>
                  
                  <div style={{ minHeight: "600px" }} className="banner-container">
                    <img src={BANNER6} height="350px" alt="" />
                    <img src={BANNER7} height="350px" style={{ paddingLeft: "200px"}} alt="" />
                  </div>

                  <div className="home-row">
                    <HomeProductWidget url="holyfuckington" />
                    <HomeProductWidget url="singularity_01" />
                  </div>
                  
                  <div className="home-row">
                    <HomeProductWidget url="n796" />
                    <HomeProductWidget url="n796_collar" />
                  </div> */}
                  
                  {/* <div className="homeBanner-container c-1">
                    <img className="homeBanner product-item" src="https://cdn.shopify.com/s/files/1/0075/7763/2850/products/kale_front_mid.png?v=1578832944" useMap="#image-map_kale" alt=""/>
                    <map name="image-map_kale">
                      <Link to="/kale">
                        <area target="" alt="" title="" href="" coords="415,11,387,21,221,57,123,114,1,171,15,207,97,347,140,340,182,339,184,548,189,834,370,821,543,818,723,805,755,810,829,803,917,807,928,801,862,321,904,321,910,300,921,292,999,148,885,101,783,37,735,29,626,8,598,6,574,3,558,12,453,21" shape="poly" />
                      </Link>
                    </map>
                    <MobileProductWidgetGeneral product={this.getProductFromUrl('kale')} isHomeWidget isDesktop />
                  </div>

                  <div className="homeBanner-container c-1">
                    <img className="homeBanner product-item" src="https://cdn.shopify.com/s/files/1/0075/7763/2850/products/chain_front_mid.png?v=1578832907" useMap="#image-map_chain" alt=""/>
                    <map name="image-map_chain">
                      <Link to="/chain">
                        <area target="" alt="" title="" href="" coords="377,25,315,44,219,58,178,79,145,100,108,120,70,132,0,161,77,350,137,339,170,343,185,339,190,345,179,355,170,405,172,859,530,851,914,816,852,370,830,306,878,300,935,304,999,117,892,84,871,68,780,27,622,12,573,0,538,12,450,17,403,2" shape="poly" />
                      </Link>
                    </map>
                    <MobileProductWidgetGeneral product={this.getProductFromUrl('chain')} isHomeWidget isDesktop />
                  </div>

                  <div className="homeBanner-container c-1">
                    <img className="homeBanner product-item" src={INVESTOR_HP} useMap="#image-map_investor" alt=""/>
                    <map name="image-map_investor">
                      <Link to="/investor">
                        <area target="" alt="" title="" href="" coords="483,197,420,206,369,227,321,266,294,307,258,392,248,451,237,477,237,503,243,511,224,600,230,647,255,666,335,690,516,694,683,680,736,657,754,624,748,571,732,513,735,492,728,460,709,353,692,304,662,254,606,223,528,196" shape="poly" />
                      </Link>
                    </map>
                    <MobileProductWidgetGeneral product={this.getProductFromUrl('investor')} isHomeWidget isDesktop />
                  </div>

                  <div className="homeBanner-container c-1">
                    <img className="homeBanner product-item" src="https://cdn.shopify.com/s/files/1/0075/7763/2850/products/opiumFront-med__1__1.png" useMap="#image-map_opium_t" alt=""/>
                    <map name="image-map_opium_t">
                      <Link to="/opium_t">
                        <area target="" alt="" title="" href="" ref="" coords="162,951,837,949,824,432,813,403,869,386,950,380,1008,193,607,63,570,84,486,84,447,71,10,188,58,379,137,388,177,377,184,405" shape="poly"/>
                      </Link>
                    </map>
                    <MobileProductWidgetGeneral product={this.getProductFromUrl('opium_t')} isHomeWidget isDesktop />
                  </div>

                  <div className="homeBanner-container c-1">
                    <img className="homeBanner product-item" src="https://cdn.shopify.com/s/files/1/0075/7763/2850/products/snakeFront_med.png" useMap="#image-map_snake_tote" alt=""/>
                    <map name="image-map_snake_tote">
                      <Link to="/snake_tote">
                        <area target="" alt="" title="" href="" coords="261,973,301,356,401,360,412,232,457,97,513,44,537,40,584,54,620,92,638,179,642,215,644,359,740,357,761,978" shape="poly"/>
                      </Link>
                    </map>
                    <MobileProductWidgetGeneral product={this.getProductFromUrl('snake_tote')} isHomeWidget isDesktop />
                  </div>

                  <div className="homeBanner-container c-1">
                    <img className="homeBanner product-item" src="https://cdn.shopify.com/s/files/1/0075/7763/2850/products/bottle_front_med.png" useMap="#image-map9" alt=""/>
                    <map name="image-map9">
                      <Link to="/bottle">
                        <area target="" alt="" title="" href="" coords="343,4,297,14,196,50,0,127,45,266,56,276,136,281,151,275,159,277,159,292,152,724,451,728,717,719,683,295,675,277,686,282,796,274,850,119,498,0,466,17,421,26,360,18" shape="poly"/>
                      </Link>
                    </map>
                    <MobileProductWidgetGeneral product={this.getProductFromUrl('bottle')} isHomeWidget isDesktop />
                  </div>

                  <div className="homeBanner-container c-1">
                    <img className="homeBanner product-item" src="https://cdn.shopify.com/s/files/1/0075/7763/2850/products/boot_back_med.png" useMap="#image-map5" alt=""/>
                    <map name="image-map5">
                      <Link to="/boot_01">
                        <area target="" alt="" title="" href="" coords="418,1,235,52,1,149,65,329,200,323,197,577,199,876,549,872,882,855,853,532,838,369,820,319,871,308,966,309,987,209,1000,128,823,66,625,6,611,6,595,2,555,14,473,13" shape="poly"/>
                      </Link>
                    </map>
                    <MobileProductWidgetGeneral product={this.getProductFromUrl('boot_01')} isHomeWidget isDesktop />
                  </div>

                  <div className="homeBanner-container c-1">
                    <img className="homeBanner product-item" src={LMMG01} useMap="#image-map2" alt=""/>
                    <map name="image-map2">
                      <Link to="/lmmg_01">
                        <area target="" alt="" title="" href="" coords="332,12,309,21,175,46,81,87,68,86,10,107,51,245,93,246,148,248,117,673,240,685,415,694,666,679,646,262,669,251,710,250,755,241,771,184,795,106,665,66,548,27,497,16,485,15,471,7,451,21,404,25,367,20" shape="poly"/>
                      </Link>
                    </map>
                    <MobileProductWidgetGeneral product={this.getProductFromUrl('lmmg_01')} isHomeWidget isDesktop />
                  </div>

                  <div className="homeBanner-container c-1">
                    <img className="homeBanner product-item" src={HOLYFUCKINGTON} useMap="#image-map6" alt=""/>
                    <map name="image-map6">
                      <Link to="/holyfuckington">
                        <area target="" alt="" title="" href="" coords="352,23,275,42,182,74,4,149,57,300,168,306,162,394,155,772,452,776,731,765,701,356,689,295,750,270,769,274,804,286,845,133,841,119,738,82,729,84,687,67,678,60,528,19,490,18,462,28,379,29" shape="poly"/>
                      </Link>
                    </map>
                    <MobileProductWidgetGeneral product={this.getProductFromUrl('holyfuckington')} isHomeWidget isDesktop />
                  </div>

                  <div className="homeBanner-container c-1">
                    <img className="homeBanner product-item" src={SINGULARITY01} useMap="#image-map3" alt=""/>
                    <map name="image-map3">
                      <Link to="/singularity_01">
                        <area target="" alt="" title="" href="" coords="355,7,330,13,185,47,164,64,130,68,5,107,43,259,101,272,137,271,170,289,156,318,149,756,342,764,526,769,734,740,711,494,681,313,685,279,706,269,727,271,778,264,803,264,849,108,703,59,688,56,671,47,506,0,481,13,437,20,384,17" shape="poly"/>
                      </Link>
                    </map>
                    <MobileProductWidgetGeneral product={this.getProductFromUrl('singularity_01')} isHomeWidget isDesktop />
                  </div>

                  <div className="homeBanner-container c-1">
                    <img className="homeBanner product-item" src={FEASTOPHER_01} useMap="#image-map7" alt=""/>
                    <map name="image-map7">
                      <Link to="/feastopher_01">
                        <area target="" alt="" title="" href="" coords="331,2,297,8,148,53,0,113,51,254,151,257,141,657,692,654,656,288,645,249,746,256,799,108,622,40,510,6,461,3,431,16,368,14" shape="poly"/>
                      </Link>
                    </map>
                    <MobileProductWidgetGeneral product={this.getProductFromUrl('feastopher_01')} isHomeWidget isDesktop />
                  </div>
                  
                  <div className="homeBanner-container c-1">
                    <img className="homeBanner product-item" src={DJFORESKIN01} useMap="#image-map4" alt=""/>
                    <map name="image-map4">
                      <Link to="/djforeskin_01">
                        <area target="" alt="" title="" href="" coords="346,7,153,71,4,121,58,280,97,273,161,281,154,416,152,755,448,764,707,747,688,292,711,281,722,285,794,273,845,125,672,64,496,4,465,22,417,27,375,21" shape="poly"/>
                      </Link>
                    </map>
                    <MobileProductWidgetGeneral product={this.getProductFromUrl('djforeskin_01')} isHomeWidget isDesktop />
                  </div>

                  <div className="homeBanner-container c-1">
                    <img className="homeBanner product-item" src={N796FULLPRINT} useMap="#image-map1" alt=""/>
                    <map name="image-map1">
                      <Link to="/n796">
                        <area target="" alt="" title="" href="" coords="326,29,319,35,309,35,300,43,205,69,21,154,22,167,52,230,63,299,125,309,139,308,166,319,175,371,171,393,171,538,171,581,171,689,173,725,184,735,309,720,425,715,668,713,669,690,642,454,634,367,636,319,648,302,694,286,737,277,762,173,780,124,601,58,503,34,478,28,436,36,398,43,361,42" shape="poly"/>
                      </Link>
                    </map>
                    <MobileProductWidgetGeneral product={this.getProductFromUrl('n796')} isHomeWidget isDesktop />
                  </div>

                  <div className="homeBanner-container c-1">
                    <img className="homeBanner product-item" src={N796_COLLAR} useMap="#image-map8" alt=""/>
                    <map name="image-map8">
                      <Link to="/n796_collar">
                        <area target="" alt="" title="" href="" coords="331,10,317,41,301,38,153,92,139,106,1,179,62,323,136,315,123,356,114,433,109,714,383,717,727,705,691,364,674,287,730,327,799,157,618,69,506,51,496,43,484,40,468,5,436,1,365,3" shape="poly"/>
                      </Link>
                    </map>
                    <MobileProductWidgetGeneral product={this.getProductFromUrl('n796_collar')} isHomeWidget isDesktop />
                  </div> */}

                  {/* <div className="feaston-home-footer">
                    <img src={HOME_FOOTER} alt=""/>
                  </div>  */}
                </div>
              {/* }
            </ScrollManager> */}
            {/* <div className="homeBanner-container c-2">
              <img className="homeBanner product-3" src={homeBanner} useMap="#image-map3" alt=""/>
              <map name="image-map3">
                  <area onClick={() => this.changeRoute('n796')} target="" alt="" title="" href="" coords="291,33,181,65,16,142,39,195,57,272,105,279,127,279,148,288,153,344,152,500,159,652,193,656,370,649,598,643,570,350,575,276,591,262,664,249,699,110,509,43,429,26,356,37" shape="poly"/>
              </map>
              <img className="homeBanner product-4" src={homeBanner2} useMap="#image-map4" alt=""/>
              <map name="image-map4">
                <area target="" alt="" title="" href="" coords="121,957,886,959,886,638,873,635,855,372,830,264,763,148,693,95,637,64,520,27,447,32,354,66,265,138,209,206,171,275,157,351,144,648,124,650,117,662,116,930" shape="poly"/>
              </map>
            </div> */}

            {/* <div className="homeBanner-container c-3">
              <img className="product-4" src={homeBanner4} useMap="#image-map4" alt=""/>
            </div>
            <div className="homeBanner-container c-2">
              <img className="homeBanner product-3" src={homeBanner3} useMap="#image-map3" alt=""/>
            </div>
            <div className="homeBanner-container c-1">
              <img className="homeBanner product-1" src={homeBanner} useMap="#image-map" alt=""/>
              <map name="image-map">
                  <area onClick={() => this.changeRoute('n796')} target="" alt="" title="" href="" coords="291,33,181,65,16,142,39,195,57,272,105,279,127,279,148,288,153,344,152,500,159,652,193,656,370,649,598,643,570,350,575,276,591,262,664,249,699,110,509,43,429,26,356,37" shape="poly"/>
              </map>
              <img className="homeBanner product-2" src={homeBanner2} useMap="#image-map2" alt=""/>
              <map name="image-map2">
                <area target="" alt="" title="" href="" coords="121,957,886,959,886,638,873,635,855,372,830,264,763,148,693,95,637,64,520,27,447,32,354,66,265,138,209,206,171,275,157,351,144,648,124,650,117,662,116,930" shape="poly"/>
              </map>
            </div> */}
          </div>
        ) : (
          this.state.productsLoaded ?
            categories[this.state.category].component :
            <div style={{ fontSize: "30px" }}> {/* To be replaced by Loading animation */}
                Loading...
            </div>
        )}

        <PanelRight noBorder noBackground atHome/*withPath*/ />
      </div>
      
    );
  }
}

const mapStateToProps = state => ({
  ...state
})

export default withRouter(connect(mapStateToProps, {}) (Home));
