export default (state = {}, action) => {
  switch (action.type) {
   case 'FETCH_ARCHIVED_FEATURE_COLLECTIONS':
    return {
      collections: action.payload
    }
   default:
    return state
  }
 }
 
 