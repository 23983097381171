import React, { Component } from 'react';
import { connect } from 'react-redux';
import { find } from 'lodash';
import PanelLeft from '../../components/PanelLeft';
import PanelRight from '../../components/PanelRight';
import ProductWidgetFeaston from './ProductWidgetFeaston';
import ProductWidgetLMMG from './ProductWidgetLMMG';

import TESTING_IMG from './testing.png';

class Product extends Component {

  constructor(props) {
    super(props);
    this.state = {
      product: null,
    };
  }

  componentWillMount() {
    if (this.props.fetchProductsReducer.products) {
      this.setState({
        product: find(this.props.fetchProductsReducer.products, { handle: this.props.match.params.product_handle }),
      })
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.fetchProductsReducer.products) {
      this.setState({
        product: find(nextProps.fetchProductsReducer.products, { handle: this.props.match.params.product_handle }),
      })
    }
  }

  render() {
    const { product } = this.state;
    const { match } = this.props;
    const { params } = match;
    return (
      <div className="Product web-page">
        <style jsx>{`
          .Product {
            height: calc(100vh - 0px);
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .Product .Product-mid {
            font-weight: bold;
            font-size: 28px;
          }
          .Product .Product-mid img {
            max-width: 100%;
          }
          .Product .Product-panelLeft {
            font-size: 13px;
            text-align: left;
          }
        `}</style>
        {product ? (
        <div className="web-mid Product-mid">
          {/* <img src={product.images[0].src} alt="product_image" /> */}
          <img src={TESTING_IMG} alt="product_image" />
        </div>) : null}
        <PanelLeft>
          <div className="Product-panelLeft">
            <div dangerouslySetInnerHTML={{__html: product ? product.descriptionHtml : null}} />
          </div>
        </PanelLeft>
        <PanelRight withPath>
          {/* <div className="PanelRight-productWidget">
            {product && params.project === "feaston" ? (
              <ProductWidgetFeaston
                product={product}
              />
            ) : null}
            {product && params.project === "lmmg" ? (
              <ProductWidgetLMMG
                product={product}
              />
            ) : null}
          </div> */}
        </PanelRight>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  ...state
})

export default connect(mapStateToProps, {}) (Product);