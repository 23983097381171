import React, { Component } from 'react';
import { client } from '../../../shopify';
import { withCookies } from 'react-cookie';
import { connect } from 'react-redux';
import { fetchCheckout } from '../../../actions/fetchCheckout';
import FEASTON_LOGO from '../../../assets/Feaston/feastonLogo.png';

class ProductWidgetFeaston extends Component {

  constructor(props) {
    super(props);
    let defaultOptionValues = {};
    this.props.product.options.forEach((selector) => {
      defaultOptionValues[selector.name] = selector.values[0].value;
    });
  }

  addVariantToCart(variantId, quantity){
    const lineItemsToAdd = [{variantId, quantity: parseInt(quantity, 10)}];
    const checkoutId = this.props.fetchCheckoutReducer.checkout.id;
    return client.checkout.addLineItems(checkoutId, lineItemsToAdd).then(res => {
      this.props.fetchCheckout(res);
    });
  }

  componentWillMount() {
    let { cookies } = this.props;
    const checkoutId = cookies.get('checkoutID');
    if (checkoutId) {
      client.checkout.fetch(checkoutId).then((checkout) => {
        if (checkout.completedAt) {
          client.checkout.create().then((res) => {
            cookies.set('checkoutID', res.id, { path: '/', expires: new Date(Date.now()+31556952000)});
          });
        }
      });
    } else {
      client.checkout.create().then((res) => {
        cookies.set('checkoutID', res.id, { path: '/', expires: new Date(Date.now()+31556952000)});
      });
    }
  }

  render() {
    const { product } = this.props;
    const { variants } = product;
    return (
      <div className="ProductWidgetFeaston">
        <style jsx>{`
          .ProductWidgetFeaston {
            position: absolute;
            bottom: 0;
            height: 40vh;
            padding: 0 25px 20px 25px;
            width: calc(17vw - 50px);
          }
          .ProductWidgetFeaston .Feaston-logo img {
            width: 100%;
          }
          .ProductWidgetFeaston .product-logo-title-container {
            font-size: 16px;
            background: white;
            width: fit-content;
            text-align: center;
            margin: 20px auto;
            padding: 0 10%;
            font-weight: bold;
          }

          .ProductWidgetFeaston .product-logo-title-container .Feaston-product-title {
            margin-top: 10px;
            text-align: left;
          }
          
          .ProductWidgetFeaston .variant-container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 5px;
          }
          .ProductWidgetFeaston .option-container .Product_option{
            background: white;
            border: none;
            border-radius: 0;
            font-size: 16px;
            margin: 0 5px 10px 5px;
          }
          .ProductWidgetFeaston select {
            text-align-last: center;
            -webkit-appearance: none;
            appearance: none;
            font-weight: bold;
            padding: 2px;
          }
          .ProductWidgetFeaston select::-ms-expand {
            display: none;
          }
          .ProductWidgetFeaston .variant-title {
            font-size: 12px;
            font-weight: bold;
          }
          .ProductWidgetFeaston .Product_buy {
            font-size: 10px;
            color: black;
            background: white;
            font-weight: bold;
            width: 100px;
            border-radius: 0;
            border-color: black;
            border: 1px solid;
            cursor: pointer;
            font-family: "Cousine";
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 4px 0 2px 0;
          }

          .ProductWidgetFeaston .variant-sold-out {
            color: red;
            font-size: 16px;
            font-weight: bold;
          }
        `}</style>
          <div> 
            <div className="product-logo-title-container">
              <div className="Feaston-logo">
                <img src={FEASTON_LOGO} alt="Feaston" />
              </div>
              <div className="Feaston-product-title">
                {product.title}
              </div>
              {/* <div>
                {this.props.fetchSelectedCurrencyReducer.selectedCurrency} {currencyAfterConversion(variant.price)}
              </div> */}
            </div>

            {variants.length > 0 ? variants.map((v) => {
              return (
                <div className="variant-container" key={v.id}>
                  <div className="variant-title">{v.title}</div>
                  {v.available ?
                    <div className="Product_buy button" onClick={() => this.addVariantToCart(v.id, 1)}>
                      ADD TO CART
                    </div>
                    :
                    <div className="variant-sold-out">
                      SOLD OUT
                    </div>}
                </div>
              )
            }) : null}
          </div>

      </div>
    );
  }
}

const mapStateToProps = state => ({
  ...state
})

const mapDispatchToProps = dispatch => ({
  fetchCheckout: (checkout) => dispatch(fetchCheckout(checkout)),
})

export default withCookies(connect(mapStateToProps, mapDispatchToProps) (ProductWidgetFeaston));
