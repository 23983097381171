export default (state = {}, action) => {
  switch (action.type) {
   case 'FETCH_BOOSTER_PACK':
    return {
      products: action.payload
    }
   default:
    return state
  }
 }
 