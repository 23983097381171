import React, { Component } from 'react';
import { connect } from 'react-redux';
import ScrollManager from "ScrollManager/index.jsx";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import CONTACT_IMG_1 from "./contact_1.png";
import CONTACT_IMG_2 from "./contact_2.png";
import CONTACT_IMG_3 from "./contact_3.png";

function MobileContact() {
  return (
    <div className="MobileContact">
      <style jsx>{`
        .MobileContact {
          padding-bottom: 60px;
        }
        .MobileContact .contact1 {
          width: 70%;
          margin: auto;
          padding-top: 50px;
        }
        .MobileContact .contact2 {
          width: 50%;
          margin: auto;
        }
        .MobileContact .contact3 {
          width: 50%;
          margin: auto;
        }
        .MobileContact img {
          width: 100%;
          padding-bottom: 50px;
        }
      `}</style>
      <div>
        <Link to={'/address'}>
        <div className="contact1">
          <img src={CONTACT_IMG_1} alt="" />
        </div>
        </Link>
        <a href="https://www.instagram.com/feaston96/" target="blank" >
          <div className="contact2">
            <img src={CONTACT_IMG_2} alt="" />
          </div>
        </a>
        <a href="mailto:info@feaston.co">
          <div className="contact3">
            <img src={CONTACT_IMG_3} alt="" />
          </div>
        </a>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  ...state
})

export default connect(mapStateToProps, {})(MobileContact);
