import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'withRouter';
import { Link } from 'react-router-dom';
import { some } from 'lodash';
import CartPopup from '../CartPopup';

class PanelRight extends Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { children, withPath, location, fetchProductsReducer, fetchIsCartOpenReducer, noBorder, noBackground, atHome } = this.props;
    const { pathname } = location;
    const { products } = fetchProductsReducer;
    const { isCartOpen } = fetchIsCartOpenReducer;
    const pathArray = "/home".concat(pathname).replace('/', "").split('/');
    return (
      <div className={`PanelRight${noBorder ? " noBorder" : ""}${noBackground ? " noBackground" : ""}${atHome ? " atHome" : ""}${isCartOpen ? "" : " cartClosed"}`}>
        <style jsx>{`
          .PanelRight {
            position: fixed;
            top: 80px;
            bottom: 90px;
            right: 0;
            width: 20vw;
            min-width: 300px;
            z-index: 1000;
            overflow-y: scroll;
          }
          .PanelRight.noBorder {
            border: none;
          }
          .PanelRight.noBackground {
            background: transparent;
          }
          .PanelRight.atHome {
            height: 500px;
            top: unset;
            position: fixed;
          }
          .PanelRight.atHome.cartClosed {
            display: none;
          }
          .PanelRight .navigation-list {
            margin-left: 10px;
            margin-top: 10px;
          }
          .PanelRight .navigation-path {
            -webkit-transform: rotate(90deg);
            -webkit-transform-origin: left top;
            -moz-transform: rotate(90deg);
            -moz-transform-origin: left top;
            -ms-transform: rotate(90deg);
            -ms-transform-origin: left top;
            -o-transform: rotate(90deg);
            -o-transform-origin: left top;
            transform: rotate(90deg);
            transform-origin: left top;
            position: absolute;
            top: 0;
            left: 100%;
            white-space: nowrap;    
            font-size: 14px;
            font-weight: bold;
          }
        `}</style>
        {
          withPath ? (
            <div className="navigation-path">
              <div className="navigation-list">
                {pathArray.length > 0 ? pathArray.map((path_name, i) => {
                  let path = '/';
                  for (let x = 0; x <= i; x++) {
                    if (pathArray[x] !== "home") {
                      path = path.concat(`${pathArray[x]}${x === i ? "" : "/"}`);
                    }
                  }
                  let pathName = path_name;
                  if (pathName == "lmmg") {
                    pathName = "冷門馬經";
                  }
                  return (
                    <span key={`navIgation-list-${path_name}`}>
                      <Link to={path}>
                        <span>{pathName.toUpperCase()}</span>
                      </Link>
                      {some(products, (p) => p.handle == path_name) ? null : <span> >>&nbsp;</span>}
                    </span>
                  )
                }) : null}
              </div>
            </div>
          ) : null
        }
        {children}
        {isCartOpen ? <CartPopup /> : null}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  ...state
})

export default withRouter(connect(mapStateToProps, {}) (PanelRight));
