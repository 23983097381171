import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import TagManager from 'react-gtm-module';
import { withRouter } from 'withRouter';
import { fetchCheckout } from '../../../actions/fetchCheckout';
import CART_IMG from './cart.png';
import CHECKOUT_IMG from './checkout.png';

class MobileHeaderCart extends Component {

  constructor(props) {
    super(props);
    this.openCheckout = this.openCheckout.bind(this);
  }

  openCheckout() {
    window.open(this.props.fetchCheckoutReducer.checkout.webUrl);
  }

  componentDidUpdate(prevProps) {
    // check add-to-cart action:
    const prevCheckout = prevProps.fetchCheckoutReducer.checkout;
    const { products } = this.props.fetchProductsReducer;
    const { checkout = {} } = this.props.fetchCheckoutReducer;
    if (checkout && prevCheckout && products && products.length > 0) {
      const { lineItems } = checkout;
      const prevLineItems = prevCheckout.lineItems;
      let cartProductQuantity = 0;
      let prevCartProductQuantity = 0;
      if (lineItems.length > 0) {
        for (let p of lineItems) {
          cartProductQuantity = cartProductQuantity + p.quantity;
        }
      }
      if (prevLineItems.length > 0) {
        for (let p of prevLineItems) {
          prevCartProductQuantity = prevCartProductQuantity + p.quantity;
        }
      }

      if (cartProductQuantity - prevCartProductQuantity > 0) { // add to cart Event:
        if (lineItems.length > 0) {
          for (let i of lineItems) {
            if (prevLineItems.find(p => p.id === i.id && i.quantity > p.quantity) || !prevLineItems.find(p => p.id === i.id)) {
              // debounce(function() { console.log(i, 1); }, 500)();
              const product = products.find((p) => i.variant && p.id === i.variant.product.id);
              if (product) {
                TagManager.dataLayer({
                  dataLayer: {
                    event: 'addToCart',
                    ecommerce: {
                      currencyCode: checkout.currencyCode,
                      add: {
                        products: [{
                          id: (product.id).split('/').pop(),
                          name: i.title,
                          price: i.variant?.price?.amount,
                          brand: product.vendor,
                          category: product.productType,
                          variant: i.variant.title,
                          quantity: 1
                        }]
                      },
                    },
                    ProductAdded: {
                      id: (product.id).split('/').pop(),
                      handle: product.handle,
                      name: i.title,
                      price: i.variant?.price?.amount,
                      brand: product.vendor,
                      category: product.productType,
                      variant: i.variant.title,
                      quantity: 1,
                      currencyCode: checkout.currencyCode,
                    },
                  },
                });
              }
            }
          }
        }
      } else { // remove from cart Event:
        if (prevLineItems.length > 0) {
          for (let i of prevLineItems) {
            if (lineItems.find(p => p.id === i.id && i.quantity < p.quantity) || !lineItems.find(p => p.id === i.id)) {
              // debounce(function() { console.log(i, 1); }, 500)();
              const product = products.find((p) => i.variant && p.id === i.variant.product.id);
              if (product) { // product may be not found if it is archived
                TagManager.dataLayer({
                  dataLayer: {
                    event: 'removeFromCart',
                    ecommerce: {
                      currencyCode: checkout.currencyCode,
                      remove: {
                        products: [{
                          id: (product.id).split('/').pop(),
                          name: i.title,
                          price: i.variant?.price?.amount,
                          brand: product.vendor,
                          category: product.productType,
                          variant: i.variant.title,
                          quantity: 1
                        }]
                      },
                    },
                    ProductRemoved: {
                      id: (product.id).split('/').pop(),
                      handle: product.handle,
                      name: i.title,
                      price: i.vvariant?.price?.amount,
                      brand: product.vendor,
                      category: product.productType,
                      variant: i.variant.title,
                      quantity: 1,
                      currencyCode: checkout.currencyCode,
                    },
                  },
                });
              }
            }
          }
        }
      }
    }
  }

  render() {
    let checkout = {};
    let cartProductQuantity = 0;
    const { fetchCheckoutReducer, fetchSelectedCurrencyReducer } = this.props;
    if (fetchCheckoutReducer) {
      checkout = fetchCheckoutReducer.checkout || {};
    }
    const currencyAfterConversion = (price) => {
      return window.Currency.convert(price, checkout.currencyCode, fetchSelectedCurrencyReducer.selectedCurrency).toFixed(2)
    };
    if (this.props.fetchCheckoutReducer.checkout && this.props.fetchCheckoutReducer.checkout.lineItems) {
      if (this.props.fetchCheckoutReducer.checkout.lineItems.length > 0) {
        for (let p of this.props.fetchCheckoutReducer.checkout.lineItems) {
          cartProductQuantity = cartProductQuantity + p.quantity;
        }
      }
    }
    return (
      <div className={`MobileHeaderCart${this.props.location.pathname === "/cart" ? " atCart" : ' notAtCart'}`}>
        <style jsx>{`
          .MobileHeaderCart.atCart {
            //display: none;
            bottom: 0;
            width: 100%;
          }
          .MobileHeaderCart {
            padding-right: 13px;
            padding-top: 8px;
            height: 60px;
            position: fixed;
            display: flex;
            align-items: center;
            justify-content: space-between;
          }
          .MobileHeaderCart.notAtCart {
            top: 0;
            right: 0;
          }
          .MobileHeaderCart .cart-img {
            padding-left: 10px;
            width: 80px;
            display: flex;
          }
          .MobileHeaderCart .checkoutBtn {
            display: flex;
            padding-right: 10px;
            width: 150px;
          }
          .MobileHeaderCart .HeaderCartNumber {
            margin-left: 5px;
          }
          .MobileHeaderCart .HeaderCartNumber.non-empty {
            background: black;
            border-radius: 50%;
            color: white;
            padding: 2px;
            width: 20px;
            height: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .MobileHeaderCart .CartTotal {
            position: fixed;
            color: green;
            //background: white;
            //color: rgb(94, 94, 94);
            padding: 5px 10px;
            font-size: 18px;
            bottom: 60px;
            right: 0;
          }
        `}</style>

        <div onClick={() => {
          if (this.props.location.pathname !== "/cart") {
            this.props.navigate(`/cart`);
          } else {
            // this.props.navigate(-1);
          }
        }
        }>
          <img className="cart-img" src={CART_IMG} alt="" />
          {/* <div className={`HeaderCartNumber${cartProductQuantity > 0 ? " non-empty" : ""}`}>
            {cartProductQuantity}
          </div> */}
        </div>

        {this.props.location.pathname === "/cart" ? (
          <div onClick={this.openCheckout}>
            <img className="checkoutBtn" src={CHECKOUT_IMG} alt="" />
          </div>
        ) : null}

        {this.props.location.pathname === "/cart" ? (
          <div className="CartTotal">
            <span className="CartTotal__total">Total:</span>&nbsp;
            <span className="CartTotal__price">{fetchSelectedCurrencyReducer.selectedCurrency} {currencyAfterConversion(checkout.subtotalPrice?.amount)}</span>
          </div>
        ) : null}

      </div>
    );
  }
}

const mapStateToProps = state => ({
  ...state
})

const mapDispatchToProps = dispatch => ({
  fetchCheckout: (checkout) => dispatch(fetchCheckout(checkout)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MobileHeaderCart));