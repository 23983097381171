import React, { Component, useState } from 'react';
import { connect } from 'react-redux';
import ScrollManager from "ScrollManager/index.jsx";
import { Helmet } from "react-helmet";
import UPCOMING from "assets/Navigation/upcoming.png"; 

function Upcoming() {
  return (
    <div className="Upcoming">
      <style jsx>{`
        .Upcoming {
          padding: 50px;
          height: calc(100vh - 90px);
        }
        .Upcoming .upcoming-title {
          height: 80px;
          margin: auto;
        }
        .Upcoming img {
          height: 100%;
          width: auto;
        }
      `}</style>
      <div className="upcoming-title">
        <img src={UPCOMING} alt="" />
      </div>
    </div >
  )
}

const mapStateToProps = state => ({
  ...state
})

export default connect(mapStateToProps, {})(Upcoming);
