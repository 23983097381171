import React, { Component } from 'react';
import { withCookies } from 'react-cookie';
import { connect } from 'react-redux';
import { Helmet } from "react-helmet";
import * as faceapi from 'face-api.js';

class Test extends Component {

  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
    this.onPlay = this.onPlay.bind(this);
    this.run = this.run.bind(this);
  }

  componentDidMount() {
    this.run();
  }
  
  async run() {
    // load face detection and face expression recognition models
    // await faceapi.nets.ssdMobilenetv1.load();
    // await faceapi.nets.ssdMobilenetv1.loadFromUri('https://justadudewhohacks.github.io/face-api.js/models')
    await faceapi.nets.ssdMobilenetv1.loadFromUri('/models')
    // await faceapi.loadFaceExpressionModel('/')
    // await faceapi.nets.faceExpressionNet.loadFromUri('https://justadudewhohacks.github.io/face-api.js/models')
    await faceapi.nets.faceExpressionNet.loadFromUri('/models')
    // changeInputSize(224)

    // try to access users webcam and stream the images
    // to the video element
    const stream = await navigator.mediaDevices.getUserMedia({ video: {} })
    const videoEl = window.document.getElementById('inputVideo')
    videoEl.srcObject = stream
  }

  async onPlay() {
    const videoEl = window.document.getElementById('inputVideo')
    const withBoxes = true;

    if(videoEl.paused || videoEl.ended) {
      return setTimeout(() => this.onPlay())
    }

    const options = new faceapi.SsdMobilenetv1Options({ minConfidence: 0.1 });

    const ts = Date.now()

    const result = await faceapi.detectAllFaces(videoEl, options).withFaceExpressions()

    // updateTimeStats(Date.now() - ts)

    if (result) {
      const canvas = window.document.getElementById('overlay')
      const dims = faceapi.matchDimensions(canvas, videoEl, true)

      const resizedResult = faceapi.resizeResults(result, dims)
      const minConfidence = 0.05
      if (withBoxes) {
        faceapi.draw.drawDetections(canvas, resizedResult)
      }
      faceapi.draw.drawFaceExpressions(canvas, resizedResult, minConfidence)
    }
    

    setTimeout(() => this.onPlay())
  }

  render() {
    return (
      <div className="web-page Test" id="Test">
        <Helmet>
          <title>Feaston Test</title>
        </Helmet>
        <style jsx>{`
          .Test {
            padding: 20px 0 0 20px;
            display: block;
            text-align: left;
          }
          .Navigation {
            display: none;
          }
          .Testscreen {
            position: relative;
          }
          #overlay {
            position: absolute;
            top: 0;
            left: 0;
          }
        `}</style>
        <div>
    
          <div className="Test_screen">
          </div>
          <div style={{ position: "relative" }} className="Testscreen">
            <video onLoadedMetadata={this.onPlay} id="inputVideo" autoPlay muted playsInline></video>
            <canvas id="overlay" />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  ...state
})

export default withCookies(connect(mapStateToProps, {}) (Test));
