import React, { Component } from 'react';
import { connect } from 'react-redux';
import PanelRight from '../../components/PanelRight';
import { withRouter } from 'withRouter';
import PanelLeft from '../../components/PanelLeft';
import { Helmet } from "react-helmet";

class Terms extends Component {

  constructor(props) {
    super(props);
    this.updateDimensions = this.updateDimensions.bind(this);
    this.state = {
      scale: 1,
      page: "term",
    }
  }

  updateDimensions() {
    const newScale = (window.innerWidth < 1155 ? 1155 : window.innerWidth)/1440;
    this.setState({
      scale: newScale < 1 ? newScale : 1,
    });
  }

  componentWillMount() {
    this.updateDimensions();
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  render() {
    const { scale, page } = this.state;
    const { match } =  this.props;
    const { url } = match;

    return (
      <div className="web-page Terms">
        <Helmet>
          <title>FEASTON - Terms</title>
          <link rel="canonical" href={`https://feaston.co${url}`} />
        </Helmet>
        <style jsx>{`
          .Terms {
            height: 100vh;
            align-items: flex-start;
          }
          .Terms .Terms-container {
            padding-top: 120px;
            position: relative;
            width: 60%;
            max-height: fit-content;
            overflow-y: hidden;
            line-height: 1.4;
            text-align: left;
            color: #000000;
            font-weight: bold;
            margin-bottom: 70px;
            display: flex;
            flex-direction: column;
            transform: scale(${scale});
            transform-origin: top;
          }
          .Terms a {
            text-decoration: underline;
          }
          .Terms .Terms-title-container {
            // font-family: Hoefler Text;
            font-size: 42px;
            text-align: center;
            margin-bottom: 38px;
            display: flex;
            align-items: baseline;
          }
          .Terms .Terms-title-container .Terms-title {
            font-size: 48px;
            color: grey;
            cursor: pointer;
          }
          .Terms .Terms-title-container .Terms-title.active {
            border-bottom: 5px solid black;
            color: black;
          }
          .Terms .Terms-last-update {
            font-size: 14px;
            margin-bottom: 20px;
          }
          .Terms .Terms-line {
            font-size: 20px;
            background: lightgrey;
            width: fit-content;
            margin-bottom: 35px;
          }
          .Terms .Terms-block {
            width: 100%;
            margin-bottom: 38px;
          }
          .Terms .Terms-block .Terms-block-title {
            font-size: 28px;
            font-weight: bold;
            margin-bottom: 10px;
          }
          .Terms .Terms-block .Terms-block-desc {
            font-size: 14px;
            padding-left: 13px;
            font-weight: normal;
            line-height: 24px;
          }
          .Terms .Terms-last {
            font-size: 14px;
            text-align: center;
            font-weight: normal;
            margin-bottom: 15px;
          }
        `}</style>
          <div className="Terms-container">
            <div className="Terms-title-container">
              <div>
                <div className={`Terms-title${page === 'term' ? ' active' : ''}`} onClick={() => this.setState({page: "term" })} style={{ marginRight: '40px' }}>Terms</div>
              </div>
              <div>
                <div className={`Terms-title${page === 'privacy' ? ' active' : ''}`} onClick={() => this.setState({page: "privacy" })}>Privacy Policy</div>
              </div>
            </div>

          {page === "term" ? (
            <div className="statement-container">
              <div className="Terms-last-update">Last Update 20210222</div>
              <div className="Terms-block">
                <div className="Terms-block-title">
                  Fulfilment
                </div>
                <div className="Terms-block-desc">
                  We will fulfil orders within 5 days. Tracking numbers will be provided after fulfilment. Shipping costs are non-refundable. Due to reduced and cancelled flights, international shipping durations have become unpredictable, expect 7-120 working days for Regular Shipping. Express Shipping will not be affected.
                </div>
              </div>
              <div className="Terms-block">
                <div className="Terms-block-title">
                  Custom Duties
                </div>
                <div className="Terms-block-desc">
                  Your order may be subject to customs clearance procedures, which can cause delays beyond our original delivery estimates. International customers may be subject to import duties and taxes, which are assessed once a shipment reaches your country. You are responsible for any custom clearance charges. Please refer to your local customs authority for more information.
                </div>
              </div>
              <div className="Terms-block">
                <div className="Terms-block-title">
                  Colour Differences
                </div>
                <div className="Terms-block-desc">
                  We have made every effort to display as accurately as possible the colours of our products that appear at the www.feaston.co . However, as the actual colours you see will depend on your monitor,
                  we cannot guarantee that your monitor's display of any colour will be accurate. Size or colour exchanges will only be issued if requested sizes and colours are in stock. Exchanges for different items are not accepted. An item can only be exchanged once.
                </div>
              </div>
              <div className="Terms-block">
                <div className="Terms-block-title">
                  Returns & Exchange
                </div>
                <div className="Terms-block-desc">
                  All sales are final. No refunds accepted, no returns honoured. Size exchanges and significantly factory defective items are accepted for exchange within 7 days from which shipment is received. 
                  We require that customers notify info(at)feaston.co of any problems with merchandise within 48 hours of receipt. Items must be in their original packaging, in as-new condition with printed receipts. Exchanges are not available for any product after 7 days.
                </div>
              </div>
              <div className="Terms-last">
                These terms and conditions may change at any time without prior notice,<br />
                revised terms and conditions shall be posted on this website.
              </div>
            </div>
          ) : (
            <div className="statement-container">
              <div>
                This Privacy Policy describes how feaston.co (the “Site” or “we”) collects, uses, and discloses your Personal Information when you visit or make a purchase from the Site.
                <br /><br />
                
              <div className="Terms-block">
                <div className="Terms-block-title">
                  Collecting Personal Information
                </div>
                <div className="Terms-block-desc">
                  When you visit the Site, we collect certain information about your device, your interaction with the Site, and information necessary to process your purchases. We may also collect additional information if you contact us for customer support. In this Privacy Policy, we refer to any information that can uniquely identify an individual (including the information below) as “Personal Information”. See the list below for more information about what Personal Information we collect and why.
                </div>
              </div>
              
              <div className="Terms-block">
                <div className="Terms-block-title">
                  Device information
                </div>
                <div className="Terms-block-desc">
                  Examples of Personal Information collected: version of web browser, IP address, time zone, cookie information, what sites or products you view, search terms, and how you interact with the Site.<br />
                  Purpose of collection: to load the Site accurately for you, and to perform analytics on Site usage to optimize our Site.<br />
                  Source of collection: Collected automatically when you access our Site using cookies, log files, web beacons, tags, or pixels.<br />
                  Disclosure for a business purpose: shared with our processor Shopify.
                </div>
              </div>

              <div className="Terms-block">
                <div className="Terms-block-title">
                  Order information
                </div>
                <div className="Terms-block-desc">
                  Examples of Personal Information collected: name, billing address, shipping address, payment information (including credit card numbers), email address, and phone number.<br />
                  Purpose of collection: to provide products or services to you to fulfill our contract, to process your payment information, arrange for shipping, and provide you with invoices and/or order confirmations, communicate with you, screen our orders for potential risk or fraud, and when in line with the preferences you have shared with us, provide you with information or advertising relating to our products or services.
                  Source of collection: collected from you.<br />
                  Disclosure for a business purpose: shared with our processor Shopify.
                </div>
              </div>  
            
              <div className="Terms-block">
                <div className="Terms-block-title">
                  Customer support information
                </div>
                <div className="Terms-block-desc">
                  Purpose of collection: to provide customer support.<br />
                  Source of collection: collected from you.<br />
                  Disclosure for a business purpose: Feaston
                </div>
              </div>

              <div className="Terms-block">
                <div className="Terms-block-title">
                  Minors
                </div>
                <div className="Terms-block-desc">
                  The Site is not intended for individuals under the age of 18. We do not intentionally collect Personal Information from children. If you are the parent or guardian and believe your child has provided us with Personal Information, please contact us at the address below to request deletion.
                </div>
              </div>
            
              <div className="Terms-block">
                <div className="Terms-block-title">
                  Sharing Personal Information
                </div>
                <div className="Terms-block-desc">
                  We share your Personal Information with service providers to help us provide our services and fulfill our contracts with you, as described above. For example:
                  <br /><br />
                  We use Shopify to power our online store. You can read more about how Shopify uses your Personal Information here: <a href="https://www.shopify.com/legal/privacy" target="blank">https://www.shopify.com/legal/privacy</a>.<br />
                  We may share your Personal Information to comply with applicable laws and regulations, to respond to a subpoena, search warrant or other lawful request for information we receive, or to otherwise protect our rights.
                </div>
              </div>
                  
              <div className="Terms-block">
                <div className="Terms-block-title">
                  Behavioural Advertising
                </div>
                <div className="Terms-block-desc">
                  As described above, we use your Personal Information to provide you with targeted advertisements or marketing communications we believe may be of interest to you. For example:
                  <br /><br />
                  We use Google Analytics to help us understand how our customers use the Site. You can read more about how Google uses your Personal Information here: <a href="https://policies.google.com/privacy?hl=en" target="blank">https://policies.google.com/privacy?hl=en</a>. You can also opt-out of Google Analytics here: <a href="https://tools.google.com/dlpage/gaoptout" target="blank">https://tools.google.com/dlpage/gaoptout</a>.
                  We share information about your use of the Site, your purchases, and your interaction with our ads on other websites with our advertising partners. We collect and share some of this information directly with our advertising partners, and in some cases through the use of cookies or other similar technologies (which you may consent to, depending on your location).
                  For more information about how targeted advertising works, you can visit the Network Advertising Initiative’s (“NAI”) educational page at <a href="http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work" target="blank">http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work</a>.
                  <br /><br />
                  You can opt out of targeted advertising by:
                  <br /><br />
                  FACEBOOK - <a href="https://www.facebook.com/settings/?tab=ads" target="blank">https://www.facebook.com/settings/?tab=ads</a><br />
                  GOOGLE - <a href="https://www.google.com/settings/ads/anonymous" target="blank">https://www.google.com/settings/ads/anonymous</a><br />
                  BING - <a href="https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads" target="blank">https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads</a><br />
                  Additionally, you can opt out of some of these services by visiting the Digital Advertising Alliance’s opt-out portal at: <a href="http://optout.aboutads.info/" target="blank">http://optout.aboutads.info/</a>.
                </div>
              </div>

              <div className="Terms-block">
                <div className="Terms-block-title">
                  Using Personal Information
                </div>
                <div className="Terms-block-desc">
                  We use your personal Information to provide our services to you, which includes: offering products for sale, processing payments, shipping and fulfillment of your order, and keeping you up to date on new products, services, and offers.
                </div>
              </div>
              
              <div className="Terms-block">
                <div className="Terms-block-title">
                  Lawful basis
                </div>
                <div className="Terms-block-desc">
                  Pursuant to the General Data Protection Regulation (“GDPR”), if you are a resident of the European Economic Area (“EEA”), we process your personal information under the following lawful bases:
                  <br /><br />
                  Your consent;<br />
                  The performance of the contract between you and the Site;<br />
                  Compliance with our legal obligations;<br />
                  To protect your vital interests;<br />
                  To perform a task carried out in the public interest;<br />
                  For our legitimate interests, which do not override your fundamental rights and freedoms.<br />
                </div>
              </div>

              <div className="Terms-block">
                <div className="Terms-block-title">
                  Retention
                </div>
                <div className="Terms-block-desc">
                  When you place an order through the Site, we will retain your Personal Information for our records unless and until you ask us to erase this information. For more information on your right of erasure, please see the ‘Your rights’ section below.
                </div>
              </div>

              <div className="Terms-block">
                <div className="Terms-block-title">
                  Automatic decision-making
                </div>
                <div className="Terms-block-desc">
                  If you are a resident of the EEA, you have the right to object to processing based solely on automated decision-making (which includes profiling), when that decision-making has a legal effect on you or otherwise significantly affects you.
                  <br /><br />
                  We do not engage in fully automated decision-making that has a legal or otherwise significant effect using customer data.
                  <br /><br />
                  Our processor Shopify uses limited automated decision-making to prevent fraud that does not have a legal or otherwise significant effect on you.
                  <br /><br />
                  Services that include elements of automated decision-making include:
                  <br /><br />
                  Temporary denylist of IP addresses associated with repeated failed transactions. This denylist persists for a small number of hours.
                  Temporary denylist of credit cards associated with denylisted IP addresses. This denylist persists for a small number of days.
                  GDPR
                  <br /><br />
                  If you are a resident of the EEA, you have the right to access the Personal Information we hold about you, to port it to a new service, and to ask that your Personal Information be corrected, updated, or erased. If you would like to exercise these rights, please contact us through the contact information below.
                  <br /><br />
                  Your Personal Information will be initially processed in Ireland and then will be transferred outside of Europe for storage and further processing, including to Canada and the United States. For more information on how data transfers comply with the GDPR, see Shopify’s GDPR Whitepaper: <a href="https://help.shopify.com/en/manual/your-account/privacy/GDPR" target="blank">https://help.shopify.com/en/manual/your-account/privacy/GDPR</a>.
                </div>
              </div>
            
            </div>
          </div>
        )}

          <div>
        </div>
      </div>
      <PanelRight /*noBorder*/ /*withPath*/>
      </PanelRight>
    </div>
    );
  }
}

const mapStateToProps = state => ({
  ...state
})

export default withRouter(connect(mapStateToProps, {}) (Terms));
