export default (state = {}, action) => {
  switch (action.type) {
   case 'FETCH_CHECKOUT':
    return {
      checkout: action.payload
    }
   default:
    return state
  }
 }
 