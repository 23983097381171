import React, { Component } from 'react';
import { connect } from 'react-redux';
import ScrollManager from "ScrollManager/index.jsx";
import { Helmet } from "react-helmet";
import UPCOMING_IMG from "./upcoming.png";

function MobileUpcoming() {
  return (
    <div className="MobileUpcoming web-page">
      <style jsx>{`
        .MobileUpcoming {
          padding-bottom: 60px;
        }
        .MobileUpcoming .title {
          padding-top: 30px;
        }
        .MobileUpcoming .title img {
          height: 20px;
          width: auto;
        }
        .MobileUpcoming img {
          width: 100%;
          padding-bottom: 50px;
        }
      `}</style>
      <div className='title'>
        <img src={UPCOMING_IMG} alt="" />
      </div>
      <div>
        <div className="contact1">
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  ...state
})

export default connect(mapStateToProps, {})(MobileUpcoming);
