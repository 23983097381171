import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'withRouter';
import categories from 'data/categories';
import ProductWidgetGeneral from '_Mobile/scenes/MobileProduct/ProductWidgetGeneral';
import MobileDescContainer from '../../../components/MobileDescContainer';
import MobileProductPathContainer from '_Mobile/components/MobileProductPathContainer';
import Slider from 'react-slick';
import { fetchIsNavigationExpanded } from 'actions/fetchIsNavigationExpanded';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Helmet } from "react-helmet";
import { SchemaOrg } from 'components/SchemaOrg';
import TagManager from 'react-gtm-module';

//get's a query parameter by name from current url
export function getUrlParameter(name) {
  name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
  var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
  var results = regex.exec(window.location.search);
  return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

class mobileProjectGeneral extends Component {

  constructor(props) {
    super(props);
    this.state = {
      productsInProject: [],
      project: props.location.state ? props.location.state.category : props.location.pathname.split("/")[1],
      activeSlide: 0,
      option: {},
    };
    this.initiateLoadedProducts = this.initiateLoadedProducts.bind(this);
    this.onOptionChange = this.onOptionChange.bind(this);
    this.nextSlide = this.nextSlide.bind(this);
    this.prevSlide = this.prevSlide.bind(this);
  }

  initiateLoadedProducts(products) {
    const project_url = this.state.project;

    let temp_product_array = [];
    if (products) {
      if (categories[project_url]) {
        for (let c of categories[project_url].products) {
          for (let p of products) {
            if (c === p.handle) {
              temp_product_array.push(p);
              break;
            } else if (c.image) {
              temp_product_array.push(c);
              break;
            }
          }
        }
      }
      
      //directly fromm shopify
      for (let p of products) {
        if (decodeURIComponent(project_url) === p.handle) {
          temp_product_array.push(p);
          break;
        }
      }

      // for setting default option:
      let defaultOptionValues = {};
      if (temp_product_array.length > 0) {
        temp_product_array[0].options.forEach((selector) => {
          defaultOptionValues[selector.name] = selector.values[0].value;
        });
      }

      const { checkout } = this.props.fetchCheckoutReducer;
      if (!this.state.productsInProject.length && checkout) {
        const product = temp_product_array[0];
        const variant = product.variants[0];
        TagManager.dataLayer({
          dataLayer: {
            event: 'viewProduct',
            ProductViewed: {
              id: (product.id).split('/').pop(),
              handle: product.handle,
              name: product.title,
              price: variant?.price?.amount,
              brand: product.vendor,
              category: product.productType,
              variant: variant.title,
              quantity: 1,
              currencyCode: checkout.currencyCode,
            },
          },
        });
      }

      this.setState({
        productsInProject: temp_product_array,
        option: defaultOptionValues,
      })
    }
  }

  onOptionChange(option) {
    this.setState({
      option,
    });
  }

  nextSlide() {
    this.refs.slickSlider.slickNext();
  }

  prevSlide() {
    this.refs.slickSlider.slickPrev();
  }

  componentDidMount() {
    const { fetchIsNavigationExpanded } = this.props;
    fetchIsNavigationExpanded(false);
    let color = getUrlParameter('color');
    this.onOptionChange({ Color: color });
    window.scrollTo(0, 0);
  }

  componentWillMount() {
    this.initiateLoadedProducts(this.props.fetchProductsReducer.products);
  }

  componentWillReceiveProps(nextProps) {
    this.initiateLoadedProducts(nextProps.fetchProductsReducer.products);
    let color = getUrlParameter('color');
    this.onOptionChange({ Color: color });
  }

  componentDidUpdate(prevProps, prevState) {
    const { checkout } = this.props.fetchCheckoutReducer;
    const { productsInProject } = this.state;
    const product = productsInProject[0];
    if ((!prevState.productsInProject.length || !prevProps.checkout) && product && checkout) {
      const variant = product.variants[0];
      TagManager.dataLayer({
        dataLayer: {
          event: 'viewProduct',
          ProductViewed: {
            id: (product.id).split('/').pop(),
            handle: product.handle,
            name: product.title,
            price: variant?.price?.amount,
            brand: product.vendor,
            category: product.productType,
            variant: variant.title,
            quantity: 1,
            currencyCode: checkout.currencyCode,
          },
        },
      });
    }
  }

  render() {
    const { productsInProject, activeSlide } = this.state;
    const SlickNextArrow = (props) => {
      return (
        <div
          className={`Mobile_General_slick_button Mobile_General_slick_button_next${activeSlide === p.images.length - 1 ? " slick_button_display_none" : ""}`}
          onClick={this.nextSlide}
        >
          <div className="inner-slick-button inner-next"/>
        </div>
      );
    }
    const SlickPrevArrow = (props) => {
      return (
        <div
          className={`Mobile_General_slick_button Mobile_General_slick_button_prev${activeSlide === 0 ? " slick_button_display_none" : ""}`}
          onClick={this.prevSlide}
        >
          <div className="inner-slick-button inner-prev"/>
        </div>
      );
    }
    const slickSettings = {
      nextArrow: <SlickNextArrow />,
      prevArrow: <SlickPrevArrow />,
      beforeChange: (current, next) =>
        this.setState({ activeSlide: next }),
      dots: true,
      appendDots: dots => (
        <div
          style={{
            position: 'unset',
            bottom: 0,
          }}
        >
          <style jsx>{`
            .slick-dots li button:before {
              //color: lightgrey;
            }
            .slick-dots li.slick-active button:before {
              //opacity: 0.5;
              //color: grey;
            }
          `}</style>
          <ul style={{ margin: 0, padding: 0 }}> {dots} </ul>
        </div>
      ),
      // customPaging: i => (
      //   <div
      //     style={{
      //       width: "30px",
      //       color: "blue",
      //       border: "1px blue solid"
      //     }}
      //   >
      //     {i + 1}
      //   </div>
      // )
    };

    const { match } =  this.props;
    const { url } = match;
    const p = productsInProject[0];
    let splitedHtmlArray = productsInProject && productsInProject.length > 0 ? p.descriptionHtml.split(/(?=<p)/) : [""];

    return (
      <div className="General Product web-page">
        <Helmet>
          <title>{`FEASTON - ${p && p.title ? p.title : 'Product'}`}</title>
          <meta property="og:title" content={`FEASTON - ${p && p.title ? p.title : 'Product'}`} />
          <meta property="og:image" content={p && p.images.length > 0 ? p.images[0].src : ""} />
          <meta property="og:description" content={p && p.description ? p.description : ""} />
          <link rel="canonical" href={`https://feaston.co${url}`} />
        </Helmet>
        {p ? <SchemaOrg p={p} url={url} /> : null}
        <style jsx>{`
          .General {
            height: calc(100vh - 61px);
            padding-bottom: 200px;
          }
          .General div {
            //overflow-y: unset;
          }
          .mobile-web-mid {
            position: fixed;
            width: 100%;
            top: 70px;
            left: 0;
            overflow-y: scroll;
            height: 100vh;
            z-index: 1;
            font-weight: bold;
          }
          .General .General-mid img {
            width: 100%;
            height: auto;
            max-height: 100%;
            object-fit: cover;
          }
          .General .Top-item {
            flex: 0 0 auto;
            //align-items: center;
            display: flex;
            padding-top: 40px;
          }
          .General .Top-item img{
            width: unset;
            max-height: unset;
            object-fit: unset;
          }
          .General .General-logo {
            margin: 0 20px;
            margin-right: 30px;
          }
          .General .General-logo img {
            width: 130px;
          }
          .General .General-desc {
            text-align: left;
            padding: 40px 20px;
            font-size: 14px;
          }
          .General .General-desc.firstDescCenter {
            //display: flex;
            //flex-direction: column;
            //justify-content: center;
          }
          .General .General-desc.General-desc-img{
            text-align: unset;
            width: unset;
          }
          .General .slick-track {
            display: flex;
            align-items: center;
          }
          .General .Mobile_General_slick_button.Mobile_General_slick_button_prev {
            left: 10px;
            border-right: 16px solid black;
          }
          .General .Mobile_General_slick_button.Mobile_General_slick_button_next {
            right: 10px;
            border-left: 16px solid black;
          }
          .General .inner-slick-button.inner-prev {
            left: 4px;
            border-right: 11px solid white;
          }
          .General .inner-slick-button.inner-next {
            right: 14px;
            border-left: 11px solid white;
          }
          .General .Mobile_General_slick_button.slick_button_display_none {
            display: none;
          }
          .General .General-scroll-mid .img {
            width: 80%;
            margin: auto;
            padding-top: 40px;
          }
          .General .General-scroll-mid:not(:first-child) .img {
            padding-top: 100px;
          }
          .PanelRight-productWidget {
            position: fixed;
            bottom: 0;
            left: 0;
            right: 0;
            background: #fff;
            z-index: 9999;
          }
          .mobile-productPage-productWidget {
            padding-bottom: 400px;
            overflow-y: scroll;
          }
        `}</style>
        <div className="mobile-web-mid General-mid Product-mid">
          {/* <MobileDescContainer page="product">
            {splitedHtmlArray.map((html, index) => {
              return (
                !html.includes("<p") || html.includes('class="title"') ? null :
                <div key={index} className={`Top-item General-desc ${index === 0 ? "firstDescCenter" : ""} ${html.includes("<img") ? "General-desc-img" : ""}`} dangerouslySetInnerHTML={{__html: html}}/> 
              )
            })}
          </MobileDescContainer> */}

          <div className="mobile-productPage-productWidget">
            {/* <MobileProductPathContainer withPath> */}
              {/* <Slider {...slickSettings} ref="slickSlider"> */}
                {p ? (this.state.option.Color ? p.images.filter((img) => { return img.altText ? !img.altText.toLowerCase().includes(`#ig`) && (img.altText.includes(`#always_in_detail_page`) || (img.altText.toLowerCase().includes(`#color_${this.state.option.Color.toLowerCase()}`) && !img.altText.includes(`#not_in_detail_page`))) : true } ) : p.images).map((img, index) => {
                  return (
                    <div className="General-scroll-mid" key={img.src}>
                      <img className="img" src={img.src} alt="General" />
                    </div>
                  )
                }) : null }
              {/* </Slider> */}

              <div className="General-desc" dangerouslySetInnerHTML={{ __html: p.descriptionHtml }} />

              <div className="PanelRight-productWidget">
                {productsInProject && productsInProject.length > 0 ? (
                  <ProductWidgetGeneral
                    product={p}
                    onOptionChange={this.onOptionChange}
                    page="productDetail"
                  />
                ) : null}
              </div>
            {/* </MobileProductPathContainer> */}

          </div>
        </div>
        
      </div>
    );
  }
}

const mapStateToProps = state => ({
  ...state
})

const mapDispatchToProps = (dispatch) => ({
  fetchIsNavigationExpanded: (bool) => dispatch(fetchIsNavigationExpanded(bool))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps) (mobileProjectGeneral));
