export default (state = {}, action) => {
  switch (action.type) {
   case 'FETCH_SELECTED_CURRENCY':
    return {
      selectedCurrency: action.payload
    }
   default:
    state.selectedCurrency = state.selectedCurrency || "USD";
    return state
  }
 }
 