import React, { Component } from 'react';
import { connect } from 'react-redux';
import ScrollManager from "ScrollManager/index.jsx";
import { Helmet } from "react-helmet";
import PAST_IMG from "./past.png";
import ES_TALK from "assets/Past/es_talk.png";
import FFF from "assets/Past/fff.png";
import HC from "assets/Past/hc.png";
import LC from "assets/Past/lc.png";
import TK from "assets/Past/tk.png";
import YHSY from "assets/Past/yhsy.png";
import GLA from "assets/Past/GLA.png";
import APD from "assets/Past/APD.png";
import HA from "assets/Past/HA.png";
import repoPop from "assets/Past/repoPop.png";
import SHEEPS from "assets/Past/SHEEPS.png";
import FL from "assets/Past/FL.png";

function MobilePast() {
  return (
    <div className="MobilePast web-page">
      <style jsx>{`
        .MobilePast {
          padding-bottom: 60px;
          flex-direction: column;
        }
        .MobilePast .title {
          padding-top: 30px;
        }
        .MobilePast .title img {
          height: 30px;
          width: auto;
        }
        .MobilePast .pasts {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          margin: auto;
          align-items: center;
          justify-content: center;
          padding-bottom: 60px;
        }
        .MobilePast .event {
          width: 50vw;
          height: auto;
          min-height: 15vh;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .MobilePast img {
          width: 100%;
          padding-bottom: 20px;
        }
      `}</style>
      <div className='title'>
        <img src={PAST_IMG} alt="" />
      </div>
      <div className="pasts">
        <div className="event">
          <img src={ES_TALK} alt="" />
        </div>
        <div className="event">
          <img src={HA} alt="" />
        </div>
        <div className="event">
          <img src={SHEEPS} alt="" />
        </div>
        <div className="event">
          <img src={repoPop} alt="" />
        </div>
        <div className="event">
          <img src={FL} alt="" />
        </div>
        <div className="event">
          <img src={APD} alt="" />
        </div>
        <div className="event">
          <img src={GLA} alt="" />
        </div>
        <div className="event">
          <img src={HC} alt="" />
        </div>
        <div className="event">
          <img src={TK} alt="" />
        </div>
        <div className="event">
          <img src={YHSY} alt="" />
        </div>
        <div className="event">
          <img src={LC} alt="" />
        </div>
        <div className="event">
          <img src={FFF} alt="" />
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  ...state
})

export default connect(mapStateToProps, {})(MobilePast);
