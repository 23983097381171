import React, { Component } from 'react';
import { client } from '../../../shopify';
import { withCookies } from 'react-cookie';
import { connect } from 'react-redux';
import { fetchCheckout } from '../../../actions/fetchCheckout';
import SizeChartPopup from 'components/SizeChartPopup';

class ProductWidgetN796 extends Component {

  constructor(props) {
    super(props);
    let defaultOptionValues = {};
    this.handleSizeChange = this.handleSizeChange.bind(this);
    this.props.product.options.forEach((selector) => {
      defaultOptionValues[selector.name] = selector.values[0].value;
    });
    this.state = {
      selectedOptions: defaultOptionValues,
      selectedVariant: null,
      selectedSize: null,
      isSizeSelected: true,
      addedToCartReminder: false,
    }
  }

  addVariantToCart(variantId, quantity){
    if (variantId) {
      const lineItemsToAdd = [{variantId, quantity: parseInt(quantity, 10)}];
      const checkoutId = this.props.fetchCheckoutReducer.checkout.id;
      return client.checkout.addLineItems(checkoutId, lineItemsToAdd).then(res => {
        this.props.fetchCheckout(res);
        this.setState({
          addedToCartReminder: true,
        }, () => {
          setTimeout(() => {
            this.setState({
              addedToCartReminder: false,
            })
          }, 3000);
        })
      });
    } else {
      this.setState({
        isSizeSelected: false,
      })
    }
  }

  handleSizeChange(size) {
    let selectedOptions = this.state.selectedOptions;
    selectedOptions["Size"] = size;
    const selectedVariant = client.product.helpers.variantForOptions(this.props.product, selectedOptions)
    this.setState({
      selectedVariant: selectedVariant,
      selectedSize: size,
      isSizeSelected: true,
      // selectedVariantImage: selectedVariant.attrs.image
    });
  }

  componentWillMount() {
    let { cookies } = this.props;
    const checkoutId = cookies.get('checkoutID');
    if (checkoutId) {
      client.checkout.fetch(checkoutId).then((checkout) => {
        if (checkout.completedAt) {
          client.checkout.create().then((res) => {
            cookies.set('checkoutID', res.id, { path: '/', expires: new Date(Date.now()+31556952000)});
          });
        }
      });
    } else {
      client.checkout.create().then((res) => {
        cookies.set('checkoutID', res.id, { path: '/', expires: new Date(Date.now()+31556952000)});
      });
    }
  }

  render() {
    const { product } = this.props;
    const { descriptionHtml, productType } = product;
    let el = window.document.createElement('html');
    if (descriptionHtml) {
      el.innerHTML = descriptionHtml;
    }
    let title = el.getElementsByClassName('title')[0] ? el.getElementsByClassName('title')[0].innerHTML : product.title;

    const sizes = product.options.find(o => o.name == "Size").values;
    const { selectedVariant } = this.state;

    const currencyAfterConversion = (price) => {
      return this.props.fetchCheckoutReducer.checkout ? 
        window.Currency.convert(price, this.props.fetchCheckoutReducer.checkout.currencyCode, this.props.fetchSelectedCurrencyReducer.selectedCurrency).toFixed(2)
        :
        0
    };

    return (
      <div className="ProductWidgetN796">
        <style jsx>{`
          .ProductWidgetN796 {
            position: absolute;
            bottom: 0;
            height: 40vh;
            padding: 0 25px 20px 25px;
            width: calc(17vw - 50px);
          }

          .ProductWidgetN796 .product-title-container {
            font-size: 18px;
            background: white;
            width: fit-content;
            text-align: center;
            margin: 20px 0;
            font-weight: bold;
          }

          .ProductWidgetN796 .product-title-container .Feaston-product-title {
            margin-top: 10px;
            text-align: left;
          }
          
          .ProductWidgetN796 .variant-container {
            display: flex;
            align-items: center;
            margin-bottom: 5px;
            font-weight: bold;
          }

          .ProductWidgetN796 .variant-container .variant{
            width: 40px;
            cursor: pointer;
          }

          .ProductWidgetN796 .option-container .Product_option{
            background: white;
            border: none;
            border-radius: 0;
            font-size: 16px;
            margin: 0 5px 10px 5px;
          }
          .ProductWidgetN796 select {
            text-align-last: center;
            -webkit-appearance: none;
            appearance: none;
            font-weight: bold;
            padding: 2px;
          }
          .ProductWidgetN796 select::-ms-expand {
            display: none;
          }
          .ProductWidgetN796 .variant-title {
            font-size: 12px;
            font-weight: bold;
          }
          .ProductWidgetN796 .Product_buy {
            font-size: 10px;
            color: black;
            background: white;
            font-weight: bold;
            width: 100px;
            border-radius: 0;
            border-color: black;
            border: 1px solid;
            cursor: pointer;
            font-family: "Cousine";
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 4px 0 2px 0;
          }

          .ProductWidgetN796 .variant-sold-out {
            color: red;
            font-size: 16px;
            font-weight: bold;
          }

          .ProductWidgetN796 .reminder {
            color: rgb(227, 8, 28);
            font-weight: bold;
            text-align: left;
            margin-top: 20px;
          }

          .ProductWidgetN796 .reminder.added-to-cart {
            color: green;
          }

          .ProductWidgetN796 .price-button-container {
            display: flex;
            justify-content: space-between;
            margin: 30px 0;
          }

          .ProductWidgetN796 .price-button-container .price {
            size: 30px;
            font-weight: bold;
            text-align: left;
            margin-right: 10px;
          }

          .ProductWidgetN796 .price-button-container .product-widget-button {
            display: flex;
            align-items: center;
          }
        `}</style>
          <div> 
            <div className="product-title-container">
              <div className="Feaston-product-title">
                {title.split('\\n').map(function(item) {
                    return (
                      <span>
                        {item.replace(/-/g, '‑')}
                        <br/>
                      </span>
                    )
                })}
              </div>
            </div>

            <div className="variant-container">
              <SizeChartPopup productType={productType}>
                <div className="size-title">
                  SIZE
                </div>
              </SizeChartPopup>
              {sizes.length > 0 ? sizes.map((s) => {
                return (
                  <div className="variant" key={s.value} onClick={() => this.handleSizeChange(s.value)}>
                    {this.state.selectedSize == s.value ? "(" : ""}
                    {s.value}
                    {this.state.selectedSize == s.value ? ")" : ""}
                  </div>
                )
              }) : null}
            </div>

            <div className="price-button-container">
              <div className="price">
                {this.props.fetchSelectedCurrencyReducer.selectedCurrency} {currencyAfterConversion(product.variants[0].price?.amount)}
              </div>
              <div className="product-widget-button">
                {(selectedVariant && selectedVariant.available) || !selectedVariant ?
                  <div className="Product_buy button" onClick={() => this.addVariantToCart(selectedVariant ? selectedVariant.id : null, 1)}>
                    ADD TO CART
                  </div>
                  :
                  <div className="variant-sold-out">
                    SOLD OUT
                  </div>}
              </div>
            </div>

            {!this.state.isSizeSelected ?
              <div className="reminder">
                SELECT YOUR SIZE
              </div> : null}

            {this.state.addedToCartReminder ?
              <div className="reminder added-to-cart">
                ADDED TO CART
              </div> : null}

          </div>

      </div>
    );
  }
}

const mapStateToProps = state => ({
  ...state
})

const mapDispatchToProps = dispatch => ({
  fetchCheckout: (checkout) => dispatch(fetchCheckout(checkout)),
})

export default withCookies(connect(mapStateToProps, mapDispatchToProps) (ProductWidgetN796));
