import React, { Component } from 'react';
import { client } from '../../../shopify';
import { withCookies } from 'react-cookie';
import { connect } from 'react-redux';
import { fetchCheckout } from '../../../actions/fetchCheckout';
import EmailUs from './emailUs.jsx';
import SizeChartPopup from 'components/SizeChartPopup';

class ProductWidgetGeneral extends Component {

  constructor(props) {
    super(props);
    let defaultOptionValues = {};
    this.handleSizeChange = this.handleSizeChange.bind(this);
    this.props.product.options.forEach((selector) => {
      if (selector.values.length === 1) {
        defaultOptionValues[selector.name] = selector.values[0].value;
      }
    });
    this.state = {
      selectedOptions: defaultOptionValues,
      selectedVariant: null,
      selectedSize: null,
      selectedColor: null,
      isSizeSelected: defaultOptionValues["Size"] || (this.props.product && this.props.product.options && !this.props.product.options.find(o => o.name === "Size")),
      isColorSelected: defaultOptionValues["Color"] || (this.props.product && this.props.product.options && !this.props.product.options.find(o => o.name === "Color")),
      addedToCartReminder: false,
      isAddPressed: false,
      isBoosterLimitReached: false,
    }
  }

  addVariantToCart(variantId, quantity) {
    const { isLoading } = this.state;
    if (isLoading) {
      return;
    }
    if (variantId) {
      const lineItemsToAdd = [{ variantId, quantity: parseInt(quantity, 10) }];
      const checkoutId = this.props.fetchCheckoutReducer.checkout.id;
      
      const { lineItems } = this.props.fetchCheckoutReducer.checkout;
      if (lineItems.length > 0) {
        for (let p of lineItems) {
          if (p.variant.id === variantId) {
            // check if booster limit is reached:
            if (p.variant.product.handle === "feaston-booster-pack" && p.quantity + quantity > 3) {
              this.setState({
                isBoosterLimitReached: true,
              }, () => {
                setTimeout(() => {
                this.setState({
                  isBoosterLimitReached: false,
                  })
                }, 3000);
              });
              return;
            }
          }
        }
      }

      this.setState({
        isLoading: true,
      }, () => {
        return client.checkout.addLineItems(checkoutId, lineItemsToAdd).then(res => {
          this.props.fetchCheckout(res);
          this.setState({
            addedToCartReminder: true,
            isAddPressed: true,
            isLoading: false
          }, () => {
            setTimeout(() => {
              this.setState({
                addedToCartReminder: false,
              })
            }, 3000);
          })
        });
      });
    } else {
      this.setState({
        isAddPressed: true,
      })
    }
  }

  handleSizeChange(size) {
    let selectedOptions = this.state.selectedOptions;
    selectedOptions["Size"] = size;
    const selectedVariant = client.product.helpers.variantForOptions(this.props.product, selectedOptions);
    this.setState({
      selectedVariant: selectedVariant,
      selectedSize: size,
      isSizeSelected: true,
      // selectedVariantImage: selectedVariant.attrs.image
    }, () => {
      const { onOptionChange } = this.props;
      if (onOptionChange) {
        onOptionChange(selectedOptions);
      }
    });
  }

  handleColorChange(color) {
    let selectedOptions = this.state.selectedOptions;
    selectedOptions["Color"] = color;
    const selectedVariant = client.product.helpers.variantForOptions(this.props.product, selectedOptions);
    this.setState({
      selectedVariant: selectedVariant,
      selectedColor: color,
      isColorSelected: true,
      // selectedVariantImage: selectedVariant.attrs.image
    }, () => {
      const { onOptionChange } = this.props;
      if (onOptionChange) {
        onOptionChange(selectedOptions);
      }
    });
  }

  componentWillMount() {
    let { cookies, product } = this.props;
    const checkoutId = cookies.get('checkoutID');
    if (checkoutId) {
      client.checkout.fetch(checkoutId).then((checkout) => {
        if (checkout.completedAt) {
          client.checkout.create().then((res) => {
            cookies.set('checkoutID', res.id, { path: '/', expires: new Date(Date.now() + 31556952000) });
          });
        }
      });
    } else {
      client.checkout.create().then((res) => {
        cookies.set('checkoutID', res.id, { path: '/', expires: new Date(Date.now() + 31556952000) });
      });
    }
    if (product.variants.length === 1) {
      this.setState({
        selectedVariant: product.variants[0],
      });
    }
  }

  render() {
    const { product, isHomeWidget } = this.props;
    const { descriptionHtml, productType } = product;
    let el = window.document.createElement('html');
    if (descriptionHtml) {
      el.innerHTML = descriptionHtml;
    }
    let title = el.getElementsByClassName('title')[0] ? el.getElementsByClassName('title')[0].innerHTML : product.title;

    const sizes = product.options.find(o => o.name === "Size") ? product.options.find(o => o.name == "Size").values : [];
    const colors = product.options.find(o => o.name === "Color") ? product.options.find(o => o.name == "Color").values : [];
    const { selectedVariant, isAddPressed, selectedColor, selectedSize } = this.state;

    const currencyAfterConversion = (price) => {
      return this.props.fetchCheckoutReducer?.checkout ?
        window.Currency.convert(price, this.props.fetchCheckoutReducer.checkout?.currencyCode, this.props.fetchSelectedCurrencyReducer.selectedCurrency).toFixed(2)
        :
        0
    };

    let isDescUnderTitle = false;
    const { handle } = product;
    if (handle === "feaston-pho") { isDescUnderTitle = true; }

    return (
      <div className="ProductWidgetGeneral">
        <style jsx>{`
          .ProductWidgetGeneral {
            position: fixed;
            bottom: 90px;
            top: 80px;
            right: 0;
            width: 20vw;
            min-width: 260px;
            padding: 0 25px 20px 25px;
            overflow-y: scroll !important;
          }

          .ProductWidgetGeneral .product-title-container {
            font-size: 18px;
            background: white;
            width: fit-content;
            text-align: center;
            margin: 20px 0;
            //padding: 0 10%;
            font-weight: bold;
          }

          .ProductWidgetGeneral .product-title-container .Feaston-product-title {
            margin-top: 10px;
            text-align: left;
          }
          
          .ProductWidgetGeneral .variant-container {
            display: flex;
            align-items: center;
            margin-bottom: 5px;
            font-weight: bold;
          }

          .ProductWidgetGeneral .variant-container .variants {
            width: 100%;
            display: flex;
            justify-content: center;
          }

          .ProductWidgetGeneral .variant-container .variant {
            width: 40px;
            cursor: pointer;
            font-weight: normal;
          }

          .ProductWidgetGeneral .color-variant-container{
            margin-top: 25px;
            justify-content: space-between;
          }

          .ProductWidgetGeneral .color-variant-container .color-variants{
            text-align: right;
          }

          .ProductWidgetGeneral .variant-container .color-variant{
            width: 100%;
            cursor: pointer;
            font-weight: normal;
          }

          .ProductWidgetGeneral .option-container .Product_option{
            background: white;
            border: none;
            border-radius: 0;
            font-size: 16px;
            margin: 0 5px 10px 5px;
          }
          .ProductWidgetGeneral select {
            text-align-last: center;
            -webkit-appearance: none;
            appearance: none;
            font-weight: bold;
            padding: 2px;
          }
          .ProductWidgetGeneral select::-ms-expand {
            display: none;
          }
          .ProductWidgetGeneral .variant-title {
            font-size: 12px;
            font-weight: bold;
          }
          .ProductWidgetGeneral .Product_buy {
            font-size: 10px;
            color: black;
            background: white;
            font-weight: bold;
            width: 100px;
            border-radius: 0;
            border-color: black;
            border: 1px solid;
            cursor: pointer;
            font-family: "Cousine";
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 4px 0 2px 0;
          }

          .ProductWidgetGeneral .variant-sold-out {
            color: red;
            font-size: 16px;
            font-weight: bold;
          }

          .ProductWidgetGeneral .reminder {
            color: rgb(227, 8, 28);
            font-weight: bold;
            text-align: left;
            margin-top: 20px;
          }

          .ProductWidgetGeneral .reminder.added-to-cart {
            color: green;
          }

          .ProductWidgetGeneral .price-button-container {
            display: flex;
            justify-content: space-between;
            margin: 30px 0;
          }

          .ProductWidgetGeneral .price-button-container .price {
            size: 30px;
            font-weight: bold;
            text-align: left;
            margin-right: 10px;
          }

          .ProductWidgetGeneral .price-button-container .product-widget-button {
            display: flex;
            align-items: center;
          }
          .ProductWidgetGeneral .General-desc {
            text-align: left;
            padding-bottom: 500px;
          }
          .ProductWidgetGeneral .General-desc a {
            word-break: break-all;
          }
          .ProductWidgetGeneral .product-function-container {
            position: fixed;
            bottom: 90px;
            right: 0;
            width: 20vw;
            min-width: 260px;
            padding: 20px;
            background: #fff;
          }
        `}</style>
        <div>
          {isDescUnderTitle ? null : <div className="General-desc" dangerouslySetInnerHTML={{ __html: descriptionHtml }} />}
          <div className="product-function-container">
            <div>
              <div className="product-title-container">
                <div className="Feaston-product-title">
                  {title.split('\\n').map(function (item) {
                    return (
                      <span key={item}>
                        {item.replace(/-/g, '‑')}
                        <br />
                      </span>
                    )
                  })}
                </div>
              </div>
              {isDescUnderTitle ? <div className="General-desc" style={{ paddingBottom: 0 }} dangerouslySetInnerHTML={{ __html: descriptionHtml }} /> : null}

              {sizes.length > 0 ? (
                <div className="variant-container">
                  <SizeChartPopup productType={productType}>
                    <div className="size-title">
                      SIZE
                    </div>
                  </SizeChartPopup>
                  <div className="variants">
                    {sizes.length > 0 ? sizes.map((s) => {
                      return (
                        <div className="variant" key={s.value} onClick={() => this.handleSizeChange(s.value)}>
                          {this.state.selectedSize == s.value ? "(" : ""}
                          {s.value}
                          {this.state.selectedSize == s.value ? ")" : ""}
                        </div>
                      )
                    }) : null}
                  </div>
                </div>
              ) : null}

              {colors.length > 0 ? (
                <div className="variant-container color-variant-container">
                  <div className="color-title">
                    COLOR
                  </div>
                  <div className="color-variants">
                    {colors.length > 0 ? colors.map((c) => {
                      return colors.length === 1 ? (
                        <div className="color-variant" key={c.value}>
                          {c.value}
                        </div>
                      ) : (
                        <div className="color-variant" key={c.value} onClick={() => this.handleColorChange(c.value)}>
                          {this.state.selectedColor == c.value ? "(" : ""}
                          {c.value}
                          {this.state.selectedColor == c.value ? ")" : ""}
                        </div>
                      );
                    }) : null}
                  </div>
                </div>
              ) : null}

              <div className="price-button-container">
                <div className="price">
                  {this.props.fetchSelectedCurrencyReducer.selectedCurrency}<br />
                  {currencyAfterConversion(product.variants[0].price?.amount)}
                </div>
                <div className="product-widget-button">
                  {product.availableForSale && ((selectedVariant && selectedVariant.available) || !selectedVariant) ?
                    <div className="Product_buy button" onClick={() => this.addVariantToCart(selectedVariant ? selectedVariant.id : null, 1)}>
                      ADD TO CART
                    </div>
                    :
                    <div className="variant-sold-out">
                      SOLD OUT

                      <EmailUs product_name={title} size={selectedSize} color={selectedColor} />

                    </div>}
                </div>
              </div>

              {isAddPressed && (!this.state.isSizeSelected || !this.state.isColorSelected) ?
                <div className="reminder">
                  SELECT YOUR {this.state.isSizeSelected ? "" : `SIZE${!this.state.isColorSelected ? "," : ""}`} {this.state.isColorSelected ? "" : "COLOR"}
                </div> : null}

              {this.state.addedToCartReminder ?
                <div className="reminder added-to-cart">
                  ADDED TO CART
                </div> : null}

              {this.state.isBoosterLimitReached ? 
                <div className="reminder">
                  Each customer may only purchase 3 boosters at a time.
                </div> : null}

              {product && product.handle === "andy-lau-lighter" ? (
                <div style={{ textAlign: "left" }}>
                  <p>
                    Select "Pick up" as delivery method.
                  </p>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  ...state
})

const mapDispatchToProps = dispatch => ({
  fetchCheckout: (checkout) => dispatch(fetchCheckout(checkout)),
})

export default withCookies(connect(mapStateToProps, mapDispatchToProps)(ProductWidgetGeneral));
