import {
  useParams
} from "react-router-dom";
import { connect } from 'react-redux';
import { includes } from 'lodash';
import HomeProductWidget from 'scenes/Home2021winter/HomeProductWidget';
import { Link } from 'react-router-dom';
import CategoryItem from "./CategoryItem";

function Category(props) {
  let { category } = useParams();
  const { fetchProductsReducer } = props;
  const { products } = fetchProductsReducer;
  if (!products) {
    return null;
  }
  let cate = {};
  cate.clothing = ['clothing', 'tee', 'hoodie', 'sweater', 'shirt', 'suit', 'shoes'];
  cate.sounds = ['sounds', 'cassette'];
  cate.items = ['items', 'slipmat', 'bags'];
  const categoryProducts = products.filter(p => includes(cate[category], p.productType.toLowerCase()));
  return (
    <div className="Category">
      <style jsx>{`
        .Category {
          width: 85%;
          display: flex;
          flex-wrap: wrap;
          padding-top: 200px;
          padding-bottom: 200px;
          margin: auto;
          justify-content: center;
        }
        .Category .categoryItem {
          width: 15vw;
          height: auto;
          padding: 5px 0;
          min-width: 150px;
          max-width: 250px;
        }
        .Category .categoryItem img{
          width: 100%;
          height: 100%;
        }
        .Category a {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0 10px;
        }
        .Category .home-row {
          display: flex;
          max-width: 1417px;
          margin: auto;
        }
        .Category .home-row>div:not(:last-child){
          padding-right: 90px;
        }
      `}</style>
      {/* {categoryProducts.map((p) => {

        return (
          <CategoryItem product={p} key={p.handle} />
        );
      })} */}
      {categoryProducts.map((p, index) => {
        // show two products in a row
        if (index % 2 === 0) {
          return (
            <div className="home-row home-row-1" key={index}>
              <HomeProductWidget p={p} />
              {categoryProducts[index + 1] && <HomeProductWidget p={categoryProducts[index + 1]} />}
            </div>
          );
        }
        return null;
      })}
    </div>
  )
}

const mapStateToProps = state => ({
  ...state
})

export default connect(mapStateToProps, {}) (Category);
