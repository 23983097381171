import React, { Component } from 'react';
import { connect } from 'react-redux';
import ScrollManager from "ScrollManager/index.jsx";
import { Link } from 'react-router-dom';
import OS from './OS.png';
import PORTRAIT01 from './portraits/IG_framed.001.png'; // P
import PORTRAIT02 from './portraits/IG_framed.002.png'; // P
import PORTRAIT03 from './portraits/IG_framed.003.png'; // P
import PORTRAIT04 from './portraits/IG_framed.004.png'; // P
import PORTRAIT05 from './portraits/IG_framed.005.png'; // P
import PORTRAIT06 from './portraits/IG_framed.006.png'; // B
import PORTRAIT07 from './portraits/IG_framed.007.png'; // B
import PORTRAIT08 from './portraits/IG_framed.008.png'; // B
import PORTRAIT09 from './portraits/IG_framed.009.png'; // B
import PORTRAIT10 from './portraits/IG_framed.010.png'; // B
import PORTRAIT11 from './portraits/IG_framed.011.png'; // G
import PORTRAIT12 from './portraits/IG_framed.012.png'; // G
import PORTRAIT13 from './portraits/IG_framed.013.png'; // G
import PORTRAIT14 from './portraits/IG_framed.014.png'; // G
import PORTRAIT15 from './portraits/IG_framed.015.png'; // G
import PORTRAIT_GROUP01 from './portraitsGroup/mobileLB_split-01.png'; // G
import PORTRAIT_GROUP02 from './portraitsGroup/mobileLB_split-02.png'; // G
import PORTRAIT_GROUP03 from './portraitsGroup/mobileLB_split-03.png'; // G
import PORTRAIT_GROUP04 from './portraitsGroup/mobileLB_split-04.png'; // G
import PortraitPopup from 'scenes/YearBookPage/PortraitPopup';
import { Helmet } from "react-helmet";
import LazyLoad, { forceCheck } from 'react-lazyload';

class MobileHome extends Component {

  constructor(props) {
    super(props);
    this.state = {
      portrait: null,
    };
    this.onPortraitClose = this.onPortraitClose.bind(this);
  }

  onPortraitClose() {
    this.setState({
      portrait: null,
    });
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
    setTimeout(() => {
      forceCheck();
      window.imageMapResize();
    }, 500);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll(event) {
    window.imageMapResize();
  }

  render() {
    const { portrait } = this.state;
    const viewportWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    const mobileSlideHeight = (1350 * viewportWidth) / 2313;
    const containerHieght = mobileSlideHeight * 29 + 250;
    const offset = 0;
    return (
      // <ScrollManager scrollKey="MobileHome" >
      //   {({ connectScrollTarget, ...props }) => 
      <div className="MobileHome">
        <Helmet>
          <title>FEASTON</title>
          {/* <meta name="description" content="FEASTON"/> */}
          <meta property="og:image" content="https://feaston.co/favicon.ico" />
          <meta property="og:title" content="FEASTON"/>
          <link rel="canonical" href="https://feaston.co" />
        </Helmet>
        <style jsx>{`
          .MobileHome {
            position: relative;
            height: auto;
            overflow-y: scroll;
            background: #fff;
            overflow-x: hidden;
            padding-bottom: 40px;
          }
          .MobileHome .lazyload-wrapper {
            display: inline-flex;
            //min-height: ${`${mobileSlideHeight}px`};
          }
          .MobileHome .homeBanner {
            max-width: 100%;
          }
          .MobileHome .coverBanner {
            max-width: 100%;
            width: auto;
          }
          .MobileHome .homeBanner img{
            width: 100%;
          }
          .MobileHome .last {
            margin-bottom: 120px;
          }
          .MobileHome .top {
            margin-top: 60px;
          }
          .MobileHome .homeBanner .banner, map area{
            outline: none;
          }
          .MobileHome .homeBanner-container {
            overflow-y: scroll;
            overflow-x: hidden;
            height: fit-content; //${`${containerHieght}px`}; //fit-content;
          }
          .MobileHome .homeBanner {
            overflow: hidden;
          }
          .MobileHome map {
            overflow: hidden;
          }
          .MobileHome .homeBanner-arrow-container {
            min-height: calc(100vh - 40px);
            display: flex;
            justify-content: center;
            align-items: center;
            min-height: -webkit-fill-available;
          }
          .MobileHome .homeBanner-arrow-container img {
            width: 100%;
          }
          .MobileHome .homeBanner-item-container {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 10px 30px;
            flex-direction: column;
          }
          .MobileHome .cover-wrapper {
            height: auto;
            min-height: calc(100vh - 40px);
          }
          .MobileHome .cover {
            min-height: calc(100vh - 40px);
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
          }
          .MobileHome .logoContainer {
            padding: 70px;
          }
          .MobileHome .schoolLogo {
            max-width: 100%;
          }
          .MobileHome .bigHead {
            max-width: 100%;
            width: 100%;
            height: auto;
            display: block;
          }
          .MobileHome .bigHead img {
            display: block;
          }
          .MobileHome .homeBanner-item-container .homeBanner-item-name {
            width: 100%;
            text-align: center;
            margin-top: 40px;
            font-size: 14px;
            font-weight: bold;
          }
          .MobileHome .feaston-home-banner {
            height: calc(100vh - 40px);
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;
            background: #FFCD06;
            margin-bottom: 100px;
            padding: 0px 40px;
          }
          .MobileHome .banner-container {
            margin-bottom: 170px;
          }
          .MobileHome .topBar {
            position: fixed;
            top: 0;
            font-weight: bold;
            font-size: 11px;
            height: 38px;
            background: #fff;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 1000;
          }
          .MobileHome .spacer {
            height: 50px;
          }
          .MobileHome .loading {
            display: none;
            width: 100%;
            height: 100vh;
            position: fixed;
            background: white;
            color: black;
            align-items: center;
            justify-content: center;
            z-index: 2;
          }
        `}</style>
          <LazyLoad offset={offset} height={'calc(100vh - 40px)'} once >
            <div className="cover-wrapper">
              <div className="cover">
                {/* <div className="logoContainer">
                  <img className="schoolLogo" src={OS} alt="" />
                </div> */}

                <div className="bigHeadContainer">
                  <img className="bigHead" src={PORTRAIT_GROUP01} useMap="#image-map_portrait_group01" alt="" />
                  <map name="image-map_portrait_group01">
                    <area onClick={() => { this.setState({ portrait: 11 })}} target="" alt="" title="" coords="85,1499,631,706" shape="rect" />
                    <area onClick={() => { this.setState({ portrait: 6 })}} target="" alt="" title="" coords="718,704,1265,1502" shape="rect" />
                    <area onClick={() => { this.setState({ portrait: 0 })}} target="" alt="" title="" coords="85,1514,633,2305" shape="rect" />
                    <area onClick={() => { this.setState({ portrait: 1 })}} target="" alt="" title="" coords="717,1517,1265,2306" shape="rect" />
                  </map>
                </div>

                <LazyLoad offset={offset} height={mobileSlideHeight} once >
                  <div className="bigHeadContainer">
                    <img className="bigHead" src={PORTRAIT_GROUP02} useMap="#image-map_portrait_group02" alt="" />
                    <map name="image-map_portrait_group02">
                      <area onClick={() => { this.setState({ portrait: 2 })}} target="" alt="" title="" coords="85,23,633,820" shape="rect" />
                      <area onClick={() => { this.setState({ portrait: 15 })}} target="" alt="" title="" coords="717,27,1267,821" shape="rect" />
                      <area onClick={() => { this.setState({ portrait: 8 })}} target="" alt="" title="" coords="95,836,644,1632" shape="rect" />
                      <area onClick={() => { this.setState({ portrait: 10 })}} target="" alt="" title=""  coords="730,838,1277,1632" shape="rect" />
                    </map>
                  </div>
                </LazyLoad>
                <LazyLoad offset={offset} height={mobileSlideHeight} once >
                  <div className="bigHeadContainer">
                    <img className="bigHead" src={PORTRAIT_GROUP03} useMap="#image-map_portrait_group03" alt="" />
                    <map name="image-map_portrait_group03">
                      <area onClick={() => { this.setState({ portrait: 9 })}} target="" alt="" title="" coords="94,25,643,830" shape="rect" />
                      <area onClick={() => { this.setState({ portrait: 4 })}} target="" alt="" title="" coords="729,26,1276,827" shape="rect" />
                      <area onClick={() => { this.setState({ portrait: 13 })}} target="" alt="" title="" coords="94,866,643,1656" shape="rect" />
                      <area onClick={() => { this.setState({ portrait: 5 })}} target="" alt="" title="" coords="729,866,1276,1655" shape="rect" />
                    </map>
                  </div>
                </LazyLoad>
                <LazyLoad offset={offset} height={mobileSlideHeight} once >
                  <div className="bigHeadContainer">
                    <img className="bigHead" src={PORTRAIT_GROUP04} useMap="#image-map_portrait_group04" alt="" />
                    <map name="image-map_portrait_group04">
                      <area onClick={() => { this.setState({ portrait: 7 })}} target="" alt="" title="" coords="85,44,632,843" shape="rect" />
                      <area onClick={() => { this.setState({ portrait: 14 })}} target="" alt="" title="" coords="718,42,1265,843" shape="rect" />
                      <area onClick={() => { this.setState({ portrait: 3 })}} target="" alt="" title="" coords="82,856,632,1663" shape="rect" />
                      <area onClick={() => { this.setState({ portrait: 12 })}} target="" alt="" title="" coords="718,859,1265,1667" shape="rect" />
                    </map>
                  </div>
                </LazyLoad>
                <PortraitPopup portrait={portrait} customMargin={'16px'} onClose={this.onPortraitClose} show={portrait != null} />
                {/* <LazyLoad offset={offset} height={mobileSlideHeight} once >
                  <img className="bigHead" src={PORTRAIT01} alt="" />
                </LazyLoad>
                <LazyLoad offset={offset} height={mobileSlideHeight} once >
                  <img className="bigHead" src={PORTRAIT11} alt="" />
                </LazyLoad>
                <LazyLoad offset={offset} height={mobileSlideHeight} once >
                  <img className="bigHead" src={PORTRAIT06} alt="" />
                </LazyLoad>

                <LazyLoad offset={offset} height={mobileSlideHeight} once >
                  <img className="bigHead" src={PORTRAIT02} alt="" />
                </LazyLoad>
                <LazyLoad offset={offset} height={mobileSlideHeight} once >
                  <img className="bigHead" src={PORTRAIT12} alt="" />
                </LazyLoad>
                <LazyLoad offset={offset} height={mobileSlideHeight} once >
                  <img className="bigHead" src={PORTRAIT07} alt="" />
                </LazyLoad>

                <LazyLoad offset={offset} height={mobileSlideHeight} once >
                  <img className="bigHead" src={PORTRAIT03} alt="" />
                </LazyLoad>
                <LazyLoad offset={offset} height={mobileSlideHeight} once >
                  <img className="bigHead" src={PORTRAIT13} alt="" />
                </LazyLoad>
                <LazyLoad offset={offset} height={mobileSlideHeight} once >
                  <img className="bigHead" src={PORTRAIT08} alt="" />
                </LazyLoad>

                <LazyLoad offset={offset} height={mobileSlideHeight} once >
                  <img className="bigHead" src={PORTRAIT04} alt="" />
                </LazyLoad>
                <LazyLoad offset={offset} height={mobileSlideHeight} once >
                  <img className="bigHead" src={PORTRAIT14} alt="" />
                </LazyLoad>
                <LazyLoad offset={offset} height={mobileSlideHeight} once >
                  <img className="bigHead" src={PORTRAIT09} alt="" />
                </LazyLoad>

                <LazyLoad offset={offset} height={mobileSlideHeight} once >
                  <img className="bigHead" src={PORTRAIT05} alt="" />
                </LazyLoad>
                <LazyLoad offset={offset} height={mobileSlideHeight} once >
                  <img className="bigHead" src={PORTRAIT15} alt="" />
                </LazyLoad>
                <LazyLoad offset={offset} height={mobileSlideHeight} once >
                  <img className="bigHead" src={PORTRAIT10} alt="" />
                </LazyLoad> */}
              </div>
            </div>
          </LazyLoad>
      </div>
      // </ScrollManager>
    );
  }
}

const mapStateToProps = state => ({
  ...state
})

export default connect(mapStateToProps, {}) (MobileHome);
