/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import { isMobile, browserName, mobileVendor, mobileModel, deviceType, osName } from "react-device-detect";
import { Link, useParams } from "react-router-dom";
import FeatureCollection from 'components/FeatureCollection';
import INDIAN_TRIP_BG from 'assets/IndianTripPopup/IndianTripBg.png';
import INDIAN_TRIP_THANK from 'assets/IndianTripPopup/IndianTripThankyou.png';
import { withCookies } from "react-cookie";

const IndianTripPopup = React.memo((props) => {
  let { cookies } = props;
  const trippedToIndia = cookies.get('trippedToIndia');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isButtonHovered, setIsButtonHovered] = useState(false);
  const [contact, setContact] = useState('');
  const [thankyou, setThankyou] = useState(false);
  
  // get all data in form and return object
  const getFormData = (form) => {
    var elements = form.elements;
    var honeypot;

    var fields = Object.keys(elements).filter(function(k) {
      if (elements[k].name === "honeypot") {
        honeypot = elements[k].value;
        return false;
      }
      return true;
    }).map(function(k) {
      if(elements[k].name !== undefined) {
        return elements[k].name;
      // special case for Edge's html collection
      }else if(elements[k].length > 0){
        return elements[k].item(0).name;
      }
    }).filter(function(item, pos, self) {
      return self.indexOf(item) == pos && item;
    });

    var formData = {};
    fields.forEach(function(name){
      var element = elements[name];
      
      // singular form elements just have one value
      formData[name] = element.value;

      // when our element has multiple items, get their values
      if (element.length) {
        var data = [];
        for (var i = 0; i < element.length; i++) {
          var item = element.item(i);
          if (item.checked || item.selected) {
            data.push(item.value);
          }
        }
        formData[name] = data.join(', ');
      }
    });

    // add form-specific values into the data
    formData.formDataNameOrder = JSON.stringify(fields);
    formData.formGoogleSheetName = form.dataset.sheet || "responses"; // default sheet name
    formData.formGoogleSendEmail
      = form.dataset.email || ""; // no email by default

    return {data: formData, honeypot: honeypot};
  }

  const loaded = () => {
    // bind to the submit event of our form
    var forms = window.document.querySelectorAll("form.gform");
    for (var i = 0; i < forms.length; i++) {
      forms[i].addEventListener("submit", handleFormSubmit, false);
    }
  };

  const handleFormSubmit = (event) => {  // handles form submit without any jquery
    event.preventDefault();           // we are submitting via xhr below
    setIsLoading(true);
    var form = event.target;
    var formData = getFormData(form);
    var data = formData.data;

    // If a honeypot field is filled, assume it was done so by a spam bot.
    if (formData.honeypot) {
      return false;
    }

    disableAllButtons(form);
    var url = form.action;
    var xhr = new XMLHttpRequest();
    xhr.open('POST', url);
    // xhr.withCredentials = true;
    xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    // xhr.setRequestHeader("Content-Type", "text/plain;charset=utf-8");
    xhr.onreadystatechange = () => {
        if (xhr.readyState === 4 && xhr.status === 200) {
          form.reset();
          var formElements = form.querySelector(".form-elements")
          if (formElements) {
            formElements.style.display = "none"; // hide form
          }
          setIsLoading(false);
          setThankyou(true);
          cookies.set('trippedToIndia', true);
        }
    };
    // url encode form data for sending as post data
    var encoded = Object.keys(data).map(function(k) {
        return encodeURIComponent(k) + "=" + encodeURIComponent(data[k]);
    }).join('&');
    xhr.send(encoded);
    console.log('encoded, ', encoded);
  }

  const disableAllButtons = (form) => {
    var buttons = form.querySelectorAll("button");
    for (var i = 0; i < buttons.length; i++) {
      buttons[i].disabled = true;
    }
  }

  const handleClose = () => {
    setDialogOpen(false);
    cookies.set('trippedToIndia', true);
  };

  const handleContactChange = (event) => {
    setContact(event.target.value);
  }

  const buttonState = (state) => {
    setIsButtonHovered(state);
  }

  const onBgClick = () => {
    if (thankyou) {
      setDialogOpen(false);
    }
  }

  const loadingSVG = (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" style={{ margin: "auto", background: isButtonHovered && !isLoading ? "white" : "rgb(0, 0, 0)", display: "block"}} width="18px" height="18px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
      <circle cx="50" cy="50" fill="none" stroke={isButtonHovered && !isLoading ? "#000000" : "#ffffff"} strokeWidth="10" r="35" strokeDasharray="164.93361431346415 56.97787143782138" transform="rotate(243.686 50 50)">
        <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="5s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
      </circle>
    </svg>
  );

  useEffect(() => {
    if (dialogOpen) {
      setTimeout(() => {
        loaded();
      }, 0);
    }
  // eslint-disable-next-line no-use-before-define
  }, [dialogOpen])

  useEffect(() => {
    setTimeout(() => {
      setDialogOpen(trippedToIndia ? false : true)
    }, 5000);
  // eslint-disable-next-line no-use-before-define
  }, [])

  return (
    <div className={`IndianTripPopup${isMobile ? ' isMobile' : ' isDesktop'}`}>
      <style jsx>{`
        .IndianTripDialog {
          font-family: 'Helventica Neue' !important;
        }
        .IndianTripPopup .triggerButton {
          font-size: 14px;
          //background: red;
          color: black;
          //border-radius: 4px;
          //border: 1px solid red;
          cursor: pointer;
          margin-top: 4px;
          outline: none;
          text-decoration: underline;
        }
        .IndianTripPopup .triggerButton:hover {
          background: white;
          color: red;
        }
        .IndianTripPopupFormElements {
          font-size: 14px;
          padding: 16px;
          max-width: 425px;
          margin: auto;
          position: absolute;
          top: ${isMobile ? '40%' : '30%'};
          left: ${isMobile ? '35%' : '50%'};
          transform: translate(-50%, -50%);
        }
        .IndianTripPopupFormElements input {
          box-sizing : border-box;
          //margin-top: 6px;
          width: 100%;
          line-height: 16px;
          outline: none;
          font-size: 16px;
        }
        .IndianTripPopupFormElements input:-webkit-autofill {
          -webkit-box-shadow: 0 0 0 30px white inset !important;
        }
        .IndianTripPopupFormElements .dialog-button {
          text-align: center;
          margin-top: 8px;
        }
        .IndianTripPopupFormElements .dialog-button button b {
          display: flex;
          width: 80px;
          margin: auto;
          align-items: center;
          justify-content: center;
        }
        .IndianTripPopupFormElements button {
          font-size: 16px;
          background: black;
          color: white;
          border-radius: 4px;
          border: 1px solid black;
          cursor: pointer;
          outline: none;
          pointer-events: ${isLoading ? "none" : "unset"};
        }
        .IndianTripPopupFormElements.isDesktop button:hover {
          background: white;
          color: black;
        }
        .IndianTripDialog div[role="dialog"] {
          position: fixed;
          width: 1440px;
          top: ${isMobile ? 'unset' : '80px'};
          margin: 0;
          max-width: 100%;
        }
        .IndianTripDialog .close {
          position: absolute;
          right: ${isMobile ? '12px' : '20px'};
          top: ${isMobile ? '12px' : '20px'};
          font-size: ${isMobile ? '14px' : '24px'};
          background: rgba(28, 164, 252);
          width: ${isMobile ? '24px' : '40px'};
          height: ${isMobile ? '24px' : '40px'};
          border-radius: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: white;
          font-weight: bold;
          cursor: pointer;
        }
      `}</style>
      {/* <div className="IndianTripPopup-button">
        <div className="triggerButton" onClick={this.handleClickOpen}>
          <b>Join Waiting List</b> 
        </div>
      </div> */}
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={dialogOpen} className="IndianTripDialog">
        <img style={{ maxWidth:"100%" }} src={thankyou ? INDIAN_TRIP_THANK : INDIAN_TRIP_BG} alt="" onClick={onBgClick}/>
        <form className="gform pure-form pure-form-stacked" method="POST"
          action="https://script.google.com/macros/s/AKfycbxq_dkVdocipREqGJlGi3m9txq__EkxkWTS6c-ZG7sJZ0OG9CLlD8nhYxqpl20546In/exec">

          <div className="form-elements IndianTripPopupFormElements">

            <div className="pure-group">
              <label htmlFor="contact">
                <b>Enter your email:</b>
                {/* We may send you notification when this item or/and related items are restocked.<br/> */}
              </label>
              <input id="contact" name="contact" value={contact} type={contact && contact !== '' && contact.includes('@') && !contact.includes('/') && !contact.includes(',') ? 'email' : 'text'} onChange={handleContactChange} //autoFocus
              required placeholder="Leave your email here"/>
            </div>

            {/* <fieldset className="pure-group" style={{ display:"none" }}>
              <label htmlFor="product_name">Selected Item: </label>
              <input id="product_name" name="product_name" placeholder={product_name} value={product_name || ""} readOnly/>
            </fieldset>

            <fieldset className="pure-group" style={{ display:"none" }}>
              <label htmlFor="color">Selected Color: </label>
              <input id="color" name="color" placeholder={color} value={color || ""} readOnly/>
            </fieldset>

            <fieldset className="pure-group" style={{ display:"none" }}>
              <label htmlFor="size">Selected Size: </label>
              <input id="size" name="size" placeholder={size} value={size || ""} readOnly/>
            </fieldset> */}

            <fieldset className="pure-group" style={{ display:"none" }}>
              <label htmlFor="device_type">Device Type: </label>
              <input id="device_type" name="device_type" placeholder={deviceType} value={deviceType || ""} readOnly/>
            </fieldset>

            <fieldset className="pure-group" style={{ display:"none" }}>
              <label htmlFor="os">OS Name: </label>
              <input id="os" name="os" placeholder={osName} value={osName || ""} readOnly/>
            </fieldset>

            <fieldset className="pure-group" style={{ display:"none" }}>
              <label htmlFor="browser">Browser Name: </label>
              <input id="browser" name="browser" placeholder={browserName} value={browserName || ""} readOnly/>
            </fieldset>

            <fieldset className="pure-group" style={{ display:"none" }}>
              <label htmlFor="mobile_device">Mobile Device: </label>
              <input id="mobile_device" name="mobile_device" placeholder={`${mobileVendor}, ${mobileModel}`} value={`${mobileVendor}, ${mobileModel}`} readOnly/>
            </fieldset>

            <fieldset className="pure-group honeypot-field" style={{ display:"none" }}>
              <label htmlFor="honeypot">To help avoid spam, utilize a Honeypot technique with a hidden text field; must be empty to submit the form! Otherwise, we assume the user is a spam bot.</label>
              <input id="honeypot" type="text" name="honeypot" defaultValue="" />
            </fieldset>

            <div className="dialog-button" >
              <button className="button-success pure-button button-xlarge" onMouseEnter={() => buttonState(true)} onMouseLeave={() => buttonState(false)}>
                <b>{isLoading ? loadingSVG : "SEND"}</b>
              </button>
            </div>
          </div>

          <div className="close" onClick={handleClose}>
            X
          </div>

        </form>
      </Dialog>
    </div>
  )
})

export default withCookies(IndianTripPopup);
// const mapStateToProps = state => ({
//   ...state
// })

// export default connect(mapStateToProps, {})(IndianTripPopup);