import { connect } from 'react-redux';
import HomeProductWidget from 'scenes/Home2021winter/HomeProductWidget';
import DOMPurify from 'dompurify'

function FeatureCollection(props) {
  const { fetchFeatureCollectionReducer, collectionProps } = props;
  const { collection = {} } = fetchFeatureCollectionReducer;
  const { products = [] } = collectionProps || collection;
  const { title, descriptionHtml } = collectionProps || collection;

  const sanitizedDesc = () => ({
    __html: DOMPurify.sanitize(descriptionHtml, { FORBID_TAGS: ['img'] })
  })
  return (
    <div className="FeatureCollection">
      <style jsx>{`
        .FeatureCollection {
          width: 100vw;
          min-width: 1150px;
          z-index: 0;
          position: relative;
        }
        .FeatureCollection .FeatureItems {
          display: block;
          width: 100%;
          max-width: 1078px;
          margin: 40px auto auto;
          padding-bottom: 80px;
        }
      `}</style>
      {descriptionHtml && <div dangerouslySetInnerHTML={sanitizedDesc()} />}
      <div className="FeatureItems">
        {products.map((p, index) => {
          return <HomeProductWidget p={p} key={index} />;
        })}
      </div>
    </div>
  );
}

const mapStateToProps = state => ({
  ...state
})

export default connect(mapStateToProps, {})(FeatureCollection);