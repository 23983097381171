import { useEffect } from 'react';
import {
  useParams
} from "react-router-dom";
import { fetchIsNavigationExpanded } from 'actions/fetchIsNavigationExpanded';
import { connect } from 'react-redux';
import { includes } from 'lodash';
import { Link } from 'react-router-dom';
import ORANGE_PUMP from './orangePump.png';
import MobileHomeProductWidget from '../MobileHome2021winter/MobileHomeProductWidget';

function MobileCategory(props) {
  let { category = 'clothing' } = useParams();
  const { fetchProductsReducer, fetchIsNavigationExpanded } = props;
  const { products } = fetchProductsReducer;

  useEffect(() => {
    fetchIsNavigationExpanded(false);
  }, [category]);

  if (!products) {
    return null;
  }
  let cate = {};
  cate.clothing = ['clothing', 'tee', 'hoodie', 'sweater', 'shirt', 'suit', 'shoes'];
  cate.sounds = ['sounds', 'cassette'];
  cate.items = ['items', 'slipmat', 'bags'];
  const categoryProducts = products.filter(p => includes(cate[category], p.productType.toLowerCase()));
  return (
    <div className="MobileCategory">
      <style jsx>{`
        .MobileCategory {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          padding-top: 70px;
          padding-bottom: 60px;
        }
        .MobileCategory .categoryItem {
          width: 40vw;
          height: auto;
        }
        .MobileCategory .categoryItem img{
          width: 100%;
          height: 100%;
        }
        .MobileCategory a {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0 5vw;
        }
        .MobileCategory .productListBanner {
          width: 100%;
          height: auto;
        }
        .MobileCategory .homeBanner-item-container {
          padding: 50px 30px;
        }
      `}</style>
      {/* <img className='productListBanner' src={ORANGE_PUMP} alt='Product_list_banner' /> */}
      {/* {categoryProducts.map((p) => {

        return (
          <Link to={`/${p.handle}`} key={p.handle}>
            <div className="categoryItem">
              <img src={p.images[0].src} alt="" />
            </div>
          </Link>
        );
      })} */}
      {categoryProducts.map((p, index) => {
          return (
            <MobileHomeProductWidget p={p} key={index}/>
          );
        }
      )}
    </div>
  )
}

const mapStateToProps = state => ({
  ...state
})

const mapDispatchToProps = (dispatch) => ({
  fetchIsNavigationExpanded: (bool) => dispatch(fetchIsNavigationExpanded(bool))
})

export default connect(mapStateToProps, mapDispatchToProps)(MobileCategory);
