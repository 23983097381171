import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'withRouter';
import categories from '../../../../data/categories';
import LMMG_LOGO from 'assets/LMMG/lmmgLogo.png';
import LMMG_FRAME from 'assets/LMMG/lmmgFrame.png';
import LM_GUY from 'assets/LMMG/lmGuyWhiteHands.png';
import { Link } from 'react-router-dom';
import MobileDescContainer from '../../../components/MobileDescContainer';

class LMMG extends Component {

  constructor(props) {
    super(props);
    this.state = {
      productsInProject: [],
      project: "lmmg",
    };
    this.initiateLoadedProducts = this.initiateLoadedProducts.bind(this);
  }

  initiateLoadedProducts(products) {
    const project_url = this.state.project;
    let temp_product_array = [];
    if (products) {
      for (let c of categories[project_url].products) {
        for (let p of products) {
          if (c === p.handle) {
            temp_product_array.push(p);
            break;
          } else if (c.image) {
            temp_product_array.push(c);
            break;
          }
        }
      }
      this.setState({
        productsInProject: temp_product_array,
      })
    }
  }

  componentWillMount() {
    this.initiateLoadedProducts(this.props.fetchProductsReducer.products);
  }

  componentWillReceiveProps(nextProps) {
    this.initiateLoadedProducts(nextProps.fetchProductsReducer.products);
  }

  render() {
    const { productsInProject } = this.state;
    return (
      <div className="LMMG web-page">
        <style jsx>{`
          .LMMG {
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .LMMG div {
            overflow-y: scroll;
          }
          .LMMG .LMMG-mid {
            font-weight: bold;
            font-size: 28px;
            display: inline-block;
          }
          .LMMG .LMMG-mid .LMMG-mid-inner {
            
          }
          .LMMG .LMMG-mid .LMMG-scroll-mid {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .LMMG .LMMG-mid .LMMG-scroll-mid.LMMG-scroll-mid-product-img {
            padding: 0 60px;
          }
          .LMMG .LMMG-mid img {
            width: 100%;
            max-height: 100%;
            object-fit: cover;
          }
          .LMMG .LMMG-product-container {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: relative;
            cursor: pointer;
            border-bottom: 1px solid black;
          }
          .LMMG .LMMG-product-container .LMMG-product-img {
            width: 50vw;
            display: flex;
            align-items: center;
            justify-content: center;
            border-right: 1px solid black;
            padding: 5px;
          }
          .LMMG .LMMG-product-container img {
            width: 80%;
            max-height: 90%;
          }
          .LMMG .LMMG-product-container .LMMG-product-title {
            width: 50vw;
            font-size: 20px;
            padding: 2px;
          }
          .LMMG .Top-item {
            flex: 0 0 auto;
            align-items: center;
            display: flex;
          }
          .LMMG .LMMG-logo, .LMMG-white-guy-container img {
            margin: 0 20px;
          }
          .LMMG .LMMG-logo img {
            width: 40px;
          }
          .LMMG .LMMG-white-guy-container img {
            width: 100px;
          }
          .LMMG .LMMG-desc {
            text-align: left;
            width: 160px;
            padding-right: 20px;
            font-size: 14px;
          }
        `}</style>
        <div className="mobile-web-mid LMMG-mid">
          <div className="LMMG-mid-inner">
            {productsInProject && productsInProject.length > 0 ? (
                  productsInProject.map((product) => {
                    return (
                      <Link key={product.id} to={`/project/lmmg/${product.handle}`}>
                        <div className="LMMG-product-container">
                          <div className="LMMG-product-img">
                            <img src={product.images[0].src} alt="" />
                          </div>
                          <div className="LMMG-product-title">
                            {product.title}
                          </div>
                        </div>
                      </Link>
                    )
                  })
                ) : null}
          </div>
        </div>
        <MobileDescContainer>
            <div className="Top-item LMMG-logo">
              <img src={LMMG_LOGO} alt="LMMG" />
            </div>
            <div className="Top-item LMMG-desc">
              The classic logo was printed in red for the first time during Lunar New Year 2019
            </div>
            <div className="Top-item LMMG-white-guy-container">
                <img src={LM_GUY} alt="" />
                <div className="LMMG-desc LMMG-white-guy-desc">
                  The classic logo was printed in red for the first time during Lunar New Year 2019
                </div>
            </div>
        </MobileDescContainer>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  ...state
})

export default withRouter(connect(mapStateToProps, {}) (LMMG));
