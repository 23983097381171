import React, { Component, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  useParams
} from "react-router-dom";
import { Link } from 'react-router-dom';
import { withRouter } from 'withRouter';
import PanelRight from 'components/PanelRight';
import { Helmet } from "react-helmet";
import HomeProductWidget from './HomeProductWidget';
import { includes } from 'lodash';
import LazyLoad from 'react-lazyload';
import IMG01_FULL from "./assets/1_full.png";
import IMG02 from "./assets/2_mid.png";
import IMG03 from "./assets/3_side.png";
import IMG04 from "./assets/4_side.png";
import IMG05 from "./assets/5_mid.png";
import IMG06_FULL from "./assets/6_full.png";
import IMG07 from "./assets/7_mid.png";
import IMG08 from "./assets/8_mid.png";
import IMG09 from "./assets/9_side.png";
import IMG10 from "./assets/10_side.png";
import IMG11 from "./assets/11_mid.png";
import IMG12_FULL from "./assets/12_full.png";
import IMG13_FULL from "./assets/13_full.png";
import IMG14 from "./assets/14_mid.png";
import IMG15 from "./assets/15_mid.png";
import IMG16_FULL from "./assets/16_mid.png";
import FeatureCollection from 'components/FeatureCollection';

function Home(props) {
  let { category } = useParams();
  const { fetchProductsReducer } = props;
  const { products } = fetchProductsReducer;

  const handleScroll = (event) => {
    window.imageMapResize();
  }

  useEffect(() => {
    window.imageMapResize();
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    }
  }, []);

  if (!products) {
    return null;
  }

  const viewportWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
  const slideHeight = Math.floor((1920 * viewportWidth) / 3072);

  let clothing = ['clothing', 'tee', 'hoodie', 'sweater', 'shirt', 'suit', 'shoes'];
  const categoryProducts = products.filter(p => includes(clothing, p.productType.toLowerCase()));

  return (
    <div className="winter2021">
      <Helmet>
        <title>FEASTON</title>
        {/* <meta name="description" content="FEASTON"/> */}
        <meta property="og:image" content="https://feaston.co/favicon.ico" />
        <meta property="og:title" content="FEASTON" />
        <link rel="canonical" href="https://feaston.co" />
      </Helmet>
      <style jsx>{`
        .HomePageBanner {
          overflow-y: auto;
          display: flex;
        }
        .winter2021 {
          position: fixed;
          left: 202px;
          right: 0;
        }
        .winter2021 .homeCover {
          position: relative;
          display: flex;
          background: white;
          align-items: center;
          margin: 0 auto;
          //min-height: 880px;
        }
        .winter2021 .homeCover .homeCover-inner {
          display: flex;
          flex-direction: column;
          align-items: center;
          height: 100%;
          width: 100%;
        }
        .winter2021 .homeCover .row {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        .winter2021 .home-row {
          display: flex;
          max-width: 1417px;
          margin: auto;
        }
        .winter2021 .home-row>div:not(:last-child){
          padding-right: 90px;
        }
        .winter2021 .homeCover .row>div:not(:last-child){
          padding-right: 90px;
        }
        .winter2021 .homeCover .row img:not(:last-child){
          padding-right: 90px;
        }
        .winter2021 .lazyload-wrapper {
          min-height: ${`${slideHeight}px`};
        }
        .winter2021 .feaston-home {
          height: calc(100vh - 80px);
          overflow: hidden;
        }
        .winter2021 .row img {
          width: 100%;
        }
        .winter2021 .rowFull {
          width: 100%;
          height: 100vh;
        }
        .winter2021 .home-row-2>div:not(:last-child) img {
          width: 440px;
        }
        .winter2021 .home-row-2>div:last-child img {
          width: 520px;
        }
        .winter2021 .home-row-4 div:not(:last-child) img {
          width: 480px;
        }
        .winter2021 .width500 {
          width: 500px;
        }
        .winter2021 .width500 img {
          width: 100%;
        }
        .winter2021 .rowHeight {
          height: 100vh;
        }
        .winter2021 .rowHeight img {
          height: 100%;
          width: auto;
        }
        .winter2021 .home-row-3 div:not(:last-child) img {
          width: 460px;
        }
      `}</style>
      <div className="feaston-home">
        <div className="homeCover">
          <div className="homeCover-inner">
          <FeatureCollection />
          {/* {categoryProducts.map((p, index) => {
            // show two products in a row
            if (index % 2 === 0) {
              return (
                <div className="home-row home-row-1" key={index}>
                  <HomeProductWidget p={p} />
                  {categoryProducts[index + 1] && <HomeProductWidget p={categoryProducts[index + 1]} />}
                </div>
              );
            }
            return null;
          })} */}


            {/* <div className="row row1 rowFull">
              <img className="" src={IMG01_FULL} alt="" />
            </div>

            <div className="home-row home-row-1">
              <HomeProductWidget url="faestonHood" />
              <HomeProductWidget url="emperorHood" />
            </div>

            <div className="row row2">
              <div style={{ width: '600px' }}>
                <img className="homeBanner product-temp" src={IMG02} alt="" />
              </div>
            </div>

            <div className="home-row home-row-2">
              <HomeProductWidget url="portsmouth" />
              <HomeProductWidget url="jazzHood" />
            </div>

            <div className="row row3">
              <HomeProductWidget url="emperorSweat" />
              <div className="width500">
                <img className="homeBanner product-temp" src={IMG03} alt="" />
              </div>
            </div>

            <div className="home-row">
              <div className="width500" style={{ position: 'relative' }}>
                <img className="homeBanner product-temp" style={{ position: 'absolute', top: 0, left: '-100px', width: '90%' }} src={IMG04} alt="" />
              </div>
              <HomeProductWidget url="singularityHood" />
            </div>

            <div className="row row4 rowHeight">
              <img className="homeBanner product-temp" src={IMG05} alt="" />
            </div>

            <div className="home-row">
              <HomeProductWidget url="esPortrait" />
              <HomeProductWidget url="P" />
            </div>

            <div className="row row5">
              <img className="homeBanner product-temp" src={IMG06_FULL} alt="" />
            </div>

            <div className="home-row home-row-4">
              <HomeProductWidget url="california" />
              <HomeProductWidget url="liberia" />
            </div>

            <div className="row row6 rowHeight">
              <img className="homeBanner product-temp" src={IMG07} alt="" />
            </div>

            <div className="home-row home-row-3">
              <HomeProductWidget url="fsports" />
              <HomeProductWidget url="bsSmile" />
            </div>

            <div className="row row7 rowHeight">
              <img className="homeBanner product-temp" src={IMG08} alt="" />
            </div>

            <div className="home-row">
              <div className="width500" style={{ position: 'relative' }}>
                <img className="homeBanner product-temp" style={{ position: 'absolute', top: 0, left: '-125px' }} src={IMG09} alt="" />
              </div>
              <HomeProductWidget url="feelington" />
            </div>
            
            <div className="row row8">
              <HomeProductWidget url="healington" />
              <HomeProductWidget url="dj_rothschild_tee" />
            </div>

            <div className="home-row">
              <HomeProductWidget url="examiners_tee" />
              <div className="width500" style={{ position: 'relative' }}>
                <img className="homeBanner product-temp" style={{ position: 'absolute', top: 0, left: '120px', width: '90%'}} src={IMG10} alt="" />
              </div>
            </div>

            <div className="row row9">
              <div style={{ width: '800px' }}>
                <img className="homeBanner product-temp" src={IMG11} alt="" />
              </div>
            </div>

            <div className="home-row">
              <HomeProductWidget url="opium_mug" />
              <HomeProductWidget url="imported_cap" />
            </div>

            <div className="row row10">
              <img className="homeBanner product-temp" src={IMG12_FULL} alt="" />
            </div>

            <div className="home-row">
              <HomeProductWidget url="opium_socks" />
              <HomeProductWidget url="3107_hood" />
            </div>

            <div className="row row11">
              <img className="homeBanner product-temp" src={IMG13_FULL} alt="" />
            </div>

            <div className="home-row">
              <HomeProductWidget url="opium_tee" />
              <HomeProductWidget url="96km_h_tee" />
            </div>

            <div className="row row12">
              <div style={{ width: '1000px' }}>
                <img className="homeBanner product-temp" src={IMG14} alt="" />
              </div>
            </div>

            <div className="home-row">
              <HomeProductWidget url="scs_tee" />
              <HomeProductWidget url="feaston_faeston_slipmat" />
            </div>

            {/* <div className="row row13">
              <img className="homeBanner product-temp" src={IMG15} alt="" />
            </div> */}

            {/* <div className="row row14" style={{ width: 'calc(66% - 100px)', paddingBottom: '50px' }}>
              <img className="homeBanner product-temp" src={IMG16_FULL} alt="" />
            </div> */}
{/* 
            <div className="row row15">
              
            </div>

            <div className="row row16 rowFull">
              
            </div> */}

          </div>
        </div>
      </div>
      <PanelRight noBorder noBackground atHome/*withPath*/ />
    </div>
  );
}

const mapStateToProps = state => ({
  ...state
})

export default withRouter(connect(mapStateToProps, {})(Home));
