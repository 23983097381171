import React, {Component} from 'react';
import { client } from 'shopify';
import { isEmpty } from 'functions.js';

class CartVariantSelector extends Component {

  constructor(props) {
    super(props);
    let defaultOptionValues = {};
    if (!isEmpty(props.line_item)) {
      props.line_item.variant.selectedOptions.forEach((selector) => {
          defaultOptionValues[selector.name] = selector.value;
      });
    }
    this.state = {
      selectedOptions: defaultOptionValues,
    }
  }

  render() {
    const { product, option } = this.props;

    let unavailableValues = [];
    let selectedOptions = this.state.selectedOptions;
    for (let value of option.values) {
      selectedOptions[option.name] = value.value;
      const selectedVariant = client.product.helpers.variantForOptions(product, selectedOptions);
      if (selectedVariant && !selectedVariant.available) {
        unavailableValues.push(value.value);
      }
    }
    
    return (
        <div>
          <style jsx>{`
            .CartVariantSelector {
              background: transparent;
              border: none;
              -moz-appearance: none;
              -webkit-appearance: none;
              cursor: pointer;
              //width: 20px !important;
              color: white;
              font-size: 15px;
              margin: 0; // for safari
            }

            .CartVariantSelector:focus {
              outline:none;
            }
          `}</style>
          <select
            className="CartVariantSelector"
            name={this.props.option.name}
            key={this.props.option.name}
            onChange={this.props.handleOptionChange}
            defaultValue={this.props.selected}
          >
            {this.props.option.values.map((value) => {
              return (
                <option disabled={unavailableValues.includes(value.value)} value={value} key={`${this.props.option.name}-${value}`}>{`${value}`}</option>
              )
            })}
          </select>
        </div>
    );
  }
}

export default CartVariantSelector;
