import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetchIsNavigationExpanded } from '../../../actions/fetchIsNavigationExpanded';
import SHOP_IMG from "assets/Navigation/shop.png";
import CLOTHING_IMG from "assets/Navigation/clothing.png";
import SOUNDS_IMG from "assets/Navigation/sounds.png";
import ITEMS_IMG from "assets/Navigation/items.png";
import EVENTS_IMG from "assets/Navigation/events.png";
import UPCOMING_IMG from "assets/Navigation/upcoming.png";
import PAST_IMG from "assets/Navigation/past.png";
import STOCKIST_IMG from "assets/Navigation/stockist.png";
import CONTACT_IMG from "assets/Navigation/contact.png";
import ARCHIVES_IMG from "assets/Navigation/archives.png";
import ARCHIVES_WHITE_IMG from "assets/Navigation/archives_white.png";
import ABOUT_IMG from "assets/Navigation/about_tinified.png";

function NavigationOptions(props) {
  const { fetchFeatureCollectionReducer, show } = props;
  const { collection } = fetchFeatureCollectionReducer;
  const isGreatReset = !collection || !collection?.product || collection?.products?.length <= 0;
  const pathname = window.location.pathname;
  const [showShop, setShowShop] = useState(false);
  const [showEvent, setShowEvent] = useState(false);
  const openShop = () => {
    setShowEvent(false);
    setShowShop(!showShop);
  }
  const openEvent = () => {
    setShowShop(false);
    setShowEvent(!showEvent);
  }

  useEffect(() => {
    setShowEvent(false)
  }, [show]);

  return (
    <div className={`MobileOptions ${!show ? "hide" : "show"}`}>
      <style jsx>{`
        .MobileNavigation .MobileOptions {
          display: flex;
          width: ${show ? '100%' : '0%'};
          font-size: 14px;
          overflow: hidden;
          transition-property: all; 
          transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
          transition-duration: 500ms;
          z-index: 9999;
        }
        .MobileNavigation .MobileOptions.hide {
          height: 0;
        }
        .MobileNavigation .MobileOptions .none {
          display: none;
        }
        .MobileNavigation .MobileOptions .option {
          margin: 0 4px;
          margin-bottom: 16px;
        }
        .MobileNavigation .MobileOptions .option_other {
          margin-top: 6px;
        }
        .MobileNavigation .MobileOptions .option_background {
          background-size: contain;
          background-repeat: no-repeat;
          width: 100px;
          height: 18px;
          display: flex;
        }
        .MobileNavigation .MobileOptions .option_shop {
          background-image: url(${SHOP_IMG});
          display: ${isGreatReset ? 'none' : 'block'};
        }
        .MobileNavigation .MobileOptions .option_events {
          background-image: url(${EVENTS_IMG});
        }
        .MobileNavigation .MobileOptions .option_stockist {
          background-image: url(${STOCKIST_IMG});
        }
        .MobileNavigation .MobileOptions .option_contact {
          background-image: url(${CONTACT_IMG});
        }
        .MobileNavigation .MobileOptions .option_archives {
          background-image: url(${!collection?.products?.length && pathname === "/" ? ARCHIVES_WHITE_IMG : ARCHIVES_IMG});
          background-size: 75px;
          background-position-x: -7px;
          background-position-y: -11px;
        }
        .MobileNavigation .MobileOptions .option_about {
          background-image: url(${ABOUT_IMG}});
          background-size: 75px;
          background-position-x: -7px;
          background-position-y: -11px;
        }
        .MobileNavigation .MobileOptions .column {
          padding-right: 24px;
          text-align: left;
          overflow: hidden;
          transition-property: all; 
          transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
          transition-duration: 500ms;
        }
        .MobileNavigation .MobileOptions .column.right.hide {
          height: 0;
        }
        .MobileNavigation .option {
          width: auto;
          height: 18px;
          max-width: 100%;
          max-height: 100%;
        }
        .MobileNavigation .option img {
          width: auto;
          max-width: 100%;
          height: 100%;
          max-height: 100%;
        }
      `}</style>
      <div className="column left">
        <Link to="/">
          <div className="option option_other option_background option_shop">
            {/* <img src={SHOP_IMG} alt="" /> */}
          </div>
        </Link>
        <Link to="/archives">
          <div className="option option_other option_background option_archives">
          </div>
        </Link>
        <Link to="/about">
          <div className="option option_other option_background option_about">
            <img src={ABOUT_IMG} alt="" />
          </div>
        </Link>
        {/* <div className="option option_other option_background option_events" onClick={openEvent}>
        </div>
        <Link to="/stockist">
          <div className="option option_other option_background option_stockist">
          </div>
        </Link>
        <Link to="/contact">
          <div className="option option_other option_background option_contact">
          </div>
        </Link> */}
      </div>
      <div className={`column right ${showShop || showEvent ? "show" : "hide"}`}>
        {showShop ? (
          <>
            <Link to="/shop/clothing">
              <div className="option option_other">
                <img src={CLOTHING_IMG} alt="" />
              </div>
            </Link>
            <Link to="/shop/sounds">
              <div className="option option_other">
                <img src={SOUNDS_IMG} alt="" />
              </div>
            </Link>
            <Link to="/shop/items">
              <div className="option option_other">
                <img src={ITEMS_IMG} alt="" />
              </div>
            </Link>
          </>
        ) : null}
        {showEvent ? (
          <>
            <Link to="/events/upcoming">
              <div className="option option_other">
                <img src={UPCOMING_IMG} alt="" />
              </div>
            </Link>
            <Link to="/events/past">
              <div className="option option_other">
                <img src={PAST_IMG} alt="" />
              </div>
            </Link>
          </>
        ) : null}
      </div>
      {/* <MobileHeaderCart /> */}
    </div>
  )
}

const mapStateToProps = state => ({
  ...state
})

const mapDispatchToProps = (dispatch) => ({
  fetchIsNavigationExpanded: (isExpanded) => dispatch(fetchIsNavigationExpanded(isExpanded)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NavigationOptions);