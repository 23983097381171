import { Fragment, useEffect, useRef, useState } from 'react';
import { fetchIsNavigationExpanded } from 'actions/fetchIsNavigationExpanded';
import { connect } from 'react-redux';
import { isWindows } from 'react-device-detect';
import BG_01 from "./background/mobFront-01.png";
import BG_02 from "./background/jpMob-02.png";
import BG_03 from "./background/mobFront-03.png";
import BG_04 from "./background/mobFront-04.png";
import BG_05 from "./background/mobFront-05.png";
import BG_06 from "./background/mobFront-06.png";
import BG_07 from "./background/mobFront-07.png";
import BG_08 from "./background/mobFront-08.png";

function MobileHome2022June(props) {
  const mobileHomeRef = useRef(null);
  const [activeBg, setActiveBg] = useState(0);
  const BG_IMGS = [
    BG_01,
    BG_02,
    BG_03,
    BG_04,
    BG_05,
    BG_06,
    BG_07,
    BG_08
  ];
  const { fetchIsNavigationExpanded } = props;

  useEffect(() => {
    fetchIsNavigationExpanded(true);
    let n = 0;
    const changeBgLoop = setInterval(() => {
      setActiveBg(n);
      // mobileHomeRef.current.style.backgroundImage = BG_IMGS[n];
      n = n === 7 ? 0 : n + 1;
    }, 3500);
    return () => clearInterval(changeBgLoop);
  }, []);

  return (
    <div className="MobileHome2022June" ref={mobileHomeRef}>
      {/* <link rel="preload" href={BG_IMGS[0]} as="image" />
      <link rel="preload" href={BG_IMGS[1]} as="image" />
      <link rel="preload" href={BG_IMGS[2]} as="image" />
      <link rel="preload" href={BG_IMGS[3]} as="image" />
      <link rel="preload" href={BG_IMGS[4]} as="image" />
      <link rel="preload" href={BG_IMGS[5]} as="image" />
      <link rel="preload" href={BG_IMGS[6]} as="image" />
      <link rel="preload" href={BG_IMGS[7]} as="image" /> */}
      <style jsx>{`
        html, body {
          overflow: hidden;
          position: relative;
          height: 100%;
        }
        .MobileHome2022June {
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
        }
        .MobileHome2022June .mobileBackground{
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-size: auto 100%;
          background-position: right bottom;
          background-repeat: no-repeat;
          overflow-y: hidden;
          display: none;
        }
        .MobileHome2022June .mobileBackground.show {
          display: block;
        }
        .MobileHome2022June .bg1 {
          background-position: top;
        }
        .MobileHome2022June .bg1 {
          background-size: cover;
        }
        .MobileHome2022June .bg5 {
          background-size: contain;
          background-position: center;
        }
      `}</style>
      {BG_IMGS.map((img, index) => {
        return (
          <Fragment key={`fragment${img}`}>
            <link key={`link${img}`} rel="preload" href={img} as="image" />
            <div key={img} style={{ backgroundImage: `url(${img})` }} className={`mobileBackground ${`bg${index}`}${activeBg === index ? " show" : "" }`} />
          </Fragment>
        )
      })}
    </div>
  )
}

const mapStateToProps = state => ({
  ...state
})

const mapDispatchToProps = (dispatch) => ({
  fetchIsNavigationExpanded: (bool) => dispatch(fetchIsNavigationExpanded(bool))
})

export default connect(mapStateToProps, mapDispatchToProps)(MobileHome2022June);
