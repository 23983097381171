import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'withRouter';
import { Link } from 'react-router-dom';
import { some } from 'lodash';

class MobileProductPathContainer extends Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { children, withPath, location, fetchProductsReducer } = this.props;
    const { pathname } = location;
    const { products } = fetchProductsReducer;
    const pathArray = "/home".concat(pathname).replace('/', "").split('/');
    return (
      <div className="MobileProductPathContainer">
        <style jsx>{`
          .MobileProductPathContainer {
            background: white;
            z-index: 1000;
            margin-bottom: 80px;
            display: inline-block;
            width: 100%;
          }
          .MobileProductPathContainer .navigation-list {
            padding: 25px;
            text-align: left;
          }
          .MobileProductPathContainer .navigation-path {
            white-space: nowrap;    
            font-size: 14px;
            font-weight: bold;
          }
        `}</style>
        {
          withPath ? (
            <div className="navigation-path">
              <div className="navigation-list">
                {pathArray.length > 0 ? pathArray.map((path_name, i) => {
                  let path = '/';
                  for (let x = 0; x <= i; x++) {
                    if (pathArray[x] !== "home") {
                      path = path.concat(`${pathArray[x]}${x === i ? "" : "/"}`);
                    }
                  }
                  let pathName = path_name;
                  if (pathName == "lmmg") {
                    pathName = "冷門馬經";
                  }
                  return (
                    <span key={`navIgation-list-${path_name}`}>
                      <Link to={path}>
                        <span>{pathName.toUpperCase()}</span>
                      </Link>
                      {some(products, (p) => p.handle == path_name) ? null : <span> >>&nbsp;</span>}
                    </span>
                  )
                }) : null}
              </div>
            </div>
          ) : null
        }
        {children}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  ...state
})

export default withRouter(connect(mapStateToProps, {}) (MobileProductPathContainer));
