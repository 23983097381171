import { connect } from 'react-redux';
import HomeProductWidget from 'scenes/Home2021winter/HomeProductWidget';
import MobileHomeProductWidget from '_Mobile/scenes/MobileHome2021winter/MobileHomeProductWidget';
import DOMPurify from 'dompurify'

function MobileFeatureCollection(props) {
  const { fetchFeatureCollectionReducer, collectionProps } = props;
  const { collection = {} } = fetchFeatureCollectionReducer;
  const { products = [] } = collectionProps || collection;
  const { title, descriptionHtml } = collectionProps || collection;

  const sanitizedDesc = () => ({
    __html: DOMPurify.sanitize(descriptionHtml.replace(/\|/g, "<br>"), { FORBID_TAGS: ['img'] })
  })
  return (
    <div className="MobileFeatureCollection web-page-align-top">
      <style jsx>{`
        .MobileFeatureCollection {
          width: 100%;
          padding-top: 20px;
        }
        .MobileFeatureCollection .collectionDesc {
          font-size: 13px;
        }
        .MobileFeatureCollection .FeatureItems {
          display: block;
          width: 100%;
          margin: 40px auto auto;
          padding-bottom: 80px;
        }
        .MobileFeatureCollection .homeBanner-item-container {
          height: 100%;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          padding: 0 2.5vw;
          flex-direction: column;
          width: 45vw;
        }
      `}</style>
      {descriptionHtml && <div className="collectionDesc" dangerouslySetInnerHTML={sanitizedDesc()} />}
      <div className="FeatureItems">
        {products.map((p, index) => {
          return <MobileHomeProductWidget p={p} key={index} />;
        })}
      </div>
    </div>
  );
}

const mapStateToProps = state => ({
  ...state
})

export default connect(mapStateToProps, {})(MobileFeatureCollection);