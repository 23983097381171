import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'withRouter';
import categories from '../../../data/categories';
import PanelLeft from '../../../components/PanelLeft';
import PanelRight from '../../../components/PanelRight';
import N796_LOGO from '../../../assets/N796/n796Logo.png';
import N796_BACKGROUND from '../../../assets/N796/n796Background.png';
import N796PRODUCT from '../../../assets/N796/n796Product.png';
import ReactPageScroller from "react-page-scroller";
import ProductWidgetN796 from '../../Product/ProductWidgetN796';
import { Helmet } from "react-helmet";
import { SchemaOrg } from 'components/SchemaOrg';

class N796 extends Component {

  constructor(props) {
    super(props);
    this.state = {
      productsInProject: [],
      project: "n796",
    };
    this.initiateLoadedProducts = this.initiateLoadedProducts.bind(this);
  }

  initiateLoadedProducts(products) {
    const project_url = this.state.project;
    let temp_product_array = [];
    if (products) {
      for (let c of categories[project_url].products) {
        for (let p of products) {
          if (c === p.handle) {
            temp_product_array.push(p);
            break;
          } else if (c.image) {
            temp_product_array.push(c);
            break;
          }
        }
      }
      this.setState({
        productsInProject: temp_product_array,
      })
    }
  }

  componentWillMount() {
    this.initiateLoadedProducts(this.props.fetchProductsReducer.products);
  }

  componentWillReceiveProps(nextProps) {
    this.initiateLoadedProducts(nextProps.fetchProductsReducer.products);
  }

  render() {
    const { productsInProject } = this.state;
    const { match } =  this.props;
    const { url } = match;
    const p = productsInProject[0];
    return (
      <div className="N796 web-page">
        <Helmet>
          <title>{`FEASTON - ${p && p.title ? p.title : 'Product'}`}</title>
          <meta property="og:title" content={`FEASTON - ${p && p.title ? p.title : 'Product'}`} />
          <link rel="canonical" href={`https://feaston.co${url}`} />
        </Helmet>
        {p ? <SchemaOrg p={p} url={url} /> : null}
        <style jsx>{`
          .N796 {
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .N796 div {
            overflow-y: unset;
          }
          .N796 .N796-mid {
            font-weight: bold;
            font-size: 28px;
            display: unset;
          }
          .N796 .N796-mid .n796-scroll-mid {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .N796 .N796-mid .n796-scroll-mid.n796-scroll-mid-product-img {
            padding: 0 125px;
          }
          .N796 .N796-mid > div {
            height: 100% !important;
          }
          .N796 .N796-mid img {
            width: 100%;
            max-height: 100%;
            object-fit: cover;
          }
          .N796 .N796-panelLeft {
            font-size: 13px;
          }
          .N796 .N796-panelLeft .N796-logo {
            padding-bottom: 20px;
          }
          .N796 .N796-panelLeft .N796-logo img {
            width: 100%;
          }
          .N796 .N796-panelLeft .N796-desc {
            text-align: left;
          }
        `}</style>
        <div className="web-mid N796-mid">
          {/* <ReactPageScroller ref={c => this._pageScroller = c}> */}
            {/* <div className="n796-scroll-mid n796-scroll-mid-product-img">
              {p ? <img src={ N796PRODUCT} alt="N796_tee" /> : null}
            </div>
            <div className="n796-scroll-mid">
              <img src={N796_BACKGROUND} alt="N796_background" />
            </div> */}
            {p && p.images.length > 0 ?
            p.images.map((image, i) => {
              return (
                <div key={image.src} className={`n796-scroll-mid${i !== p.images.length - 1 ? " n796-scroll-mid-product-img" : ""}`}>
                  {p ? <img src={image.src} alt="General_tee" /> : null}
                </div>
              )
            }) : null}
          {/* </ReactPageScroller>   */}
        </div>
        <PanelLeft>
          <div className="N796-panelLeft">
            <div className="N796-logo">
              <img src={N796_LOGO} alt="N796" />
            </div>
            <div className="N796-desc" dangerouslySetInnerHTML={{__html: productsInProject && productsInProject.length > 0 ? p.descriptionHtml : ""}} />
          </div>
        </PanelLeft>
        <PanelRight withPath>
          <div className="PanelRight-productWidget">
            {productsInProject && productsInProject.length > 0 ? (
              <ProductWidgetN796
                product={p}
              />
            ) : null}
          </div>
        </PanelRight>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  ...state
})

export default withRouter(connect(mapStateToProps, {}) (N796));
