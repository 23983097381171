import React, {Component} from 'react';
import LineItem from './LineItem';
import { client } from '../../shopify';
import { withCookies } from 'react-cookie';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import EMMPTY_CART_IMG from '_Mobile/assets/jc_empty_cart.png';
import CART_LOADING_IMG from './cart_loading.png';
import { fetchCheckout } from '../../actions/fetchCheckout';

// * deprecated (for cookies ProductsInCart)
// function removeByKey(array, params) {
// 	array.some(function(item, index) {
// 		if (array[index][params.key] === params.value) {
// 			array.splice(index, 1);
// 			return true; // stops the loop
// 		}
// 		return false;
// 	});
// 	return array;
// }

class Cart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cartLoading: false,
      isBoosterLimitReached: false,
    };
    this.openCheckout = this.openCheckout.bind(this);
    this.removeLineItemInCart = this.removeLineItemInCart.bind(this);
    this.updateQuantityInCart = this.updateQuantityInCart.bind(this);
    this.handleOptionChange = this.handleOptionChange.bind(this);
    this.addVariantToCart = this.addVariantToCart.bind(this);
  }

  openCheckout() {
    window.open(this.props.fetchCheckoutReducer.checkout.webUrl);
  }

  updateQuantityInCart(lineItemId, quantity, variantId) {
    const checkoutId = this.props.fetchCheckoutReducer.checkout.id;
    const lineItemsToUpdate = [{id: lineItemId, quantity: parseInt(quantity, 10)}];
    if (parseInt(quantity, 10) === 0) {
      this.removeLineItemInCart(lineItemId, variantId);
    } else {

      const { lineItems } = this.props.fetchCheckoutReducer.checkout;
      if (lineItems.length > 0) {
        for (let p of lineItems) {
          if (p.variant.id === variantId) {
            // check if booster limit is reached:
            if (p.variant.product.handle === "feaston-booster-pack" && parseInt(quantity, 10) > 3 && parseInt(quantity, 10) - p.quantity > 0) {
              this.setState({
                isBoosterLimitReached: true,
              }, () => {
                setTimeout(() => {
                this.setState({
                  isBoosterLimitReached: false,
                  })
                }, 3000);
              });
              return;
            }
          }
        }
      }

      return client.checkout.updateLineItems(checkoutId, lineItemsToUpdate).then(res => {
        this.props.fetchCheckout(res);
      });
    }
  }

  removeLineItemInCart(lineItemId, variantId) {
    const checkoutId = this.props.fetchCheckoutReducer.checkout.id

    return client.checkout.removeLineItems(checkoutId, [lineItemId]).then(res => {
      this.props.fetchCheckout(res);
    });
  }

  handleOptionChange(event, line_item, product) {
    const target = event.target;

    let selectedOptions = {};
    line_item.variant.selectedOptions.forEach((selector) => {
      selectedOptions[selector.name] = selector.value;
    });
    selectedOptions[target.name] = target.value;

    const selectedVariant = client.product.helpers.variantForOptions(product, selectedOptions);
    this.removeLineItemInCart(line_item.id, line_item.variant.id).then(() => {
      this.addVariantToCart(selectedVariant.id, line_item.quantity);
    });

  }

  addVariantToCart(variantId, quantity){
    const lineItemsToAdd = [{variantId, quantity: parseInt(quantity, 10)}];
    const checkoutId = this.props.fetchCheckoutReducer.checkout.id;

    return client.checkout.addLineItems(checkoutId, lineItemsToAdd).then(res => {
      this.props.fetchCheckout(res);
    });
  }

  componentWillMount() {
    let { cookies } = this.props;
    const checkoutId = cookies.get('checkoutID');
    if (checkoutId) {
      client.checkout.fetch(checkoutId).then((checkout) => {
        if (checkout.completedAt) {


          client.checkout.create().then((res) => {
            this.setState({
              cartLoading: false,
            }, () => {
              cookies.set('checkoutID', res.id, { path: '/', expires: new Date(Date.now()+31556952000)});
              this.props.fetchCheckout(res);
            });
          });


        } else {
          this.setState({
            cartLoading: false,
          }, () => this.props.fetchCheckout(checkout) );
        }
      });
    } else {
      client.checkout.create().then((res) => {
        this.setState({
          cartLoading: false,
        }, () => {
          cookies.set('checkoutID', res.id, { path: '/', expires: new Date(Date.now()+31556952000)});
          this.props.fetchCheckout(res);
        });
      });
    }
  
  }

  render() {
    let checkout = {};
    const { fetchProductsReducer, fetchCheckoutReducer, fetchSelectedCurrencyReducer } = this.props;
    if (fetchCheckoutReducer) {
      checkout = fetchCheckoutReducer.checkout;
    }

    let line_items = checkout && checkout.lineItems && fetchProductsReducer.products ? checkout.lineItems.map((line_item) => {
      let product = null;
      if (fetchProductsReducer.products) {
        for (let p of fetchProductsReducer.products) {
          if (line_item.variant && p.id === line_item.variant.product.id.toString()) {
            product = p;
            break;
          }
        }
      }

      if (!product) {
        this.removeLineItemInCart(line_item.id);
      }

      return product ? (
        <LineItem
          updateQuantityInCart={this.updateQuantityInCart}
          removeLineItemInCart={this.removeLineItemInCart}
          handleOptionChange={this.handleOptionChange}
          key={line_item.id.toString()}
          line_item={line_item}
          product={product}
          options={product ? product.options : []}
          checkoutCurrencyCode={checkout.currencyCode}
          warning={this.state.isBoosterLimitReached && product.handle === 'feaston-booster-pack' ? <>You can only buy<br />3 boosters at a time.</> : ""}
        />
      ) : null;
    }) : null;

    const currencyAfterConversion = (price) => {
      return window.Currency.convert(price, checkout.currencyCode, fetchSelectedCurrencyReducer.selectedCurrency).toFixed(2)
    };
    return (
      fetchCheckoutReducer && checkout ?
        <div className="Cart" onClick={(e) => e.stopPropagation()}>
          <style jsx>{`
            .Cart {
              position: absolute;
              bottom: 0;
              height: 500px;
              width: 100%; /*375px;*/
              max-width: 100%;
              background: rgb(252, 117, 9);
              cursor: auto;
              overflow: hidden;
              color: white;
              overflow-y: scroll;
            }
            .Cart .CartHeader {
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 30px;
              font-weight: bold;
              padding: 20px 0 10px 0;
            }
            .Cart .CartLineItems {
              padding: 10px;
            }
            .Cart .CartTotal__container {
              font-size: 20px;
            }
            .Cart .CartTotal__container .shipping-notice {
              font-size: 13px;
              padding: 10px 0;
              line-height: 1.7;
            }
            .Cart .CartTotal__container .CartTotal {
              padding: 0 0 20px 0; 
            }
            .terms {
              text-decoration: underline;
              padding: 0 0 80px 0;
              color: white;
              font-size: 18px;
            }
            .Cart .Cart__footer {
              position: fixed;
              bottom: 0;
              height: 50px;
              width: 17vw; /*375px;*/
              background: rgb(252, 117, 9);
            }
            .Cart .Cart__footer .Cart__checkout {
              height: 36px;
              font-size: 25px;
              font-weight: bold;
              color: white;
              background: rgb(252, 117, 9);
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
              border: 2px solid white;
              margin: 5px;
            }

            .Cart .CartLoading, .Cart .EmptyCart {
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
            }
            .Cart .CartLoading, .Cart .EmptyCart img {
              width: 100%;
            }
          `}</style>

          {this.state.cartLoading ? (
            <div className="CartLoading">
              <img src={CART_LOADING_IMG} alt="" />
            </div>
          ) : (
            checkout.lineItems.length > 0 ? (
            <div>
              <div className="CartHeader">
                <div className="text-Cart">cart</div>
              </div>

              <div className="CartLineItems">
                {line_items}
              </div>

              <div className="CartTotal__container">
                <div className="shipping-notice">
                  shipping calculated at checkout<br />
                  apply discount code at the next step
                </div>
                <div className="CartTotal">
                  <span className="CartTotal__total">Subtotal</span>&nbsp;
                  <span className="CartTotal__price">{fetchSelectedCurrencyReducer.selectedCurrency} {currencyAfterConversion(checkout.subtotalPrice?.amount)}</span>
                </div>
                <Link to="/terms">
                  <div className="terms">
                    terms and conditions
                  </div>
                </Link>
              </div>

              <div className="Cart__footer">
                <div className="Cart__checkout" onClick={this.openCheckout}>
                  Checkout
                </div>
              </div>
            </div>
            ) : (
            <div className="EmptyCart">
              <img src={EMMPTY_CART_IMG} alt="" />
            </div> 
            )
          )}

        </div>
      : null
    )
  }
}

const mapStateToProps = state => ({
  ...state
})

const mapDispatchToProps = dispatch => ({
  fetchCheckout: (checkout) => dispatch(fetchCheckout(checkout)),
})

export default withCookies(connect(mapStateToProps, mapDispatchToProps) (Cart));