import MobileFeaston from "../../_Mobile/scenes/MobileProject/feaston";
import Feaston from "../../scenes/Project/feaston";
import MobileN796 from "../../_Mobile/scenes/MobileProject/n796";
import N796 from "../../scenes/Project/n796";
import MobileLMMG from "../../_Mobile/scenes/MobileProject/lmmg";
import LMMG from "../../scenes/Project/lmmg";
import ProjectGeneral from "../../scenes/Project/general";
import mobileProjectGeneral from "../../_Mobile/scenes/MobileProject/mobileProjectGeneral";

const categories = {
  "feaston" : {
    products: ["snare-boot", "rise-crew", "snare-boot", "rise-crew", "snare-boot", "rise-crew", "snare-boot", "rise-crew", "snare-boot", "rise-crew", "snare-boot", "rise-crew"], //, "rise-crew"
    component: Feaston, 
    mobileComponent: MobileFeaston,
  },
  "n796": {
    products: ["n796"],
    component: N796, 
    mobileComponent: MobileN796,
  },
  "lmmg" : {
    products: ["snare-boot", "rise-crew", "snare-boot", "rise-crew", "snare-boot", "rise-crew", "snare-boot", "rise-crew", "snare-boot", "rise-crew", "snare-boot", "rise-crew"], //, "rise-crew"
    component: LMMG,
    mobileComponent: MobileLMMG,
  },
  "boot_01": {
    products: ["ronaldin̄o-t-shirt"],
    component: ProjectGeneral, 
    mobileComponent: mobileProjectGeneral,
  },
  "holyfuckington": {
    products: ["holy-fuckington™-t-shirt"],
    component: ProjectGeneral, 
    mobileComponent: mobileProjectGeneral,
  },
  "djforeskin_01": {
    products: ["dj-foreskin-worldtour-98-99-t-shirt"],
    component: ProjectGeneral, 
    mobileComponent: mobileProjectGeneral,
  },
  "singularity_01": {
    products: ["singularity-t-shirt"],
    component: ProjectGeneral, 
    mobileComponent: mobileProjectGeneral,
  },
  "lmmg_01": {
    products: ["冷門馬經-t-shirt"],
    component: ProjectGeneral, 
    mobileComponent: mobileProjectGeneral,
  },
  "congo_01": {
    products: ["big-portrait-tee"],
    component: ProjectGeneral, 
    mobileComponent: mobileProjectGeneral,
  },
  "feastopher_01": {
    products: ["everlasting-feasts-t-shirt"],
    component: ProjectGeneral, 
    mobileComponent: mobileProjectGeneral,
  },
  "n796_collar": {
    products: ["n796-full-print-collar-shirt"],
    component: ProjectGeneral, 
    mobileComponent: mobileProjectGeneral,
  },
  "bottle": {
    products: ["butane-bottle-tee"],
    component: ProjectGeneral, 
    mobileComponent: mobileProjectGeneral,
  },
  "snake_tote": {
    products: ["snake-tote"],
    component: ProjectGeneral, 
    mobileComponent: mobileProjectGeneral,
  },
  "jaya_tote": {
    products: ["jayasalakia-tote"],
    component: ProjectGeneral, 
    mobileComponent: mobileProjectGeneral,
  },
  "opium_t": {
    products: ["opium-tee"],
    component: ProjectGeneral, 
    mobileComponent: mobileProjectGeneral,
  },
  "opium_tote": {
    products: ["opium-tote"],
    component: ProjectGeneral, 
    mobileComponent: mobileProjectGeneral,
  },
  "hunan": {
    products: ["hunan-autonomy-tote"],
    component: ProjectGeneral, 
    mobileComponent: mobileProjectGeneral,
  },
  "pickles_tote": {
    products: ["pickles-tote"],
    component: ProjectGeneral, 
    mobileComponent: mobileProjectGeneral,
  },
  "pickles_t": {
    products: ["pickles-tee"],
    component: ProjectGeneral, 
    mobileComponent: mobileProjectGeneral,
  },
  "investor": {
    products: ["investor-cap"],
    component: ProjectGeneral, 
    mobileComponent: mobileProjectGeneral,
  },
  "kale": {
    products: ["kale-tee"],
    component: ProjectGeneral, 
    mobileComponent: mobileProjectGeneral,
  },
  "chain": {
    products: ["chain-tee"],
    component: ProjectGeneral, 
    mobileComponent: mobileProjectGeneral,
  },
  "discontinued_siu_mai": {
    products: ["絕版燒賣-discontinued-siu-mai-tee"],
    component: ProjectGeneral, 
    mobileComponent: mobileProjectGeneral,
  },
  "deep_learning": {
    products: ["deep-learning-tee"],
    component: ProjectGeneral, 
    mobileComponent: mobileProjectGeneral,
  },
  "sm2202": {
    products: ["micro-narratives-tee"],
    component: ProjectGeneral, 
    mobileComponent: mobileProjectGeneral,
  },
  "andy_lau": {
    products: ["andy-lau-lighter"],
    component: ProjectGeneral, 
    mobileComponent: mobileProjectGeneral,
    pickUpOnly: true,
  },
  "3107_hood": {
    products: ["3107-hoodie"],
    component: ProjectGeneral, 
    mobileComponent: mobileProjectGeneral,
  },
  "band1_tee": {
    products: ["band-1-tee"],
    component: ProjectGeneral, 
    mobileComponent: mobileProjectGeneral,
  },
  "ES_hood": {
    products: ["eugene-simpson-hoodie"],
    component: ProjectGeneral, 
    mobileComponent: mobileProjectGeneral,
  },
  "grandpa_tee": {
    products: ["grandpa-tee"],
    component: ProjectGeneral, 
    mobileComponent: mobileProjectGeneral,
  },
  "imported_cap": {
    products: ["imported-cap"],
    component: ProjectGeneral, 
    mobileComponent: mobileProjectGeneral,
  },
  "imported_hood": {
    products: ["imported-hoodie"],
    component: ProjectGeneral, 
    mobileComponent: mobileProjectGeneral,
  },
  "kid_tee": {
    products: ["kid-tee"],
    component: ProjectGeneral, 
    mobileComponent: mobileProjectGeneral,
  },
  "logo_hood_black": {
    products: ["logo-hoodie"],
    component: ProjectGeneral, 
    mobileComponent: mobileProjectGeneral,
  },
  "logo_hood_red": {
    products: ["logo-hoodie-1"],
    component: ProjectGeneral, 
    mobileComponent: mobileProjectGeneral,
  },
  "marker_hood": {
    products: ["marker-hoodie"],
    component: ProjectGeneral, 
    mobileComponent: mobileProjectGeneral,
  },
  "marker_tee": {
    products: ["marker-tee"],
    component: ProjectGeneral, 
    mobileComponent: mobileProjectGeneral,
  },
  "names_tee": {
    products: ["names-tee"],
    component: ProjectGeneral, 
    mobileComponent: mobileProjectGeneral,
  },
  "panopticon_tee": {
    products: ["panopticon-tee"],
    component: ProjectGeneral, 
    mobileComponent: mobileProjectGeneral,
  },
  "smile_tee": {
    products: ["smile-tee"],
    component: ProjectGeneral, 
    mobileComponent: mobileProjectGeneral,
  },
  "sunn5G_tee": {
    products: ["sunn-5g-tee"],
    component: ProjectGeneral, 
    mobileComponent: mobileProjectGeneral,
  },
  "toast_tee": {
    products: ["toast-tee"],
    component: ProjectGeneral,
    mobileComponent: mobileProjectGeneral,
  },
  "yaohan_hood": {
    products: ["yaohan-hoodie"],
    component: ProjectGeneral, 
    mobileComponent: mobileProjectGeneral,
  },
  "fushinsha_sweat": {
    products: ["不審者-suspicious-person-sweater"],
    component: ProjectGeneral, 
    mobileComponent: mobileProjectGeneral,
  },
  "juk_hood": {
    products: ["粥-hoodie"],
    component: ProjectGeneral, 
    mobileComponent: mobileProjectGeneral,
  },
  "ljmb_tee": {
    products: ["蘭州麵包-tee"],
    component: ProjectGeneral, 
    mobileComponent: mobileProjectGeneral,
  },
  "opium_mug": {
    products: ["鴉片-opium-mug"],
    component: ProjectGeneral, 
    mobileComponent: mobileProjectGeneral,
  },
  "opium_sweat": {
    products: ["鴉片-opium-sweater"],
    component: ProjectGeneral, 
    mobileComponent: mobileProjectGeneral,
  },
  "opium_tee": {
    products: ["鴉片-opium-tee"],
    component: ProjectGeneral, 
    mobileComponent: mobileProjectGeneral,
  },
  "juk_tee": {
    products: ["粥-tee"],
    component: ProjectGeneral, 
    mobileComponent: mobileProjectGeneral,
  },
  "scs_tee": {
    products: ["social-credit-system-tee"],
    component: ProjectGeneral, 
    mobileComponent: mobileProjectGeneral,
  },
  "feaston_labs_tee": {
    products: ["feaston-labs-tee"],
    component: ProjectGeneral, 
    mobileComponent: mobileProjectGeneral,
  },
  "examiners_tee": {
    products: ["examiners-tee"],
    component: ProjectGeneral, 
    mobileComponent: mobileProjectGeneral,
  },
  "dj_rothschild_tee": {
    products: ["dj-rothschild-tee"],
    component: ProjectGeneral, 
    mobileComponent: mobileProjectGeneral,
  },
  "96km_h_tee": {
    products: ["96km-h-tee"],
    component: ProjectGeneral, 
    mobileComponent: mobileProjectGeneral,
  },
  "opium_socks": {
    products: ["opium-鴉片-socks"],
    component: ProjectGeneral, 
    mobileComponent: mobileProjectGeneral,
  },
  // 2021winter:
  "bsSmile": {
    products: ["backside-smile-tee"],
    component: ProjectGeneral, 
    mobileComponent: mobileProjectGeneral,
  },
  "esPortrait": {
    products: ["eugene-simpson-photo-tee"],
    component: ProjectGeneral, 
    mobileComponent: mobileProjectGeneral,
  },
  "faestonHood": {
    products: ["faeston-hoodie"],
    component: ProjectGeneral, 
    mobileComponent: mobileProjectGeneral,
  },
  "california": {
    products: ["feaston-california-tee"],
    component: ProjectGeneral, 
    mobileComponent: mobileProjectGeneral,
  },
  "jazzHood": {
    products: ["feaston-jazz-hoodie"],
    component: ProjectGeneral, 
    mobileComponent: mobileProjectGeneral,
  },
  "liberia": {
    products: ["liberia-logo-tee"],
    component: ProjectGeneral, 
    mobileComponent: mobileProjectGeneral,
  },
  "portsmouth": {
    products: ["feaston-portsmouth-sweater"],
    component: ProjectGeneral, 
    mobileComponent: mobileProjectGeneral,
  },
  "fsports": {
    products: ["feaston-sport-tee"],
    component: ProjectGeneral, 
    mobileComponent: mobileProjectGeneral,
  },
  "feelington": {
    products: ["feelington-tee"],
    component: ProjectGeneral, 
    mobileComponent: mobileProjectGeneral,
  },
  "healington": {
    products: ["healington-tee"],
    component: ProjectGeneral, 
    mobileComponent: mobileProjectGeneral,
  },
  "P": {
    products: ["p-sign-tee"],
    component: ProjectGeneral, 
    mobileComponent: mobileProjectGeneral,
  },
  "singularityHood": {
    products: ["singularity-hoodie"],
    component: ProjectGeneral, 
    mobileComponent: mobileProjectGeneral,
  },
  "emperorHood": {
    products: ["皇帝拳-hoodie"],
    component: ProjectGeneral, 
    mobileComponent: mobileProjectGeneral,
  },
  "emperorSweat": {
    products: ["皇帝拳-sweater"],
    component: ProjectGeneral, 
    mobileComponent: mobileProjectGeneral,
  },
  "feaston_faeston_slipmat": {
    products: ["feaston-faeston-slipmat"],
    component: ProjectGeneral, 
    mobileComponent: mobileProjectGeneral,
  },
}

// export const projects = [
//   {
//     url: "feaston",
//     products: ["snare-boot"], //, "rise-crew"
//     component: Feaston, 
//     mobileComponent: MobileFeaston,
//   }, 
//   {
//     url: "n796", 
//     products: ["rise-crew"],
//     component: N796, 
//     mobileComponent: MobileFeaston,
//   },
// ];

export default categories;