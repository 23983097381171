import React, { Component } from 'react';
import { withCookies } from 'react-cookie';
import { connect } from 'react-redux';
import { withRouter } from 'withRouter';
import { Helmet } from "react-helmet";
import html2canvas from 'html2canvas';
import * as jsPDF from 'jspdf';
import { Textfit } from 'react-textfit';
import Tabletop from 'tabletop';
import RECEIPT_TEMPLATE from './receiptTemplate.png';
import DCODE from './dcode.png';
import ReactToPrint from "react-to-print";
import axios from 'axios';

export function returnCurrentDateYMD() {
  let today = new Date();
  let dd = String(today.getDate()).padStart(2, '0');
  let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  let yyyy = today.getFullYear();
  today = yyyy + mm + dd;
  return today;
}

export function pad(num, size) {
  let s = num + "";
  while (s.length < size) s = "0" + s;
  return s;
}

export function addressCommaAdd(addressText) {
  return addressText ? `${addressText},\n` : '';
}

export const toTitleCase = (phrase) => {
  return phrase
    .toLowerCase()
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

class CreateReceipt extends Component {

  constructor(props) {
    super(props);
    this.state = {
      ordersRead: false,
      data: [], // all orders
      order: null,
      shopifyOrder: null,
      toggleShown: true,
      serverError: false,
      discountExisted: false,
    };
    this.onExport = this.onExport.bind(this);
    this.fetchExcelOrders = this.fetchExcelOrders.bind(this);
    this.onNewOrdersKey = this.onNewOrdersKey.bind(this);
    this.onNewOrderNo = this.onNewOrderNo.bind(this);
    this.onNewShopifyOrderNo = this.onNewShopifyOrderNo.bind(this);
    this.onOrderSubmit = this.onOrderSubmit.bind(this);
    this.onShopifyOrderSubmit = this.onShopifyOrderSubmit.bind(this);
    this.fetchShopifyOrder = this.fetchShopifyOrder.bind(this);
  }

  componentDidMount() {
    let { cookies } = this.props;
    const ordersKey = cookies.get('ordersKey');
    const orderNo = cookies.get('orderNo');
    const orderNo_shopify = cookies.get('orderNo_shopify');
    if (ordersKey && orderNo) {
      this.onOrderSubmit();
    } else if (ordersKey) {
      this.fetchExcelOrders(ordersKey);
    }
    if (orderNo_shopify) {
      this.fetchShopifyOrder(orderNo_shopify);
    }
    // axios.get(`http://localhost:3107/api/order`)
    //   .then(res => {
    //     const dataa = res.data;
    //     console.log('res: ', res, dataa);
    // });
  }

  onExport() {
    let { cookies } = this.props;
    const orderNo = cookies.get('orderNo');
    html2canvas(document.getElementById('receipt_preview'), {
      allowTaint: true,
      useCORS: true,
      logging: false,
      x: 20,
      y: 20,
    }).then(
      function(canvas) {
        const imgData = canvas.toDataURL('image/png');
        //document.getElementById('preview').append(canvas);
        var wid;
        var hgt;
        var img = canvas.toDataURL("image/png", wid = canvas.width, hgt = canvas.height);
        var hratio = hgt/wid;
        var doc = new jsPDF('p','pt','a3');
        var width = doc.internal.pageSize.width;    
        var height = width * hratio;
        doc.addImage(img,'PNG', 0, 0, width, height);
        doc.save(`receipt_${orderNo}.pdf`);
      },
    );
  }

  fetchExcelOrders(ordersKey, callback) {
    Tabletop.init({
        key: ordersKey, //'1W8SiC-CgPWpSm5hQGA-4aWiTHgA70WQvgWlp-fSfjiQ',
        callback: googleData => {
          console.log('google sheet data --->', googleData);
          this.setState({
            data: googleData,
            ordersRead: true,
          }, () => {
            if (callback) {
              callback();
            }
          });
        },
        error: () => {
          this.setState({
            ordersRead: false
          });
        },
        simpleSheet: true
    });
  }

  fetchShopifyOrder(shopifyOrderNo, callback) {
    axios.get(`http://localhost:3107/api/order`, {
      params: {
        orderRef: shopifyOrderNo,
      },
    }).then(res => {
      const order = res.data;
      this.setState({
        shopifyOrder: order[0],
        serverError: false,
      }, () => {
        if (order) {
          //     this.setState({
          //       order
          //     }, () => {
          //       setTimeout(() => this.setState({
          //         ...this.state,
          //       }, () => {
          //         html2canvas(document.getElementById('receipt_preview'), {
          //           allowTaint: true,
          //           useCORS: true,
          //           logging: false,
          //           x: 20,
          //           y: 20,
          //         }).then(
          //           function(canvas) {
          //             var wid;
          //             var hgt;
          //             var img = canvas.toDataURL("image/png", wid = canvas.width, hgt = canvas.height);
              
          //             var hratio = hgt/wid;
          //             var width = 1121;    
          //             var height = width * hratio;
          //             var oImg = document.createElement("img");
          //             oImg.setAttribute('src', img);
          //             oImg.setAttribute('alt', '');
          //             oImg.setAttribute('width', width);
          //             oImg.setAttribute('height', height);
          //             document.getElementById('preview').innerHTML = '';
          //             document.getElementById('preview').append(oImg);
          //           }
          //         );
          //       }), 1000);
          //     });
          //   } else {
          //     this.setState({
          //       order: null
          //     });
          //   }
        }
        if (callback) {
          callback();
        }
      });
    }).catch(() => {
      this.setState({
        serverError: true,
      });
      alert('Local server not started.');
    });
  }

  onNewOrdersKey(event) {
    const newOrdersKey = event.target.value;
    let { cookies } = this.props;
    cookies.set('ordersKey', newOrdersKey, { path: '/', expires: new Date(Date.now()+31556952000)});
    this.fetchExcelOrders(newOrdersKey);
  }

  onNewOrderNo(event) {
    const newOrderNo = event.target.value;
    let { cookies } = this.props;
    cookies.set('orderNo', newOrderNo, { path: '/', expires: new Date(Date.now()+31556952000)});
  }

  onNewShopifyOrderNo(event) {
    const newShopifyOrderNo = event.target.value;
    let { cookies } = this.props;
    cookies.set('orderNo_shopify', newShopifyOrderNo, { path: '/', expires: new Date(Date.now()+31556952000)});
    if (newShopifyOrderNo) {
      this.fetchShopifyOrder(newShopifyOrderNo);
    }
  }

  onOrderSubmit() {
    let { cookies } = this.props;
    this.fetchExcelOrders(cookies.get('ordersKey'), () => {
      const { data } = this.state;
      const order = data.find(o => o.Ref === cookies.get('orderNo'));
      if (order) {
        this.setState({
          order
        }, () => {
          setTimeout(() => this.setState({
            ...this.state,
          }, () => {
            html2canvas(document.getElementById('receipt_preview'), {
              allowTaint: true,
              useCORS: true,
              logging: false,
              x: 20,
              y: 20,
            }).then(
              function(canvas) {
                var wid;
                var hgt;
                var img = canvas.toDataURL("image/png", wid = canvas.width, hgt = canvas.height);
        
                var hratio = hgt/wid;
                var width = 1121;    
                var height = width * hratio;
                var oImg = document.createElement("img");
                oImg.setAttribute('src', img);
                oImg.setAttribute('alt', '');
                oImg.setAttribute('width', width);
                oImg.setAttribute('height', height);
                document.getElementById('preview').innerHTML = '';
                document.getElementById('preview').append(oImg);
              }
            );
          }), 1000);
        });
      } else {
        this.setState({
          order: null
        });
      }
    });
  }

  onShopifyOrderSubmit() {
    const { cookies } = this.props;
    this.fetchExcelOrders(cookies.get('ordersKey'), () => {
      const url = 'https://script.google.com/macros/s/AKfycbzPvSd_YvcxSnlmwsn2QwDkr706Q_JAWcLWxoBB1h8Cl0t2pl8/exec';
      var xhr = new XMLHttpRequest();
      const { shopifyOrder, data } = this.state;
      const previousOrderFeastonRef = data[data.length - 1].Ref;
      if (!shopifyOrder) {
        return;
      }
      if (!data[data.length - 1].Ref) { // for getting wrong formatted data (unknown reason), then try
        this.onShopifyOrderSubmit();
        return;
      }
      const { shipping_address, fulfillments, currency, shipping_lines, total_price } = shopifyOrder;
      const { name, company, address1, address2, city, province, zip, country } = shipping_address;
      //const { line_items } = fulfillments[0];
      const { line_items } = shopifyOrder;
      const shipping_price = parseFloat(shipping_lines[0].price);
      let itemsText = "";
      line_items.forEach((item, index) => {
        let { title, variant_title, quantity, price } = item;
        title = toTitleCase(title);
        console.log('replace 1', variant_title);
        let variantText = " (" + variant_title.toUpperCase().replace(/ \/ /g, "-")
          .replace(/BLACK/g, "BLK")
          .replace(/WHITE/g, "WHT") + ") ";
        itemsText += title + variantText + "x " + quantity + " $" + currency + parseFloat(price) + (index === line_items.length - 1 ? "" : ",") + "\n";
      });

      const isExisting = (element) => element.ShopifyRef && element.ShopifyRef === cookies.get('orderNo_shopify');
      let existedAt = data.findIndex(isExisting);
      if (existedAt === -1) {
        existedAt = "";
      } else {
        existedAt += 2;
      }
      console.log('orderrrrr', shopifyOrder, data, previousOrderFeastonRef);
      console.log('replace 2', previousOrderFeastonRef);
      let submitData = {
        Date: returnCurrentDateYMD(),
        Ref: "FEASTON" + pad(parseInt(data.find(isExisting).Ref.replace("FEASTON", ""), 10) + (existedAt ? 0 : 1), 3),
        Name: name,
        Items: itemsText,
        Address: addressCommaAdd(company) + addressCommaAdd(address1) + addressCommaAdd(address2) + addressCommaAdd(city) + addressCommaAdd(province) + addressCommaAdd(zip) + country,
        Shipping: shipping_price || "",
        Total: parseFloat(total_price),
        DiscountCode: data.find(isExisting) ? data.find(isExisting).DiscountCode : "",
        Discount: data.find(isExisting) ? data.find(isExisting).Discount : "",
        Currency: currency === "USD" ? "" : currency,
        ShopifyRef: cookies.get('orderNo_shopify'),
        existedAt,
        //formDataNameOrder: '["ShopifyRef"]',
        formGoogleSheetName: 'responses',
        formGoogleSendEmail: '',
        honeypot: '',
      };
      this.setState({ order: submitData });
      xhr.open('POST', url);
      xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
      xhr.onreadystatechange = () => {
        // ...
      };
      // url encode form data for sending as post data
      var encoded = Object.keys(submitData).map(function(k) {
          return encodeURIComponent(k) + "=" + encodeURIComponent(submitData[k]);
      }).join('&');
      xhr.send(encoded); 
    });
    
    // this.fetchExcelOrders(cookies.get('ordersKey'), () => {
    //   const { data } = this.state;
    //   const order = data.find(o => o.Ref === cookies.get('orderNo'));
    //   if (order) {
    //     this.setState({
    //       order
    //     }, () => {
    //       setTimeout(() => this.setState({
    //         ...this.state,
    //       }, () => {
    //         html2canvas(document.getElementById('receipt_preview'), {
    //           allowTaint: true,
    //           useCORS: true,
    //           logging: false,
    //           x: 20,
    //           y: 20,
    //         }).then(
    //           function(canvas) {
    //             var wid;
    //             var hgt;
    //             var img = canvas.toDataURL("image/png", wid = canvas.width, hgt = canvas.height);
        
    //             var hratio = hgt/wid;
    //             var width = 1121;    
    //             var height = width * hratio;
    //             var oImg = document.createElement("img");
    //             oImg.setAttribute('src', img);
    //             oImg.setAttribute('alt', '');
    //             oImg.setAttribute('width', width);
    //             oImg.setAttribute('height', height);
    //             document.getElementById('preview').innerHTML = '';
    //             document.getElementById('preview').append(oImg);
    //           }
    //         );
    //       }), 1000);
    //     });
    //   } else {
    //     this.setState({
    //       order: null
    //     });
    //   }
    // });
  }

  render() {
    let { cookies } = this.props;
    const { ordersRead, orderRead, toggleShown, data, order, shopifyOrder, serverError } = this.state;
    const ordersKey = cookies.get('ordersKey');
    const orderNo = cookies.get('orderNo');
    const orderNo_shopify = cookies.get('orderNo_shopify');
    let dcode = false;
    let date, ref, name, address, items, shipping, total, discountCode, discount, currency;
    if (order) {
      date = order.Date;
      ref = order.Ref;
      name = order.Name;
      address = order.Address;
      items = order.Items;
      shipping = order.Shipping;
      total = order.Total;
      discountCode = order.DiscountCode;
      discount = order.Discount;
      currency = order.Currency || 'USD';
    }
    if (discountCode && discount) {
      dcode = true;
    }
    return (
      <div className="web-page CR" id="CR">
        <Helmet>
          <title>FEASTON CR</title>
        </Helmet>
        <style jsx>{`
          .CR {
            padding: 20px 0 0 20px;
            display: block;
            text-align: left;
          }
          .CR .button {
            cursor: pointer;
            border: 1px solid black;
            border-radius: 4px;
            width: fit-content;
            padding: 4px;
            margin-bottom: 10px;
            background: white;
          }
          .CR .fixed_buttons {
            position: fixed;
            bottom: 50px;
            left: 20px;
          }
          .CR .fixed_buttons .togglable {
            display: ${toggleShown ? 'block' : 'none'};
          }
          .CR .fixed_buttons .orderInput {
            margin-bottom: 10px;
          }
          .CR .fixed_buttons .orderInput input {
            background: rgba(0, 0, 0, 0.1);
            width: 150px;
          }
          .CR .fixed_buttons .keyInput input {
            background: rgba(0, 0, 0, 0.1);
            width: 300px;
          }
          .CR .fixed_buttons .fetchStatus {
            color: ${ordersRead ? 'green' : 'red'};
            margin-left: 20px;
          }
          .CR .fixed_buttons .orderFetchStatus {
            color: ${order ? 'green' : 'red'};
            margin-left: 20px;
          }
          .CR .fixed_buttons .shopifyOrderFetchStatus {
            color: ${shopifyOrder ? 'green' : 'red'};
            margin-left: 20px;
          }
          .CR .receipt_preview {
            height: 1800px;
            width: 1272px;
            background: green;
            margin-bottom: 20px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            background: URL(${RECEIPT_TEMPLATE});
            position: relative;
          }
          .CR .receipt_preview > div {
            position: absolute;
            //background: green;
            //opacity: 0.8;
          }
          .CR .bold {
            font-weight: bold;
          }
          .CR .font1 {
            font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
          }
          .CR .font2 {
            font-family: "Baskerville", Arial, sans-serif;
          }
          .CR .receipt_preview .date {
            font-size: 67px;
            top: 271px;
            left: 594px;  
          }
          .CR .receipt_preview .ref {
            font-size: 67px;
            top: 350px;
            left: 565px;
          }
          .CR .receipt_preview .name {
            font-size: 32px;
            top: 575px;
            left: 838px;
          }
          .CR .receipt_preview .address {
            top: 620px;
            left: 838px;
          }
          .CR .receipt_preview .address div {
            width: 100%;
          }
          .CR .receipt_preview .items {
            top: 928px;
            left: 197px;
          }
          .CR .receipt_preview .items div {
            width: 100%;
          }
          .CR .receipt_preview .dcode-container {
            width: 350px;
            bottom: 179px;
            right: 158px;
          }
          .CR .receipt_preview .dcode-container > div {
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
          }

          .CR .receipt_preview .dcode-container img {
            width: 100%;
          }
          .CR .receipt_preview .dcode-container .discount {
            display: flex;
            width: 48px;
            height: 44px;
            left: 182px;
            top: 20px;
            font-size: 36px;
            color: black;
            font-family: "Superclarendon", "Helvetica Neue", Helvetica, Arial, sans-serif;
          }
          .CR .receipt_preview .dcode-container .dcode {
            bottom: 61px;
            left: 155px;
            color: white;
          }
          .CR .receipt_preview .dcode-container .dcode div {
            width: 100%;
          }
          .CR #preview {
            height: 1800px;
            width: 1272px;
            display: none;
          }
        `}</style>
        <div>
          <div id="receipt_preview" className="receipt_preview">
            <div className="date bold font1">
             {date}
            </div>
            <div className="ref bold font1">
             {ref} 
            </div>
            <div className="name font1" style={{ display:"flex", width: '360px', height: '40px' }}>
              <Textfit> 
                {name}
              </Textfit> 
            </div>
            <div className="address font1" style={{ display:"flex", width: '360px', height: '150px' }}>
              <Textfit mode="multi">
                {address ? address.split(',').map((a, i) => {
                  return (
                    <>
                      {a}{i == address.split(',').length - 1 ? '' : ','}<br/>
                    </>
                  )
                }): address}
              </Textfit>
            </div>
            <div className="items font2" style={{ display:"flex", width: '980px', height: dcode ? '452px' : '700px' }}>
              <Textfit mode="multi">
                {items ? items.split(',').map((item, i) => {
                  return (
                    <span key={i} style={{ whiteSpace: 'nowrap' }}>
                      {item}{i == items.split(',').length - 1 ? '' : ','}<br/>
                    </span>
                  )
                }): items}
                {shipping ? <><br/>shipping: ${shipping}{currency}<br/></> : null}
                <span style={{ textDecoration: 'underline' }}><br/>Total: ${total}{currency}</span>
              </Textfit>
            </div>

            {dcode ? (
              <div className="dcode-container">
                <img src={DCODE} alt="promo_code" />
                <div className="dcode" style={{ display: "flex", width: '146px', height: '34px' }}>
                  <Textfit mode="multi"> 
                    {discountCode}
                  </Textfit>
                </div>
                <div className="discount">
                  {discount}
                </div>
              </div>
            ) : null}
          </div>
          <div className="fixed_buttons">
            <div style={{ marginBottom: '10px', cursor: 'pointer', fontSize: '20px', width: '14px' }} onClick={() => this.setState({ toggleShown: !this.state.toggleShown })}>
              {toggleShown ? '-' : '+'}
            </div>
            <div className='togglable'>
              {ordersRead && order ? (
                <>
                  <div className="button export_button" onClick={this.onExport}>
                    Export
                  </div>
                  <ReactToPrint
                    // onBeforePrint={() => {
                    //   html2canvas(document.getElementById('receipt_preview'), {
                    //     allowTaint: true,
                    //     useCORS: true,
                    //     logging: false,
                    //     x: 20,
                    //     y: 20,
                    //   }).then(
                    //     function(canvas) {
                    //       var wid: number;
                    //       var hgt: number;
                    //       var img = canvas.toDataURL("image/png", wid = canvas.width, hgt = canvas.height);
                 
                    //       var hratio = hgt/wid;
                    //       var width = 1121;    
                    //       var height = width * hratio;
                    //       var oImg = document.createElement("img");
                    //       oImg.setAttribute('src', img);
                    //       oImg.setAttribute('alt', '');
                    //       oImg.setAttribute('width', width);
                    //       oImg.setAttribute('height', height);
                    //       document.getElementById('preview').innerHTML = '';
                    //       document.getElementById('preview').append(oImg);
                    //     }
                    //   );
                    // }}
                    trigger={() => (
                      <div className="button export_button" href="#">
                        Print
                      </div>
                    )}
                    content={() => this.printComponentRef}
                  />
                </>
              ) : null}

              {!serverError ? (
                <div className="orderInput">
                  <span style={{ marginRight: '10px' }}>
                    SHOPIFY Order No.: 
                  </span>
                  <input type="text" defaultValue={orderNo_shopify} onChange={this.onNewShopifyOrderNo}></input>

                  <span onClick={this.onShopifyOrderSubmit} className="button" style={{ marginLeft: '20px', fontSize: '14px', padding: '2px' }}>
                    Submit
                  </span>
                  <span className="shopifyOrderFetchStatus">
                    {shopifyOrder ? "FETCHING SUCCESS" : "NOT FETCHED"}
                  </span>
                </div>
              ) : null}
              
              {ordersRead ? (
                <div className="orderInput">
                  <span style={{ marginRight: '10px' }}>
                    Order No.: 
                  </span>
                  <input type="text" defaultValue={orderNo} onChange={this.onNewOrderNo}></input>
                  <span onClick={this.onOrderSubmit} className="button" style={{ marginLeft: '20px', fontSize: '14px', padding: '2px' }}>
                    Submit
                  </span>
                  <span className="orderFetchStatus">
                    {order ? "FETCHING SUCCESS" : "NOT FETCHED"}
                  </span>
                </div>
              ) : null}
              <div className="keyInput">
                <span style={{ marginRight: '10px' }}>
                  Excel Key: 
                </span>
                <input type="text" defaultValue={ordersKey} onChange={this.onNewOrdersKey}></input>
                <span className="fetchStatus">
                  {ordersRead ? "FETCHING SUCCESS" : "NOT FETCHED"}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div id="preview" ref={el => (this.printComponentRef = el)}>
        </div>
      </div>
      
    );
  }
}

const mapStateToProps = state => ({
  ...state
})

export default withCookies(withRouter(connect(mapStateToProps, {}) (CreateReceipt)));
