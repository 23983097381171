import React, { Component, useState } from 'react';
import { connect } from 'react-redux';
import ScrollManager from "ScrollManager/index.jsx";
import { Helmet } from "react-helmet";
import ADDRESS_IMG from "_Mobile/scenes/MobileContact/kingsfordAddress.png";

function Address() {
  return (
    <div className="Address">
      <style jsx>{`
        .App.App_desktop {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .Address img {
          width: 100%;
          padding-bottom: 50px;
        }
      `}</style>
      <div>
        <a target="_blank" href={"http://maps.google.com/?q=Kingsford Industrial Centre, 13 Wang Hoi Road, Kowloon Bay, Hong Kong"} rel="noreferrer">
          <img src={ADDRESS_IMG} alt="" />
        </a>
      </div>
    </div >
  )
}

const mapStateToProps = state => ({
  ...state
})

export default connect(mapStateToProps, {})(Address);
