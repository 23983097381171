import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'withRouter';
import categories from '../../../data/categories';
import PanelLeft from '../../../components/PanelLeft';
import PanelRight from '../../../components/PanelRight';
import ProductWidgetGeneral from '../../Product/ProductWidgetGeneral';
import { Helmet } from "react-helmet";
import { SchemaOrg } from 'components/SchemaOrg';
import TagManager from 'react-gtm-module';

//get's a query parameter by name from current url
export function getUrlParameter(name) {
  name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
  var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
  var results = regex.exec(window.location.search);
  return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

export function toCamelCase(str) {
  return str
      .replace(/\s(.)/g, function($1) { return $1.toUpperCase(); })
      .replace(/\s/g, '')
      .replace(/^(.)/, function($1) { return $1.toLowerCase(); });
}

class ProjectGeneral extends Component {

  constructor(props) {
    super(props);
    this.state = {
      productsInProject: [],
      project: props.location.state ? props.location.state.category : props.location.pathname.split("/")[1],
      option: {},
    };
    this.initiateLoadedProducts = this.initiateLoadedProducts.bind(this);
    this.onOptionChange = this.onOptionChange.bind(this);
  }

  initiateLoadedProducts(products) {
    const project_url = this.state.project;
    let temp_product_array = [];
    if (products) {
      if (categories[project_url]) {
        for (let c of categories[project_url].products) {
          for (let p of products) {
            if (c === p.handle) {
              temp_product_array.push(p);
              break;
            } else if (c.image) {
              temp_product_array.push(c);
              break;
            }
          }
        }
      }

      //directly fromm shopify
      for (let p of products) {
        if (decodeURIComponent(project_url) === p.handle) {
          temp_product_array.push(p);
          break;
        }
      }

      // for setting default option:
      let defaultOptionValues = {};
      if (temp_product_array.length > 0) {
        temp_product_array[0].options.forEach((selector) => {
          defaultOptionValues[selector.name] = selector.values[0].value;
        });
      }

      const { checkout } = this.props.fetchCheckoutReducer;
      if (!this.state.productsInProject.length && checkout) {
        const product = temp_product_array[0];
        const variant = product.variants[0];
        TagManager.dataLayer({
          dataLayer: {
            event: 'viewProduct',
            ProductViewed: {
              id: (product.id).split('/').pop(),
              handle: product.handle,
              name: product.title,
              price: variant.price?.amount,
              brand: product.vendor,
              category: product.productType,
              variant: variant.title,
              quantity: 1,
              currencyCode: checkout.currencyCode,
            },
          },
        });
      }
      
      this.setState({
        productsInProject: temp_product_array,
        option: defaultOptionValues,
      });
    }
  }

  onOptionChange(option) {
    this.setState({
      option,
    });
  }

  componentDidMount() {
    let color = getUrlParameter('color');
    this.onOptionChange({ Color: color });
  }

  componentWillMount() {
    this.initiateLoadedProducts(this.props.fetchProductsReducer.products);
  }

  componentWillReceiveProps(nextProps) {
    this.initiateLoadedProducts(nextProps.fetchProductsReducer.products);
    let color = getUrlParameter('color');
    this.onOptionChange({ Color: color });
  }

  componentDidUpdate(prevProps, prevState) {
    const { checkout } = this.props.fetchCheckoutReducer;
    const { productsInProject } = this.state;
    const product = productsInProject[0];
    if ((!prevState.productsInProject.length || !prevProps.checkout) && product && checkout) {
      const variant = product.variants[0];
      TagManager.dataLayer({
        dataLayer: {
          event: 'viewProduct',
          ProductViewed: {
            id: (product.id).split('/').pop(),
            handle: product.handle,
            name: product.title,
            price: variant.price?.amount,
            brand: product.vendor,
            category: product.productType,
            variant: variant.title,
            quantity: 1,
            currencyCode: checkout.currencyCode,
          },
        },
      });
    }
  }

  render() {
    const { productsInProject, option } = this.state;
    const { match } =  this.props;
    const { url } = match;
    const p = productsInProject[0];

    return (
      <div className={`General web-page ${p && p.handle ? `web-page_${p.handle}` : ""}`}>
        <Helmet>
          <title>{`FEASTON - ${p && p.title ? p.title : 'Product'}`}</title>
          <meta property="og:title" content={`FEASTON - ${p && p.title ? p.title : 'Product'}`} />
          <meta property="og:image" content={p && p.images.length > 0 ? p.images[0].src : ""} />
          <meta property="og:description" content={p && p.description ? p.description : ""} />
          <link rel="canonical" href={`https://feaston.co${url}`} />
        </Helmet>
        {p ? <SchemaOrg p={p} url={url} /> : null}
        <style jsx>{`
          .General {
            display: flex;
            padding-left: 100px;
            justify-content: start;
          }
          .General div {
            overflow-y: unset;
          }
          .General .General-mid {
            font-weight: bold;
            font-size: 28px;
            display: unset;
          }
          .General .General-mid .General-scroll-mid {
            display: flex;
            align-items: center;
            justify-content: center;
            height: auto;
            min-height: 100vh;
          }
          .General .General-mid .with_margin_bottom {
            margin-bottom: 50px;
          }
          .General .General-mid .General-scroll-mid.General-scroll-mid-product-img {
            padding: 0 125px;
            min-height: calc(100vh - 80px);
          }
          .General .General-mid > div {
            // height: 100% !important;
          }
          .General .General-mid img {
            width: 100%;
            max-height: 100%;
            object-fit: cover;
          }
          .web-page_andy-lau-lighter .web-mid .General-scroll-mid {
            min-height: calc(100vh - 50px);
          }
          .web-page_andy-lau-lighter .web-mid .General-scroll-mid img {
            max-height: calc(100vh - 50px);
            object-fit: contain;
          }
        `}</style>
        <div className="web-mid General-mid">
          {/* <ReactPageScroller ref={c => this._pageScroller = c}> */}
          {p && p.images.length > 0 ?
            (this.state.option.Color ? p.images.filter((img) => { return img.altText ? !img.altText.toLowerCase().includes(`#ig`) && (img.altText.includes(`#always_in_detail_page`) || (img.altText.includes(`#color_${this.state.option.Color}`) && !img.altText.includes(`#not_in_detail_page`))) : true } ) : p.images).map((image, index) => {
              return (
                <div key={image.src} className={`General-scroll-mid General-scroll-mid-product-img${index === p.images.length - 1 ? ' with_margin_bottom' :''}`}>
                  {p ? <img src={image.src} alt="General_tee" /> : null}
                </div>
              )
            }) : null}
          {/* </ReactPageScroller>   */}
        </div>
        <PanelRight>
          <div className="PanelRight-productWidget">
            {productsInProject && productsInProject.length > 0 ? (
              <ProductWidgetGeneral
                product={p}
                onOptionChange={this.onOptionChange}
              />
            ) : null}
          </div>
        </PanelRight>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  ...state
})

export default withRouter(connect(mapStateToProps, {}) (ProjectGeneral));
