import React, { Component } from 'react';
import { withCookies } from 'react-cookie';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import { isMobile, browserName, mobileVendor, mobileModel, deviceType, osName } from "react-device-detect";
import PORTRAIT11 from './portraits/joe.png';
import PORTRAIT12 from './portraits/arthur.png';
import PORTRAIT16 from './portraits/nerve.png';
import PORTRAIT15 from './portraits/jaspoer.png';
import PORTRAIT14 from './portraits/laiki.png';
import PORTRAIT13 from './portraits/terence.png';
import PORTRAIT04 from './portraits/cheuk.png';
import PORTRAIT01 from './portraits/travis.png';
import PORTRAIT02 from './portraits/karen.png';
import PORTRAIT03 from './portraits/winkey.png';
import PORTRAIT05 from './portraits/vv.png';
import PORTRAIT06 from './portraits/kam.png';
import PORTRAIT07 from './portraits/rachel.png';
import PORTRAIT08 from './portraits/ngLung.png';
import PORTRAIT09 from './portraits/ango.png';
import PORTRAIT10 from './portraits/88.png';

const portraitsArray = [
  PORTRAIT01, PORTRAIT02, PORTRAIT03, PORTRAIT04,
  PORTRAIT05, PORTRAIT06, PORTRAIT07, PORTRAIT08,
  PORTRAIT09, PORTRAIT10, PORTRAIT11, PORTRAIT12,
  PORTRAIT13, PORTRAIT14, PORTRAIT15, PORTRAIT16,
]

class PortraitPopup extends Component {

  constructor(props) {
    super(props);
    this.handleClose = this.handleClose.bind(this);
  }

  handleClose() {
    const { onClose } = this.props;
    if (onClose) {
      onClose();
    }
  }

  render() {
    const { portrait, show, customMargin } = this.props;
    if (!portraitsArray[portrait] || !show ) {
      return null;
    }
    return (
      <div className="portraitPopup bg-white">
        <style jsx>{`
          .portraitPopup .popup-trigger-button {
            text-decoration: underline;
            cursor: pointer;
          }
          .MuiDialog-paper {
            margin: ${customMargin || '32px'};
          }
        `}</style>
        <Dialog onClose={this.handleClose} aria-labelledby="customized-dialog-title" open={show}>
          <div className="portraitPopup-dialog">
            <img style={{ maxWidth:"100%" }} src={portraitsArray[portrait]} alt="" />
          </div>
        </Dialog>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  ...state
})

export default withCookies(connect(mapStateToProps) (PortraitPopup));
