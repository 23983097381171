import React, { Component } from 'react';
import { connect } from 'react-redux';
import ScrollManager from "ScrollManager/index.jsx";
import { Helmet } from "react-helmet";
import STOCKIST_IMG_1 from "assets/Stockists/DN4.jpg";
import STOCKIST_IMG_2 from "assets/Stockists/infree.png";
import STOCKIST_IMG_3 from "assets/Stockists/whitenoise.png";

function MobileStockist() {
  return (
    <div className="MobileStockist web-page">
      <style jsx>{`
        .MobileStockist {
          padding-bottom: 60px;
        }
        .MobileStockist .stockist {
          width: 35%;
          margin: auto;
          padding-top: 10px;
        }
        .MobileStockist img {
          width: 100%;
          padding-bottom: 30px;
        }
      `}</style>
      <div>
        <div className="stockist stockist1">
          <a href="https://www.instagram.com/doors_and_fours/" target="blank" >
            <img src={STOCKIST_IMG_1} alt="" />
          </a>
        </div>
        <div className="stockist stockist2">
          <a href="https://www.instagram.com/infree.records/" target="blank" >
            <img src={STOCKIST_IMG_2} alt="" />
          </a>
        </div>
        <div className="stockist stockist3">
          <a href="https://www.instagram.com/white_noise_records/" target="blank" >
            <img src={STOCKIST_IMG_3} alt="" />
          </a>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  ...state
})

export default connect(mapStateToProps, {})(MobileStockist);
