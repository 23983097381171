import React, { Component } from 'react';
import { connect } from 'react-redux';
import ScrollManager from "ScrollManager/index.jsx";
import { Helmet } from "react-helmet";
import ADDRESS_IMG from "_Mobile/scenes/MobileContact/kingsfordAddress.png";

function MobileAddress() {
  return (
    <div className="MobileAddress">
      <style jsx>{`
        .MobileAddress {
          padding-top: 220px !important;
          padding-bottom: 60px;
        }
        .MobileAddress .address {
          width: 70%;
          margin: auto;
          padding-top: 20px;
        }
        .MobileAddress img {
          width: 100%;
          padding-bottom: 50px;
        }
      `}</style>
      <div>
        <a target="_blank" href={"http://maps.google.com/?q=Kingsford Industrial Centre, 13 Wang Hoi Road, Kowloon Bay, Hong Kong"} rel="noreferrer">
          <div className="address">
            <img src={ADDRESS_IMG} alt="" />
          </div>
        </a>
      </div>

    </div >
  )
}

const mapStateToProps = state => ({
  ...state
})

export default connect(mapStateToProps, {})(MobileAddress);
