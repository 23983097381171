import { useNavigate, useMatch, useLocation } from 'react-router-dom';

export const withRouter = (Component) => {
  const Wrapper = (props) => {
    const navigate = useNavigate();
    // const match = useMatch('/*/*');
    const location = useLocation();
    const match = {
      url: location?.pathname,
    };
    return (
      <Component
        navigate={navigate}
        match={match}
        location={location}
        {...props}
        />
    );
  };
  
  return Wrapper;
};