import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withRouter } from 'withRouter';
import MobileProductWidgetGeneral from '_Mobile/scenes/MobileProduct/ProductWidgetGeneral';
import { getCoverImg } from 'functions.js';

function createElementFromHTML(htmlString) {
  var div = document.createElement('div');
  div.innerHTML = htmlString.trim();
  // Change this to div.childNodes to support multiple top-level nodes
  return div; 
}

class Home extends Component {

  constructor(props) {
    super(props);
    this.state = {
      option: {},
    };
    this.onOptionChange = this.onOptionChange.bind(this);
  }

  onOptionChange(option) {
    this.setState({
      option,
    });
  }

  render() {
    const { pickUpOnly, product, isIframe } = this.props;
    const { option } = this.state;
    const { handle } = product;
    // const product = this.getProductFromUrl(url);
    let descriptionHtml = null;
    let areaHTML = null;
    if (product) {
      ({ descriptionHtml } = product);
    }
    if (descriptionHtml) {
      const areaHTMLs = [...createElementFromHTML(descriptionHtml).getElementsByTagName("AREA")];
      areaHTML = option.Color ? areaHTMLs.find(a => a.alt=option.Color) : areaHTMLs[0];
    }
    
    // temp
    areaHTML = null;
    return (
      <div className="homeBanner-item-container">
        
        <style jsx>{`
          .homeBanner-item-container .homeBanner {
            max-width: 100%;
          }
          .homeBanner-item-container .homeBanner img{
            width: 100%;
          }
          .homeBanner-item-container .homeBanner .banner, map area{
            outline: none;
          }
          .homeBanner-item-container .homeBanner-container {
            overflow-y: scroll;
            height: fit-content;
          }
          .homeBanner-item-container .homeBanner {
            overflow: hidden;
          }
          .homeBanner-item-container map {
            overflow: hidden;
          }
          .homeBanner-item-container {
            height: 100%;
            //min-height: 100vh;
            //margin-bottom: 170px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0 30px;
            flex-direction: column;
          }
          .homeBanner-item-container .homeBanner-item-name {
            width: 100%;
            text-align: center;
            margin-top: 40px;
            font-size: 14px;
            font-weight: bold;
          }
        `}</style>
        {areaHTML ? (
          <img className="homeBanner product-item" src={getCoverImg(product, option.Color).src} useMap={`#image-map_${handle}`} alt={getCoverImg(product, option.Color).altText || ""}/>
        ) : (
          isIframe ? (
            <Link to={`/${handle}`} target="_blank" rel="noopener noreferrer">
              <img className="homeBanner product-item" src={getCoverImg(product, option.Color).src} useMap={`#image-map_${handle}`} alt={getCoverImg(product, option.Color).altText || ""}/>
            </Link>
          ) : (
            <Link to={`/${handle}`}>
              <img className="homeBanner product-item" src={getCoverImg(product, option.Color).src} useMap={`#image-map_${handle}`} alt={getCoverImg(product, option.Color).altText || ""}/>
            </Link>
          )
        )}
        <map name={`image-map_${handle}`}>
          {isIframe ? (
            <Link to={`/${handle}`} target="_blank" rel="noopener noreferrer">
              {areaHTML ? <span dangerouslySetInnerHTML={{
                __html: areaHTML.outerHTML
              }} /> : null}
            </Link>
          ) : (
            <Link to={`/${handle}`}>
              {areaHTML ? <span dangerouslySetInnerHTML={{
                __html: areaHTML.outerHTML
              }} /> : null}
            </Link>
          )}
        </map>
        <MobileProductWidgetGeneral product={product} onOptionChange={this.onOptionChange} isHomeWidget pickUpOnly={pickUpOnly} isIframe />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  ...state
})

export default withRouter(connect(mapStateToProps, {}) (Home));
