import React, { Component } from 'react';
import { connect } from 'react-redux';
import ScrollManager from "ScrollManager/index.jsx";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import FW1986 from "./FW1986.png";
import PIE from "./pie_desktop.png";
import FeatureCollection from 'components/FeatureCollection';

function GreatReset(props) {
  const { fetchFeatureCollectionReducer } = props;
  const { collection } = fetchFeatureCollectionReducer;
  if (!collection) {
    return (
      <div>
        <style jsx>{`
          .HeaderCart {
            display: none;
          }
          .Navigation .NavigationOptions {
            display: none;
          }
        `}</style>
      </div>
    )
  } else if (collection?.products?.length > 0) {
    const banner = collection.metafields?.find(
      (metafield) => metafield?.key === "collection_banner_url_desktop"
    )?.value;
    const bannerTo = collection.metafields?.find(
      (metafield) => metafield?.key === "collection_banner_link_path"
    )?.value;
    return (
      <>
        {bannerTo ? (
          <Link to={bannerTo}>
            <div>
              <img style={{ width: '634px', height: 'auto' }} src={banner} alt="" />
            </div>
          </Link>) : (
            banner ? (
              <div>
                <img style={{ width: '634px', height: 'auto' }} src={banner} alt="" />
              </div>
            ) : null
          )}
        <FeatureCollection />
      </>
    )
  }
  return (
    <div className="GreatReset">
      <style jsx>{`
        .App, .NavOptions, .NavLogo {
          background-color: #201c42 !important;
        }
        .App .Navigation .header {
          filter: invert(1);
        }
        .option_shop {
          display: none;
        }
        .GreatReset {
          width: 100%;
          display: flex;
          align-items: flex-end;
          justify-content: center;
          text-align: left;
        }
        .GreatReset img {
          width: 100%;
          height: auto;
          max-width: 1480px;
        }
        .GreatReset .text {
          padding-bottom: 80px;
        }
        .HeaderCart {
          display: none;
        }
      `}</style>
      <img src={FW1986} alt="" />
    </div>
  )
}

const mapStateToProps = state => ({
  ...state
})

export default connect(mapStateToProps, {})(GreatReset);
