import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'withRouter';
import categories from 'data/categories';
import N796_LOGO from 'assets/N796/n796Logo.png';
import N796_BACKGROUND from 'assets/N796/n796Background.png';
import N796PRODUCT from 'assets/N796/n796Product.png';
import ReactPageScroller from "react-page-scroller";
import ProductWidgetN796 from '_Mobile/scenes/MobileProduct/ProductWidgetN796';
import MobileDescContainer from '../../../components/MobileDescContainer';
import MobileProductPathContainer from '_Mobile/components/MobileProductPathContainer';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Helmet } from "react-helmet";
import { SchemaOrg } from 'components/SchemaOrg';

class N796 extends Component {

  constructor(props) {
    super(props);
    this.state = {
      productsInProject: [],
      project: "n796",
      activeSlide: 0,
    };
    this.initiateLoadedProducts = this.initiateLoadedProducts.bind(this);
    this.nextSlide = this.nextSlide.bind(this);
    this.prevSlide = this.prevSlide.bind(this);
  }

  initiateLoadedProducts(products) {
    const project_url = this.state.project;
    let temp_product_array = [];
    if (products) {
      for (let c of categories[project_url].products) {
        for (let p of products) {
          if (c === p.handle) {
            temp_product_array.push(p);
            break;
          } else if (c.image) {
            temp_product_array.push(c);
            break;
          }
        }
      }
      this.setState({
        productsInProject: temp_product_array,
      })
    }
  }

  nextSlide() {
    this.refs.slickSlider.slickNext();
  }

  prevSlide() {
    this.refs.slickSlider.slickPrev();
  }

  componentWillMount() {
    this.initiateLoadedProducts(this.props.fetchProductsReducer.products);
  }

  componentWillReceiveProps(nextProps) {
    this.initiateLoadedProducts(nextProps.fetchProductsReducer.products);
  }

  render() {
    const { productsInProject, activeSlide } = this.state;
    const SlickNextArrow = (props) => {
      return (
        <div
          className={`Mobile_n796_slick_button Mobile_n796_slick_button_next${activeSlide === p.images.length - 1 ? " slick_button_display_none" : ""}`}
          onClick={this.nextSlide}
        >
          <div class="inner-slick-button inner-next"/>
        </div>
      );
    }
    const SlickPrevArrow = (props) => {
      return (
        <div
          className={`Mobile_n796_slick_button Mobile_n796_slick_button_prev${activeSlide === 0 ? " slick_button_display_none" : ""}`}
          onClick={this.prevSlide}
        >
          <div class="inner-slick-button inner-prev"/>
        </div>
      );
    }
    const slickSettings = {
      nextArrow: <SlickNextArrow />,
      prevArrow: <SlickPrevArrow />,
      beforeChange: (current, next) =>
        this.setState({ activeSlide: next }),
    };

    const { match } =  this.props;
    const { url } = match;
    const p = productsInProject[0];
    let splitedHtmlArray = productsInProject && productsInProject.length > 0 ? p.descriptionHtml.split(/(?=<p)/) : [""];
    
    return (
      <div className="N796 Product web-page">
        <Helmet>
          <title>{`FEASTON - ${p && p.title ? p.title : 'Product'}`}</title>
          <meta property="og:title" content={`FEASTON - ${p && p.title ? p.title : 'Product'}`} />
          <link rel="canonical" href={`https://feaston.co${url}`} />
        </Helmet>
        {p ? <SchemaOrg p={p} url={url} /> : null}
        <style jsx>{`
          .N796 {
            height: calc(100vh - 61px);
          }
          .N796 div {
            overflow-y: unset;
          }
          .N796 .N796-mid {
            font-weight: bold;
            display: unset;
            position: unset;
            top: 0;
            height: calc(100vh - 40px);
          }
          /*
          .N796 .N796-mid .n796-scroll-mid {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .N796 .N796-mid .n796-scroll-mid.n796-scroll-mid-product-img {
            padding: 0 60px;
          }*/
          .N796 .N796-mid img {
            width: 100%;
            max-height: 100%;
            object-fit: cover;
          }
          .N796 .Top-item {
            flex: 0 0 auto;
            align-items: center;
            display: flex;
          }
          .N796 .Top-item img{
            width: unset;
            max-height: unset;
            object-fit: unset;
          }
          .N796 .N796-logo {
            margin: 0 20px;
            margin-right: 30px;
          }
          .N796 .N796-logo img {
            width: 130px;
          }
          .N796 .N796-desc {
            text-align: left;
            width: 200px;
            padding-right: 20px;
            font-size: 14px;
          }
          .N796 .N796-desc.N796-desc-img{
            text-align: unset;
            width: unset;
          }
          .N796 .slick-track {
            display: flex;
            align-items: center;
          }
          /* .N796 .Mobile_n796_slick_button {
            background: transparent;
            position: absolute;
            top: calc(50% - 5px);
            z-index: 1;
            width: 0; 
            height: 0;
            border-top: 16px solid transparent;
            border-bottom: 16px solid transparent;
          }
          .N796 .inner-slick-button {
            position: relative;
            top: -12px;   
            width: 0;
            height: 0;
            border-top: 12px solid transparent;
            border-bottom: 12px solid transparent;
          } */
          .N796 .Mobile_n796_slick_button.Mobile_n796_slick_button_prev {
            left: 10px;
            border-right: 16px solid black;
          }
          .N796 .Mobile_n796_slick_button.Mobile_n796_slick_button_next {
            right: 10px;
            border-left: 16px solid black;
          }
          .N796 .inner-slick-button.inner-prev {
            left: 4px;
            border-right: 11px solid white;
          }
          .N796 .inner-slick-button.inner-next {
            right: 14px;
            border-left: 11px solid white;
          }
          .N796 .Mobile_n796_slick_button.slick_button_display_none {
            display: none;
          }
          .N796 .n796-scroll-mid .img-not-last{
            width: 80%;
            margin: auto;
            padding-top: 10px;
          }
        `}</style>
        <div className="mobile-web-mid N796-mid Product-mid">
          <MobileDescContainer page="product">
            <div className="Top-item N796-logo">
              <img src={N796_LOGO} alt="N796" />
            </div>
            {splitedHtmlArray.map((html) => {
              return (
                <div className={`Top-item N796-desc ${html.includes("<img") ? "N796-desc-img" : ""}`} dangerouslySetInnerHTML={{__html: html}}/> 
              )
            })}    
            {/* <div className="Top-item N796-desc">
              N796 is an
              on-going graphic
              project started in 2018. All graphics
              in this project
              are co-created in a 
              connected virtual 
              space. Within this virtual space, each participant has the freedom to include
              any new imagery,
            </div>
            <div className="Top-item N796-desc">
              amend and exclude any existing imagery while all amendments 
              are visible to all 
              participants.<br/>
              <br/>
              Selected graphics from N796 are now available as single print editions.<br/>
              <br/>
              Navigate N796 with arrow keys or view specific prints by clicking on the numbered code. 
            </div> */}
          </MobileDescContainer>
          <Slider {...slickSettings} ref="slickSlider">
            {/* <div className="n796-scroll-mid n796-scroll-mid-product-img">
               // <img src={p ? p.images[0].src : null} alt="N796_tee" />
              {p ? <img src={ N796PRODUCT} alt="N796_tee" /> : null}
            </div>
            <div className="n796-scroll-mid">
              <img src={N796_BACKGROUND} alt="N796_background" />
            </div> */}
            {p ? p.images.map((img, index) => {
              return (
                <div className="n796-scroll-mid" key={img.src}>
                  <img className={`${index < p.images.length - 1 ? "img-not-last" : ""}`} src={img.src} alt="N796" />
                </div>
              )
            }) : null }
          </Slider>

          <div className="mobile-productPage-productWidget">
            <MobileProductPathContainer withPath>
              <div className="PanelRight-productWidget">
                {productsInProject && productsInProject.length > 0 ? (
                  <ProductWidgetN796
                    product={p}
                  />
                ) : null}
              </div>
            </MobileProductPathContainer>

          </div>
        </div>
        
      </div>
    );
  }
}

const mapStateToProps = state => ({
  ...state
})

export default withRouter(connect(mapStateToProps, {}) (N796));
