import React, { Component } from 'react';
import { client } from 'shopify';
import { withCookies } from 'react-cookie';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import { isMobile, browserName, mobileVendor, mobileModel, deviceType, osName } from "react-device-detect";
import SIZE_CHART from './measurements.png';
import SIZE_CHART_HOODIE_SWEATER from './measurements_hoodie.png';

class SizeChartPopup extends Component {

  constructor(props) {
    super(props);
    this.state = {
      dialogOpen: false,
      isButtonHovered: false,
    };
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleClickOpen() {
    this.setState({
      dialogOpen: true,
    });
  }

  handleClose() {
    this.setState({
      dialogOpen: false,
    });
  }

  render() {
    const { dialogOpen, isLoading, isButtonHovered, contact } = this.state;
    const { children, productType } = this.props;
    const product_type = productType.toLowerCase();
    const isWithPopup = product_type === "tee" || product_type === "hoodie" || product_type === "sweater";
    let chart_img = SIZE_CHART;
    if (product_type === "hoodie" || product_type === "sweater") {
      chart_img = SIZE_CHART_HOODIE_SWEATER;
    }
    
    return isWithPopup ? (
      <div className="sizeChartPopup bg-white">
        <style jsx>{`
          .sizeChartPopup .popup-trigger-button {
            text-decoration: underline;
            cursor: pointer;
          }
          .sizeChartPopup-dialog {
            padding: 12px;
          }
          .sizeChartPopup-dialog .dialog-button {
            text-align: center;
            margin-top: 8px;
          }
          .sizeChartPopup-dialog .dialog-button button b {
            display: flex;
            width: 80px;
            margin: auto;
            align-items: center;
            justify-content: center;
          }
          .sizeChartPopup-dialog button {
            font-size: 16px;
            background: black;
            color: white;
            border-radius: 4px;
            border: 1px solid black;
            cursor: pointer;
            outline: none;
          }
        `}</style>
        <div className="popup-trigger-button" onClick={this.handleClickOpen}>
          {children}
        </div>
        <Dialog onClose={this.handleClose} aria-labelledby="customized-dialog-title" open={dialogOpen}>
          <div className="sizeChartPopup-dialog">
            <img style={{ maxWidth:"100%" }} src={chart_img} alt="" />
        
            <div className="dialog-button" >
              <button className="" onClick={this.handleClose}>
                <b>CLOSE</b>
              </button>
            </div>
          </div>
        </Dialog>
      </div>
    ) : children
  }
}

const mapStateToProps = state => ({
  ...state
})

export default withCookies(connect(mapStateToProps) (SizeChartPopup));
